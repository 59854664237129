import { DepenseParPoste, getPricesWithSubscription } from './bilanEnergieService';
import { DUREE_PROJETCTION_FINANCIERE } from './calculEconomie';
import { InstallationPV, energieAutoconsommee } from './installationPV';
import * as api from '../apiParticulierService';
import { stepListToAuditAndClient } from '../localStorageService';
import * as storageService from '../localStorageService';
import { PackageData } from './package';

const ModeConsommationNames = ['Autoconsommation', 'ReventeTotale', 'ReventeSurplus'] as const;
export type ModeConsommation = (typeof ModeConsommationNames)[number];

export const PV_MICRO_ONDULEUR_MIDFIX = '-MO-';
export const PV_MICRO_ONDULEUR_PREFIX = 'MO-';
export const PV_MICRO_ONDULEUR_SUFFIX = '-MO';

/**
 * Le panneau, avec les caractéristiques minimale pour le calcul de l'économie d'énergie.
 */
export type PanneauPhotovoltaique = {
    puissance: number;
    nombrePanneau: number;
    efficacite: number;

    // tauxAutoconsommation: number;
    //modeConsommation: ModeConsommation;
    prixAvecAide?: number;
};

export const getPanneauFromPackage = (pvPackage: PackageData): PanneauPhotovoltaique => {
    const microOnduleur: boolean = pvPackage.reference
        ? pvPackage.reference?.includes(PV_MICRO_ONDULEUR_MIDFIX) ||
          pvPackage.reference?.includes(PV_MICRO_ONDULEUR_PREFIX) ||
          pvPackage.reference?.includes(PV_MICRO_ONDULEUR_SUFFIX)
        : false;

    const product = pvPackage.mainProduct;
    // pour le mode :
    // si le gain est undefined, on considère que c'st revente en surplus
    // si c'est 0, c'est revente totale,
    // sinon auto conso
    return {
        nombrePanneau: product.quantite,
        puissance: product.puissance ? product.puissance * product.quantite : 0,
        efficacite: microOnduleur ? storageService.CONSTANTES_PHOTOVOLTAIQUES.efficaciteMicroOndulee : storageService.CONSTANTES_PHOTOVOLTAIQUES.efficacite,
        // tauxAutoconsommation: pvProduct.gain ?? 0,
        //modeConsommation: pvProduct.modeConsommation,
    };
};
/**
 * Dans cette classe, en mise à jour d'une version de Septembre 2022. On adment que le panneau peut être null.
 * Et on refait tous les calculs en tenant compte de ce fait.
 */
export class SimulationPhotovoltaique {
    /**
     * Construit un simulateur photovoltaique.
     * @param depenseInitiale selon la dépense énergétique intiiale (après les autres travaux, mais avant installation PV)
     * @param installation les caractéristiques prévisionnelle de l'installation (ensoleillement, inclinaison ...)
     */
    constructor(depenseInitiale: DepenseParPoste, installation: InstallationPV | undefined) {
        this.depenseInitiale = depenseInitiale;
        this.installation = installation;

        // Si pas de mode, on prend le defaut : revente en surplus
        const mode: ModeConsommation = this.installation?.modeConsommation ?? 'ReventeSurplus';
        // le taux de l'electricité produite par le panneau qui sera auto consommée.
        // Si undefined, on considère qu'on est dans le cas 'revente du surplus'
        let targetTauxAutoConso: number;

        // Ici, on fait le calcul le plus important, celui tu taux d'autoconsommation.

        // Etape 1 : calcul de la consigne d'autoconsommation
        // voici la règle :
        // - si mode Autoconsommation => Hardcoded 70% (le max possible)
        // - si revente en surplus => utilise la paramètre targetTauxAutoConso (qui vient du selecteur UI.)
        // - si revente totale => 0

        switch (mode) {
            case 'ReventeTotale':
                targetTauxAutoConso = 0;
                break;
            case 'Autoconsommation':
                targetTauxAutoConso = storageService.CONSTANTES_PHOTOVOLTAIQUES.tauxAutoconsommationMax_B2C;
                break;
            case 'ReventeSurplus':
                // Tous les taux d'autoconsommation _doivent_ être relatif à ma dépense après travaux.
                // Le taux selectionné dans l'interface dans le cas de revente en surplus est relatif à ce que le panneau produit !
                // Il faut donc le ramener à un taux comparable :
                // Par proportion avec la dépense initiale :
                const consoElecHorsAbo = this.depenseInitiale.consoElectricite();
                // valeur par defaut, initialisée avec 70%, comme pour autoconsommation
                targetTauxAutoConso = storageService.CONSTANTES_PHOTOVOLTAIQUES.tauxAutoconsommationMax_B2C;
                if (consoElecHorsAbo !== 0 && this.installation !== undefined) {
                    // la qté d'energie avec le taux cible est :
                    // E = this.installation.panneau.tauxAutoconsommation * this.installation?.maxProd()
                    // le taux d'autoconsommation avec cette énergie est E / elecInitHorsAbo
                    //console.log('installation = ' + JSON.stringify(this.installation.energieAutoconsommee));
                    targetTauxAutoConso = energieAutoconsommee(this.installation) / consoElecHorsAbo;
                }

                break;
        }

        // Ensuite, par rapport à la consigne, on calcule le taux optimal
        this.tauxAutoConsommation = this.getAutoConsoOptimal(targetTauxAutoConso);
        //console.log('panneau ' + this.installation?.panneau?.puissance + 'w  taux autoconso ' + targetTauxAutoConso + ' => ' + this.tauxAutoConsommation);
    }

    depenseInitiale: DepenseParPoste;
    tauxAutoConsommation: number;
    installation: InstallationPV | undefined;

    /**
     * Calcule la production maximale, selon le panneau, la zone géographique et la configuration del'installation
     * @returns La production maximale que le panneau pourra produire.
     */
    maxProd = (): number => {
        if (!this.installation) return 0;
        return this.installation.maxProd;
    };

    /**
     * On calcule le taux d'autoconsommation optimal Par rappport à la dépense initiale après travaux (avant PV), en fonction d'une consigne (optionnelle)
     *
     *
     * @param targetTauxAutoConso (optionnel) Le taux d'autoconsommation par rapport à la depense intiale après travaux, (taux cible). Si undefined, on trouve le taux maximum d'autoconsommation possible (on revendra le reste)
     *
     * @returns
     */
    getAutoConsoOptimal = (targetTauxAutoConso?: number): number => {
        // ATTENTION : il ne faut pas confondre :
        //  1) le taux d'autoconsommation, relatif à la consommation du foyer
        //  2) le taux d'autoconsommation, relatif a la partie produite par le panneau solaire qu'on va effectivement auto-consommer
        // le taux d'autoconsommation 1 ne pas dépasser 70% de la dépense initiale après travaux.(CONSTANTES_PHOTOVOLTAIQUES.tauxAutoconsommationMax)
        // Parce que le panneau ne produit rien la nuit (alors qu'on continue de consommer du chaufage par exemple), donc en moyenne on ne peut pas autoconsommer 100%, seulement 70% MAX.
        //
        // Alors que on peut consommer tout ce que le panneau produit, un panneau sous-dimentionné par exemple, tout ce qu'il produit est immédiatement injecté et consommé.

        // REGLE METIER (au risque de se répeter)
        // Le taux d'autoconsommation (1) ne pas dépasser 70% de la dépense initiale après travaux.
        // (le taux d'autoconsommation (1) * max prod du panneau ) ne peut pas dépasser ce que le panneau peut produire
        // (le taux d'autoconsommation (2) * max prod du panneau ) ne peut pas dépasser 70% de la dépense initiale après travaux.

        // Si panneau est undefined, maxprod = 0
        const maxprod = this.maxProd();
        //console.log('installation : ' + (this.installation?.panneau?.puissance ?? 0) + 'K   max prod ' + maxprod * 0.1582 + '€');
        const depenseInitiale = this.depenseInitiale.consoElectricite();
        if (depenseInitiale === 0) {
            // n'arrive pas, il faudrait que le type n'ait pas le courant, mais on veut quand même éviter la division par zéro
            // de toutes façon, si la production max est a zero, quelque soit le taux d'autoconsommation, ca fera toujours 0.
            // console.log("taux d'autoconsomation optimal = 0 (maxprod 0)");
            return 0;
        }

        const TauxAutoconsomationSiMaxProd = maxprod / depenseInitiale;
        // on prend le plus petit des 3 taux : ca fait plafond.
        // Pourquoi :
        // - on ne veut pas auto consommer plus que ce qu'on se fixe : target.
        // - on ne veut pas auto consommer plus que le max : CONSTANTES_PHOTOVOLTAIQUES.tauxAutoconsommationMax
        // - on ne peut pas auto consommer plus que ce que produit le PV : tauxMaxProd
        // note si targetTauxAutoconso est undefined, on prend 1, sur qu'il ne sera aps le plus petit.
        let target: number = targetTauxAutoConso === undefined ? 1 : targetTauxAutoConso;
        const tauxPlafonne = Math.min(TauxAutoconsomationSiMaxProd, storageService.CONSTANTES_PHOTOVOLTAIQUES.tauxAutoconsommationMax_B2C, target);
        return tauxPlafonne;
    };

    /**
     * réalise le calcul des gains, économies, revente, puissances, pour ce panneau, POUR LA PREMIERE ANNEE
     * @returns Un bilan complet.
     */
    bilan = (): BilanPhotovoltaique | undefined => {
        // si on a pas de panneau ... pas de panneau
        if (this.installation === undefined || this.installation.panneau === undefined) {
            return undefined;
        }
        //const SHOW_DEBUG = process.env.REACT_APP_MODE_DEBUG === 'true'; // apparaît que si on est en debug
        const SHOW_DEBUG = false;

        const consoInitialElec = this.depenseInitiale.consoElectricite();
        const coutInitialElec = this.depenseInitiale.prixElectricite(); // (avec abonnement)

        // initialisation du résultat
        // donc, on consomme tout ce qu'on peut. Et on revend le reste.
        const result: BilanPhotovoltaique = {
            // La puissance qu'on continue d'acheter chez EDF == C'est ce qu'on achetait avant, moins ce qu'on autoconsomme
            consoHorsPhotovoltaique: consoInitialElec - this.tauxAutoConsommation * consoInitialElec,
            coutHorsPhotovoltaiqueHorsAbo: 0,
            coutHorsPhotovoltaique: 0, // calculé plus tard.
            tauxAutoconsommationEffectif: this.tauxAutoConsommation, // déjà calculée plus haut.
            independence: 0,
            autoconsomationEnergie: this.tauxAutoConsommation * consoInitialElec, // la part autoconsomée de l'énergie
            autoconsomationGain: this.depenseInitiale.prixElectricite(), // Tout, puisqu'on ne consome plus rien chez EDF.
            reventeEnergie: 0,
            reventeGain: 0,
        };
        if (SHOW_DEBUG) {
            console.groupCollapsed('Bilan() ');
            console.log(
                'conso hpv = ' + result.consoHorsPhotovoltaique + ' = ' + consoInitialElec + ' - ' + this.tauxAutoConsommation + ' * ' + consoInitialElec
            );
            console.log('autoconsomationEnergie = ' + result.autoconsomationEnergie + ' = ' + this.tauxAutoConsommation + ' * ' + consoInitialElec);
        }
        // Rappel : On ne peut pas autoconsomer plus de 70%.
        // Il doit rester au moins 30% d'electricité à la fin.
        // si jamais c'est inférieur à 30% de ma consomation initiale, on met à 30% de ma consommation initiale,
        // car le panneau ne peut pas ramener à 0 sans batterie (non implémenté)

        // NORMALEMENT : le taux d'autoconsommation qu'on s'est donné tant de mal a calculer, permet déjà de s'assurer que on ne dépasse pas les limites.
        // Mais bon ceinture et bretelles, et l'historique du projet a montré suffisemment d'erreur de calcul pour qu'on prenne plus de précautions.
        const consoMiniFinale = this.depenseInitiale.consoElectricite() * (1 - storageService.CONSTANTES_PHOTOVOLTAIQUES.tauxAutoconsommationMax_B2C);
        if (result.consoHorsPhotovoltaique < consoMiniFinale) result.consoHorsPhotovoltaique = consoMiniFinale;

        // énergie auto consommée
        result.autoconsomationEnergie = consoInitialElec - result.consoHorsPhotovoltaique;

        // le cout, il faut multiplier cette puissance par le prix unitaire et ajouter le prix de l'abonneemnt.
        result.coutHorsPhotovoltaiqueHorsAbo = result.consoHorsPhotovoltaique * (this.depenseInitiale.electricitePrice?.unitPrice ?? 0);
        result.coutHorsPhotovoltaique = result.coutHorsPhotovoltaiqueHorsAbo + this.depenseInitiale.abonnementElectricite();
        if (SHOW_DEBUG) {
            console.log(
                'cout hpv = ' +
                    result.coutHorsPhotovoltaique +
                    ' = ' +
                    result.coutHorsPhotovoltaiqueHorsAbo +
                    ' + ' +
                    this.depenseInitiale.abonnementElectricite()
            );
            console.groupEnd();
        }

        // Le gain c'est la différence entre ce qu'on payait avant et ce qu'on payera après. (les deux avec abonnement) (ou les deux hors abonnement).
        result.autoconsomationGain = coutInitialElec - result.coutHorsPhotovoltaique;

        // Si on a  de la revente :
        // - on est en RenventeSurplus
        // - on est en revente totale
        // On a pas *le droit* de revendre lorsuq'on est en mode autoconsommation !!
        if (this.installation.modeConsommation !== 'Autoconsommation') {
            // ici, il y aurait plusieurs formules pour calculer la revente.
            // ELLES NE SONT PAS EQUIVALENTES (a cause des cas limites)
            result.reventeEnergie = this.maxProd() - result.autoconsomationEnergie; // l'energie du panneau solaire qu'on a pas autoconsommée

            const prixKwhRevente = getPrixKwhRevente(this.installation.modeConsommation, this.installation.panneau.puissance);
            result.reventeGain = result.reventeEnergie * prixKwhRevente; // fois son prix
        }

        // On risque de diviser par 0 alors on élimine le cas :
        // si jamais on a le coutInitialElec à 0, la divison par zero tend vers l'infi, alors on met le max : 100% (valeur d'initilisation du result.)
        if (coutInitialElec === 0) return result;

        // TODO Mode KWH : le calcul est sans doute faux ici, ca rmélange de taux de KWh différent.
        // NOUVELLE FORMULLE :
        // indep = (autoconsommation la première année + revente la première année) / conso elec  avant travaux
        // version du calcul sur l'energie
        if (consoInitialElec !== 0) result.independence = (result.autoconsomationEnergie + result.reventeEnergie) / consoInitialElec;
        // version du calcul sur le cout
        //if (coutInitialElec !== 0) result.independence = (result.autoconsomationGain + result.reventeGain) / this.depenseInitiale.prixElectriciteHorsAbo();

        // Le pourcentage de gain par rapport à la dépense initiale

        return result;
    };

    /**
     * retourne le calcul à long terme du retour sur invesitissement potovoltaique.
     * @param bilan
     * @param durationYear
     * @returns
     */
    // WARNING, cette fonction N'EST utilisée QUE dans RecapIneligiblePhotovoltaique.tsx
    // A DATE ELLE EST SANS DOUTE FAUSSE.

    calculEconomiePhotovoltaique = (bilan: BilanPhotovoltaique | undefined, durationYear = DUREE_PROJETCTION_FINANCIERE): EconomiePV_LongTerme => {
        console.error('calculEconomiePhotovoltaique OBSOLETE');
        // voir les remarques, mais ce retour est trivial, sans panneau, on paie rien, et on économise rien, et il n'y a pas d'aides.
        if (this.installation === undefined || this.installation.panneau === undefined || bilan === undefined) {
            return {
                autoconso: 0,
                revente: 0,
                prixPanneau: 0,
                totalHorsInstallation: 0,
                moyenneParAn: 0,
            };
        }

        const indexations = storageService.getConfig('indexations');

        const prixKWhRevente = getPrixKwhRevente(this.installation.modeConsommation, this.installation.panneau.puissance);

        let prixKWhAuto = getPrixKwhAutoConso();

        let autoconso_Year0 = bilan.autoconsomationEnergie; // 0 si le panneau est undefined
        let surplus_Year0 = bilan.reventeEnergie; // 0 si le panneau est undefined, ou si autoconommation (sans revente)

        let gainAutoCumul = 0;
        let gainReventeCumul = 0;

        let twentyFirstYears = durationYear;
        if (durationYear > 20) twentyFirstYears = 20;

        let i = 0;
        //CONSTANTES.prixKwhReventeSurplus
        for (i; i < twentyFirstYears; i++) {
            // Les 20 premières années : j'autoconsome,  et je revend le surplus
            gainAutoCumul += autoconso_Year0 * prixKWhAuto * Math.pow(indexations.electricite_autoconsomation, i); // 0 si autoconso_Year0 = 0, == si le panneau est undefined
            gainReventeCumul += surplus_Year0 * prixKWhRevente * Math.pow(indexations.electricite_revente, i); // 0 si surplus_Year0 = 0  == si le panneau est undefined
        }

        if (durationYear > 20) {
            for (i; i < durationYear; i++) {
                // Les années 21 et suivantes : J'autoconsome tout.
                gainAutoCumul += (autoconso_Year0 + surplus_Year0) * prixKWhAuto * Math.pow(indexations.electricite_autoconsomation, i); // 0 si (autoconso_Year0 + surplus_Year0 = 0), == si le panneau est undefined
            }
        }

        const totalHorsInstallation = gainAutoCumul + gainReventeCumul; // 0 si panneau undefined
        return {
            autoconso: gainAutoCumul,
            revente: gainReventeCumul,
            prixPanneau: this.installation.panneau ? this.installation.panneau.prixAvecAide ?? 0 : 0,
            totalHorsInstallation: totalHorsInstallation,
            moyenneParAn: totalHorsInstallation / durationYear,
        };
    };
}

export const getAideInvestissement = async (installation: InstallationPV | undefined): Promise<number> => {
    if (!installation || !installation.panneau) return 0;
    //console.log('modeConsommation = ' + panneau.modeConsommation);
    if (installation.modeConsommation !== 'ReventeSurplus') return 0;

    //const slice = await api.getAideBonus(installation.panneau.puissance / 1000, installation.modeConsommation);
    const slice = await getAideBonusLocal(installation.panneau.puissance / 1000, installation.modeConsommation);
    //console.log('aide WS ' + (slice?.amount ?? 0) + ' /  local = ' + (sliceLocal?.amount ?? 0));

    return installation.panneau.puissance * (slice?.amount ?? 0);
};

/** economie cumulée sur plusieurs années */
export type EconomiePV_LongTerme = {
    /** L'electricité cumulée sur la période qu'on n'achetera plus.(CoutElecInit - CoutElecFinalHorsPv) */
    autoconso: number;
    /** (en pourcentage) part de la prodution max qu'on utilise pas et qu'on revendra direct. */
    revente: number;
    /** prix d'achat initial du panneau,  */
    prixPanneau?: number;
    /** total des gains (autoconso + revente) sur la période*/
    totalHorsInstallation: number;
    /** moeynne anuelle du gain total */
    moyenneParAn: number;
};

export type BilanPhotovoltaique = {
    /** la puissance electrique qu'on continue d'acheter chez EDF */
    consoHorsPhotovoltaique: number;
    /** le cout de l'electricité qu'on continue d'acheter chez EDF. */
    coutHorsPhotovoltaique: number;
    /** le cout de l'electricité qu'on continue d'acheter chez EDF, hors abonnement. */
    coutHorsPhotovoltaiqueHorsAbo: number;
    /** prodMax / ConsoInitiale */
    independence: number;

    /** en pourcentage, le taux d'autoconsommation effectif la première année, potentiellement plus petit que la cible.
     **/
    tauxAutoconsommationEffectif: number;
    /** (en KWh) la partie de la prod max qu'on autoconsome (qu'on n'achete plus)*/
    autoconsomationEnergie: number;
    /** L'electricité qu'on n'achete plus.(CoutElecInit - CoutElecFinalHorsPv) */
    autoconsomationGain: number;
    /** (en KWh) la partie de la prod max qu'on utilise pas et qu'on revend direct. */
    reventeEnergie: number;
    /** combien rapporte le surplus : le suplus * prix du KWh */
    reventeGain: number;
};

export const HARDCODED_DEFAULT_PRIX_REVENTE_9K = 0.13; // Totalement arbitraire, dans l'immédiat.
export const HARDCODED_DEFAULT_PRIX_REVENTE_3K = 0.13; // Totalement arbitraire, dans l'immédiat.

// cherche le rendeement selon la puissance et le mode de consommation dans le fichier investmentBonus.
export const getPrixKwhRevente = (mode: ModeConsommation, power: number): number => {
    // hard coded default : de 0 à 3, retourner 0.50
    //                      de 3 à 9, retourner 0.37
    const def = power / 1000.0 <= 3 ? HARDCODED_DEFAULT_PRIX_REVENTE_3K : HARDCODED_DEFAULT_PRIX_REVENTE_9K;

    const BONUS = storageService.getConfig('investmentBonus');
    if (!BONUS || !Array.isArray(BONUS) || BONUS.length <= 0) {
        console.log('getPrixVente returned default ' + def);
        return def;
    }

    //let log = 'For power : ' + power / 1000.0 + '  / Mode ' + mode + ' => ';
    for (const r of BONUS!) {
        if (r.type === 'TarifAchat' && r.consumptionMode === mode) {
            const result = (r.slices as Array<api.AmountPerSlice>).find((s) => s.thresholdMin < power / 1000.0 && power / 1000.0 <= s.thresholdMax);
            // console.log('result : ' + result?.thresholdMin + ' < ' + power / 1000.0 + ' <= ' + result?.thresholdMax);
            // console.log(log + result?.amount + ' €/Kwh');
            if (result) return result?.amount;
        }
    }
    console.log('getPrixVente returned default ' + def);
    return def;
};

export const getAideBonusLocal = async (power: number, mode: ModeConsommation): Promise<api.AmountPerSlice | undefined> => {
    const BONUS = storageService.getConfig('investmentBonus');
    if (!BONUS || !Array.isArray(BONUS) || BONUS.length <= 0) {
        return await api.getAideBonus(power, mode);
    }

    for (const r of BONUS!) {
        if (r.type === 'PrimeInvestissement' && r.consumptionMode === mode) {
            const result = (r.slices as Array<api.AmountPerSlice>).find((s) => s.thresholdMin < power && power <= s.thresholdMax);
            // console.log('result : ' + result?.thresholdMin + ' < ' + power + ' <= ' + result?.thresholdMax);
            // console.log('PrimeInvestissement, ' + r.modeConsommation + ',amount ' + result?.amount + ' €/Kwh');
            if (result) return result;
        }
    }

    return undefined;
};
// cherche le rendeement selon l'inclinaison et l'orientation, dans le fichier sunshine Yield.
export const getPrixKwhAutoConso = (): number => {
    const audit = stepListToAuditAndClient().audit;
    let abonnementElectricite = +audit.heaterSubscriptionElectricity.value;
    const elecPrices = getPricesWithSubscription('electricity', abonnementElectricite);
    return elecPrices.unitPrice;
};
