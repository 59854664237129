import { CONSTANTES_PHOTOVOLTAIQUES } from '../localStorageService';
import { stepListToAuditAndClient } from '../localStorageService';
import { Writable } from '../tools/TypeHelper';
import { PosteDepense, EnergyTypes } from './calculPreconisationsCore';
import { PackageData, isPV } from './package';
import { isSubThemeType, SubThemeType } from './theme';

export const KnownRootCategoriesNames = ['Photovoltaïque', 'Services', 'Matériel électrique', 'Chauffage', 'Rénovation', 'Autres'] as const;
export type KnownRootCategories = (typeof KnownRootCategoriesNames)[number];
const writableKnownRootCategoriesNames = KnownRootCategoriesNames as Writable<typeof KnownRootCategoriesNames> as Array<string>;

export const KnownParentCategoriesNames = [
    // 'Ampoules LED',
    // 'Assurance',
    // 'Ballon',
    'Panneau',
    'Chauffage',
    'Services',
    'Pompe à chaleur',
    'Rénovation',
    'Matériel électrique',
    'Isolation',
    'Confort',
    'Autres',
] as const;
export type KnownParentCategories = (typeof KnownParentCategoriesNames)[number];
const writableKnownParentCategoriesNames = KnownParentCategoriesNames as Writable<typeof KnownParentCategoriesNames> as Array<string>;

export const KnownCategoriesNames = [
    'Pac Air Eau',
    'Pac Air Air',
    'Ampoules LED',
    'Ballon',
    'VMC',
    "Brasseur d'air",
    'Domotique / Prise conectée',
    'Murs',
    'Planchers',
    'Combles',
    'Rampants',
    'LED',
    'E-connect',
    'Mono Cristalin',
    'Batterie',
    'Pose',
    'Assurance',
    'Pergola Photovoltaïque',
    'Carport Photovoltaïque',
    'Borne de recharge',
] as const;
export type KnownCategories = (typeof KnownCategoriesNames)[number];
const writableKnownCategoriesNames = KnownCategoriesNames as Writable<typeof KnownCategoriesNames> as Array<string>;

export type Categorized = {
    breadcrumb: string;
    categorie_racine: KnownRootCategories | string;
    categorie_parent: KnownParentCategories | string;
    categorie: KnownCategories | string;
};

export type ProduitIcoll = Categorized & {
    product_id: string;
    nom: string;
    desc?: string;
    /** la quantité >0 (supérieure stricte, car une quantité nulle ou négative ne signifie rien) */
    quantite: number;
    /** La quantité recommandée relativement à l'audit */
    quantiteRecommandee?: number;
    /** puissance en KWc (pour le PV) ou en KWh (pour les autres) */
    puissance?: number;
    //wc?: string;
    //modeConsommation: ModeConsommation;
    /**  */
    prix_ht: number;
    tva: number;

    // Données qui vont permettre le calcul de gain d'énergie !
    /** le poste de dépense ou l'on va gagner de la conso grâce a ce produit. */
    cibleGain?: PosteDepense;
    /** Le gain est un pourcentage de gain, que la préco fait gagner sur la cible  du gain.  */
    gain: number;
    /** cible du surcout  */
    cibleSurcout?: EnergyTypes;
    /** le surcout est un pourcentage, de surcout qu'on aura sur la cible du surcout */
    surcout: number;

    // doc
    img_path?: string;
    doc_path?: string;
};

export const isKnownRootCategories = (candidate: string | undefined): candidate is KnownRootCategories => {
    if (!candidate) return false;
    return writableKnownRootCategoriesNames.includes(candidate);
};
export const isKnownParentCategories = (candidate: string | undefined): candidate is KnownParentCategories => {
    if (!candidate) return false;
    return writableKnownParentCategoriesNames.includes(candidate);
};
export const isKnownCategories = (candidate: string | undefined): candidate is KnownCategories => {
    if (!candidate) return false;
    return writableKnownCategoriesNames.includes(candidate);
};
export const isCategories = (candidate: string | undefined): candidate is KnownRootCategories | KnownParentCategories | KnownCategories => {
    if (!candidate) return false;
    return isKnownRootCategories(candidate) || isKnownParentCategories(candidate) || isKnownCategories(candidate);
};

export const extractProducts = (products?: Array<{ [key: string]: string }>): { mainProduct: ProduitIcoll; poseProduct?: ProduitIcoll } => {
    if (!products || products.length === 0 || products.length > 2) throw new Error('bad Package configuration, bad number of elements');

    // First case, there is only one product, return it as the main product.
    if (products.length === 1) {
        return { mainProduct: convertToProduitIcoll(products[0]) };
    }
    // second case, there is two prodcut, one should be a 'Pose' product, the other is the main.
    if (products[0].categorie === 'Pose') {
        return { mainProduct: convertToProduitIcoll(products[1]), poseProduct: convertToProduitIcoll(products[0]) };
    }
    if (products[1].categorie === 'Pose') {
        return { mainProduct: convertToProduitIcoll(products[0]), poseProduct: convertToProduitIcoll(products[1]) };
    }

    // all other case are errors (no products, more than 2, and 2 product with no pose product.)
    throw new Error('Bad package combinaison for package.id = ' + products[0].package_id);
};

const parseEnergyType = (candidate: string | null | undefined | boolean): EnergyTypes | undefined => {
    if (!candidate) return undefined; // manage undefined, null and false.
    if (candidate === true) return undefined;

    const normalizedCandidate = candidate
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
        .toUpperCase(); // Old method: .replace(/[\u0300-\u036f]/g, "");
    switch (normalizedCandidate) {
        case 'Fioul'.toUpperCase():
            return 'Fioul';
        case 'Gaz'.toUpperCase():
            return 'Gaz';
        case 'Bois'.toUpperCase():
            return 'Bois';
        case 'Electricite'.toUpperCase():
            return 'Electricite';
        case 'PAC'.toUpperCase():
            return 'PAC';
    }
    console.log('parseEnergyType failed for ' + normalizedCandidate);
    return undefined;
};

const parseGain = (productCandidate: Record<string, any>): number => {
    let result = parsePercent(productCandidate.eco_conso);
    // si on a pas de vrai résultat ET qu'on est sur un panneau photovoltaique :
    // on utilise le gain par défaut, qui est la valeur de revente du sruplus (entre 0 et 1)

    if (result !== 0) return result;

    if (isPV(productCandidate)) return CONSTANTES_PHOTOVOLTAIQUES.tauxAutoconsommationMax_B2C;

    return 0;
};

const parsePercent = (candidate: string | null | undefined | boolean | number): number => {
    if (candidate === 0 || !candidate || candidate === true) {
        return 0;
    }

    if (typeof candidate === 'string') {
        const parsed = Number.parseFloat(candidate);
        return Number.isNaN(parsed) ? 0 : parsed / 100.0;
    }

    return candidate / 100.0;
};
const parsePosteDepense = (productCandidate: { [key: string]: string }): PosteDepense | undefined => {
    const candidate: string | null | undefined | boolean = productCandidate.type_economie_product;
    if (candidate) {
        const normalizedCandidate = candidate
            .normalize('NFD')
            .replace(/\p{Diacritic}/gu, '')
            .toUpperCase(); // Old method: .replace(/[\u0300-\u036f]/g, "");

        switch (normalizedCandidate) {
            case 'Chauffage'.toUpperCase():
                return 'Chauffage';
            case 'EauChaude'.toUpperCase():
            case 'Eau Chaude'.toUpperCase():
                return 'EauChaude';
            case 'Electricite'.toUpperCase():
            case 'Autres'.toUpperCase():
                return 'Autres';
        }
    }

    // Try to guess
    if (isKnownRootCategories(productCandidate.categorie_racine)) {
        switch (productCandidate.categorie_racine) {
            case 'Chauffage':
                return 'Chauffage';
            case 'Matériel électrique':
            case 'Photovoltaïque':
                return 'Autres';
        }
    }

    return undefined;
};

const convertToProduitIcoll = (productOutput: { [key: string]: string }): ProduitIcoll => {
    // on a décidé de grouper puissance et WC, pour se faciliter plus tard.
    // la on a soit la puissance, soit le WC en fonction du typage du produit.
    // on prend la valeur valide et on la met dans puissance.
    const puissance = productOutput.puissance;
    const wc = productOutput.wc;
    const puissanceOK = puissance != null && puissance !== undefined && !isNaN(+puissance) && +puissance !== 0;
    const wcOK = wc != null && wc !== undefined && !isNaN(+wc) && +wc !== 0;
    let puissanceresult = '0';
    if (puissanceOK && wcOK) {
        if (isPV(productOutput)) puissanceresult = wc;
        else puissanceresult = puissance;
    } else if (puissanceOK) {
        puissanceresult = puissance;
    } else if (wcOK) {
        // ATTENTION, ici, on pourrait croire qu'il faille divier par 100, mais non.
        // tous les calculs sont construit pour la valeur telle qu'elle revient de icoll !
        // Pour choisir le bon package, on fera le calcul à la volée plus tard.
        puissanceresult = wc;
    }

    // check quantité, ca doit être un chiffre, non 0 (car sans quantité, c'est quoi ??)
    const qte = productOutput.quantite;
    const qteOK = qte != null && qte !== undefined && !isNaN(+qte) && +qte > 0;
    const qteResult = qteOK ? +qte : 1;

    const result: ProduitIcoll = {
        // Common to icoll
        product_id: productOutput.product_id.toString(), // here in facts, it could be a number... secure it with toString.
        nom: productOutput.nom,
        desc: productOutput.desc ?? undefined,
        quantite: qteResult,
        puissance: +puissanceresult,
        //wc: productOutput.wc,
        prix_ht: +productOutput.prix_ht,
        tva: +productOutput.tva,

        breadcrumb: productOutput.breadcrumb,
        categorie_racine: productOutput.categorie_racine,
        categorie_parent: productOutput.categorie_parent,
        categorie: productOutput.categorie,

        cibleGain: parsePosteDepense(productOutput),
        cibleSurcout: parseEnergyType(productOutput.type_energie_product),
        gain: parseGain(productOutput),
        surcout: parsePercent(productOutput.energie_conso),

        doc_path: productOutput.doc_path,
        img_path: productOutput.img_path,
    };

    // if (!isKnownRootCategories(result.categorie_racine)) console.log('unknown RootCategories :' + result.categorie_racine);
    // if (!isKnownParentCategories(result.categorie_parent)) console.log('unknown ParentCategories :' + result.categorie_parent);
    // if (!isKnownCategories(result.categorie)) console.log('unknown Categories :' + result.categorie);

    // Maintenant que c'est fait ...
    // Il y a des règles exceptionnelles.
    // on les appliques (ou pas)
    applySpecificRules(result);

    return result;
};

const UnitesNames = ['aucune', 'euros', 'kWh', 'mcarre', 'mcube', 'litre', 'kWc'] as const;
export type Unites = (typeof UnitesNames)[number];
export const uniteToString = (unite: Unites): string => {
    switch (unite) {
        case 'aucune':
            return '';
        case 'euros':
            return '€';
        case 'kWh':
            return 'kWh';
        case 'mcarre':
            return 'm²';
        case 'mcube':
            return 'm³';
        case 'litre':
            return 'L';
        case 'kWc':
            return 'kWc';
        default:
            return '';
    }
};

export const getUniteFor = (pack: PackageData): Unites => {
    // if package has known theme type :
    if (isSubThemeType(pack.themeLb)) return uniteForTheme[pack.themeLb];
    // if product have some difened category, then convert.
    if (isCategories(pack.mainProduct.categorie)) return uniteForCategory[pack.mainProduct.categorie];
    if (isCategories(pack.mainProduct.categorie_parent)) return uniteForCategory[pack.mainProduct.categorie_parent];
    if (isCategories(pack.mainProduct.categorie_racine)) return uniteForCategory[pack.mainProduct.categorie_racine];

    //console.log('unknown category : ' + pack.mainProduct.breadcrumb);
    // w have done our best, return
    return 'aucune';
};

export const uniteForTheme: { [key in SubThemeType]: Unites } = {
    'Eau chaude sanitaire': 'kWh',
    Chauffage: 'kWh',
    'Changement comportemental': 'aucune',
    Isolation: 'mcarre',
    Photovoltaïque: 'kWc',
    Batterie: 'kWh',
    Ventilation: 'aucune',
    'Porte et fenêtre': 'aucune',

    Veranda: 'aucune',
    Pergola: 'aucune',
    Carport: 'aucune',
    'Ma piéce en plus': 'aucune',
    'Nettoyage-refection': 'aucune',
    "Mon contrat d'énergie": 'aucune',
    'Assurance habitation': 'aucune',
    'Alarme et sécurité': 'aucune',
    'Voiture électrique': 'aucune',
    'Moto électrique': 'aucune',
    'Borne de recharge': 'aucune',
    Piscine: 'aucune',
    Portail: 'aucune',
    'Eclairage exterieur': 'aucune',
    'Garantie de revenu solaire': 'aucune',
};

export const uniteForCategory: { [key in KnownRootCategories | KnownParentCategories | KnownCategories]: Unites } = {
    Photovoltaïque: 'kWc',
    'Pergola Photovoltaïque': 'kWc',
    'Carport Photovoltaïque': 'kWc',
    'Borne de recharge': 'kWh',
    Chauffage: 'kWh',
    'Mono Cristalin': 'kWc',
    Panneau: 'kWc',
    Batterie: 'kWh',
    'Pompe à chaleur': 'kWh',
    'Pac Air Air': 'kWh',
    'Pac Air Eau': 'kWh',
    "Brasseur d'air": 'kWh',
    'E-connect': 'aucune',
    Autres: 'aucune',
    Combles: 'mcarre',
    Murs: 'mcarre',
    Isolation: 'mcarre',
    Planchers: 'mcarre',
    Rampants: 'mcarre',
    LED: 'aucune',
    Confort: 'aucune',
    VMC: 'aucune',
    Services: 'aucune',
    Ballon: 'aucune',
    Rénovation: 'aucune',
    'Matériel électrique': 'aucune',
    'Ampoules LED': 'aucune',
    'Domotique / Prise conectée': 'aucune',
    Pose: 'aucune',
    Assurance: 'aucune',
};

// #region specific rules

// Pour ces 4 règles, voir le mail "état des travaux" du Lun 07/11/2022 18:15

const applySpecificRules = (mainProduct: ProduitIcoll): void => {
    const audit = stepListToAuditAndClient().audit;

    rule_1_chauffage_replacePacByPac(audit, mainProduct);
    rule_2_chauffage_replaceWoodByWood(audit, mainProduct);
    rule_3_chauffage_replacePacByWood(audit, mainProduct);
    rule_4_eauChaude_replacePacByPac(audit, mainProduct);
};

// MOVE THIS WHEN READING PACKAGES.

const rule_1_chauffage_replacePacByPac = (audit: any, mainProduct: ProduitIcoll): void => {
    // règle énoncée par ylan :
    // 1) Un produit à installer utilisant comme energie  l'Elec Thermodynamic (Pac) Pac en remplacement d’une installation utilisant comme energie Elec Thermodynamic (Pac)
    // J’applique l’économie 100 % et je prends comme valeur de consommation (a la place de ce qui est présent dans icoll)  de 70 %
    // Comment je determine:
    // Si le champ Etape3/Énergie de chauffage rempli dans l’audit est ‘Elec thermodynamique’ et que le champ « type_energie_product » du package envoyé est ‘Elec thermodynamique’
    // => Alors il faut remplacer la valeur « energie_conso » du package par 70.

    // J'élimine les cas qui ne me concernent pas
    if (audit.heaterType.value !== '3') return; // l'energie de l'audit n'est pas une pompe a chaleur
    if ((mainProduct.categorie_parent as KnownParentCategories) !== 'Pompe à chaleur') return; // la catégorie icoll n'est pas une pompe a chaleur
    if (mainProduct.cibleSurcout === undefined) return; // la cible du surcout est undefined
    if (mainProduct.cibleSurcout !== 'PAC' && mainProduct.cibleSurcout !== 'Electricite') return; // la cible du surcout n'est pas PAC.

    // le champs energie_conso correspond au surcout.
    mainProduct.surcout = 0.7;
};
const rule_2_chauffage_replaceWoodByWood = (audit: any, mainProduct: ProduitIcoll): void => {
    // règle énoncée par ylan :
    // 2) Un produit  à  installer utilisant comme energie le bois en remplacement d’un système utilisant comme energie le bois
    // J’applique l’économie 100 % et je prends comme valeur de consommation (a la place de ce qui est présent dans icoll)  de 80 %
    // Comment je determine:
    // Si le champ Etape3/Énergie de chauffage rempli dans l’audit est ‘Bois’ et que le champ « type_energie_product » du package envoyé est ‘Bois’
    // => Alors il faut remplacer la valeur « energie_conso » du package par 80.

    // J'élimine les cas qui ne me concernent pas
    if (audit.heaterType.value !== '1') return; // l'audit n'utilis epas de Bois.
    if (mainProduct.cibleGain !== 'Chauffage') return; // la cible du gain n'est pas le chauffage
    if (mainProduct.cibleSurcout === undefined) return; // le surcout est undefined
    if (mainProduct.cibleSurcout !== 'Bois') return; // le surcout n'est pas du bois

    // le champs energie_conso correspond au surcout.
    mainProduct.surcout = 0.8;
};
const rule_3_chauffage_replacePacByWood = (audit: any, mainProduct: ProduitIcoll): void => {
    // règle énoncée par ylan :
    // 3) Un produit  à  installer utilisant comme energie  le bois  en remplacement d’un système utilisant comme energie l'Elec Thermodynamic (Pac) (pas prevu encore dans les package à ce stade )
    // J’applique l’économie 100 % et je prends comme valeur de consommation (a la place de ce qui est présent dans icoll)  de 70 %
    // Comment je determine:
    // Si le champ Etape3/Énergie de chauffage rempli dans l’audit est ‘Elec thermodynamique’ et que le champ « type_energie_product » du package envoyé est ‘Bois’
    // => Alors il faut remplacer la valeur « energie_conso » du package par 70.

    // J'élimine les cas qui ne me concernent pas
    if (audit.heaterType.value !== '3') return; // l'audit n'utilise pas de pompe a chaleur pour le chauffage
    if (mainProduct.cibleGain !== 'Chauffage') return; // la cible du gain n'est pas le chauffage
    if (mainProduct.cibleSurcout === undefined) return; // la cible du surcout est nulle
    if (mainProduct.cibleSurcout !== 'Bois') return; // la cible du surcout n'est aps le bois.

    // le champs energie_conso correspond au surcout.
    mainProduct.surcout = 0.7;
};
const rule_4_eauChaude_replacePacByPac = (audit: any, mainProduct: ProduitIcoll): void => {
    // règle énoncée par ylan :
    // 4) Un produit à installer utilisant comme energie  l'Elec Thermodynamic (Pac) en remplacement d’une installation utilisant comme energie Elec Thermodynamic (Pac)
    //    (soit lié au chauffage soit par la présence d’un ballon thermodynamique)
    // J’applique l’économie 100 % et je prends comme valeur de consommation (a la place de ce qui est présent dans icoll)  de 50 %
    // Comment je determine:
    // 2 CAS
    // a) Si le champ Etape3/Énergie de chauffage rempli dans l’audit est 'Elec thermodynamique’
    //       ET  que le type d’energie sélectionné pour le Type d’énergie pour l’eau chaude sanitaire est: “Lié au chauffage”
    //    => Alors il faut remplacer la valeur « energie_conso » du package par 50.
    // b) Si le type d’energie sélectionné pour le Type d’énergie pour l’eau chaude sanitaire est: “Electrique”
    //       ET que le choix “Ballon thermodynamique ou solaire est selectionné
    //       ET que « type_energie_product » du package envoyé est ‘Elec thermodynamique’
    //    => lors il faut remplacer la valeur « energie_conso » du package par 50.

    // si chauffage thermo et eauchaude lié au chauffage :
    const eauchaudeThermoLieChauffage_casA = audit.heaterHotWaterIncluded.value === true && audit.heaterType.value === '3';
    const eauchaudeThermo_casB =
        audit.heaterHotWaterIncluded.value === false &&
        (audit.heaterHotSolarWaterThermodynamic.value === true || audit.heaterHotWaterThermodynamic.value === true);

    // J'élimine les cas qui ne me concernent pas
    if (!eauchaudeThermoLieChauffage_casA && !eauchaudeThermo_casB) return; // on est ni das le cas A, ni dans el cas B

    if (mainProduct.cibleGain !== 'EauChaude') return; // la cible du gain n'est pas l'eau chaude

    if (mainProduct.cibleSurcout === undefined) return; // la cible du surcout n'est pas definie
    if (mainProduct.cibleSurcout !== 'PAC' && mainProduct.cibleSurcout !== 'Electricite') return; // la cible du surcout n'est pas pompe a chaleur

    // le champs energie_conso correspond au surcout.
    mainProduct.surcout = 0.5;
};

// #endregion
