import { copyEnergyPrice, DepenseParPoste, getEconomieBetween, inc, indexedAtYear } from './bilanEnergieService';
import * as storageService from '../localStorageService';

export const DUREE_PROJETCTION_FINANCIERE = 25;

export type Indexations = {
    generale: number;
    bois: number;
    electricite: number;
    fioul: number;
    gaz: number;
    electricite_revente: number;
    electricite_autoconsomation: number;
};

/**
 * cacul de l'économie sur le nombre d'année, en tenant compte d'une indexation générale (commune) des prix des différentes énergies.
 * @param depenseInitiale la dépense d'énergie annuelle initiale avant travaux
 * @param depenseFinale la dépense d'énergie annuelle finale, après travaux.
 * @param durationYear le nombre d'année
 * @returns le gain sur le nombre d'année tenant compte de l'indexation du prix des énergies.
 */
export const calculEconomie_IndexationSimple = (
    depenseInitiale: DepenseParPoste,
    depenseFinale: DepenseParPoste,
    durationYear = DUREE_PROJETCTION_FINANCIERE
): number => {
    // Algorithme :
    // on calcule l'économie de la première année (variable Year0)
    // on calcule ensuite par augmentation géométrique, l'économie chaque année.
    // on cumule ces économies chaque année, dans la variable cumul.
    const indexations = storageService.getConfig('indexations');
    // voici l'algo :
    let Year0 = depenseInitiale.prix() - depenseFinale.prix();
    let cumul = 0;
    for (let i = 0; i < durationYear; i++) {
        cumul += Year0 * Math.pow(indexations.generale, i);
    }
    return cumul;
};

/**
 * cacul de l'économie sur le nombre d'année, en tenant compte d'une indexation des prix des différentes énergies, par tpye.
 * (même algo que la version 'simple', mais tous les types de dépenses y compris abonnement ont un taux d'indexation potentiellement différent).
 * @param depenseInitiale la dépense d'énergie annuelle initiale avant travaux
 * @param depenseFinale la dépense d'énergie annuelle finale, après travaux.
 * @param durationYear le nombre d'année
 * @returns le gain sur le nombre d'année tenant compte de l'indexation du prix des énergies.
 */
export const calculEconomie_IndexationMultiple = (
    depenseInitiale: DepenseParPoste,
    depenseFinale: DepenseParPoste,
    durationYear = DUREE_PROJETCTION_FINANCIERE
): DepenseParPoste => {
    // WARNING : duplication de code avec calculProjectionFinanciereGlobale
    // Algorithme.
    // C'est le même que pour calculEconomie_IndexationSimple.
    // sauf que le prix de chaque énergie et de chaque abonnement est déterminé séparement en fonction de son propre taux d'indexation.
    const indexations = storageService.getConfig('indexations');

    // Cumul, initialisé à 0
    const economieCumul: DepenseParPoste = new DepenseParPoste();
    economieCumul.fioulPrice = copyEnergyPrice(depenseInitiale.fioulPrice);
    economieCumul.electricitePrice = copyEnergyPrice(depenseInitiale.electricitePrice);
    economieCumul.boisPrice = copyEnergyPrice(depenseInitiale.boisPrice);
    economieCumul.gazPrice = copyEnergyPrice(depenseInitiale.gazPrice);

    // La je suis pas bien sur, mais il y a un effet de bord qui compte les abonnement une fois de trop.
    // (ou alors le escel les compte une fois de mois !)
    if (economieCumul.fioulPrice) economieCumul.fioulPrice.subscriptionPrice = 0;
    if (economieCumul.electricitePrice) economieCumul.electricitePrice.subscriptionPrice = 0;
    if (economieCumul.boisPrice) economieCumul.boisPrice.subscriptionPrice = 0;
    if (economieCumul.gazPrice) economieCumul.gazPrice.subscriptionPrice = 0;

    // Year 0
    const economie0 = getEconomieBetween(depenseInitiale, depenseFinale);

    for (let year = 0; year < durationYear; year++) {
        // cumul += y0 * (1,05)^i
        const ecoAtYear_i = indexedAtYear(economie0, indexations, year);
        inc(economieCumul, ecoAtYear_i);
    }

    return economieCumul;
};
