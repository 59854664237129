import { stepListToAuditAndClient } from '../localStorageService';
import { ModeConsommation, PanneauPhotovoltaique } from './calculPhotovoltaique';
import * as pvgis from '../pvgis/api';
import { DEFAULT_LAT, DEFAULT_LNG, GeoPosition } from '../tools/mapTools';
import { canInstallPV } from '../tools/auditTools';
import * as storageService from '../localStorageService';

export type Ensoleillement = {
    id: string;
    name: string;
    energy: number;
    region: string;
    hourPerYear: number;
};
// C'est la définition de l'orientation telle que trouvée dans le fichier Sunshine Yield.
export type Orientation = 'est' | 'ouest' | 'sud' | 'sudest' | 'sudouest';

// README :
// Depuis certains changements de conception, l'installation PV est calculé au tout debut du process complet.
// au moment du aprsing des packages en provenance de icoll.
// Comme react oblige a cloner souvent les themes (et leur contenu, qui incluent maintenant InstallationPV)
// Et COMME le clonage par serialisation+déserialisation JSON, ne s'occupe pas des méthodes de classe
// InstallationPV NE DOIT PAS ETRE UNE CLASSE.

/**
 * should be initialized with initializeInstallationPV
 */
export type InstallationPV = {
    panneau: PanneauPhotovoltaique | undefined;
    // TODO : site is useless.
    site: SitePV | undefined;
    /**
     * Calcule la production maximale, selon le panneau, la zone géographique et la configuration del'installation
     * La production maximale que le panneau pourra produire,  selon le panneau, la zone géographique et la configuration de l'installation
     * en Wh
     */
    maxProd: number;
    PVcalcReport: pvgis.PVcalcReport | undefined;
    maxProdPvGis: number;
    modeConsommation: ModeConsommation;
    tauxAutoconsommation: number;
};

// CA NE DOIT PAS ETRE UNE VARIABLE,
// parce que le storageService.CONSTANTES_PHOTOVOLTAIQUES n'existerai pas encore
export const dummyInstallationPV = (): InstallationPV => {
    return {
        maxProd: 0,
        maxProdPvGis: 0,
        panneau: undefined,
        site: undefined,
        PVcalcReport: undefined,
        modeConsommation: 'ReventeSurplus',
        tauxAutoconsommation: storageService.CONSTANTES_PHOTOVOLTAIQUES.tauxAutoconsommationMax_B2C,
    };
};

export const initializeInstallationPV = async (panneau: PanneauPhotovoltaique | undefined, PVcalcReport: pvgis.PVcalcReport): Promise<InstallationPV> => {
    const audit = stepListToAuditAndClient().audit;
    const site = createSite(audit);
    let maxProd = 0;
    let maxProdPvGis = 0;
    if (panneau && site) {
        const ratio = panneau.puissance * panneau.efficacite;
        maxProd = site.energieEnsoleillement * site.rendementInclinaison * ratio;
        // TODO trouver comment pvgis calcul le facteur de correction ?

        if (PVcalcReport.outputs.totals.fixed) maxProdPvGis = (PVcalcReport.outputs.totals.fixed.E_y / 1000) * ratio;
    }
    //console.log('site.energieEnsoleillement  = ' + site.energieEnsoleillement);
    //console.log('site.rendementInclinaison  = ' + site.rendementInclinaison);
    const result: InstallationPV = {
        panneau,
        site,
        maxProd,
        PVcalcReport,
        maxProdPvGis,
        modeConsommation: 'ReventeSurplus',
        /**
         * le taux de l'electricité produite par le panneau qui sera auto consommée. Doit être entre 0 (revente totale) et 1 (autoconsomation totale).
         * */
        tauxAutoconsommation: storageService.CONSTANTES_PHOTOVOLTAIQUES.tauxAutoconsommationMax_B2C,
    };
    return result;
};

export const energieAutoconsommee = (installation: InstallationPV): number => {
    return !installation.panneau ? 0 : installation.tauxAutoconsommation * installation.maxProd;
};

export const setModeConsommation = (installation: InstallationPV, mode: ModeConsommation, tauxAutoconso?: number): void => {
    if (installation.panneau && tauxAutoconso !== undefined) installation.tauxAutoconsommation = tauxAutoconso;
    installation.modeConsommation = mode;
};

export const getProduction = (installation: InstallationPV, radiation: number): number => {
    if (!installation.panneau || !installation.site) return 0;

    // VERIFIER SI ON PREND OU PAS LE RENDEMENT D'INCLINAISON :
    // LES DONNEES PVGIS en tiennent un peu compte.
    return installation.panneau.puissance * radiation * installation.site.rendementInclinaison * installation.panneau.efficacite;
};

export const initializeInstallationPVSyncNoPvGis = (panneau: PanneauPhotovoltaique | undefined): InstallationPV => {
    const audit = stepListToAuditAndClient().audit;
    const site = createSite(audit);
    let maxProd = 0;
    if (panneau && site) {
        maxProd = panneau.puissance * site.energieEnsoleillement * site.rendementInclinaison * panneau.efficacite;
    }

    const result: InstallationPV = {
        panneau,
        site,
        maxProd,
        PVcalcReport: undefined,
        maxProdPvGis: 0,
        modeConsommation: 'ReventeSurplus',
        tauxAutoconsommation: storageService.CONSTANTES_PHOTOVOLTAIQUES.tauxAutoconsommationMax_B2C,
    };
    return result;
};

// #region site

export type SitePV = {
    departementId: string;
    inclinaison: number;
    orientation: Orientation;
    geoPosition: GeoPosition;
    // Les deux variables qui servent vraiment :
    energieEnsoleillement: number; // l'energie solaire fournie par le soleil dans ce département.
    rendementInclinaison: number; // le rendement d'un panneau solaire, placé selon une orientation et une inclinaison.
};

// cherche l'energie solaire dans le département, dans le fichier Sunshine.
export const getEnsoleillement = (departementNumber: string): Ensoleillement | undefined => {
    const ENSOLEILLEMENTS = storageService.getConfig('sunshines');
    for (const e of ENSOLEILLEMENTS!) {
        // TODO update to work with code instead of names
        if (e.id === departementNumber) {
            return e;
        }
    }
    return undefined;
};

// cherche le rendeement selon l'inclinaison et l'orientation, dans le fichier sunshine Yield.
export const getRendement = (inclinaison: number, orientation: Orientation): number | undefined => {
    const RENDEMENTS = storageService.getConfig('sunshinesyield');
    for (const r of RENDEMENTS!) {
        // yes double == ,  not triple ===
        // eslint-disable-next-line eqeqeq
        if (r.inclinaison == inclinaison) {
            return r.rendement[orientation];
        }
    }
    return undefined;
};

// recherche les données de l'installation dans l'audit.
// On en stocke plusieurs qui ne serviront pas pour les calculs.
export const createSite = (audit: any): SitePV => {
    // console.log('inclinaison : ' + JSON.stringify(audit.tilt));
    // console.log('exposisiton : ' + JSON.stringify(audit.roofExposure));

    // Si on est pas en appartement, on a ddes cas limites à vérifier :
    const result: SitePV = {
        departementId: '0',
        inclinaison: 0,
        orientation: 'sud',
        energieEnsoleillement: 0,
        rendementInclinaison: 0,
        geoPosition: { lat: 0, lng: 0 },
    };
    // TODO : is not maison !
    const canHavePV = canInstallPV(audit);

    if (!canHavePV) {
        // en cas d'appartement les champs inclinaison et orientation ne sont pas remplit.
        // Et de toutes façons à la fin, les packages PV seront filtrés.
        // donc on va utiliser des valeurs par défaut.
        result.orientation = 'sud';
        result.inclinaison = 0;
    } else {
        if (audit.tilt?.value_label === '' || audit.roofExposure?.value_label === '') throw new Error('Bad audit Orientation');
        if (audit.userAddressLatitude?.value === undefined || audit.userAddressLongitude?.value === undefined) throw new Error('Bad audit geo coordinates');
        // Ici on admet que si la maison est orienté NORD, le toit ayant 2 côté possède aussi une face orientée sud, sur laquelle on placera le panneau.
        // c'est une sale approximation. Mais ce n'est pas une erreur, on suit le specs.
        switch (audit.roofExposure?.value_label) {
            case 'Sud':
            case 'Nord':
                result.orientation = 'sud';
                break;
            case 'Nord-Est':
            case 'Sud-Ouest':
                result.orientation = 'sudouest';
                break;
            case 'Nord-Ouest':
            case 'Sud-Est':
                result.orientation = 'sudest';
                break;
            case 'Est':
                result.orientation = 'est';
                break;
            case 'Ouest':
                result.orientation = 'ouest';
                break;
            default:
                throw new Error('Bad audit Orientation');
        }

        const incl = audit.tilt?.value_label;
        result.inclinaison = +incl.substr(0, incl.length - 1);
    }
    const ensoleillement = getEnsoleillement(audit.department.value.slice(0, 2));

    result.departementId = ensoleillement?.id ?? '0';
    result.energieEnsoleillement = ensoleillement?.energy ? ensoleillement.energy / 1000.0 : 0;
    result.rendementInclinaison = getRendement(result.inclinaison, result.orientation) ?? 0;

    result.geoPosition = {
        lat: Number(audit.userAddressLatitude?.value) ?? DEFAULT_LAT,
        lng: Number(audit.userAddressLongitude?.value) ?? DEFAULT_LNG,
    };
    return result;
};

export const toExposition = (exposition: string): Orientation => {
    const exp = exposition.toLowerCase();
    // Ici on admet que si la maison est orienté NORD, le toit ayant 2 côté possède aussi une face orientée sud, sur laquelle on placera le panneau.
    // c'est une sale approximation. Mais ce n'est pas une erreur, on suit le specs.
    switch (exp) {
        case 'nord':
        case 'n':
        case 'sud':
        case 's':
            return 'sud';

        case 'nord-est':
        case 'ne':
        case 'so':
        case 'sud-ouest':
            return 'sudouest';

        case 'nord-ouest':
        case 'sud-est':
        case 'no':
        case 'se':
            return 'sudest';

        case 'est':
        case 'e':
            return 'est';

        case 'ouest':
        case 'o':
            return 'ouest';

        default:
            throw new Error('Bad audit Orientation');
    }
};
//#endregion
