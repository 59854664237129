import { useEffect, useState } from 'react';

type presenceSS = 'avecSS' | 'horsSS';
type presenceCA = 'avecCA' | 'horsCA';

export type LevelCountMode = `${presenceSS}${presenceCA}`;

/**
 * retourne le nombre de niveaux suivant differentes règles.
 * @param audit l'audit pour lequel on veut le mode.
 * @param mode par défaut, c'est audit.floorCount directement
 * @returns le nombre de niveaux selon le mode de calcul.
 */
export const getLevelCount = (audit: any, mode: LevelCountMode = 'avecSSavecCA'): number => {
    if (!audit || audit.floorCount === undefined) {
        return 1;
    }
    // audit.floorCount correspond à avecSSavecCA
    let floorCount = +audit.floorCount.value;

    switch (mode) {
        case 'horsSShorsCA':
            // Typiquement pour la surface des murs, hauteur de la goutière
            // supprimer les combles aménagés.
            if (audit && audit.ceilingType && audit.ceilingType.value_label === 'Combles aménagés') floorCount--;
            // supprimer le sous sol.
            if (audit && audit.floorType && audit.floorType.value_label === 'Plancher sur sous sol') floorCount--;
            break;

        case 'horsSSavecCA':
            // supprimer le sous sol.
            // Cardonnel
            if (audit && audit.floorType && audit.floorType.value_label === 'Plancher sur sous sol') floorCount--;

            break;

        case 'avecSShorsCA':
            // supprimer les combles aménagés.
            if (audit && audit.ceilingType && audit.ceilingType.value_label === 'Combles aménagés') floorCount--;
            break;

        case 'avecSSavecCA':
        default:
            // rien, c'est le cas par défaut.
            break;
    }
    // console.log('floor count (mode : ' + mode + ') : ' + floorCount);
    return floorCount;
};

const surfaceDunNiveau = (audit: any): number => {
    if (audit.buildingPlans === undefined) return 0;
    let surfaceNiv0 = 0;
    let shape = audit.buildingPlans.value[0];

    // Le calcul de la longueur des murs est plus simple qu'il n' parait.
    // Pour les formes suivantes rectangle, L, T, Y et X, c'est comme le rectangle.
    // Pour autre, faute de mieux, on prend, comme le rectangle.

    const baseRect = +audit.buildingWidth.value * +audit.buildingLength.value;

    switch (+shape.shape) {
        case 1: // 'Rectangulaire',
            surfaceNiv0 = baseRect;
            break;
        case 11: // 'En L',
            surfaceNiv0 = baseRect - shape.l1 * (audit.buildingWidth.value - shape.l2);

            break;
        case 12: // 'En L inversé',
            surfaceNiv0 = baseRect - shape.l1 * shape.l2;
            break;
        case 21: // 'En T',
        case 22: // 'En T inversé',
            surfaceNiv0 = baseRect - shape.l1 * shape.l2 - shape.l3 * shape.l4;
            break;
        case 41: // 'En Y',
        case 42: // 'En Y inversé',
            surfaceNiv0 = baseRect - shape.l1 * shape.l2 - shape.l3 * shape.l4 - shape.l5 * shape.l6;
            break;
        case 51: // 'En X',
            surfaceNiv0 = baseRect - shape.l1 * shape.l2 - shape.l3 * shape.l4 - shape.l5 * shape.l6 - shape.l7 * shape.l8;
            break;

        case 31: // 'En U',
        case 32: // 'En U inversé',
            surfaceNiv0 = baseRect - shape.l1 * shape.l2;
            break;

        case 52: // 'En H',
            surfaceNiv0 = baseRect - shape.l1 * shape.l2 - shape.l5 * shape.l7;
            break;
        case 99: // 'Autre forme',
            surfaceNiv0 = baseRect;
            break;
        default:
            surfaceNiv0 = 0;
            break;
    }
    if (isNaN(surfaceNiv0)) return baseRect;
    return surfaceNiv0;
};

const perimetreDunNiveau = (audit: any): number => {
    if (audit.buildingPlans === undefined) return 0;
    let perimetreNiv0 = 0;
    let shape = audit.buildingPlans.value[0];

    // Le calcul de la longueur des murs est plus simple qu'il n' parait.
    // Pour les formes suivantes rectangle, L, T, Y et X, c'est comme le rectangle.
    // Pour autre, faute de mieux, on prend, comme le rectangle.

    switch (+shape.shape) {
        case 1: // 'Rectangulaire',
        case 11: // 'En L',
        case 12: // 'En L inversé',
        case 21: // 'En T',
        case 22: // 'En T inversé',
        case 41: // 'En Y',
        case 42: // 'En Y inversé',
        case 51: // 'En X',
            perimetreNiv0 = shape.length + shape.length + shape.width + shape.width;
            break;

        case 31: // 'En U',
        case 32: // 'En U inversé',
            perimetreNiv0 = shape.length + shape.length + shape.width + shape.width + shape.l2 + shape.l2;
            break;

        case 52: // 'En H',
            perimetreNiv0 = shape.length + shape.length + shape.width + shape.width + shape.l2 + shape.l2 + shape.l7 + shape.l7;
            break;
        case 99: // 'Autre forme',
            perimetreNiv0 = shape.length + shape.length + shape.width + shape.width;
            break;
    }
    if (isNaN(perimetreNiv0)) return 0;
    return perimetreNiv0;
};
export const getSurfaceDesMurs = (audit: any): number => {
    if (audit.buildingPlans === undefined) return 0;

    let height = audit.buildingPlans.value[0].height;

    let contourNiv0 = perimetreDunNiveau(audit);
    // on calcule le nombre de niveaux :
    const floorCount = getLevelCount(audit, 'horsSShorsCA');

    // Le contour du niveau 0 est considéré comme le même que les autres niveaux.
    // La hauteur des murs est dans shape
    const surfaceMurs = floorCount * contourNiv0 * height;
    // console.log('surface des murs (fnêtre incluses) = ' + surfaceMurs);
    const surfaceMursHorsOuvrants = surfaceMurs - getSurfaceDesFenetres(audit) - getSurfaceDesPortes(audit);

    return surfaceMursHorsOuvrants;
};
export const getSurfaceDesFenetres = (audit: any): number => {
    var surface = 0;

    if (audit.buildingWindows1details !== undefined && audit.buildingWindows1details.value !== undefined) {
        const N = audit.buildingWindows1details.value.N;
        const NE = audit.buildingWindows1details.value.NE;
        const E = audit.buildingWindows1details.value.E;
        const SE = audit.buildingWindows1details.value.SE;
        const S = audit.buildingWindows1details.value.S;
        const SO = audit.buildingWindows1details.value.SO;
        const O = audit.buildingWindows1details.value.O;
        const NO = audit.buildingWindows1details.value.NO;
        if (N !== undefined) surface += N.count * N.surface;
        if (NE !== undefined) surface += NE.count * NE.surface;
        if (E !== undefined) surface += E.count * E.surface;
        if (SE !== undefined) surface += SE.count * SE.surface;
        if (S !== undefined) surface += S.count * S.surface;
        if (SO !== undefined) surface += SO.count * SO.surface;
        if (O !== undefined) surface += O.count * O.surface;
        if (NO !== undefined) surface += NO.count * NO.surface;
    }
    if (audit.buildingWindows2details !== undefined && audit.buildingWindows2details.value !== undefined) {
        const N = audit.buildingWindows2details.value.N;
        const NE = audit.buildingWindows2details.value.NE;
        const E = audit.buildingWindows2details.value.E;
        const SE = audit.buildingWindows2details.value.SE;
        const S = audit.buildingWindows2details.value.S;
        const SO = audit.buildingWindows2details.value.SO;
        const O = audit.buildingWindows2details.value.O;
        const NO = audit.buildingWindows2details.value.NO;
        if (N !== undefined) surface += N.count * N.surface;
        if (NE !== undefined) surface += NE.count * NE.surface;
        if (E !== undefined) surface += E.count * E.surface;
        if (SE !== undefined) surface += SE.count * SE.surface;
        if (S !== undefined) surface += S.count * S.surface;
        if (SO !== undefined) surface += SO.count * SO.surface;
        if (O !== undefined) surface += O.count * O.surface;
        if (NO !== undefined) surface += NO.count * NO.surface;
    }

    if (audit.buildingDoorWindows1details !== undefined && audit.buildingDoorWindows1details.value !== undefined) {
        const N = audit.buildingDoorWindows1details.value.N;
        const NE = audit.buildingDoorWindows1details.value.NE;
        const E = audit.buildingDoorWindows1details.value.E;
        const SE = audit.buildingDoorWindows1details.value.SE;
        const S = audit.buildingDoorWindows1details.value.S;
        const SO = audit.buildingDoorWindows1details.value.SO;
        const O = audit.buildingDoorWindows1details.value.O;
        const NO = audit.buildingDoorWindows1details.value.NO;
        if (N !== undefined) surface += N.count * N.surface;
        if (NE !== undefined) surface += NE.count * NE.surface;
        if (E !== undefined) surface += E.count * E.surface;
        if (SE !== undefined) surface += SE.count * SE.surface;
        if (S !== undefined) surface += S.count * S.surface;
        if (SO !== undefined) surface += SO.count * SO.surface;
        if (O !== undefined) surface += O.count * O.surface;
        if (NO !== undefined) surface += NO.count * NO.surface;
    }
    // console.log('surface des fenêtres = ' + surface);
    return surface;
};

export const getSurfaceDesPortes = (audit: any): number => {
    // TODO, visiblement on ne sait aps calculer la surface des portes.
    const SURFACE_D_UNE_PORTE = 2.0; // standard arbitraire en m² pour une porte de 90cm X 200cm
    let porteCount = 1;

    if (audit && audit.buildingDoor2Presence && audit.buildingDoor2Presence.value === true) porteCount++;

    return porteCount * SURFACE_D_UNE_PORTE;
};
/** Alias surface rempant */
export const getSurfaceToiture = (audit: any): number => {
    // Surface à isoler :
    // surface = globale_de_l'habitation / Nb_niveaux / cosinus(inclinaison)
    if (audit.SHON !== undefined && audit.floorCount !== undefined && +audit.floorCount.value !== 0) {
        var cos = 1;
        if (audit.tilt !== undefined) {
            const angleDegres = +audit.tilt.value_label.slice(0, -1);
            // Si l'angle fait 90° (par pourrissage des données), on a cos = 0, et crash à la division.
            cos = Math.cos(angleDegres * (180 / Math.PI));
        }
        return +audit.SHON.value / +audit.floorCount.value / cos;
    }
    return 0;
};
/** Alias surface rempant */
export const getSurfaceRampants = getSurfaceToiture;

// Isoler les combles aménagés === isoler les rempants === isoler le toit.
// isoler les combles non aménagés === isoler le plancher des combles (on a pas l'intention de les chauffer.)

export const getSurfacePlancherComble = (audit: any): number => {
    return surfaceDunNiveau(audit);
};
export const getSurfacePlancher = (audit: any): number => {
    // if (!audit || !audit.SHON || !audit.floorCount || audit.floorCount.value === 0) return 0;
    // return +audit.SHON.value / +audit.floorCount.value;
    return surfaceDunNiveau(audit);
};

export const canInstallPV = (audit: any): boolean => {
    return audit.projectType && audit.projectType?.value_label !== 'Appartement' && audit.projectType?.value_label !== 'Immeuble collectif';
};
export const isAppartement = (audit: any): boolean => {
    return audit.projectType && audit.projectType?.value_label === 'Appartement';
};
export const isMaison = (audit: any): boolean => {
    return audit.projectType && audit.projectType?.value_label === 'Maison individuelle';
};

// TODO, tout ce qu'il y a partir de la devrait se trouver ailleurs.
// Ce sont des outils génériques. Ce fichier ne devrait contenir que des outils liés à l'audit.

// Unique keys
export const randomKeyGen = (): string => {
    let result = '';

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 15; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return result;
};

// Modal counter
export const useCounter = (maxTime: number) => {
    const [counter, setCounter] = useState(maxTime);

    useEffect(() => {
        let indexCounter: any = null;

        if (counter > 0) {
            indexCounter = setInterval(() => {
                setCounter(counter - 1);
            }, 1000);
        }

        return () => clearInterval(indexCounter);
    }, [counter]);

    return counter;
};

export const removeItemAtIndexWithCopy = <T>(arr: Array<T>, index: number): Array<T> => {
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
};

// Resize image before setting it into base64
export const resizeImage = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const img = new Image();

        img.onload = () => {
            const canvas: HTMLCanvasElement = document.createElement('canvas');
            let width: number = img.width;
            let height: number = img.height;

            // If image is > 500px
            if (width > 500 || height > 500) {
                if (width > height) {
                    height *= 500 / width;
                    width = 500;
                } else {
                    width *= 500 / height;
                    height = 500;
                }
            }

            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');
            ctx?.drawImage(img, 0, 0, width, height);

            const base64 = canvas.toDataURL('image/jpeg');
            resolve(base64);
        };

        img.onerror = reject;
        img.src = URL.createObjectURL(file);
    });
};

// Create dynamic select list
export const createDynamicList = (min: number, max: number) => {
    const list = [];

    for (let i = min; i <= max; i++) {
        const item: { label: string; value: string } = {
            label: `${i}`,
            value: `${i}`,
        };

        list.push(item);
    }

    return list;
};

// Extract number from string
export const extractNumberFromString = (input: string): number | null => {
    if (typeof input !== 'string' || !input) {
        return null;
    }

    const parts = input.split(' ');
    let number: number | null = null;

    for (const part of parts) {
        if (!isNaN(parseInt(part, 10))) {
            number = parseInt(part, 10);
            break;
        }
    }

    return number;
};

export const formatNumberWithLeadingZero = (num: number) => {
    if (num >= 1 && num <= 9) {
        return `0${num}`;
    } else {
        return num.toString();
    }
};

/** recursively log a json object, with collapsible output
 * WARNING : this log is UGLY for perf, do not use massively.
 */
export const prettyLog = (key: string | undefined, value: unknown, open = false) => {
    if (value === null) {
        console.log(key ? key + ' : null' : 'null');
        return;
    }
    if (value === undefined || typeof value === undefined) {
        console.log(key ? key + ' : undefined' : 'undefined');
        return;
    }

    if (typeof value === 'number' || typeof value === 'boolean' || typeof value === 'symbol') {
        console.log(key ? key + ' : ' : '', value);
        return;
    }
    if (typeof value === 'string') {
        console.log(key ? key + ' : ' : '', '"' + value + '"');
        return;
    }
    if (typeof value === 'object') {
        if (Array.isArray(value)) {
            open ? console.group(key ? key + ' : [' : '[') : console.groupCollapsed(key ? key + ' : [' : '[');
            const array = value as Array<unknown>;
            for (const elem of array) {
                // recursive call
                prettyLog(undefined, elem, open);
            }
            console.log(']');
        } else {
            open ? console.group(key ? key + ' : {' : '{') : console.groupCollapsed(key ? key + ' : {' : '{');
            for (const subkey of Object.keys(value as object)) {
                const elem = (value as Record<string, unknown>)[subkey];
                // recursive call
                prettyLog(subkey, elem, open);
            }
            console.log('}');
        }

        console.groupEnd();
    }
};
export const removeItemAtIndex = <T>(arr: Array<T>, index: number): Array<T> => {
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
};
