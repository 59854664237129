import React, { useEffect, useState } from 'react';
// Calculs
import { getDepensesParPoste } from '../../services/calculs/bilanEnergieService';
import { CONSTANTES_PHOTOVOLTAIQUES } from '../../services/localStorageService';
import { PanneauPhotovoltaique, SimulationPhotovoltaique } from '../../services/calculs/calculPhotovoltaique';

// Styles
import './RecapIneligiblePhotovoltaique.scss';

// Icons
import { ReactComponent as IconHouse } from '../../assets/icons/synthese/icon-house.svg';
import { ReactComponent as IconLightning } from '../../assets/icons/synthese/icon-lightning.svg';
import { ReactComponent as IconAmpoule } from '../../assets/icons/synthese/icon-ampoule.svg';
import { ReactComponent as IconIndependance } from '../../assets/icons/synthese/icon-independance.svg';
import { ReactComponent as IconEconomie } from '../../assets/icons/synthese/icon-economie.svg';
import { ReactComponent as IconRecap } from '../../assets/icons/synthese/icon-recap.svg';
import { ReactComponent as IconPhotoHouse } from '../../assets/icons/synthese/icon-photo-house.svg';
import { useHistory } from 'react-router';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { Redirect } from 'react-router-dom';
import { postActionLogementValidateData } from '../../services/apiParticulierService';
import * as storageService from '../../services/localStorageService';

import { currencyFormat, parseCurrency } from '../../services/tools/TypeHelper';
import { Aide } from '../../services/calculs/ticket';
import { InstallationPV, initializeInstallationPVSyncNoPvGis } from '../../services/calculs/installationPV';
import { ROUTE_AUDIT_AL_RECAP_INELIGIBLE, ROUTE_AUDIT_AL_SIGNATURE } from '../../routing/paths';
import { DUREE_PROJETCTION_FINANCIERE } from '../../services/calculs/calculEconomie';
import { isPV } from '../../services/calculs/package';

export type PrixPanneau = {
    prixTTC: number;
    aides: Array<Aide>;
    totalAides: number;
    prixAvecAide: number;
};

const useCounter = (maxTime: number) => {
    const [counter, setCounter] = useState(maxTime);

    useEffect(() => {
        let indexCounter: any = null;

        if (counter > 0) {
            indexCounter = setInterval(() => {
                setCounter(counter - 1);
            }, 1000);
        }

        return () => clearInterval(indexCounter);
    }, [counter]);

    return counter;
};

// Display HTML + counter
const SwalComponent = () => {
    const counter = useCounter(45);

    return (
        <div>
            Merci de patienter, cette opération peut prendre plusieurs dizaines de secondes
            <div className="swal2-loader mt-3 mx-auto d-flex"></div>
            <p className="mt-3">{counter}</p>
        </div>
    );
};

export const RecapIneligiblePhotovoltaique: React.FC = () => {
    const setPanneauPhotovoltaique = (data: any) => {
        const panneau: PanneauPhotovoltaique = {
            puissance: 0,
            nombrePanneau: 0,
            prixAvecAide: 0,
            efficacite: CONSTANTES_PHOTOVOLTAIQUES.efficacite,
        };

        const prixPanneau: PrixPanneau = {
            prixTTC: 0,
            aides: Array<Aide>(),
            totalAides: 0,
            prixAvecAide: 0,
        };

        let productOK = false;
        for (const product of data.Products) {
            if (isPV(product)) {
                //packageDesc = data.Package;
                panneau.nombrePanneau = +product.quantite;
                panneau.puissance = +product.wc * panneau.nombrePanneau;

                panneau.prixAvecAide = parseCurrency(data['Net à payer']);
                prixPanneau.prixAvecAide = panneau.prixAvecAide;
                prixPanneau.prixTTC = parseCurrency(data['Prix devis TTC']);
                prixPanneau.totalAides = parseCurrency(data['Montant des aides cumulées']);
                if (data.Aides) {
                    for (const a of data.Aides) {
                        for (const key of Object.keys(a)) {
                            const newaide: Aide = {
                                titre: key,
                                montant_lbl: a[key],
                                montant_lbl_abs: a[key], // TODO : check this
                                montant: parseCurrency(a[key]),
                                product_id: product.produc_id,
                                product_name: product.nom,
                                // 2024-09-13, RTA
                                // Ces 4 champs ont été ajoutés pour que ca compile.
                                // Mais ce code est très ancien et n'est plus utilisé.
                                // aucune idée des imapcts ici
                                isLocale: true,
                                shouldAppearsInAnnexe: false,
                                isCompatibleMar: false,
                                isMarRelated: false,
                            };
                            prixPanneau.aides.push(newaide);
                        }
                    }
                }

                productOK = true;
            }
        }

        if (!productOK) {
            // What do we do
            console.log('FAIL : KO, or no data, or invalide data');
            console.log('Data  : ' + JSON.stringify(data));
            return null;
        }

        return { prixPanneau, panneau };
    };

    const AlertSwal = withReactContent(Swal);
    const history = useHistory();
    // récupération de l'audit
    const AUDIT_PARAM = storageService.stepListToAuditAndClient();
    const INPUT_AUDIT = AUDIT_PARAM.audit;

    // Si on a pas de données photovoltaique, c'est qu'on a été mal redirigé, ce cas ne devrait jamais arriver
    const photovoltaicStr = localStorage.getItem('RecapIneligible');
    if (!photovoltaicStr) return <Redirect to={ROUTE_AUDIT_AL_RECAP_INELIGIBLE} />;

    const photovoltaicData = JSON.parse(photovoltaicStr);

    // A partir des déclaration présente dans l'audit, on calcule le cout, par poste energetique.
    const depenseInitiale = getDepensesParPoste(INPUT_AUDIT)!;
    //depenseInitiale.logDetail();
    // A partir de l'audit on détermine les niveaux d'ensolleillement et de rendement en cas d'installation d'un panneau solaire.
    // Lit les données de l'audit et retrouve les valeurs téléchargées dans le local storage.

    // Pareil si il n'y a aps d'audit id, ca ne devrait pas pouvoir arriver
    const auditId = localStorage.getItem('auditId');
    if (!auditId) return <Redirect to={ROUTE_AUDIT_AL_RECAP_INELIGIBLE} />;

    const result = setPanneauPhotovoltaique(photovoltaicData);
    if (!result) {
        console.log('panneau solaire non valide, dans photovoltaicData');
        return <Redirect to={ROUTE_AUDIT_AL_RECAP_INELIGIBLE} />;
    }
    const panneau = result.panneau;
    //const installation = new InstallationPV(panneau);
    const installation: InstallationPV = initializeInstallationPVSyncNoPvGis(panneau);
    const prixPackage = result.prixPanneau;

    const simuPhotovoltaique = new SimulationPhotovoltaique(depenseInitiale, installation);
    const maxProd = simuPhotovoltaique.maxProd();
    const bilan = simuPhotovoltaique.bilan();
    const economie25ans = simuPhotovoltaique.calculEconomiePhotovoltaique(bilan, DUREE_PROJETCTION_FINANCIERE);

    /**
     * Fonction pour l'envoi de la validation
     */
    const launchActionLogementValidateRequest = async () => {
        // Affichage écran de chargement
        AlertSwal.fire({
            title: 'Génération du bon de commande',
            html: <SwalComponent />,
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: false,
            focusConfirm: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });
        try {
            const actionLogementJSON = JSON.parse(localStorage.getItem(`actionLogementJSON`)!);
            console.log('actionLogementJSON ' + JSON.stringify(actionLogementJSON));

            const responseActionLogementValidate = await postActionLogementValidateData(actionLogementJSON);
            console.log('responseActionLogementValidate ' + JSON.stringify(responseActionLogementValidate?.response?.data));
            // Fermeture écran de chargement
            setTimeout(() => {
                Swal.close();
            }, 1000);

            history.push(ROUTE_AUDIT_AL_SIGNATURE + '?token=' + responseActionLogementValidate?.response?.data?.accessToken);
            return responseActionLogementValidate?.response?.data?.accessToken;
        } catch (error) {
            console.log('responseActionLogementValidate catched : ' + JSON.stringify(error));
            throw error;
        }
    };

    return (
        <div className="container pb-4" id="syntheseP">
            <div className="row mt-4 bg-white p-container">
                <h1 className="p-main-title px-custom">Je produis mon électricité</h1>

                <div className="col-12 col-md-9 ps-md-0 ps-lg-custom">
                    <div className="p-eligible-pave">
                        Vous n'êtes pas éligible aux aides Action Logement, cependant la configuration de votre habitation{' '}
                        <u>
                            vous permet d'installer des panneaux photovoltaïque et de réaliser {currencyFormat(bilan!.autoconsomationGain + bilan!.reventeGain)}{' '}
                            d'économie/gain soit {currencyFormat(economie25ans.totalHorsInstallation)} sur 25ans
                        </u>
                    </div>

                    <div className="p-container-left">
                        <div className="p-installation">
                            <h2>Mon installation photovoltaïque</h2>

                            <div className="power">
                                <ul>
                                    <li>
                                        <IconHouse />
                                    </li>
                                    <li className="select">
                                        <label htmlFor="photovoltaic">Puissance</label>
                                        <span>{panneau.puissance / 1000} kWc</span>
                                    </li>
                                </ul>

                                <div className="panel-number">
                                    <p>
                                        Nbre de panneaux photovoltaïques <span>{panneau.nombrePanneau}</span>
                                    </p>
                                </div>

                                <div className="panel-revente">
                                    <div className="production">
                                        <h4>
                                            Mode de Production:
                                            <br />
                                            <span className="text-uppercase">revente en surplus</span>
                                        </h4>

                                        <IconLightning />
                                    </div>

                                    <ul className="revente-list">
                                        <li>Partie autoconsommée</li>
                                        <li>
                                            <span>70%</span>
                                        </li>
                                        <li>Autoconso./ 25 ans</li>
                                        <li>{currencyFormat(economie25ans.autoconso)} </li>
                                        <li>Revente sur 25 ans</li>
                                        <li>{currencyFormat(economie25ans.revente)} </li>
                                        <li>Moyenne annuelle</li>
                                        <li>{currencyFormat(economie25ans.moyenneParAn)} </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="p-economie">
                            <h2>Mes économies/gains réalisées</h2>
                            <ul className="card-list">
                                <li>
                                    <IconIndependance />
                                    <h5>{Math.round(bilan!.independence * 100.0)}%</h5>
                                    <p>indépendance électrique</p>
                                </li>
                                <li>
                                    <IconEconomie />
                                    <h5>{currencyFormat(economie25ans.totalHorsInstallation)}</h5>
                                    <p>Economies et gains sur 25 ans</p>
                                </li>
                                <li>
                                    <IconLightning />
                                    <h5>{Math.round(maxProd)} kWh</h5>
                                    <p>Production Photovoltaïque</p>
                                </li>
                            </ul>

                            <div className="panel-table">
                                <div className="lines">
                                    <div className="line"></div>
                                    <div className="line"></div>
                                    <div className="line"></div>
                                    <div className="line"></div>
                                </div>

                                <div className="chart">
                                    <div className="bigline before">
                                        <p>
                                            {Math.round(depenseInitiale.consoElectricite())} kWh
                                            <br />
                                            {currencyFormat(depenseInitiale.prixElectricite())}
                                        </p>
                                    </div>
                                    <div className="bigline after">
                                        <div className="economie">
                                            <span>
                                                -
                                                {Math.round(
                                                    // TODO : ca ne devrait être que hors abonnement.
                                                    // (100 * (depenseInitiale.totalElectriciteHorsAbo() - bilan!.coutHorsPhotovoltaiqueHorsAbo)) /
                                                    //     depenseInitiale.totalElectriciteHorsAbo()
                                                    (100 * (depenseInitiale.prixElectricite() - bilan!.coutHorsPhotovoltaique)) /
                                                        depenseInitiale.prixElectricite()
                                                )}
                                                %
                                            </span>
                                            d'économie/gain d'életricité
                                        </div>

                                        <p>
                                            <i>Autoconsommation</i>
                                            {Math.round(bilan!.autoconsomationEnergie)} kWh
                                            <br />
                                            <i>Revente de surplus</i>
                                            {Math.round(bilan!.reventeEnergie)} kWh
                                            <br />
                                            <i>Coûts</i>
                                            {
                                                // TODO : ca ne devrait être que hors abonnement.
                                                //currencyFormat(bilan!.coutHorsPhotovoltaiqueHorsAbo - bilan!.reventeGain)
                                                currencyFormat(bilan!.coutHorsPhotovoltaique - bilan!.reventeGain)
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="panel-idea">
                        <h3 className="title">
                            Faites {currencyFormat(economie25ans.totalHorsInstallation)} d'économie/gain d'électricité grâce à votre installation photovoltaïque
                        </h3>
                        <div className="pave">
                            <IconAmpoule />
                            <p>
                                Choisissez le niveau d'indépendance énergétique auquel vous souhaitez accéder.
                                <br />
                                Ne subissez plus les augmentations successif de l'énergie (+50% en 10ans)
                                <br />
                                <strong>
                                    <u>Produire votre électricité, c'est augmenter son pouvoir d'achat et valoriser votre bien</u>
                                </strong>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-3 pe-md-0 pe-lg-custom">
                    <div className="recapitulatif">
                        <div className="recapitulatif-header">
                            <IconRecap />
                        </div>

                        <div className="recapitulatif-body">
                            <h2 className="recapitulatif-body-title">Récapitulatif</h2>

                            <p>Centrale photovoltaïque</p>
                            <p>Puissance : {panneau.puissance / 1000} kW</p>
                            <p>Installation incluse</p>

                            <ul>
                                <li className="price">
                                    <ul>
                                        <li className="left">Prix:</li>
                                        <li className="right">{currencyFormat(prixPackage.prixTTC)}</li>
                                    </ul>
                                </li>
                                {prixPackage.aides.map((aide, i) => (
                                    <li key={auditId + '_nom_' + i + '_' + aide.titre}>
                                        <ul>
                                            <li className="left">{aide.titre}</li>
                                            <li className="right">{currencyFormat(aide.montant)}</li>
                                        </ul>
                                    </li>
                                ))}
                                <li className="left">Total des aides</li>
                                <li className="right">{currencyFormat(prixPackage.totalAides)}</li>
                                <li className="total">
                                    <ul>
                                        <li className="left">Total aides déduites:</li>
                                        <li className="right">{currencyFormat(prixPackage.prixAvecAide)}</li>
                                    </ul>
                                </li>
                            </ul>
                            <>
                                <button type="button" className="btn-commander" onClick={launchActionLogementValidateRequest}>
                                    Je commande
                                </button>
                            </>
                        </div>
                    </div>

                    <div className="recapitulatif-icon">
                        <IconPhotoHouse />
                    </div>
                </div>
            </div>
        </div>
    );
};

//<button type="button" className="btn-commander" onClick={launchActionLogementValidateRequest} >Je commande</button>
