import React, { useState } from 'react';
import { Formik } from 'formik';
import { CONSTANTES_PHOTOVOLTAIQUES } from '../../../services/localStorageService';
import { ROUTE_SIM_ANNEXE, ROUTE_SIM_FINANCEMENT, ROUTE_SIM_TVA } from '../../../routing/paths';
import { Link, useLocation } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { PropertyType } from '../../../services/localStorageService';
import * as storageService from '../../../services/localStorageService';

import { getFirstInstallation, hasFullTVA, hasInsurance } from '../../../services/calculs/theme';
import { getPrixKwhRevente } from '../../../services/calculs/calculPhotovoltaique';
import PdfModalContent from '../../../components/PdfModalContent/PdfModalContent';
import { dummyInstallationPV, getEnsoleillement, getRendement, toExposition } from '../../../services/calculs/installationPV';
import { percentFormat } from '../../../services/tools/TypeHelper';
import * as api from '../../../services/apiParticulierService';
import * as bdc from '../parcoursBdcCommmons';
import { useHistory } from 'react-router';
import { validationSchema } from './schema/schema';

// Icons
import { ReactComponent as IconPhotovoltaique } from '../../../assets/icons/simulator/icon-photovoltaique.svg';
import { ReactComponent as IconTva } from '../../../assets/icons/simulator/icon-tva.svg';
import { ReactComponent as Loader } from '../../../assets/icons/loader.svg';
import { ModalCounter } from '../../../components/ModalCounter/ModalCounter';
// import GoogleMapsWrapper from '../../../components/GoogleMaps/GoogleMapsWrapper';
import { randomKeyGen } from '../../../services/tools/auditTools';
import { editablePolygonOptions } from '../../../components/GoogleMaps/GoogleMapsDraw';

// README : voir BonDeCommande.tsx

// Les PDF que cet écran permet de créer
// EN CAS DE CHANGEMENT FAIRE ATTENTION AUX FONCTIONS initialisePdfContext() et arePdfInitialized().
const PDF_NAMES_FOR_THIS_SCREEN: api.DocNames[] = ['simulation', 'mandat_enedis', 'assurance_mma'];

export type RendementFormValues = {
    sunlight: number;
    roofPitch: string;
    direction: string;
    installationPower: string;
    installationEfficiency: string;
    production: number;
    insurance: string;
    contractNumber: string;
    solarEnergyPanels: CustomPolygon[];
};

export type GeoPosition = {
    lat: number;
    lng: number;
};

export type Path = GeoPosition[];

export type CustomPolygonSerializable = {
    paths: GeoPosition[];
    area?: number;
    id: string;
};

export interface PolygonWithID extends google.maps.Polygon {
    id?: string;
}

export interface CustomPolygon extends CustomPolygonSerializable {
    instance: PolygonWithID;
}

// Convert polygons into polygons with googlemaps instances
export const convertToNonSerializable = (serializableArr: CustomPolygonSerializable[]): CustomPolygon[] => {
    if (!serializableArr) return Array<CustomPolygon>();

    const energyPanelsWithInstance: Array<CustomPolygon> = serializableArr.map((polygon) => {
        const newInstance = new google.maps.Polygon({
            ...editablePolygonOptions,
            paths: polygon.paths,
        });
        return { ...polygon, instance: newInstance } as CustomPolygon;
    });
    return energyPanelsWithInstance;
};

// Convert polygons into serializable polygons to be stored in localStorage
// TODO Technical Debt: We could maybe use JSON.stringify() replacer param to implement a better serializer
export const convertToSerializable = (customs: CustomPolygon[]): CustomPolygonSerializable[] => {
    const serializableArr = customs.map((custom) => {
        const vertices = custom.instance.getPath();
        const area = google.maps.geometry.spherical.computeArea(custom.instance.getPath());

        const paths = vertices.getArray().map((latLng) => ({
            lat: latLng.lat(),
            lng: latLng.lng(),
        }));

        const newPolygon: CustomPolygonSerializable = {
            id: custom.instance?.id ?? randomKeyGen(),
            paths,
            area,
        };
        return newPolygon;
    });
    return serializableArr;
};

// #region tools

// //     /** Icoll list for listeexposition*/
const toIcollOrientation = (sogysOrientation: string): string => {
    switch (sogysOrientation) {
        case 'Est':
        case 'est':
        case 'E':
            return '2';
        case 'sudest':
        case 'sud-est':
        case 'Sud-Est':
        case 'SE':
            return '3';
        case 'sud':
        case 'Sud':
        case 'S':
            return '4';
        case 'sudouest':
        case 'sud-ouest':
        case 'Sud-Ouest':
        case 'SO':
            return '5';
        case 'ouest':
        case 'Ouest':
        case 'O':
            return '6';
    }
    return '1';
};

// /** Icoll list for listeinclinaison*/
const toIcollInclinaison = (sogysInclinaison: string): string => {
    switch (sogysInclinaison) {
        case '0':
            return '1';
        case '10':
        case '15':
            return '2';
        case '30':
        case '35':
            return '3';
        case '40':
        case '45':
            return '4';
        case '60':
            return '5';
        case '50':
        case '55':
            return '6';
        case '90':
            return '7';
        case '20':
        case '25':
            return '11';
    }
    return '1';
};
// #endregion

const Rendement: React.FC = () => {
    const [loader, setLoader] = useState<boolean>(false);
    const [pdfLoader, setPdfLoader] = useState<boolean>(false);

    const AlertSwal = withReactContent(Swal);
    const context = useLocation<bdc.BonDeCommandeContexte>().state;
    const installation = getFirstInstallation(context.themes) ?? dummyInstallationPV();
    const PV = installation.panneau;
    const maxProd = installation.maxProd;
    const hasSolar = maxProd > 0;

    const insuranceVisible: boolean = hasInsurance(context.themes); // remplacer `false par une variable`

    const audit = storageService.stepListToAuditAndClient().audit;
    const ensoleillement = getEnsoleillement(audit.department.value.slice(0, 2))?.energy;

    // Values from Audit steps
    const steps = storageService.getAudit();
    const sunlight = ensoleillement;
    const roofPitch = steps.step2.tilt;
    const direction = steps.step2.roofExposure.value_label;
    const installationPower = ((PV?.puissance ?? 0) / 1000).toString();
    const installationEfficiency = CONSTANTES_PHOTOVOLTAIQUES.efficacite;
    const ensoleillementYield = getRendement(roofPitch.value_label.slice(0, -1), toExposition(direction));
    const solarMaskPercent = steps.step2.solarMask.value === false ? '0' : '0.' + steps.step2.solarMaskPercent.value_label.slice(0, -1);
    // const center: GeoPosition = {
    //     lat: Number(steps.step1.userAddressLatitude?.value) ?? DEFAULT_LAT,
    //     lng: Number(steps.step1.userAddressLongitude?.value) ?? DEFAULT_LNG,
    // };

    const initialValues: RendementFormValues = {
        sunlight: sunlight ?? 0,
        roofPitch: roofPitch.value_label,
        direction: direction,
        installationPower,
        installationEfficiency: percentFormat(installationEfficiency),
        production: maxProd ?? 0,
        insurance: storageService.checkPropertyExistThenCreateOrRenderSimulator('insurance', 'Assurance panneaux solaires', PropertyType['basic']),
        contractNumber: storageService.checkPropertyExistThenCreateOrRenderSimulator('contractNumber', 'Numéro de contrat', PropertyType['basic']),
        solarEnergyPanels: convertToNonSerializable(
            storageService.checkPropertyExistThenCreateOrRenderSimulator('solarEnergyPanels', 'Panneaux solaires', PropertyType['basic'])
        ),
    };

    // TODO Si jamais assurance doit redevenir obligatoire, le dé-commenter
    // const validationSchema = Yup.object().shape({
    //     sunlight: Yup.string().notRequired(),
    //     roofPitch: Yup.string().notRequired(),
    //     direction: Yup.string().notRequired(),
    //     installationPower: Yup.string().notRequired(),
    //     installationEfficiency: Yup.string().notRequired(),
    //     production: Yup.string().notRequired(),
    //     insurance: insuranceVisible ? Yup.string().required("L'Assurance panneaux solaires est obligatoire") : Yup.string().notRequired(),
    //     contractNumber: insuranceVisible ? Yup.string().required('Le numéro de contrat est obligatoire') : Yup.string().notRequired(),
    // });

    // construit la vue des pdf par onglet pour l'affichage. (dans une AlertSwal.fire(...))
    const buildModalContent = (pdfList: Array<api.PdfOutput>): SweetAlertOptions => {
        const pdfContent = bdc.buildModalPdfContentBase();
        pdfContent.html = <PdfModalContent tabs={pdfList} />;
        return pdfContent;
    };

    // construit la modal qui affiche un compte à rebours.
    const buildModalCountDown = (seconds: number): SweetAlertOptions => {
        const content = bdc.buildModalCountDownBase(seconds * 1000);
        content.html = <ModalCounter timer={seconds} />;
        return content;
    };

    const pdfModal = () => {
        // Extract pdfList
        const pdfList = bdc.getOutput(context.pdfState, PDF_NAMES_FOR_THIS_SCREEN);

        // si on a déjà des pdf, on les affiche
        if (pdfList.length !== 0) {
            AlertSwal.fire(buildModalContent(pdfList));
            return;
        }

        setLoader(false);
        setPdfLoader(true);

        const aborter = new AbortController();

        AlertSwal.fire(buildModalCountDown(api.DOWNLOAD_TIME_OUT)).then((value: SweetAlertResult<any>) => {
            bdc.aborterCallback(value, aborter, setPdfLoader);
        });

        // sinon on les télécharge.
        bdc.downloadPdf(aborter, clearPdfContext, initialisePdfContext, context)
            .then(() => {
                // Extract pdfList
                // same call but context has change because we juste downloaded the pdf.
                const pdfList = bdc.getOutput(context.pdfState, PDF_NAMES_FOR_THIS_SCREEN);
                setPdfLoader(false);
                AlertSwal.close();
                AlertSwal.fire(buildModalContent(pdfList));
            })
            .catch((err) => {
                setLoader(false);
                setPdfLoader(false);

                bdc.logBdcError(err);

                // Modal contenant le message d'erreur
                AlertSwal.fire(bdc.buildModalSimpleMessage(api.ERROR_INDISPONIBLE));
            });
    };
    const { push } = useHistory();

    const clearPdfContext = (): void => {
        for (const docname of PDF_NAMES_FOR_THIS_SCREEN) {
            context.pdfState[docname] = {};
        }
    };

    // initialise le context des pdf de cet écran.
    const initialisePdfContext = (): api.DocParameters[] => {
        console.log('initialisePdfContext');

        // ATTENTION :
        // DANS l'ideal il faudrait boucler sur PDF_NAMES_FOR_THIS_SCREEN
        // Mais vu que les paramètres sont très différents les uns des autres, on le fait à la main.
        // En cas de changement, s'assurer que tous les PDF décris dans PDF_NAMES_FOR_THIS_SCREEN sont couverts.
        const result = Array<api.DocParameters>();
        // si pas de panneau solaire, on ne télécharge aucun document.
        // A mon avis on ne devrait même pas afficher celui-ci et passer direct au suivant.
        if (!hasSolar) return result;

        const prixKwhRevente = !PV ? 0 : getPrixKwhRevente(installation.modeConsommation, PV.puissance);

        const inputSimu: api.DocParameters = {
            docName: 'simulation',
            parameters: {
                pv_ensoleillement_annuel: (sunlight ?? 0) + '',
                id_exposition: toIcollOrientation(direction),
                id_inclinaison: toIcollInclinaison(roofPitch.value_label.slice(0, -1)),
                pv_coef_orientation_inclinaison: (ensoleillementYield ?? 0) + '',
                pv_puissance_installation: installationPower,
                pv_efficacite_installation: installationEfficiency + '',
                pv_production_escomptee: (Math.round(maxProd) ?? 0) + '',
                pv_tarif_base: prixKwhRevente.toFixed(4),
            },
        };
        result.push(inputSimu);
        context.pdfState['simulation'] = { input: inputSimu, output: undefined };

        const inputMandat: api.DocParameters = {
            docName: 'mandat_enedis',
            parameters: { coche1: '1', coche2: '1', coche3: '1', coche4: '1' },
        };
        result.push(inputMandat);
        context.pdfState['mandat_enedis'] = { input: inputMandat, output: undefined };

        if (insuranceVisible) {
            const simdata = storageService.getSim();
            const inputMma: api.DocParameters = {
                docName: 'assurance_mma',
                parameters: {
                    num_contrat_habitation: simdata.contractNumber.value,
                    compagnie_assurance_nom: simdata.insurance.value,

                    id_exposition: toIcollOrientation(direction),
                    id_inclinaison: toIcollInclinaison(roofPitch.value_label.slice(0, -1)),

                    pv_coefficient_ombrage: solarMaskPercent,
                    pv_puissance_installation: installationPower,
                    cotisation_puissance: selectPuissanceMMA(+installationPower),
                },
            };
            result.push(inputMma);
            context.pdfState['assurance_mma'] = { input: inputMma, output: undefined };
        }

        return result;
    };

    const selectPuissanceMMA = (power: number): string => {
        if (power <= 4) return 'cotisation_4_kwc';
        if (power <= 5) return 'cotisation_5_kwc';
        if (power <= 6) return 'cotisation_6_kwc';
        if (power <= 7) return 'cotisation_7_kwc';
        if (power <= 8) return 'cotisation_8_kwc';
        return 'cotisation_9_kwc';
    };

    /**
     * détermine si les PDF nécessaires de cet écran sont correctement initialisés!
     * La logique sera différente sur chaque écran !!!
     * On considère qu'un pdf initialisé lorsque
     *  - il est nécessaire
     *  - son input est correctement définie (!undefined)
     * @returns true si tout est initialisé correctement, false sinon.
     */
    const arePdfInitialized = (): boolean => {
        if (hasSolar && context.pdfState['simulation'].input === undefined) {
            //console.log('arePdfInitialized simulation false ');
            return false;
        }
        if (hasSolar && context.pdfState['mandat_enedis'].input === undefined) {
            //console.log('arePdfInitialized mandat_enedis false ');
            return false;
        }
        if (hasSolar && insuranceVisible && context.pdfState['assurance_mma'].input === undefined) {
            //console.log('arePdfInitialized assurance_mma false ');
            return false;
        }
        //console.log('arePdfInitialized true ');
        return true;
    };

    arePdfInitialized();

    const validateAndNavigate = async (values: RendementFormValues): Promise<void> => {
        // const panelPolygons: Path[] = values.solarEnergyPanels?.map(({ paths }: { paths: Path }) => paths);
        setLoader(true);
        setPdfLoader(false);
        try {
            // si on a initialisé le(s) pdf(s), on le fait
            if (!arePdfInitialized()) {
                initialisePdfContext();
            }
            // for (const docname of PDF_NAMES_FOR_THIS_SCREEN) {
            //     console.log('Continuer ecran rendement, context = ' + JSON.stringify(context.pdfState[docname], null, 2));
            // }
            // puis on navigue.
            // si batiment récent, pas de remise de TVA, on saute.
            const houseIsOld = steps.step2.buildingAge.value;
            const fulltva = hasFullTVA(context.themes);
            const shouldShowTva = houseIsOld && !fulltva;

            const dest = shouldShowTva ? ROUTE_SIM_TVA : ROUTE_SIM_FINANCEMENT;
            // storageService.setPrevisitValue('solarPanelsPicture', createImplantation(center, panelPolygons));
            push(dest, context);
            setLoader(false);
        } catch (err) {
            console.log((err as any).response.message);

            setLoader(false);
            setPdfLoader(false);

            // Modal contenant le message d'erreur
            AlertSwal.fire({
                title: 'Erreur',
                icon: 'error',
                html: <p>{api.ERROR_INDISPONIBLE}</p>,
                width: 600,
                confirmButtonText: 'Fermer',
                customClass: {
                    confirmButton: 'btn btn-continue min-width',
                },
            });
        }
    };

    return (
        <Formik validateOnMount={true} initialValues={initialValues} validationSchema={validationSchema} onSubmit={() => {}}>
            {({ values, errors, setValues, handleBlur }) => {
                return (
                    <div className="container container-audit">
                        <h1 className="main-title-mini">Rendement</h1>

                        <div className="card card-audit-simulator engagement-client mb-5">
                            <div className="card-header">
                                <h2>
                                    <IconPhotovoltaique fill="#FFFFFF" />
                                    Simulation de rendement
                                </h2>
                            </div>

                            <div className="card-body">
                                <div className="row mb-3">
                                    <div className="col-12 col-md-4 mb-3 mb-md-0">
                                        <label htmlFor="sunlight">Ensoleillement (kWh/m2/an)</label>
                                        <input type="text" name="sunlight" id="sunlight" value={values.sunlight} disabled={true} />
                                    </div>
                                    <div className="col-12 col-md-4 mb-3 mb-md-0">
                                        <label htmlFor="roofPitch">Inclinaison de la toiture</label>
                                        <input type="text" name="roofPitch" id="roofPitch" value={values.roofPitch} disabled={true} />
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <label htmlFor="direction">Orientation</label>
                                        <input type="text" name="direction" id="direction" value={values.direction} disabled={true} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-4 mb-3 mb-md-0">
                                        <label htmlFor="installationPower">Puissance de l'installation (kWc)</label>
                                        <input type="text" name="installationPower" id="installationPower" value={values.installationPower} disabled={true} />
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <label htmlFor="installationEfficiency">Efficacité de l'installation</label>
                                        <input
                                            type="text"
                                            name="installationEfficiency"
                                            id="installationEfficiency"
                                            value={values.installationEfficiency}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="col-12 col-md-4 mb-3 mb-md-0">
                                        <label htmlFor="production">Production annuelle photovoltaïque (kWh)</label>
                                        <input type="text" name="production" id="production" value={Math.round(values.production)} disabled={true} />
                                    </div>
                                </div>
                                {/* <div className="mb-4 d-flex flex-row">
                                    <h2 className="title-card">Schéma de la toiture et emplacement des modules</h2>
                                    <h3 className="title-card-sub">&nbsp;(vue du dessus)</h3>
                                </div>
                                <GoogleMapsWrapper center={center} /> */}
                            </div>
                        </div>

                        {insuranceVisible && (
                            <div className="card card-audit-simulator mb-5">
                                <div className="card-header">
                                    <IconTva fill="#FFFFFF" />
                                    <h2>Assurance Solaire MMA information relative à votre assurance habitation</h2>
                                </div>

                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 col-md-4 mb-4">
                                            <div className="form-group">
                                                <label htmlFor="contractNumber">Numéro de contrat</label>
                                                <input
                                                    type="text"
                                                    value={values.contractNumber}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        clearPdfContext(); // would force to re-doawnload pdf.
                                                        setValues({
                                                            ...values,
                                                            contractNumber: event.target.value,
                                                        });

                                                        storageService.setSimValue('contractNumber', event.target.value);
                                                    }}
                                                    onBlur={handleBlur}
                                                    name="contractNumber"
                                                    id="contractNumber"
                                                    placeholder="Numéro de contrat"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-4 mb-4">
                                            <div className="form-group">
                                                <label htmlFor="insurance">Compagnie d'assurance</label>
                                                <input
                                                    type="text"
                                                    value={values.insurance}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        clearPdfContext(); // would force to re-doawnload pdf.
                                                        setValues({
                                                            ...values,
                                                            insurance: event.target.value,
                                                        });

                                                        storageService.setSimValue('insurance', event.target.value);
                                                    }}
                                                    onBlur={handleBlur}
                                                    id="insurance"
                                                    name="insurance"
                                                    placeholder="Compagnie d'assurance"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="btn-grp justify-content-end">
                            <Link
                                to={{
                                    pathname: ROUTE_SIM_ANNEXE,
                                    state: context,
                                }}
                                className="btn btn-retour"
                            >
                                Retour
                            </Link>

                            <div className={pdfLoader || loader ? 'not-allowed' : 'allowed'}>
                                <button
                                    type="button"
                                    className={`btn btn-bdc-modal${Object.entries(errors).length > 0 ? ' disabled' : ''}`}
                                    onClick={pdfModal}
                                    disabled={!hasSolar || loader || pdfLoader}
                                >
                                    {pdfLoader && <Loader />}
                                    <span style={{ opacity: pdfLoader ? '0' : '1' }}>Visualiser les documents {hasSolar}</span>
                                </button>
                            </div>
                            <div className={pdfLoader || loader ? 'not-allowed' : 'allowed'}>
                                <button
                                    className={`btn btn-continue${Object.entries(errors).length > 0 ? ' disabled' : ''}`}
                                    onClick={() => validateAndNavigate(values)}
                                    disabled={loader || pdfLoader}
                                >
                                    {loader && <Loader />}
                                    <span style={{ opacity: loader ? '0' : '1' }}>Continuer</span>
                                </button>
                            </div>
                        </div>
                    </div>
                );
            }}
        </Formik>
    );
};

export default Rendement;
