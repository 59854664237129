import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link, useHistory, useLocation } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { PropertyType } from '../../../services/localStorageService';
import * as storageService from '../../../services/localStorageService';

import { ROUTE_SIM_FINANCEMENT, ROUTE_SIM_SIGN } from '../../../routing/paths';
import * as api from '../../../services/apiParticulierService';
import PdfModalContent from '../../../components/PdfModalContent/PdfModalContent';
import * as bdc from '../parcoursBdcCommmons';
import { ModalCounter } from '../../../components/ModalCounter/ModalCounter';

// Icons
import { ReactComponent as IconConfirmation } from '../../../assets/icons/simulator/icon-confirmation.svg';
import { ReactComponent as Loader } from '../../../assets/icons/loader.svg';

// README : voir BonDeCommande.tsx

// Les PDF que cet écran permet de créer
// EN CAS DE CHANGEMENT FAIRE ATTENTION AUX FONCTIONS initialisePdfContext().
const PDF_NAMES_FOR_THIS_SCREEN: api.DocNames[] = ['engagement_client_sogys'];

const SEND_WHOLE_CMD_TIME_OUT = 240;

type FormValues = {
    engagement1: boolean;
    engagement2: boolean;
    engagement3: boolean;
    engagement4: boolean;
    engagement5: boolean;
};

const EngagementClient: React.FC = () => {
    const [loader, setLoader] = useState<boolean>(false);
    const [pdfLoader, setPdfLoader] = useState<boolean>(false);

    const AlertSwal = withReactContent(Swal);
    const simdata = storageService.getSim();
    const context = useLocation<bdc.BonDeCommandeContexte>().state;

    const initialValues: FormValues = {
        engagement1: storageService.checkPropertyExistThenCreateOrRenderSimulator(
            'engagement1',
            "Je certifie que l'entreprise m'a proposé un contrat d'entretien",
            PropertyType['boolean']
        ),
        engagement2: storageService.checkPropertyExistThenCreateOrRenderSimulator(
            'engagement2',
            'J\'autorise "nom de l\'entreprise" le cas échéant à communiquer mes coordonnées',
            PropertyType['boolean']
        ),
        engagement3: storageService.checkPropertyExistThenCreateOrRenderSimulator(
            'engagement3',
            'Je reconnais que toutes les simulations financières',
            PropertyType['boolean']
        ),
        engagement4: storageService.checkPropertyExistThenCreateOrRenderSimulator(
            'engagement4',
            'Les aides ont été calculé par rapport aux informations transmises sur votre foyers fiscal',
            PropertyType['boolean']
        ),
        engagement5: storageService.checkPropertyExistThenCreateOrRenderSimulator(
            'engagement5',
            "L'entreprise m'a proposé un crédit affecté qui engendrera des intérêts d'emprunt",
            PropertyType['boolean']
        ),
    };

    const validationSchema = Yup.object({
        engagement1: Yup.boolean().oneOf([true], `Cette case est obligatoire`),
        engagement2: Yup.boolean().notRequired(),
        engagement3: Yup.boolean().oneOf([true], `Cette case est obligatoire`),
        engagement4: Yup.boolean().oneOf([true], `Cette case est obligatoire`),
        engagement5: Yup.boolean().oneOf([true], `Cette case est obligatoire`),
    });

    // construit la vue des pdf par onglet pour l'affichage. (dans une AlertSwal.fire(...))
    const buildModalContent = (pdfList: Array<api.PdfOutput>): SweetAlertOptions => {
        const pdfContent = bdc.buildModalPdfContentBase();
        pdfContent.html = <PdfModalContent tabs={pdfList} />;
        return pdfContent;
    };

    // construit la modal qui affiche un compte à rebours.
    const buildModalCountDown = (seconds: number, overrideTitle: string | undefined = undefined): SweetAlertOptions => {
        const content = bdc.buildModalCountDownBase(seconds * 1000);
        if (overrideTitle) content.title = overrideTitle;
        content.html = <ModalCounter timer={seconds} />;
        return content;
    };

    const pdfModal = () => {
        // Extract pdfList
        const pdfList = bdc.getOutput(context.pdfState, PDF_NAMES_FOR_THIS_SCREEN);

        // si on a déjà des pdf, on les affiche
        if (pdfList.length !== 0) {
            AlertSwal.fire(buildModalContent(pdfList));
            return;
        }

        setLoader(false);
        setPdfLoader(true);

        const aborter = new AbortController();

        AlertSwal.fire(buildModalCountDown(api.DOWNLOAD_TIME_OUT)).then((value: SweetAlertResult<any>) => {
            bdc.aborterCallback(value, aborter, setPdfLoader);
        });

        // sinon on les télécharge.
        bdc.downloadPdf(aborter, clearPdfContext, initialisePdfContext, context)
            .then(() => {
                // Extract pdfList
                // same call but context has change because we juste downloaded the pdf.
                const pdfList = bdc.getOutput(context.pdfState, PDF_NAMES_FOR_THIS_SCREEN);
                setPdfLoader(false);
                AlertSwal.close();
                AlertSwal.fire(buildModalContent(pdfList));
            })
            .catch((err) => {
                setLoader(false);
                setPdfLoader(false);

                bdc.logBdcError(err);

                // Modal contenant le message d'erreur
                AlertSwal.fire(bdc.buildModalSimpleMessage(api.ERROR_INDISPONIBLE));
            });
    };

    const { push } = useHistory();

    // vide les contexte des pdf concernés par cet écran
    const clearPdfContext = (): void => {
        //console.log('clearPdfContext');
        for (const docname of PDF_NAMES_FOR_THIS_SCREEN) {
            context.pdfState[docname] = {};
        }
    };

    // initialise le context des pdf de cet écran.
    const initialisePdfContext = (): api.DocParameters[] => {
        // ATTENTION :
        // DANS l'ideal il faudrait boucler sur PDF_NAMES_FOR_THIS_SCREEN
        // Mais vu que les paramètres sont très différents les uns des autres, on le fait à la main.
        // En cas de changement, s'assurer que tous les PDF décris dans PDF_NAMES_FOR_THIS_SCREEN sont couverts.
        const docName: api.DocNames = 'engagement_client_sogys';
        const input: api.DocParameters = { docName, parameters: undefined };
        context.pdfState[docName] = { input, output: undefined };

        return [input];
    };

    /**
     * détermine si les PDF nécessaires de cet écran sont correctement initialisés!
     * La logique sera différente sur chaque écran !!!
     * On considère qu'un pdf initialisé lorsque
     *  - il est nécessaire
     *  - son input est correctement définie (!undefined)
     * @returns true si tout est initialisé correctement, false sinon.
     */
    const arePdfInitialized = (): boolean => {
        for (const docname of PDF_NAMES_FOR_THIS_SCREEN) {
            if (context.pdfState[docname].input === undefined) {
                return false;
            }
        }
        return true;
    };

    const validateAndNavigate = async (): Promise<void> => {
        setLoader(true);
        setPdfLoader(false);

        const aborter = new AbortController();

        const docname = storageService.isCurrentAuditMar() ? 'Devis' : 'Bon de commande';
        AlertSwal.fire(buildModalCountDown(SEND_WHOLE_CMD_TIME_OUT, 'Génération de votre\n' + docname)).then((value: SweetAlertResult<any>) => {
            bdc.aborterCallback(value, aborter, setPdfLoader);
        });

        // const auditId = localStorage.getItem('auditId')!;
        const flowId = localStorage.getItem('flowId')!;

        try {
            // si on pas a initialisé le(s) pdf(s), on le fait
            if (!arePdfInitialized()) {
                initialisePdfContext();
            }

            //console.time('Envoyer tous les docs');
            // Là on a fini le parcours bon de commande.
            // à chaque étape on a soit téléchargé le document, soit pas.
            // maintenant, il faut signer.
            // avant de signer, il faut envoyer tous les documents qu'on a pas encore envoyé

            try {
                // create all document that have not already been generated (/downloaded)
                await bdc.downloadPdfMissing(aborter, context);
            } catch (err) {
                setLoader(false);
                setPdfLoader(false);

                bdc.logBdcError(err);

                // Modal contenant le message d'erreur
                AlertSwal.fire(bdc.buildModalSimpleMessage(api.ERROR_INDISPONIBLE));

                return;
            } finally {
                // console.timeEnd('Envoyer tous les docs');
                setLoader(false);
                setPdfLoader(false);
            }

            // update devis data
            await api.updateDevis(flowId, simdata);
            // then sign.
            const sign: api.SignOutput = await api.signDocs(flowId, SEND_WHOLE_CMD_TIME_OUT * 1000, aborter);
            // On ferme la modale de décompte
            AlertSwal.close();
            // puis on navigue.
            // ATTENTION. ici, j'envoie le contexte de création de dossier néthéos, par le retour de sign.
            // l'objectif est qu'en cas de retour à cette page,
            // la page 'Signer' puisse faire revenir ce contexte pour que la procédure puisse recommencer.
            // Certains cas ne sont pas couverts, comme le bouton back du navigateur.
            push(ROUTE_SIM_SIGN + '?token=' + sign.accessToken, context);

            setPdfLoader(false);
            setLoader(false);
        } catch (err) {
            bdc.logBdcError(err);
            // On traite le cas d'erreur qui dit qu'un parcours est déjà en cours.
            const icollErr = err as any;
            if (
                icollErr.status === 'Bad Request' &&
                icollErr &&
                icollErr.response &&
                icollErr.response.message === 'Un parcours de signature est déjà en cours !'
            ) {
                console.log('Parcours déjà en cours : RETRY.');
                try {
                    await api.rejectDocs(flowId);
                    console.log(' ... rejected succeed.');
                    const sign: api.SignOutput = await api.signDocs(flowId);
                    console.log(' ... retry sign succeed !');
                    // On ferme la modale de décompte
                    AlertSwal.close();
                    // puis on navigue.
                    push(ROUTE_SIM_SIGN + '?token=' + sign.accessToken, context);

                    setLoader(false);
                    setPdfLoader(false);
                    return;
                } catch (err) {
                    console.log('catch => ' + JSON.stringify(err));
                    // Si ca ne marche pas deux fois de suite, on abandonne.
                    // on reprend le parcours
                    // errorMessage = "Le devis n'a pas pu être validé. (" + (err as any).result.response.message + '), après 2 essais.';
                }
            } else {
                console.log('else => ' + JSON.stringify(err));
                //errorMessage = "Le devis n'a pas pu être validé. (" + (err as any).response.message + ')';
            }

            setLoader(false);
            setPdfLoader(false);

            // On ferme la modale de décompte
            AlertSwal.close();

            // Modal contenant le message d'erreur
            AlertSwal.fire({
                title: 'Erreur',
                icon: 'error',
                //html: <p>{errorMessage}</p>,
                html: <p>{api.ERROR_INDISPONIBLE}</p>,
                width: 600,
                confirmButtonText: 'Fermer',
                customClass: {
                    confirmButton: 'btn btn-continue min-width',
                },
            });
        }
    };

    return (
        <Formik validateOnMount={true} initialValues={initialValues} validationSchema={validationSchema} onSubmit={() => {}}>
            {({ values, errors, handleBlur, setValues }) => {
                return (
                    <div className="container container-audit">
                        <h1 className="main-title-mini">Engagement client</h1>

                        <div className="card card-audit-simulator engagement-client mb-5">
                            <div className="card-header">
                                <IconConfirmation fill="#FFF" />
                                <h2>Confirmer en cochant les cases</h2>
                            </div>

                            <div className="card-body">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-10 mb-3">
                                        <div className="form-group contract-checkbox">
                                            <input
                                                type="checkbox"
                                                onBlur={handleBlur}
                                                onChange={() => {
                                                    clearPdfContext();
                                                    setValues({
                                                        ...values,
                                                        engagement1: !values.engagement1,
                                                    });

                                                    storageService.setSimValue('engagement1', !values.engagement1);
                                                }}
                                                id="engagement1"
                                                name="engagement1"
                                                checked={values.engagement1}
                                                required
                                            />
                                            <label htmlFor="engagement1" className="label-to-p">
                                                Je certifie que l'entreprise m'a proposé un contrat d'entretien et que pour des questions pratique et
                                                d'éfficacité j'opterais pour la souscription d'un contrat d'entretien auprès d'une entreprise locale et ou
                                                nationale. Ce contrat prendra en charge le service après vente une fois les délais de garantie de la pose passée
                                                (hors décennal). Une liste d'entreprises partenaires et spécialisées me sera transmise par mail afin que je
                                                prenne contact avec eux.
                                                <sup>*</sup>
                                            </label>

                                            <div className="fake-box"></div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-10 mb-3">
                                        <div className="form-group contract-checkbox">
                                            <input
                                                type="checkbox"
                                                onBlur={handleBlur}
                                                onChange={() => {
                                                    clearPdfContext();

                                                    setValues({
                                                        ...values,
                                                        engagement2: !values.engagement2,
                                                    });

                                                    storageService.setSimValue('engagement2', !values.engagement2);
                                                }}
                                                id="engagement2"
                                                name="engagement2"
                                                checked={values.engagement2}
                                            />
                                            <label htmlFor="engagement2" className="label-to-p">
                                                J'autorise <strong>SOGYS</strong> le cas échéant à communiquer mes coordonnées à une entreprise locale ou
                                                nationale afin d'établir ce contrat d'entretien.
                                            </label>

                                            <div className="fake-box"></div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-10 mb-3">
                                        <div className="form-group contract-checkbox">
                                            <input
                                                type="checkbox"
                                                onBlur={handleBlur}
                                                onChange={() => {
                                                    clearPdfContext();
                                                    setValues({
                                                        ...values,
                                                        engagement3: !values.engagement3,
                                                    });

                                                    storageService.setSimValue('engagement3', !values.engagement3);
                                                }}
                                                id="engagement3"
                                                name="engagement3"
                                                checked={values.engagement3}
                                                required
                                            />
                                            <label htmlFor="engagement3" className="label-to-p">
                                                Je reconnais que toutes les simulations financières réalisées sont faites qu'à titre indicatives et certifie la
                                                véracité des informations transmises ayant servies à les réaliser.<sup>*</sup>
                                            </label>

                                            <div className="fake-box"></div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-10 mb-3">
                                        <div className="form-group contract-checkbox">
                                            <input
                                                type="checkbox"
                                                onBlur={handleBlur}
                                                onChange={() => {
                                                    clearPdfContext();
                                                    setValues({
                                                        ...values,
                                                        engagement4: !values.engagement4,
                                                    });

                                                    storageService.setSimValue('engagement4', !values.engagement4);
                                                }}
                                                id="engagement4"
                                                name="engagement4"
                                                checked={values.engagement4}
                                                required
                                            />
                                            <label htmlFor="engagement4" className="label-to-p">
                                                Les aides ont été calculé par rapport aux informations transmises sur votre foyers fiscal. Je pourrais vérifier
                                                mon éligibilité sur le site gouvernemental{' '}
                                                <a href="https://france-renov.gouv.fr/aides/simulation" rel="noreferrer" target="_blank">
                                                    https://france-renov.gouv.fr/aides/simulation
                                                </a>{' '}
                                                et qu'en aucun cas je pourrais me retourner contre l'entrprise si ces données s'avérerait fausses et ou que
                                                j'ometterais de faire et ou suivre les démarches me permettant de les obtenir.<sup>*</sup>
                                            </label>

                                            <div className="fake-box"></div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-10">
                                        <div className="form-group contract-checkbox">
                                            <input
                                                type="checkbox"
                                                onBlur={handleBlur}
                                                onChange={() => {
                                                    clearPdfContext();
                                                    setValues({
                                                        ...values,
                                                        engagement5: !values.engagement5,
                                                    });

                                                    storageService.setSimValue('engagement5', !values.engagement5);
                                                }}
                                                id="engagement5"
                                                name="engagement5"
                                                checked={values.engagement5}
                                                required
                                            />
                                            <label htmlFor="engagement5" className="label-to-p">
                                                (si choix financement) L'entreprise m'a proposé un crédit affecté qui engendrera des intérêts d'emprunt. Je
                                                reconnais par la présente que les projections financières ont été réalisées hors intérêt d'emprunt.<sup>*</sup>
                                            </label>

                                            <div className="fake-box"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="btn-grp justify-content-end">
                            <Link to={{ pathname: ROUTE_SIM_FINANCEMENT, state: context }} className="btn btn-retour">
                                Retour
                            </Link>

                            <div className={`${Object.entries(errors).length > 0 || loader || pdfLoader ? 'not-allowed' : 'allowed'}`}>
                                <button
                                    type="button"
                                    className={`btn btn-bdc-modal${Object.entries(errors).length > 0 ? ' disabled' : ''}`}
                                    onClick={pdfModal}
                                    disabled={loader || pdfLoader}
                                >
                                    {pdfLoader && <Loader />}
                                    <span style={{ opacity: pdfLoader ? '0' : '1' }}>Visualiser le document</span>
                                </button>
                            </div>

                            <div className={`${Object.entries(errors).length > 0 || loader || pdfLoader ? 'not-allowed' : 'allowed'}`}>
                                <button
                                    className={`btn btn-continue ${Object.entries(errors).length > 0 ? 'disabled' : ''}`}
                                    onClick={validateAndNavigate}
                                    disabled={loader || pdfLoader}
                                >
                                    {loader && <Loader />}
                                    <span style={{ opacity: loader ? '0' : '1' }}>Continuer</span>
                                </button>
                            </div>
                        </div>
                    </div>
                );
            }}
        </Formik>
    );
};

export default EngagementClient;
