import { FilterThemePreference } from '../../pages/espace-agent/mon-compte/preference';
import * as storageService from '../../services/localStorageService';
import { filterChangementComportemental } from './filterPreconisation/filterChangementComportemental';
import { filterEauChaudeSanitaire } from './filterPreconisation/filterEauChaude';
import { filterIsolation } from './filterPreconisation/filterIsolation';
import { filterPorteEtFenetre } from './filterPreconisation/filterPorteFenetre';
import { filterBatterie, filterGarantieRvenuSolaire, filterPV } from './filterPreconisation/filterPV';
import { filterVentilation } from './filterPreconisation/filterVentilation';
import { PackageData } from './package';
import { isSubThemeType, Theme, SubTheme, SubThemeType, isComplexThemeType } from './theme';
import { filterChauffage } from './filterPreconisation/filterChauffage';

// Readme
//
// Dans ce fichier, on va trouver des références à tous les filtres placés dans le dossier du même nom.
// L'idée généralee st de prendre un audit et de trouver tous les les packages Icoll
// Puis trouver les packages qui matchent le mieux pour l'amélioration énergétique de cette maison.
//
// Il y a 2 aspects :
//  - le fitlrage
//  - la selection
//
// Le filtrage permet de supprimer de la liste les packages qui ne correspondent pas.
// La selection permet de modifier l'état 'applicable' du package.
//
// Le nombre de règles de filtrage et de selection a explosé entre mars et mai 2023.
// Les effets de bords deviennent assez conséquents, la maintenabilité est épouvantable.
// En particulier pour les pompes a chaleur. (chauffage).

const TVA_MAJORED = '-TVAMAJ';
/** filtre les packages dont les taux de tva ne sont pas adaptés. */
export const filterTva = async (audit: any, themes: Array<Theme>): Promise<void> => {
    // si la maison est ancienne
    //   => on garde les packages à TVA minorée.
    // si la maison est récente
    //   => on garde les packages à TVA majorée
    // les package à TVA majorée sont marqué avec -TVAMAJ dans leur ref.

    const houseIsNew = !(storageService.getAudit().step2.buildingAge.value as boolean);

    for (const theme of themes) {
        for (const subTheme of theme.subThemes!) {
            subTheme.packages = subTheme.packages.filter((pack: PackageData) => {
                //const tvamajpack = pack.reference.endsWith(TVA_MAJORED);
                // doit retourner true pour garder, false pour supprimer.
                // if (houseIsNew && tvamajpack) return true;
                // if (!houseIsNew && !tvamajpack) return true;
                // return false;
                return houseIsNew === pack.reference.endsWith(TVA_MAJORED);
            });
        }
    }
};

const AL = 'AL-';

/** filtre les packages liés à action logement. */
export const filterAL = async (themes: Array<Theme>): Promise<void> => {
    for (const theme of themes) {
        for (const subTheme of theme.subThemes!) {
            subTheme.packages = subTheme.packages.filter((pack: PackageData) => {
                return !pack.reference.startsWith(AL);
            });
        }
    }
};

export const filterByUserPref = async (pref: FilterThemePreference | undefined, themes: Array<Theme>): Promise<Array<Theme>> => {
    if (!pref) {
        console.log('pref not ready');
        return themes;
    }

    for (const theme of themes) {
        const themeType = theme.themeType;
        for (const subTheme of theme.subThemes!) {
            const subThemeType = subTheme.subThemeType;
            const compelxeTheme = `${themeType}/${subThemeType}`;
            if (isComplexThemeType(compelxeTheme)) {
                if (pref[compelxeTheme] === false) {
                    // remove the subtheme
                    subTheme.packages = [];
                }
            }
        }
    }
    return themes;
};

// Creer toutes les préconisations selon l'annexe 2.
// Retourne un tableau de preconisation.
export const filterPreconisations = async (audit: any, themes: Array<Theme>): Promise<Array<Theme>> => {
    for (const theme of themes) {
        for (const subTheme of theme.subThemes!) {
            if (!isSubThemeType(subTheme.subThemeType)) {
                await dummyFilter(audit, subTheme, themes);
            } else {
                await filters[subTheme.subThemeType](audit, subTheme, themes);
            }
        }
    }

    const result = themes.filter((theme) => {
        if (!theme.subThemes) return false;
        theme.subThemes = theme.subThemes.filter((subtheme) => {
            return subtheme.packages && subtheme.packages.length > 0;
        });
        if (theme.subThemes.length === 0) {
            return false;
        }
        return true;
    });
    return result;
};

/**
 * Filtre neutre. utilisée si jamais le theme.themeType n'est pas un vrai themeType (ajouté côté icoll sans qu'on en ait connaissance)
 * @param audit l'audit sur lequel on préconise
 * @param subTheme le theme dont le type est inconnu.
 * @returns le theme filtré.
 */
const dummyFilter: filterMethod = async (audit: any, subTheme: SubTheme): Promise<SubTheme> => {
    //console.log('dummyFilter : ' + theme.themeType);
    return subTheme;
};

export const comparePackagePuissance = (a: PackageData, b: PackageData): number => {
    if (a.mainProduct.puissance === undefined && b.mainProduct.puissance === undefined) return 0;
    if (a.mainProduct.puissance === undefined) return -1; // undefined est plus petit que tout
    if (b.mainProduct.puissance === undefined) return 1;
    // La comparaison est différente en cas de PV.
    // TODO BATTERIE ??

    if (a.mainProduct.categorie_racine === 'Photovoltaïque' && b.mainProduct.categorie_racine === 'Photovoltaïque') {
        if (a.mainProduct.puissance * a.mainProduct.quantite < b.mainProduct.puissance * b.mainProduct.quantite) return -1;
        if (a.mainProduct.puissance * a.mainProduct.quantite > b.mainProduct.puissance * b.mainProduct.quantite) return 1;
    } else {
        if (a.mainProduct.puissance < b.mainProduct.puissance) return -1;
        if (a.mainProduct.puissance > b.mainProduct.puissance) return 1;
    }
    return 0;
};
export const comparePackagePuissanceReverse = (a: PackageData, b: PackageData): number => {
    return comparePackagePuissance(b, a);
};

export const logRef = (message: string, packages: Array<PackageData> = []) => {
    // console.log(
    //     message +
    //         ' : (' +
    //         packages.length +
    //         ') ' +
    //         JSON.stringify(
    //             packages.map((e) => e.reference),
    //             null,
    //             2
    //         )
    // );
};

export type filterMethod = (audit: any, subTheme: SubTheme, themes: Array<Theme>) => Promise<SubTheme>;

const filters: { [key in SubThemeType]: filterMethod } = {
    // regular filters, correspond to Theme == "Economie d'énergie"
    Chauffage: filterChauffage,
    'Eau chaude sanitaire': filterEauChaudeSanitaire,
    'Changement comportemental': filterChangementComportemental,
    Isolation: filterIsolation,
    Photovoltaïque: filterPV,
    Ventilation: filterVentilation,
    'Garantie de revenu solaire': filterGarantieRvenuSolaire,

    'Porte et fenêtre': filterPorteEtFenetre, // TODO

    // dummy filters, correspond to Theme != "Economie d'énergie"
    Batterie: filterBatterie,
    Veranda: dummyFilter,
    Pergola: dummyFilter,
    Carport: dummyFilter,
    'Ma piéce en plus': dummyFilter,
    'Nettoyage-refection': dummyFilter,
    "Mon contrat d'énergie": dummyFilter,
    'Assurance habitation': dummyFilter,
    'Alarme et sécurité': dummyFilter,
    'Voiture électrique': dummyFilter,
    'Moto électrique': dummyFilter,
    'Borne de recharge': dummyFilter,
    Piscine: dummyFilter,
    Portail: dummyFilter,
    'Eclairage exterieur': dummyFilter,
};
