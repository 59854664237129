export const ROUTE_ROOT = '/';
export const ROUTE_LOGIN = '/login';
export const ROUTE_MENTIONS_LEGALES = '/mentions-legales';

// AUDIT
export const ROUTE_AUDIT = '/audit';
export const ROUTE_AUDIT_INFO_CLIENT = '/audit/informations-client';
export const ROUTE_AUDIT_INFO_HABITATION = '/audit/informations-habitation';
export const ROUTE_AUDIT_INFO_SYSTEMES_ENERGETIQUES = '/audit/informations-systemes-energetiques';
export const ROUTE_AUDIT_INFO_DEPENSES_ENERGETIQUE = '/audit/informations-depenses-energetiques';
export const ROUTE_AUDIT_INFO_RECUEIL_CONSTAT = '/audit/informations-recueil-constat';
export const ROUTE_AUDIT_INFO_EVELOPPE = '/audit/informations-enveloppe';

export const ROUTE_AUDIT_OUVRANT = '/audit/ouvrants';
export const ROUTE_AUDIT_PHOTOS = '/audit/photos';
export const ROUTE_AUDIT_RDV = '/audit/rendez-vous';

export const ROUTE_AUDIT_FINALISATION_SANS_RDV = '/audit/finalisation-sans-rendez-vous';
export const ROUTE_AUDIT_RDV_SIGNATURE = '/audit/rendez-vous-signature';

// AUDIT THERMIQUE
// export const ROUTE_AUDIT_THERMIQUE = '/audit-thermique/audit';

// ACTION LOGEMENT
export const ROUTE_AUDIT_AL = '/audit/action-logement';
export const ROUTE_AUDIT_AL_RECAP = '/audit/action-logement/recapitulatif';
export const ROUTE_AUDIT_AL_RECAP_INELIGIBLE_PV = '/audit/action-logement/recapitulatif-ineligible-photovoltaique';
export const ROUTE_AUDIT_AL_RECAP_INELIGIBLE = '/audit/action-logement/recapitulatif-ineligible';
export const ROUTE_AUDIT_AL_SIGNATURE = '/audit/action-logement/signature';
export const ROUTE_AUDIT_AL_FINALISATION = '/audit/action-logement/finalisation';

// RESTE A CHARGE
export const ROUTE_AUDIT_RAC = '/audit/reste-a-charge';
export const ROUTE_AUDIT_RAC_RECAP = '/audit/reste-a-charge/recapitulatif';
export const ROUTE_AUDIT_RAC_FINALISATION = '/audit/reste-a-charge/finalisation';

// RECOMMANDATION
export const ROUTE_SIM_RECOMMANDATION = '/simulator/recommandation';

// BON DE COMMANDE
export const ROUTE_SIM_SYNTHESE = '/bon-de-commande/synthese';
export const ROUTE_SIM_PAC = '/bon-de-commande/pompe-a-chaleur';
export const ROUTE_SIM_BON_DE_COMMANDE = '/bon-de-commande/recapitulatif';
export const ROUTE_SIM_DEVIS = '/bon-de-commande/devis';
export const ROUTE_SIM_EXIT = '/bon-de-commande/exit';
export const ROUTE_SIM_ANNEXE = '/bon-de-commande/annexe';
export const ROUTE_SIM_ENGAGEMENT_CLIENT = '/bon-de-commande/engagement-client';
export const ROUTE_SIM_FINANCEMENT = '/bon-de-commande/financement';
export const ROUTE_SIM_TVA = '/bon-de-commande/tva';
export const ROUTE_SIM_RENDEMENT = '/bon-de-commande/rendement';
export const ROUTE_SIM_SIGN = '/bon-de-commande/Sign';
export const ROUTE_SIM_PDF = '/bon-de-commande/pdf';

// PRE VISITE
export const ROUTE_PV_VALIDATION = '/pre-visite/validation';
export const ROUTE_PV_POMPE_A_CHALEUR = '/pre-visite/pompe-a-chaleur';
export const ROUTE_PV_PHOTOVOLTAIQUE = '/pre-visite/photovoltaique';
export const ROUTE_PV_THERMODYNAMIQUE = '/pre-visite/thermodynamique';
export const ROUTE_PV_PREVISION = '/pre-visite/prevision';
export const ROUTE_PV_PLANNING = '/pre-visite/planning';
export const ROUTE_PV_FIN_DE_COMMANDE = '/pre-visite/fin-de-commande';
export const ROUTE_PV_FINALISATION = '/pre-visite/finalisation';
export const ROUTE_PV_PDF = '/pre-visite/pdf';

// ESPACE AGENT
export const ROUTE_EA_INDEX = '/espace-agent';
export const ROUTE_EA_LIST = '/espace-agent/list-audit';
export const ROUTE_EA_CREATION = '/espace-agent/creation-audit';
export const ROUTE_EA_FORMATION = '/espace-agent/espace-formation';
export const ROUTE_EA_COMPTE = '/espace-agent/mon-compte';
export const ROUTE_EA_DETAIL = `/espace-agent/list-audit/:id`;
export const ROUTE_EA_NETWORK = `/espace-agent/mon-reseau`;
export const ROUTE_EA_NETWORK_DETAIL = `/espace-agent/mon-reseau/:id`;
export const ROUTE_EA_CREATE = `/espace-agent/creation-agent`;
export const ROUTE_EA_MARKETING = '/espace-agent/outil-marketing';
