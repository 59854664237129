import React from 'react';

import Cookies from 'js-cookie';
import './finalisation.scss';
import { isLocal } from '../../services/tools/generalTools';

const FinalisationSansRdv: React.FC = () => {
    const companyLogo = Cookies.getJSON('Auth').companyLogo;

    return (
        <div className="container">
            <div className="signature-logos mt-5">
                <div className="main-logo">
                    <img src={companyLogo} alt="Logicoll" aira-hidden="true" />
                </div>
            </div>
            <br />
            <br />
            <p>Merci</p>
            <p>
                Vous recevrez d’ici la fin de journée votre audit énergétique ainsi qu’un dossier de présentation et de recommandations afin d’améliorer le
                confort et les dépenses énergétiques de votre habitation.
            </p>
            <p>Ceux-ci sont totalement gratuits et offerts par Sogys.</p>
            <p>
                Nos équipes se tiennent à votre disposition, 7 jours sur 7 de 8h à 22h au <a href="tel:0826046046">0826 046 046</a> ou par email à{' '}
                <a href="mailto:contact@sogys.fr">contact@sogys.fr</a>
            </p>
            <p>À très bientôt</p>
            <p>L'équipe Sogys</p>
            {isLocal() && <a href="../simulator/recommandation">Simulator preco</a>}
        </div>
    );
};

export default FinalisationSansRdv;
