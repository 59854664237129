import React from 'react';
// Simulator
import * as themesService from '../../services/calculs/theme';
import { SubThemeContainerComponent } from './SubThemeContainer';

// Components

// Style
import '../../pages/audit-simulator/audit-simulator.scss';

const ThemesMenu: React.FC<{ currentThemeIndex: number; themes: themesService.Theme[]; changeThemes: (index: number) => void }> = ({
    currentThemeIndex,
    themes,
    changeThemes,
}) => {
    const tabsNumber = { '--tabsLength': themes.length } as React.CSSProperties;

    return (
        <ul className="card-tabs" style={tabsNumber}>
            {themes.map((theme, index) => {
                return (
                    <li
                        key={theme.themeType}
                        onClick={() => {
                            changeThemes(index);
                        }}
                        data-active={currentThemeIndex === index ?? false}
                        className="card-tabs-item"
                    >
                        {theme.themeType}
                    </li>
                );
            })}
        </ul>
    );
};

const SubThemesPanel: React.FC<{ themes: themesService.Theme[]; currentThemeIndex: number }> = ({ themes, currentThemeIndex }) => {
    if (!themes || themes.length === 0) return <></>;

    const subThemes = themes[currentThemeIndex].subThemes!;

    return (
        <div className="card-body p-0">
            {subThemes.map((theme) => (
                <SubThemeContainerComponent {...theme} key={theme.subThemeType}></SubThemeContainerComponent>
            ))}
        </div>
    );
};

const Themes: React.FC<{ currentThemeIndex: number; themes: themesService.Theme[]; changeThemes: (index: number) => void }> = ({
    currentThemeIndex,
    themes,
    changeThemes,
}) => {
    return (
        <>
            <div className="tabs">
                <ThemesMenu currentThemeIndex={currentThemeIndex} themes={themes} changeThemes={changeThemes} />
            </div>
            <SubThemesPanel themes={themes} currentThemeIndex={currentThemeIndex} />
        </>
    );
};

export default Themes;
