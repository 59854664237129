import { filterMethod } from '../filterPreconisation';
import { SubTheme } from '../theme';

export const filterPorteEtFenetre: filterMethod = async (audit: any, subTheme: SubTheme): Promise<SubTheme> => {
    // loop on all package, and select everyhing
    for (const pack of subTheme.packages) {
        pack.applicable = true;
    }
    return subTheme;
};
