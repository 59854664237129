import { createScenarios } from './aideRenovationDAmpleur';
import * as defs from './aideRenovationDAmpleurDefs';

export const scenariosToNumbers = (scenarios: Array<defs.Scenario>): Array<string> => {
    const stringnified: Array<string> = scenarios.map((s) => scenarioToNumbers(s));
    const set = new Set(stringnified);
    return Array.from(set);
};
export const scenarioToNumbers = (scenario: defs.Scenario): string => {
    const result = new Array<string>();
    Object.entries(scenario).forEach((value: [string, boolean]) => {
        if (value && value[1])
            switch (value[0] as defs.ScenarioShortNames) {
                case 'Isolation Combles':
                    result.push('combles (1)');
                    break;
                case 'Isolation Rampants':
                    result.push('rampants (1)');
                    break;
                case 'Isolation des planchers bas':
                    result.push('plancher (2)');
                    break;
                case 'Isolation des murs exterieurs':
                    result.push('murs (3)');
                    break;
                case 'Pompe à chaleur air eau':
                    result.push('pac AE (4)');
                    break;
                case 'Pompe à chaleur air air':
                    result.push('pac AA (4)');
                    break;
                case 'Ballon Thermodynamique':
                    result.push('ballon (5)');
                    break;
                case 'Ventilation mécanique double flux (VMC)':
                    result.push('vmc (6)');
                    break;
                case "Porte d'entée isolante":
                    result.push('porte (7)');
                    break;
            }
    });
    return result.join(', ');
};

export const scenarioWithPriceToNumbers = (scenario: defs.ScenarioWithPrice): string => {
    const result = new Array<string>();
    Object.entries(scenario).forEach((value) => {
        if (value && value[1].inclus)
            switch (value[0] as defs.ScenarioShortNames) {
                case 'Isolation Combles':
                    result.push('combles (1)');
                    break;
                case 'Isolation Rampants':
                    result.push('rampants (1)');
                    break;
                case 'Isolation des planchers bas':
                    result.push('plancher (2)');
                    break;
                case 'Isolation des murs exterieurs':
                    result.push('murs (3)');
                    break;
                case 'Pompe à chaleur air eau':
                    result.push('pac AE (4)');
                    break;
                case 'Pompe à chaleur air air':
                    result.push('pac AA (4)');
                    break;
                case 'Ballon Thermodynamique':
                    result.push('ballon (5)');
                    break;
                case 'Ventilation mécanique double flux (VMC)':
                    result.push('vmc (6)');
                    break;
                case "Porte d'entée isolante":
                    result.push('porte (7)');
                    break;
            }
    });
    return result.join(', ');
};
const scenariosAreEquals = (testeName: string, input: Array<string>, awaited: Array<string>): boolean => {
    let error = false;
    if (input.length !== awaited.length) {
        console.groupCollapsed(testeName + ' : pas la même taille');
        console.log('   obtenu   (' + awaited.length + ') = ' + JSON.stringify(awaited, null, 2));
        console.log('   attendu  (' + input.length + ') = ' + JSON.stringify(input, null, 2));
        console.groupEnd();
        return false;
    }
    if (input.length === 0) {
        console.log(testeName + ' OK');
        return true;
    }
    for (let i = 0; i < input.length; ++i) {
        if (input[i] !== awaited[i]) {
            if (!error) console.groupCollapsed(testeName + '  pas les mêmes éléments');
            console.log('index ' + i + ':');
            console.log('   obtenu  = ' + awaited[i]);
            console.log('   attendu = ' + input[i]);
            error = true;
        }
    }
    if (!error) console.log(testeName + ' OK');
    else console.groupEnd();

    return error;
};

const allTests = [
    {
        name: 'test1',
        input: {
            // comble aménagés
            ceilingInsulationPresence: { value: true },
            //plancher isolé
            floorInsulationPresence: { value: true },
            // isolation des murs
            exteriorWallInsulationPresence: { value: true },
            // extérieur true, intérieur false.
            exteriorWallInsulationExterior: { value: true },
            // epaisseur intérieur >=12 ou non
            exteriorWallInsulationThickness: { value: 12 },
            // elec == 0, bois == 1, pac == 3, combustible == 4
            heaterType: { value: '3' },
            //chauffe eau thermo
            heaterHotWaterThermodynamic: { value: true },
            // vmc double flux 5.
            ventilationType: { value: '5' },
        },
        awaitedOutput: [],
    },
    {
        name: 'test2',
        input: {
            // comble aménagés
            ceilingInsulationPresence: { value: false },
            //plancher isolé
            floorInsulationPresence: { value: false },
            // isolation des murs
            exteriorWallInsulationPresence: { value: false },
            // extérieur true, intérieur false.
            exteriorWallInsulationExterior: { value: false },
            // epaisseur intérieur >=12 ou non
            //exteriorWallInsulationThickness: { value: 12 },
            // elec == 0, bois == 1, pac == 3, combustible == 4
            heaterType: { value: '4' },
            //chauffe eau thermo
            heaterHotWaterThermodynamic: { value: false },
            // vmc double flux 5.
            ventilationType: { value: '1' },
        },
        awaitedOutput: [
            'combles (1), plancher (2), pac AE (4), ballon (5)',
            'combles (1), plancher (2), murs (3), pac AE (4), ballon (5), vmc (6), porte (7), fenetre (8)',
        ],
    },
    {
        name: 'test3',
        input: {
            // comble aménagés
            ceilingInsulationPresence: { value: true },
            //plancher isolé
            floorInsulationPresence: { value: false },
            // isolation des murs
            exteriorWallInsulationPresence: { value: false },
            // extérieur true, intérieur false.
            exteriorWallInsulationExterior: { value: false },
            // epaisseur intérieur >=12 ou non
            //exteriorWallInsulationThickness: { value: 12 },
            // elec == 0, bois == 1, pac == 3, combustible == 4
            heaterType: { value: '4' },
            //chauffe eau thermo
            heaterHotWaterThermodynamic: { value: false },
            // vmc double flux 5.
            ventilationType: { value: '1' },
        },
        awaitedOutput: ['plancher (2), pac AE (4), ballon (5), porte (7)', 'plancher (2), murs (3), pac AE (4), ballon (5), vmc (6), porte (7), fenetre (8)'],
    },
    {
        name: 'test4',
        input: {
            // comble aménagés
            ceilingInsulationPresence: { value: true },
            //plancher isolé
            floorInsulationPresence: { value: true },
            // isolation des murs
            exteriorWallInsulationPresence: { value: false },
            // extérieur true, intérieur false.
            exteriorWallInsulationExterior: { value: false },
            // epaisseur intérieur >=12 ou non
            //exteriorWallInsulationThickness: { value: 12 },
            // elec == 0, bois == 1, pac == 3, combustible == 4
            heaterType: { value: '4' },
            //chauffe eau thermo
            heaterHotWaterThermodynamic: { value: false },
            // vmc double flux 5.
            ventilationType: { value: '1' },
        },
        awaitedOutput: ['murs (3), pac AE (4), ballon (5), porte (7)', 'murs (3), pac AE (4), ballon (5), vmc (6), porte (7), fenetre (8)'],
    },
    {
        name: 'test5',
        input: {
            // comble aménagés
            ceilingInsulationPresence: { value: true },
            //plancher isolé
            floorInsulationPresence: { value: true },
            // isolation des murs
            exteriorWallInsulationPresence: { value: false },
            // extérieur true, intérieur false.
            exteriorWallInsulationExterior: { value: false },
            // epaisseur intérieur >=12 ou non
            //exteriorWallInsulationThickness: { value: 12 },
            // elec == 0, bois == 1, pac == 3, combustible == 4
            heaterType: { value: '3' },
            //chauffe eau thermo
            heaterHotWaterThermodynamic: { value: false },
            // vmc double flux == 5.
            ventilationType: { value: '1' },
        },
        awaitedOutput: ['murs (3), ballon (5), vmc (6), porte (7)', 'murs (3), ballon (5), vmc (6), porte (7), fenetre (8)'],
    },
    {
        name: 'testA',
        input: {
            // comble aménagés
            ceilingInsulationPresence: { value: true },
            //plancher isolé
            floorInsulationPresence: { value: true },
            // isolation des murs
            exteriorWallInsulationPresence: { value: false },
            // extérieur true, intérieur false.
            exteriorWallInsulationExterior: { value: false },
            // epaisseur intérieur >=12 ou non
            //exteriorWallInsulationThickness: { value: 12 },
            // elec == 0, bois == 1, pac == 3, combustible == 4
            heaterType: { value: '0' },
            //chauffe eau thermo
            heaterHotWaterThermodynamic: { value: false },
            // vmc double flux == 5.
            ventilationType: { value: '1' },
        },
        awaitedOutput: ['murs (3), pac AA (4), ballon (5), porte (7)', 'murs (3), pac AA (4), ballon (5), vmc (6), porte (7), fenetre (8)'],
    },
    {
        name: 'testB',
        input: {
            // comble aménagés
            ceilingInsulationPresence: { value: true },
            //plancher isolé
            floorInsulationPresence: { value: true },
            // isolation des murs
            exteriorWallInsulationPresence: { value: false },
            // extérieur true, intérieur false.
            exteriorWallInsulationExterior: { value: false },
            // epaisseur intérieur >=12 ou non
            //exteriorWallInsulationThickness: { value: 12 },
            // elec == 0, bois == 1, pac == 3, combustible == 4
            heaterType: { value: '1' },
            //chauffe eau thermo
            heaterHotWaterThermodynamic: { value: false },
            // vmc double flux == 5.
            ventilationType: { value: '1' },
        },
        awaitedOutput: ['murs (3), ballon (5), vmc (6), porte (7)', 'murs (3), ballon (5), vmc (6), porte (7), fenetre (8)'],
    },
    {
        name: 'test6',
        input: {
            // comble aménagés
            ceilingInsulationPresence: { value: true },
            //plancher isolé
            floorInsulationPresence: { value: true },
            // isolation des murs
            exteriorWallInsulationPresence: { value: false },
            // extérieur true, intérieur false.
            exteriorWallInsulationExterior: { value: false },
            // epaisseur intérieur >=12 ou non
            //exteriorWallInsulationThickness: { value: 12 },
            // elec == 0, bois == 1, pac == 3, combustible == 4
            heaterType: { value: '4' },
            //chauffe eau thermo
            heaterHotWaterThermodynamic: { value: true },
            // vmc double flux == 5.
            ventilationType: { value: '1' },
        },
        awaitedOutput: ['murs (3), pac AE (4), vmc (6), porte (7), fenetre (8)'],
    },
    {
        name: 'test7',
        input: {
            // comble aménagés
            ceilingInsulationPresence: { value: false },
            //plancher isolé
            floorInsulationPresence: { value: true },
            // isolation des murs
            exteriorWallInsulationPresence: { value: false },
            // extérieur true, intérieur false.
            exteriorWallInsulationExterior: { value: false },
            // epaisseur intérieur >=12 ou non
            //exteriorWallInsulationThickness: { value: 12 },
            // elec == 0, bois == 1, pac == 3, combustible == 4
            heaterType: { value: '4' },
            //chauffe eau thermo
            heaterHotWaterThermodynamic: { value: true },
            // vmc double flux == 5.
            ventilationType: { value: '1' },
        },
        awaitedOutput: ['combles (1), pac AE (4), vmc (6), porte (7), fenetre (8)', 'combles (1), murs (3), pac AE (4), vmc (6), porte (7), fenetre (8)'],
    },
    {
        name: 'test8',
        input: {
            // comble aménagés
            ceilingInsulationPresence: { value: false },
            //plancher isolé
            floorInsulationPresence: { value: true },
            // isolation des murs
            exteriorWallInsulationPresence: { value: false },
            // extérieur true, intérieur false.
            exteriorWallInsulationExterior: { value: false },
            // epaisseur intérieur >=12 ou non
            //exteriorWallInsulationThickness: { value: 12 },
            // elec == 0, bois == 1, pac == 3, combustible == 4
            heaterType: { value: '3' },
            //chauffe eau thermo
            heaterHotWaterThermodynamic: { value: true },
            // vmc double flux == 5.
            ventilationType: { value: '1' },
        },
        awaitedOutput: ['combles (1), vmc (6), porte (7), fenetre (8)', 'combles (1), murs (3), vmc (6), porte (7), fenetre (8)'],
    },
    {
        name: 'test9',
        input: {
            // comble aménagés
            ceilingInsulationPresence: { value: false },
            //plancher isolé
            floorInsulationPresence: { value: true },
            // isolation des murs
            exteriorWallInsulationPresence: { value: true },
            // extérieur true, intérieur false.
            exteriorWallInsulationExterior: { value: false },
            // epaisseur intérieur >=12 ou non
            exteriorWallInsulationThickness: { value: 20 },
            // elec == 0, bois == 1, pac == 3, combustible == 4
            heaterType: { value: '3' },
            //chauffe eau thermo
            heaterHotWaterThermodynamic: { value: true },
            // vmc double flux == 5.
            ventilationType: { value: '1' },
        },
        awaitedOutput: ['combles (1), vmc (6), porte (7), fenetre (8)'],
    },
];
export const TUScenarios = () => {
    console.group("test unitaires de scénarios renovation d'ampleur");
    for (const test of allTests) scenariosAreEquals(test.name, test.awaitedOutput, scenariosToNumbers(createScenarios(test.input)));
    console.groupEnd();
};
