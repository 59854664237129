import React, { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { PackageData } from '../../services/calculs/package';
import { AidesLocalesBuilder, updateTicket } from '../../pages/audit-simulator/ticketUpdater';
import * as packageService from '../../services/calculs/package';
import * as themesService from '../../services/calculs/theme';
import * as productService from '../../services/calculs/products';
import { currencyFormat, percentFormat } from '../../services/tools/TypeHelper';
import ModeProductionPVContainerComponent from './ModeProductionPVContainer';

import withReactContent from 'sweetalert2-react-content';
import Swal, { SweetAlertOptions } from 'sweetalert2';

// Icon
import { ReactComponent as IconHouse } from '../../assets/icons/synthese/icon-house.svg';
import { ReactComponent as IconPacAirEau } from '../../assets/icons/synthese/icon-pompeachaleur.svg';
import { ReactComponent as IconInsulation } from '../../assets/icons/synthese/icon-insulation.svg';
import { ReactComponent as IconInsulationFloor } from '../../assets/icons/synthese/icon-insulation-floor.svg';
import { ReactComponent as IconBulb } from '../../assets/icons/synthese/icon-bulb.svg';
import { ReactComponent as IconBallon } from '../../assets/icons/synthese/icon-ballon.svg';
import { ReactComponent as IconPlug } from '../../assets/icons/synthese/icon-plug.svg';
import { ReactComponent as IconMissing } from '../../assets/icons/synthese/icon-missing.svg';
import { ReactComponent as IconUpload } from '../../assets/icons/synthese/icon-upload.svg';
import { applyPackageSelectorSpecialRules } from '../../services/calculs/packageSelectorSpecialRules';
import {
    ticketStateAtom,
    aidesLocalesListStateAtom,
    ticketLoaderAtom,
    themesStateAtom,
    aideRenovationDAmpleurStateAtom,
    aideRenovationDAmpleurParamsStateAtom,
} from '../../services/Recoil/Atom/Themes.atom';
import * as ticketService from '../../services/calculs/ticket';
import * as storageService from '../../services/localStorageService';
import { RenovationDAmpleurElligible, RenovationDAmpleurParams } from '../../services/calculs/aides/aideRenovationDAmpleurDefs';

/** dans une carte panneau solaire, affichier la puissance par panneau (true), ou totale (false) */
let OPTION_SHOW_POWER_PER_PANEL: boolean = false;

const PackageComponent: React.FC<PackageData> = (card: React.PropsWithChildren<PackageData>) => {
    const [themes, setThemes] = useRecoilState(themesStateAtom);
    const setTicket = useSetRecoilState(ticketStateAtom);
    const [aidesLocales, setAidesLocales] = useRecoilState(aidesLocalesListStateAtom);
    const renoDAmpleur = useRecoilValue(aideRenovationDAmpleurStateAtom);
    const renoDAmpleurParams = useRecoilValue(aideRenovationDAmpleurParamsStateAtom);
    const setTicketIsLoading = useSetRecoilState(ticketLoaderAtom);
    const AlertSwal = withReactContent(Swal);
    const SHOW_DEBUG = process.env.REACT_APP_MODE_DEBUG === 'true'; // apparaît que si on est en debug
    const mmaActivated: boolean = storageService.getConfig('customerFlowParameters').operationsCommerciales.remiseMMA;

    // Modal ouvrant les PDF dans les packages
    const pdfModal = (url: string) => {
        AlertSwal.fire({
            title: '',
            html: <iframe title="PDF" src={url} width="100%" height="600" />,
            width: 1200,
            confirmButtonText: 'Fermer',
            customClass: {
                confirmButton: 'btn btn-continue min-width',
            },
            reverseButtons: true,
        });
    };

    /**
     * Change l'état de selection du package et met à jour tout l'écran en fonction de cette nouvelle selection.
     * WARNING, cette fonction est assez critique, soyons prudents.
     */
    const toggleCardSelectionV2 = async () => {
        setTicketIsLoading(true);
        // On fait un gros clone profond, pour que tout soit en lecture écriture.
        const allThemesClone: Array<themesService.Theme> = JSON.parse(JSON.stringify(themes)) as Array<themesService.Theme>;
        // La selection / déselection d'un package peut entrainter la selection / déselection d'autres packages.
        for (const theme of allThemesClone) {
            const allSubThemesClone = theme.subThemes!;
            for (const subTheme of allSubThemesClone) {
                // si le theme ne contient pas le card id, on ignore.
                if (!subTheme.packages.find((item) => item.id === card.id)) continue;
                // on retrouve le mode de selection pour les packages de ce theme (multiple par défaut)
                let selectMode: packageService.PackageSelectMode = 'Multiple';
                if (themesService.isSubThemeType(subTheme.subThemeType)) {
                    selectMode = packageService.packageSelectionMode[subTheme.subThemeType];
                }
                for (const pack of subTheme.packages) {
                    switch (selectMode) {
                        case 'Multiple':
                            // En cas de sélection multiple, on inverse simplement la variable
                            if (pack.id === card.id) pack.applicable = !card.applicable;

                            break;
                        case 'ZeroOrOne':
                            // En cas de selection unique (par exemple on ne peut prendre qu'un seul PV)
                            // si le package concerné est inversé,
                            // les autres sont déselectionnés.
                            if (pack.id === card.id) pack.applicable = !card.applicable;
                            else pack.applicable = false;
                            break;
                    }
                }
            }
        }
        // maintenant que l'utilsateur a changé certaines selections,
        // on applique les exceptions
        const messages = applyPackageSelectorSpecialRules(allThemesClone);
        showMessages(messages);

        // on doit forcer le re-render de l'ecran conteneur, pour cela on appelle la fonction updateTicket

        // On vient de changer les package, il faut appeler l'api !
        // on passe donc undefined dans la variable ticket.
        await updateTicket(allThemesClone, setThemes, undefined, setTicket, aidesLocales, setAidesLocales, aideBuilder, {
            renoDAmpleur,
            params: renoDAmpleurParams,
        });
        setTicketIsLoading(false);
    };

    const showMessages = async (messages: Array<{ title: string; message: string }>): Promise<void> => {
        for (const mess of messages) {
            const swal: SweetAlertOptions = {
                title: mess.title,
                html: mess.message,
                showConfirmButton: true,
                focusConfirm: true,
                customClass: {
                    confirmButton: 'btn btn-continue min-width',
                },
            };
            await AlertSwal.fire(swal);
        }
    };

    const aideBuilder: AidesLocalesBuilder = async (
        aidesLocales: ticketService.AidesLocalesList,
        allThemesClone: Array<themesService.Theme>,
        ticket: ticketService.TicketData,
        context: { renoDAmpleur: RenovationDAmpleurElligible; params: RenovationDAmpleurParams }
    ): Promise<ticketService.AidesLocalesList> => {
        // On recalcule les aides locales liées aux packages.
        // Et celle liée au montant ht.
        const aides: ticketService.AidesLocalesList = {
            ...aidesLocales,
            [ticketService.AIDE_PANNEAU_SOLAIRE_NAME]: await ticketService.createAideReventeSurplus(allThemesClone),
            [ticketService.AIDE_REMISE_MMA_NAME]: await ticketService.createAideMMA(allThemesClone),
            [ticketService.AIDE_RENO_AMPLEUR]: ticketService.createAideRenoDAmpleur(context.renoDAmpleur, context.params, ticket),
        };

        return aides;
    };

    const defaultIcon = card.mainProduct.img_path;

    const getIcon = (): JSX.Element | undefined => {
        switch (card.mainProduct.categorie) {
            case 'Air/Air':
                return <IconMissing fill="#434D5D" />;
            case 'Air/Eau':
                return <IconPacAirEau fill="#434D5D" />;
            case 'Ampoules LED':
                return <IconBulb fill="#434D5D" />;
            case 'Ballon':
                return <IconBallon fill="#434D5D" />;
            case "Brasseur d'air":
                return <IconMissing fill="#434D5D" />;
            case 'Domotique / Prise conectée':
                return <IconPlug fill="#434D5D" />;
            case 'Isolation murs':
                return <IconInsulation fill="#434D5D" />;
            case 'Isolation Plancher':
                return <IconInsulationFloor fill="#434D5D" />;
            case 'Mono Cristalin':
                return <IconHouse fill="#434D5D" />;
            case 'Pose':
                return <IconMissing fill="#434D5D" />;
            default:
                // TODO Change this to the default icon
                return undefined;
        }
    };

    const puissance = (): JSX.Element | undefined => {
        if (card.mainProduct === undefined) return undefined;

        // SI la puissance vaut 0, c'est que le produit n'a pas de puissance.
        if (!card.mainProduct.puissance || card.mainProduct.puissance === 0) return undefined;

        let puissance = card.mainProduct.puissance;
        let puissanceLabel = 'Puissance';
        if (packageService.packIsPV(card)) {
            if (OPTION_SHOW_POWER_PER_PANEL === false) {
                puissance *= card.mainProduct.quantite;
                puissance /= 1000;
            } else {
                puissanceLabel = 'Puissance par panneau';
            }
        }
        const unite: productService.Unites = productService.getUniteFor(card);
        const uniteString: string = productService.uniteToString(unite);

        return (
            <li>
                {puissanceLabel}
                <span>
                    {puissance} {uniteString}
                </span>
            </li>
        );
    };

    const quantityLabelcontainer = (): JSX.Element | undefined => {
        if (!card || card.mainProduct === undefined) return undefined;

        let unite: productService.Unites = productService.getUniteFor(card);
        // cas particulier de la puissance : les unités sont sur la puissance, pas sur la quantité !
        if (card.mainProduct.puissance && card.mainProduct.puissance !== 0) unite = 'aucune';
        const uniteString: string = productService.uniteToString(unite);

        return (
            <>
                {card.mainProduct.quantiteRecommandee !== undefined ? (
                    <>
                        <li>
                            {quantityLabel()}{' '}
                            <span>
                                <InputWithTimeout /> {uniteString}
                            </span>
                        </li>
                        <li>
                            <span>
                                Quantité recommandée : {Math.round(card.mainProduct.quantiteRecommandee)} {uniteString}
                            </span>
                        </li>
                    </>
                ) : (
                    <li>
                        {quantityLabel()}{' '}
                        <span>
                            {Math.round(+(card.mainProduct.quantiteRecommandee ?? card.mainProduct.quantite))} {uniteString}
                        </span>
                    </li>
                )}
            </>
        );
    };

    // Cet input va envoyer la requete au bout d'une seconde apres que le dernier caractere ait été tappé
    const InputWithTimeout = () => {
        const [value, setValue] = useState<number | string>(card.mainProduct.quantite);
        let [timerId, setTimerId] = useState<string | number | NodeJS.Timeout | undefined>(undefined);

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            if (timerId) {
                clearTimeout(timerId);
            }
            let val = e.target.value;
            if (val === '' || val === undefined) {
                val = '0';
            }
            setValue(val);
            setTimerId(
                setTimeout(async () => {
                    setTicketIsLoading(true);
                    const allThemesClone = changeQuantity(parseInt(val));
                    // On vient de changer la quantité d'un produit icoll, cela va changer le devis
                    // il faut appeler l'api, (ticket:undefined)
                    // relancer les calculs d'aide à l'aide du builder
                    await updateTicket(allThemesClone, setThemes, undefined, setTicket, aidesLocales, setAidesLocales, aideBuilder, {
                        renoDAmpleur,
                        params: renoDAmpleurParams,
                    });
                    setTicketIsLoading(false);
                }, 1000)
            );
        };

        return (
            <input
                type="number"
                name="adContrat"
                id="adContract"
                style={{ width: '50px' }}
                className="input-ticket"
                onChange={handleChange}
                disabled={!card.applicable}
                value={value}
            />
        );
    };

    const changeQuantity = (newQuantity: number | undefined): themesService.Theme[] => {
        // On fait un gros clone profond, pour que tout soit en lecture écriture.
        const allThemeClone: Array<themesService.Theme> = JSON.parse(JSON.stringify(themes)) as Array<themesService.Theme>;
        for (const theme of allThemeClone) {
            const allSubThemesClone = theme.subThemes!;
            for (const subTheme of allSubThemesClone) {
                // si le theme ne contient pas le card id, on ignore.
                if (!subTheme.packages.find((item) => item.id === card.id)) continue;
                // rebuild the theme with an updated package, depending of selection mode of the theme.

                for (const pack of subTheme.packages) {
                    // On toggle la variable
                    if (pack.id === card.id) {
                        pack.mainProduct.quantite = newQuantity ?? pack.mainProduct.quantiteRecommandee ?? 1;
                        // NON !!
                        // si il y a un produit de pose, la pose pose contient tout, sans quantité !
                        // if (pack.poseProduct) pack.poseProduct.quantite = newQuantity ?? pack.mainProduct.quantiteRecommandee ?? 1;
                    }
                }
            }
        }
        return allThemeClone;
    };

    const quantityLabel = (): string => {
        switch (card.mainProduct.categorie_racine) {
            case 'Rénovation': {
                if (card.mainProduct.categorie_parent === 'Confort') {
                    return 'Quantité';
                }
                return 'Surface à isoler';
            }
            case 'Isolation':
                return 'Surface à isoler';
            case 'Photovoltaïque':
                if (card.mainProduct.categorie === 'Batterie') return 'Quantité';
                else return 'Nombre de panneaux';
            case 'Eau chaude sanitaire':
            case 'Chauffage':
            case 'Ventilation':
            case 'Changement comportemental':
                return "Nombre d'unités";
            default:
                return 'Quantité';
        }
    };
    const buildBandeau = (): JSX.Element => {
        if (packageService.packIsMMA(card)) {
            const deadLineOK = ticketService.isDeadLineAideOk();
            return mmaActivated && deadLineOK ? <>Offert</> : <>&nbsp;</>;
        }
        if (packageService.packIsPV(card)) {
            if (!card.installationPV) return <>&nbsp;</>;
            return (
                <>
                    {/* TODO use Unité */}
                    Production: <span>{Math.round(card.installationPV.maxProd)} kWh</span>
                </>
            );
        }
        if (card.mainProduct.gain === 0) return <>&nbsp;</>;
        return (
            <>
                Economie d'énergie: <span>-{percentFormat(card.mainProduct.gain - card.mainProduct.surcout)}</span>
            </>
        );
    };

    const buildPdfLink = (): JSX.Element | undefined => {
        const link = card.mainProduct.doc_path;
        if (!link) return undefined;
        return (
            <button type="button" className="btn btn-pdf" onClick={() => pdfModal(link)}>
                <IconUpload />
                PDF
            </button>
        );
    };

    // Adjust font-size according to title length
    const titleSize = (title: string) => {
        let fontClass: string = '';

        if (title.length > 25) {
            fontClass = 'title--medium';
        }

        if (title.length > 35) {
            fontClass = 'title--mini';
        }

        if (title.length > 45) {
            fontClass = 'title--nano';
        }

        return fontClass;
    };

    return (
        <div className={`card-col${card.applicable ? ' active' : ''}`} key={card.id} data-theme={card.themeId}>
            <div className="card-col-header">{buildBandeau()}</div>
            <div className="card-col-body">
                <h4 className="card-col-body--title">
                    <span className={titleSize(card.title)}>{card.title}</span>
                    <>
                        <div className="custom-checkbox">
                            <label htmlFor={card.id}>
                                <input type="checkbox" checked={card.applicable} id={card.id} onChange={() => toggleCardSelectionV2()} />
                                <div className="input-display"></div>
                            </label>
                        </div>
                    </>
                </h4>
                <span style={SHOW_DEBUG ? { color: 'red', fontSize: '0.7em' } : { display: 'none' }} className={titleSize(card.title)}>
                    {card.reference + ' ' + card.mainProduct.tva + '%'}
                </span>
                {/* <span className={titleSize(card.title)} style={SHOW_DEBUG ? { color: 'red' } : { display: 'none' }}>
                    {card.poseProduct ? 'avec pose' : 'sans pose'}
                    <br />
                    {card.mainProduct.prix_ht + (card.poseProduct ? ' / ' + card.poseProduct?.prix_ht : '')}
                    <br />
                </span> */}
                <div className="card-col-body--puissance">
                    {buildPdfLink()}
                    {defaultIcon !== undefined && defaultIcon !== '' ? (
                        <div className="icon">
                            {/* TODO: à tester quand iColl enverra des images */}
                            <img src={defaultIcon} alt="Icon" aria-hidden="true" />
                        </div>
                    ) : (
                        <div className="icon">{getIcon()}</div>
                    )}
                </div>

                {packageService.packIsPV(card) && <ModeProductionPVContainerComponent {...card} />}

                {
                    <div className="card-col-body--panel">
                        <ul className="card-col-body--list">
                            {quantityLabelcontainer()}
                            {puissance()}
                        </ul>
                    </div>
                }
                <ul className="card-col-production--list total">
                    <li>
                        Prix<span>{currencyFormat(card.priceTtc)}</span>
                    </li>
                    <li>
                        Total des aides<span>{currencyFormat(card.totalHelp)}</span>
                    </li>

                    <li>
                        Total aides déduites<span>{currencyFormat(card.totalHelpDeduced)}</span>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default PackageComponent;
