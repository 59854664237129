import { atom, selector } from 'recoil';
import {
    ROUTE_PV_FINALISATION,
    ROUTE_PV_PHOTOVOLTAIQUE,
    ROUTE_PV_POMPE_A_CHALEUR,
    ROUTE_PV_PREVISION,
    ROUTE_PV_THERMODYNAMIQUE,
    ROUTE_PV_VALIDATION,
} from '../../../routing/paths';
import { ThemeOfEconomie, isSubThemeType } from '../../calculs/theme';
import * as themesService from '../../../services/calculs/theme';
import { themesStateAtom } from './Themes.atom';
import { isCurrentAuditMar } from '../../localStorageService';

// Subthemes applicable to Previsit
export type SearchedSubThemes = Exclude<ThemeOfEconomie, 'Isolation' | 'Changement comportemental'>;

// Typeguard to check if subtheme is applicable to Previsit
export const isSearchedSubThemesType = (candidate: string): candidate is SearchedSubThemes => {
    if (!isSubThemeType(candidate)) return false;
    if (candidate === 'Isolation' || candidate === 'Changement comportemental') return false;
    return true;
};

type PackageRouter = typeof ROUTE_PV_VALIDATION | typeof ROUTE_PV_POMPE_A_CHALEUR | typeof ROUTE_PV_PHOTOVOLTAIQUE | typeof ROUTE_PV_THERMODYNAMIQUE;

export type Package = {
    subTheme: SearchedSubThemes;
    title: string;
    reference: string;
};

const packageMap = new Map<SearchedSubThemes, PackageRouter>([
    ['Photovoltaïque', ROUTE_PV_PHOTOVOLTAIQUE],
    ['Chauffage', ROUTE_PV_POMPE_A_CHALEUR],
    ['Eau chaude sanitaire', ROUTE_PV_THERMODYNAMIQUE],
]);

export const contextPackagesSelector = selector<Array<Package> | null>({
    key: 'contextPackages',
    get: ({ get }) => {
        const themes = get(themesStateAtom);

        // Carefull : hardcoded theme
        const themeTypeToParse: themesService.ThemeType = "Economie d'énergie";

        const packs: Array<Package> = [];
        // Extract interesting theme
        const mainTheme = themes.find(({ themeType }) => themeType === themeTypeToParse);
        // Extract interesting subthemes
        const subThemes = mainTheme?.subThemes?.filter(({ subThemeType }) => isSearchedSubThemesType(subThemeType));

        // Iterate through subthemes
        subThemes?.forEach(({ packages, subThemeType }) => {
            // Iterate through packages
            packages.forEach(({ applicable, title, reference }) => {
                // Extract packages applicable to client
                if (applicable) {
                    // Note: Cast is valid since we filtered with typeguard
                    packs.push({ title, subTheme: subThemeType as SearchedSubThemes, reference });
                }
            });
        });

        return packs;
    },
});

// stores current location in previsit
export const currentRouteAtom = atom<PackageRouter>({
    key: 'currentPackagesRoute',
    default: ROUTE_PV_VALIDATION,
});

// extracts only routes from packages
export const packageRoutesSelector = selector<Array<PackageRouter>>({
    key: 'packagesRoutes',
    get: ({ get }) => {
        const packages = get(contextPackagesSelector);

        const order = packages?.map(({ subTheme }) => packageMap.get(subTheme)).filter((value) => value !== undefined);

        return [ROUTE_PV_VALIDATION, ...new Set(order as Array<PackageRouter>)];
    },
});

// Determines the next route
export const nextPackageRouteSelector = selector<PackageRouter>({
    key: 'nextPackagesRoute',
    get: ({ get }) => {
        const packagesElements = get(packageRoutesSelector);
        const current = get(currentRouteAtom);

        const key = packagesElements.findIndex((step) => step === current);

        let outOfBound = ROUTE_PV_PREVISION;
        if (isCurrentAuditMar()) outOfBound = ROUTE_PV_FINALISATION;

        return packagesElements[key + 1] ?? outOfBound;
    },
});
