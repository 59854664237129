import Cookies from 'js-cookie';
import { colors } from '../pages/pre-visite/photovoltaique/Photovoltaique';
import { ConstantesPhotovoltaique, CustomerFlowParameters, FinancoScale, FlowFundingOutput, InvestmentBonus } from './apiParticulierService';
import { AppointmentOutput, AuditType, isAuditMar, isAuditThermique, isAuditType } from './apiFlowService';
import { EnergyPrice } from './calculs/bilanEnergieService';
import { Ensoleillement, Orientation } from './calculs/installationPV';
import { Indexations } from './calculs/calculEconomie';
import { PreconisationChauffage } from './calculs/calculPreconisationsCore';
import { Labelled } from './tools/TypeHelper';

export enum PropertyType {
    boolean,
    basic,
    number,
    withLabel,
    building,
    window,
    photo,
    buildingDetail,
    dependentDetail,
    anexe,
    typeOfWork,
    workCheckboxes,
    heatingCircuit,
    radiatorCircuit,
    date,
    roofShape,
    loan,
    array,
}

export type Agent = {
    agentCode: string;
    nom: string;
    nom_agent: string;
    prenom_agent: string;
    email: string;
    tel: string;
    companyName: string;
    companyAddress: string;
    companyLogo: string;
    e_attestation: boolean;
    code_fournisseur: string;
};

export type ClientIdentity = {
    userCivility: string;
    userLastname: string;
    userFirstname: string;
    userAddress: string;
    userZipCode: string;
    userLocality: string;
    userTelephone: string;
    userEmail: string;
    signature: string;
};

// TODO remove this
const setLocalStorage = (name: string, value: any) => {
    if (localStorage.getItem(name)) {
        return localStorage.setItem(name, value);
    }
    return false;
};

/**
 * Fonction pour vérifier la présence du gabarit JSON pour l'agent courant
 */
export const checkLocalStorageCurrentAgent = (): boolean => {
    const agentCode = Cookies.getJSON('Auth').agentCode;
    return !!localStorage.getItem(agentCode);
};

export const getLocalStorageAgent = (): Agent => {
    const agent = Cookies.getJSON('Auth');
    return agent as Agent;
};

export const getClientIdentity = (): ClientIdentity => {
    const audit = getAudit();
    const step1 = audit?.step1!;
    return {
        userCivility: step1.userCivility?.value,
        userLastname: step1.userLastname?.value,
        userFirstname: step1.userFirstname?.value,
        userAddress: step1.userAddress?.value,
        userZipCode: step1.userZipCode?.value,
        userLocality: step1.userLocality?.value,
        userTelephone: step1.userTelephone?.value,
        userEmail: step1.userEmail?.value,
        signature: 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=',
    };
};

// #region get / set / reset  audit -PV -SIM (full)

// TODO :
// const stepsList = ['step1', 'step2', 'step3', 'step4', 'step5', 'step6', 'step7', 'step8'] as const;
// export type StepList = (typeof stepsList)[number];

// export type Audit = {
//     [key in StepList]: any;
// } & {
//     auditSent: boolean;
//     agentCode: string;
//     currentStep: number;
//     campagne: string;
// };
/**
 * Fonction pour récupérer l'audit de l'agent courant, dans le local storage.
 */
export const getAudit = () => {
    const cookie = Cookies.getJSON('Auth');
    if (!cookie) return undefined;
    const agentCode = cookie.agentCode;
    return JSON.parse(localStorage.getItem(agentCode) as string);
};

/**
 * Fonction pour récupérer le type de l'audit, dans le local storage.
 */
export const getCurrentAuditType = (): AuditType | undefined => {
    const auditType = localStorage.getItem('auditType');
    return isAuditType(auditType) ? (auditType as AuditType) : undefined;
};
/**
 * Fonction pour déterminer si l'audit est de type MAR
 */
export const isCurrentAuditMar = (): boolean => {
    const auditType = localStorage.getItem('auditType');
    return isAuditMar(auditType);
};
/**
 * Fonction pour déterminer si l'audit est de type Thermique
 */
export const isCurrentAuditThermique = (): boolean => {
    const auditType = localStorage.getItem('auditType');
    return isAuditThermique(auditType);
};
/**
 * Fonction pour récupérer la simulation ("-SIM") dans le local storage
 */
export const getSim = () => {
    const cookie = Cookies.getJSON('Auth');
    if (!cookie) return undefined;
    const agentCode = cookie.agentCode + '-SIM';
    return JSON.parse(localStorage.getItem(agentCode) as string);
};

/**
 * Fonction pour récupérer le gabarit JSON pour l'agent courant
 */
export const getPrevisit = () => {
    const cookie = Cookies.getJSON('Auth');
    if (!cookie) return undefined;
    const agentCode = cookie.agentCode + '-PV';
    return JSON.parse(localStorage.getItem(agentCode) as string);
};

/**
 * retourne 2 objets
 *    - un audit applatit
 *    - un ClientIdentity
 */
export const stepListToAuditAndClient = (): { audit: any; clientIdentity: ClientIdentity } => {
    const auditLocalStorage = getAudit();
    //console.log('auditLocalStorage = ' + JSON.stringify(auditLocalStorage));
    const stepsList: string[] = ['step1', 'step2', 'step3', 'step4', 'step5', 'step6', 'step7', 'step8'];

    // Injection de toutes les propriétés dans l'objet Audit
    let audit: any = {};
    stepsList.forEach((step: any) => {
        audit = Object.assign(audit, auditLocalStorage[step]);
    });
    const clientIdentity = getClientIdentity();

    // Suppression informations inutiles
    Object.keys(clientIdentity).forEach((key: string) => {
        delete audit[key];
    });
    // Suppression images au format base64
    picturesBase64List.forEach((key: string) => {
        if (audit[key].value) {
            audit[key].value = '';
        }
    });
    // Suppression Boolean inutile
    delete audit.isValid;

    // Préparation du formData pour la requête
    let auditAndClient: any = {
        clientIdentity,
        audit,
    };
    return auditAndClient;
};

/**
 * Fonction pour remplir le gabarit JSON pour l'agent courant
 */
export const setAudit = (value: any): void => {
    const cookie = Cookies.getJSON('Auth');
    if (!cookie) return undefined;
    const agentCode = cookie.agentCode;

    localStorage.setItem(agentCode, JSON.stringify(value));
};
/**
 * Fonction pour VIDER le gabarit JSON pour l'agent courant
 */
export const resetAudit = (): void => {
    const cookie = Cookies.getJSON('Auth');
    if (!cookie) return;
    const agentCode = cookie.agentCode;

    localStorage.removeItem(agentCode);
};

// Set simulator template
export const setSim = (value: any): void => {
    const cookie = Cookies.getJSON('Auth');
    if (!cookie) return;
    const simulator = `${cookie.agentCode}-SIM`;
    localStorage.setItem(simulator, JSON.stringify(value));
};

/**
 * pour vider les donénes de simulation
 */
export const resetSim = (): void => {
    const cookie = Cookies.getJSON('Auth');
    if (!cookie) return;
    const simulator = `${cookie.agentCode}-SIM`;

    localStorage.removeItem(simulator);
};

// Set previsit template
export const setPrevisit = (value: any): void => {
    const cookie = Cookies.getJSON('Auth');
    if (!cookie) return;
    const preVisite = `${cookie.agentCode}-PV`;
    localStorage.setItem(preVisite, JSON.stringify(value));
};

// reet previsit template
export const resetPrevisit = (): void => {
    const cookie = Cookies.getJSON('Auth');
    if (!cookie) return;
    const preVisite = `${cookie.agentCode}-PV`;
    localStorage.removeItem(preVisite);
};
// Set funding data
export const setFunding = (value: any): void => {
    const cookie = Cookies.getJSON('Auth');
    if (!cookie) return;
    const funding = `${cookie.agentCode}-FUND`;
    localStorage.setItem(funding, JSON.stringify(value));
};
// Set funding data
export const resetFunding = (): void => {
    const cookie = Cookies.getJSON('Auth');
    if (!cookie) return;
    const funding = `${cookie.agentCode}-FUND`;
    localStorage.removeItem(funding);
};

export const getFunding = (): FlowFundingOutput | undefined => {
    const cookie = Cookies.getJSON('Auth');
    if (!cookie) return;
    const funding = `${cookie.agentCode}-FUND`;
    return JSON.parse(localStorage.getItem(funding) as string);
};

//#endregion

//#region recoAppointement

export const getRecoAppointment = (): AppointmentOutput | null => {
    const r = localStorage.getItem('recoAppointment');
    return r === null ? null : (JSON.parse(r) as AppointmentOutput);
};
export const setRecoAppointment = (recoAppointment: AppointmentOutput | null): void => {
    if (recoAppointment === null) resetRecoAppointment();
    localStorage.setItem('recoAppointment', JSON.stringify(recoAppointment));
};
export const resetRecoAppointment = (): void => {
    localStorage.removeItem('recoAppointment');
};

//#endregion

// #region set / reset  audit -PV -SIM (check properties)

// TODO cette fonction peut retourner, false (echec) ou void (on sait aps ce que ca fait) ou une valeur d'un type indeterminé !
/**
 * Fonction permettant le contrôle si une propriété existe dans l'étape, sinon création, sinon renvoi de l'élément.
 * @param step (Numéro de l"étape)
 * @param propertyName (Nom de la propriété)
 * @param propertyDescription (Description de la propriété dans le JSON)
 * @param propertyType (Le type de propriété boolean|basic|number|withLabel)
 */
export const checkPropertyExistThenCreateOrRender = (step: number, propertyName: string, propertyDescription: string, propertyType: PropertyType) => {
    // Récupération du template global
    const template = getAudit();
    // Vérification présence de la propriété dans l'étape
    if (!template[`step${step}`].hasOwnProperty(propertyName)) {
        let newProperty: any = {
            desc: propertyDescription,
        };
        // Définition du type
        switch (propertyType) {
            case PropertyType['boolean']:
                newProperty.value = false;
                break;
            case PropertyType['basic']:
                newProperty.value = '';
                break;
            case PropertyType['number']:
                newProperty.value = null;
                break;
            case PropertyType['withLabel']:
                newProperty.value = '';
                newProperty.value_label = '';
                break;
            case PropertyType['building']:
                newProperty.value = [
                    {
                        name: '',
                        shape: '1',
                        height: 2.5,
                        length: 10,
                        width: 5,
                        orientation: 'N',
                        img: '',
                    },
                ];
                break;
            case PropertyType['buildingDetail']:
                newProperty.value = [
                    {
                        buildingType: '',
                        buildingTypeSurface: '',
                        identicalBuildingType: '',
                        buildingTypeMainRoom: '',
                        buildingTypeBathroom: '',
                        buildingTypeBathroom2: '',
                        buildingTypeWC: '',
                        buildingTypeEmission: '',
                        buildingTypeECS: '',
                    },
                ];
                break;
            case PropertyType['dependentDetail']:
                newProperty.value = [];
                break;
            case PropertyType['window']:
                newProperty.value = {};
                break;
            case PropertyType['array']:
                newProperty.value = [];
                break;
            case PropertyType['photo']:
                newProperty.value = '';
                newProperty.url = '';
                break;
        }
        // Récupération de l'étape
        let alteredStep = template[`step${step}`];
        // injection du nouveau champ
        alteredStep[propertyName] = newProperty;
        template[`step${step}`] = alteredStep;
        // Injection dans local storage
        // TODO CHECK THIS REPLACEMENT.
        // Récupération code agent
        //const agentCode = Cookies.getJSON('Auth').agentCode;
        //return setLocalStorage(agentCode, JSON.stringify(template));
        return setAudit(template);
    }
    return template[`step${step}`][propertyName].value;
};

/**
 * Fonction permettant le contrôle si une propriété existe dans l'étape, sinon création, sinon renvoi de l'élément.
 * @param propertyName (Nom de la propriété)
 * @param propertyDescription (Description de la propriété dans le JSON)
 * @param propertyType (Le type de propriété boolean|basic|number|withLabel)
 */

export const checkPropertyExistThenCreateOrRenderPreVisite = (propertyName: string, propertyDescription: string, propertyType: PropertyType) => {
    const getCookie = Cookies.getJSON('Auth');
    if (getCookie === undefined) {
        console.log("Problème de cookie, vérifier que `checkPropertyExistThenCreateOrRenderPrevisite` n'a pas été appelé trop tôt");
    }
    // Récupération code agent
    const agentCode = getCookie.agentCode + '-PV';
    // Récupération du template global
    const template = getPrevisit();
    // Vérification présence de la propriété dans l'étape
    if (!template.hasOwnProperty(propertyName)) {
        let newProperty: any = {
            desc: propertyDescription,
        };
        // Définition du type
        switch (propertyType) {
            case PropertyType['boolean']:
                newProperty.value = '';
                break;
            case PropertyType['basic']:
                newProperty.value = '';
                break;
            case PropertyType['number']:
                newProperty.value = null;
                break;
            case PropertyType['withLabel']:
                newProperty.value = '';
                newProperty.value_label = '';
                break;
            case PropertyType['heatingCircuit']:
                newProperty.value = [{ type: { label: '', value: '' }, source: { label: '', value: '' }, temperature: '' }];
                break;
            case PropertyType['radiatorCircuit']:
                newProperty.value = [
                    {
                        thermostaticValve: '',
                        width: '',
                        height: '',
                        depth: '',
                        inputTemperature: '',
                        outputTemperature: '',
                        radiatorType: '',
                        topView: '',
                    },
                ];
                break;
            case PropertyType['roofShape']:
                newProperty.value = {
                    cheminee: { color: colors[1], elements: [] },
                    chien_assis: { color: colors[3], elements: [] },
                    panneaux_photovoltaiques: { color: colors[4], elements: [] },
                    toiture: { color: colors[0], elements: [] },
                    velux: { color: colors[2], elements: [] },
                };
                break;
            case PropertyType['date']:
                newProperty.value = null;
                break;
        }
        // Injection dans local storage
        return setLocalStorage(agentCode, JSON.stringify(template));
    }

    return template[propertyName].value;
};
/**
 * Fonction permettant le contrôle si une propriété existe dans l'étape, sinon création, sinon renvoi de l'élément.
 * @param propertyName (Nom de la propriété)
 * @param propertyDescription (Description de la propriété dans le JSON)
 * @param propertyType (Le type de propriété boolean|basic|number|withLabel)
 */
export const checkPropertyExistThenCreateOrRenderSimulator = (propertyName: string, propertyDescription: string, propertyType: PropertyType) => {
    const getCookie = Cookies.getJSON('Auth');
    if (getCookie === undefined) {
        console.log("Problème de cookie, vérifier que `checkPropertyExistThenCreateOrRenderSimulator` n'a pas été appelé trop tôt");
    }
    // Récupération code agent
    const sim = getCookie.agentCode + '-SIM';
    // Récupération du template global
    const template = getSim();
    // Vérification présence de la propriété dans l'étape
    if (!template.hasOwnProperty(propertyName)) {
        let newProperty: any = {
            desc: propertyDescription,
        };
        // Définition du type
        switch (propertyType) {
            case PropertyType['boolean']:
                newProperty.value = false;
                break;
            case PropertyType['basic']:
                newProperty.value = '';
                break;
            case PropertyType['number']:
                newProperty.value = null;
                break;
            case PropertyType['withLabel']:
                newProperty.value = '';
                newProperty.value_label = '';
                break;
            case PropertyType['dependentDetail']:
                newProperty.value = [];
                break;
            case PropertyType['anexe']:
                newProperty.value = [
                    {
                        designation: '',
                        prix: '',
                    },
                ];
                break;
            case PropertyType['loan']:
                newProperty.value = null;
        }
        // Injection dans local storage
        return setLocalStorage(sim, JSON.stringify(template));
        // TODO NICOLAS : is this would do it ?
        // setSim(JSON.stringify(template))
    }

    return template[propertyName].value;
};

//#endregion

// #region steps management

export const checkLocalStorageCurrentStep = () => {
    const agentCode = Cookies.getJSON('Auth').agentCode;
    if (JSON.parse(localStorage.getItem(agentCode) as string)) {
        return JSON.parse(localStorage.getItem(agentCode) as string).currentStep;
    }
    return false;
};

export const getAuditCurrentStep = (): number => {
    const agentCode = Cookies.getJSON('Auth').agentCode;
    return JSON.parse(localStorage.getItem(agentCode) as string).currentStep;
};
export const setAuditCurrentStep = (newStep: number) => {
    const audit = getAudit();
    audit.currentStep = newStep;
    return setAudit(audit);
};
/**
 * Fonction pour vérifier si l'étape courante est intégralement remplie.
 * @param step
 */
export const isAuditStepValid = (step: number) => {
    const template = getAudit();
    const currentStep = template[`step${step}`];
    return currentStep.isValid;
};

//#endregion

// #region camapgne

export const campagneNames = ['reste_a_charge', 'action_logement', undefined] as const;
export type Campagne = (typeof campagneNames)[number];

export const getAuditCampagne = (): Campagne => {
    const agentCode = Cookies.getJSON('Auth').agentCode;
    return JSON.parse(localStorage?.getItem(agentCode) as string)?.campagne;
};

export const getAuditCampagne_fromCookie = (): Campagne => {
    return Cookies.getJSON('Auth').campagne as Campagne;
};

/**
 * Fonction pour set une campagne
 */
export const setAuditCampagne = (value: Campagne) => {
    const template = getAudit();
    template.campagne = value;
    return setAudit(template);
};

// #endregion

// #region set audit -PV -SIM values

/**
 * Fonction pour mettre à jour la valeur une donnée précise du JSON
 */
export const setAuditValue = (step: number, isValid: boolean, propertyName: string, value: any, label?: string) => {
    const template = getAudit();
    template[`step${step}`][propertyName].value = value;
    if (label) {
        template[`step${step}`][propertyName].value_label = label;
    }
    template[`step${step}`].isValid = isValid;
    return setAudit(template);
};

/**
 * Fonction pour mettre à jour la valeur une donnée précise du JSON
 */
export const setSimValue = (propertyName: string, value: any, label?: string) => {
    const template = getSim();
    template[propertyName].value = value;
    if (label) {
        template[propertyName].value_label = label;
    }

    return setSim(template);
};

/**
 * Fonction pour mettre à jour la valeur une donnée précise du JSON
 */
export const setPrevisitValue = (propertyName: string, value: any, label?: string) => {
    const template = getPrevisit();
    template[propertyName].value = value;
    if (label) {
        template[propertyName].value_label = label;
    }

    return setPrevisit(template);
};

/**
 * Fonction pour mettre à jour la valeur une donnée précise du JSON
 */
export const removeAuditValue = (step: number, propertyName: string, removeLabel?: boolean) => {
    const audit = getAudit();
    audit[`step${step}`][propertyName].value = '';
    if (removeLabel) {
        audit[`step${step}`][propertyName].value_label = '';
    }
    return setAudit(audit);
};

/**
 * Fonction pour mettre à jour la valeur une donnée précise du JSON Simulator
 */
export const removeSimValue = (propertyName: string, removeLabel?: boolean, emptyArray?: boolean) => {
    const template = getSim();
    template[propertyName].value = '';

    if (removeLabel) {
        template[propertyName].value_label = '';
    }

    if (emptyArray) {
        template[propertyName].value = [];
    }

    return setSim(template);
};

/**
 * Fonction pour mettre à jour la valeur une donnée précise du JSON Simulator
 */
export const removePrevisitValue = (propertyName: string, removeLabel?: boolean, emptyArray?: boolean) => {
    const template = getPrevisit();
    template[propertyName].value = '';

    if (removeLabel) {
        template[propertyName].value_label = '';
    }

    if (emptyArray) {
        template[propertyName].value = [];
    }

    return setPrevisit(template);
};

/**
 * Fonction pour déclarer une étape comme valide ou invalide
 */
export const setAuditStepValidity = (step: number, isValid: boolean) => {
    const template = getAudit();
    template[`step${step}`].isValid = isValid;
    return setAudit(template);
};

//#endregion

//#region Building plan

/**
 * Fonction pour mettre à jour la valeur d'un building au changement de forme
 */
export const setGlobalBuildingPlan = (isValid: boolean, value: any) => {
    const template = getAudit();
    template[`step2`]['buildingPlans'].value = value;
    template[`step2`].isValid = isValid;
    return setAudit(template);
};

/**
 * Fonction pour mettre à jour la valeur une donnée précise du JSON
 */
export const setLocalStorageValueBuildingPlan = (isValid: boolean, propertyName: string, index: number, value: string | number) => {
    const template = getAudit();
    template[`step2`]['buildingPlans'].value[index][propertyName] = value;
    template[`step2`].isValid = isValid;
    return setAudit(template);
};

// #endregion Fin Building plan

/**
 * Liste des images de l'étape 8 en base64 à purger
 */
export const picturesBase64List = [
    'buildingPictureGeneral',
    'buildingPictureNorth',
    'buildingPictureEast',
    'buildingPictureSouth',
    'buildingPictureWest',
    'buildingPictureRoofing',
    'buildingPictureOther',
    'buildingPictureBalcony',
    'buildingPicturesSolarMask',
    'buildingPicturesheaterGeneratorPower',
    'buildingPicturesheaterFeature',
    'buildingPicturesenergyWater',
    'buildingPictureRoofing',
    'buildingPicturesPathology1',
    'buildingPicturesPathology2',
    'buildingPicturesPathology3',
    'buildingPicturesPathology4',
    'buildingPicturesPathology5',
    'buildingPicturesPathology6',
    'buildingPicturesPathology7',
    'buildingPictureCeilingInsulationPresence',
    'buildingPictureFloorInsulationPresence',
    'buildingPictureExteriorWall',
    'buildingPictureUnheatedRoom',
    'buildingPictureDoor1',
    'buildingPictureDoor2',
    'buildingPictureDoorUnheatedRoom1Presence',
    'buildingPictureBuildingWindows1',
    'buildingPictureBuildingWindows2',
    'buildingPictureDoorWindows1',
    'energyCostsElectricInvoice1',
    'energyCostsElectricInvoice2',
    'energyCostsElectricInvoice3',
    'energyCostsGasInvoice1',
    'energyCostsGasInvoice2',
    'energyCostsGasInvoice3',
];

//#region get config files

// Récupère un certains nombre de fichiers de configuration et les stockes dans le local storage du navigateur.
// Ces fichiers sont lu du serveur directement par appels des routes associées.

const configFileNames = [
    'energyprices',
    'constantesPhotovoltaiques',
    'sunshines',
    'sunshinesyield',
    'indexations',
    'preconisationsChauffage',
    'investmentBonus',
    'customerFlowParameters',
    //'financoScales',
] as const;
export type ConfigFileName = (typeof configFileNames)[number];

export type ConfigFileType<T extends ConfigFileName> = {
    energyprices: Array<EnergyPrice>;
    constantesPhotovoltaiques: ConstantesPhotovoltaique;
    sunshines: Array<Ensoleillement>;
    sunshinesyield: Array<{ inclinaison: number; rendement: { [key in Orientation]: number } }>;
    indexations: Indexations;
    preconisationsChauffage: Array<PreconisationChauffage>;
    investmentBonus: Array<InvestmentBonus>;
    customerFlowParameters: CustomerFlowParameters;
    //financoScales: Array<Labelled<FinancoScale>>;
}[T];

export const getConfig = <T extends ConfigFileName>(name: T): ConfigFileType<T> => {
    return JSON.parse(localStorage.getItem(name)!) as ConfigFileType<T>;
};

// Cas spécial
export const getFinacoScale = (): Array<Labelled<FinancoScale>> => {
    const scalesFromLocalStorage: string | null = localStorage.getItem('financoScales');
    return scalesFromLocalStorage ? JSON.parse(scalesFromLocalStorage) : [];
};

export const CONSTANTES_PHOTOVOLTAIQUES: ConstantesPhotovoltaique = getConfig('constantesPhotovoltaiques');

//#endregion
