// Dans ce fichier, on retrouve les classes métiers.
// - EnergieFinale
// - EnergiePrimaire
// - EnergieParSource
// - EnergieParPoste
// L'objectif de ce fichier est de fournir un bilan energetique en deux étapes :
// - getEnergieFinalePrimaireTheorique bilan en KWh
// - getDepensesParPoste bilan en euros
/// Ceci représente de l'energie  par source, en KWh.

import { Indexations } from './calculEconomie';
import * as storageService from '../localStorageService';

/// En KWh
export class EnergieParSource {
    electricite: number = 0;
    fioul: number = 0;
    gaz: number = 0;
    bois: number = 0;
    total = (): number => {
        return this.electricite + this.totalCombustible();
    };
    totalCombustible = (): number => {
        return this.fioul + this.gaz + this.bois;
    };
    log = (indent = '  ', prices: Array<number> | undefined = undefined) => {
        // only for debug logging. no real calculations here.
        let prix = '';
        if (prices && prices[0]) prix = ' / ' + this.electricite * prices[0] + ' €';
        else prix = '';
        console.log(indent + 'electricite = ' + this.electricite + ' KWh' + prix);
        if (prices && prices[1]) prix = ' / ' + this.fioul * prices[1] + ' €';
        else prix = '';
        console.log(indent + 'fioul = ' + this.fioul + ' KWh' + prix);
        if (prices && prices[2]) prix = ' / ' + this.gaz * prices[2] + ' €';
        else prix = '';
        console.log(indent + 'gaz = ' + this.gaz + ' KWh' + prix);
        if (prices && prices[3]) prix = ' / ' + this.bois * prices[3] + ' €';
        else prix = '';
        console.log(indent + 'bois = ' + this.bois + ' KWh' + prix);
    };
    copie = (): EnergieParSource => {
        const result = new EnergieParSource();
        result.electricite = this.electricite;
        result.fioul = this.fioul;
        result.gaz = this.gaz;
        result.bois = this.bois;
        return result;
    };
    energieName = (): string => {
        let result = '';
        if (this.totalCombustible() === 0) return 'Electricité';
        if (this.gaz !== 0) result += 'Gaz & ';
        if (this.bois !== 0) result += 'Bois & ';
        if (this.fioul !== 0) result += 'Fioul & ';
        if (this.electricite !== 0) result += 'Electricité & ';
        result = result.slice(0, -2); // remove '& ';
        return result;
    };
}

// Dépense d'énergie par poste. Chaque poste est divisé par source.
// Les données sont exprimées en KWh, et ne sont pas arrondies.
export class DepenseParPoste {
    chauffage: EnergieParSource = new EnergieParSource();
    eauChaude: EnergieParSource = new EnergieParSource();
    autres: EnergieParSource = new EnergieParSource();
    // initialisation des prix des énergies (abonnement et prix unitaires) avec les prix par défaut selon les énergies.
    electricitePrice: EnergyPrice = getPricesWithSubscription('electricity', undefined);
    gazPrice: EnergyPrice = getPricesWithSubscription('gaz', undefined);
    fioulPrice: EnergyPrice = getPricesWithSubscription('fuel', undefined);
    boisPrice: EnergyPrice = getPricesWithSubscription('wood', undefined);

    abonnementElectricite = (): number => {
        return this.consoElectricite() === 0 ? 0 : this.electricitePrice ? this.electricitePrice.subscriptionPrice : 0;
    };
    abonnementGaz = (): number => {
        return this.consoGaz() === 0 ? 0 : this.gazPrice ? this.gazPrice.subscriptionPrice : 0;
    };
    // toujours à 0, mais conservé par soucis de symétrie
    abonnementFioul = (): number => {
        return this.consoFioul() === 0 ? 0 : this.fioulPrice ? this.fioulPrice.subscriptionPrice : 0;
    };
    // toujours à 0, mais conservé par soucis de symétrie
    abonnementBois = (): number => {
        return this.consoBois() === 0 ? 0 : this.boisPrice ? this.boisPrice.subscriptionPrice : 0;
    };
    ratioPrixChauffage = (): number => {
        const total = this.prixHorsAbo();
        if (total === 0) return 0;
        return this.prixChauffage() / total;
    };
    ratioPrixEauChaude = (): number => {
        const total = this.prixHorsAbo();
        if (total === 0) return 0;
        return this.prixEauChaude() / total;
    };
    ratioPrixAutres = (): number => {
        // TODO harmoniser
        const total = this.prixHorsAbo();
        if (total === 0) return 0;
        return this.prixAutres() / total;
    };
    ratioConsoChauffage = (): number => {
        const total = this.conso();
        if (total === 0) return 0;
        return this.chauffage.total() / total;
    };
    ratioConsoEauChaude = (): number => {
        const total = this.conso();
        if (total === 0) return 0;
        return this.eauChaude.total() / total;
    };
    ratioConsoAutres = (): number => {
        // TODO harmoniser
        const total = this.conso();
        if (total === 0) return 0;
        return this.autres.total() / total;
    };
    prixHorsAbo = (): number => {
        return this.prixElectriciteHorsAbo() + this.prixCombustibleHorsAbo();
    };
    prix = (): number => {
        return this.prixElectricite() + this.prixCombustible();
    };
    prixElectriciteHorsAbo = (): number => {
        return this.consoElectricite() * this.electricitePrice!.unitPrice;
    };
    prixFioulHorsAbo = (): number => {
        return this.consoFioul() * this.fioulPrice!.unitPrice;
    };
    prixGazHorsAbo = (): number => {
        return this.consoGaz() * this.gazPrice!.unitPrice;
    };
    prixBoisHorsAbo = (): number => {
        return this.consoBois() * this.boisPrice!.unitPrice;
    };
    prixElectricite = (): number => {
        return this.prixElectriciteHorsAbo() + this.abonnementElectricite();
    };
    prixFioul = (): number => {
        return this.prixFioulHorsAbo() + this.abonnementFioul();
    };
    prixGaz = (): number => {
        return this.prixGazHorsAbo() + this.abonnementGaz();
    };
    prixBois = (): number => {
        return this.prixBoisHorsAbo() + this.abonnementBois();
    };
    prixCombustibleHorsAbo = (): number => {
        return this.prixFioulHorsAbo() + this.prixBoisHorsAbo() + this.prixGazHorsAbo();
    };
    prixCombustible = (): number => {
        return this.prixFioul() + this.prixBois() + this.prixGaz();
    };
    prixChauffage = (): number => {
        return (
            this.chauffage.electricite * this.electricitePrice!.unitPrice +
            this.chauffage.bois * this.boisPrice.unitPrice +
            this.chauffage.fioul * this.fioulPrice.unitPrice +
            this.chauffage.gaz * this.gazPrice.unitPrice
        );
    };
    prixEauChaude = (): number => {
        return (
            this.eauChaude.electricite * this.electricitePrice!.unitPrice +
            this.eauChaude.bois * this.boisPrice.unitPrice +
            this.eauChaude.fioul * this.fioulPrice.unitPrice +
            this.eauChaude.gaz * this.gazPrice.unitPrice
        );
    };
    prixAutres = (): number => {
        return (
            this.autres.electricite * this.electricitePrice!.unitPrice +
            this.autres.bois * this.boisPrice.unitPrice +
            this.autres.fioul * this.fioulPrice.unitPrice +
            this.autres.gaz * this.gazPrice.unitPrice
        );
    };
    conso = (): number => {
        return this.consoElectricite() + this.consoFioul() + this.consoGaz() + this.consoBois();
    };

    consoElectricite = (): number => {
        return this.chauffage.electricite + this.eauChaude.electricite + this.autres.electricite;
    };
    consoFioul = (): number => {
        return this.chauffage.fioul + this.eauChaude.fioul + this.autres.fioul;
    };
    consoGaz = (): number => {
        return this.chauffage.gaz + this.eauChaude.gaz + this.autres.gaz;
    };
    consoBois = (): number => {
        return this.chauffage.bois + this.eauChaude.bois + this.autres.bois;
    };
    logDetail = (message?: string) => {
        console.log(message + ' DepenseEnergieParPoste Detail : ' + this.prix());
        console.log('  abonement Elec = ' + this.abonnementElectricite());
        console.log('  abonement gaz = ' + this.abonnementGaz());
        const prices = [this.electricitePrice.unitPrice, this.fioulPrice.unitPrice, this.gazPrice.unitPrice, this.boisPrice.unitPrice];
        console.log('  chauffage = ' + this.chauffage.total());
        this.chauffage.log('    ', prices);
        console.log('  eauChaude = ' + this.eauChaude.total());
        this.eauChaude.log('    ', prices);
        console.log('  autres    = ' + this.autres.total());
        this.autres.log('    ', prices);
    };
    copie = (): DepenseParPoste => {
        const result = new DepenseParPoste();
        result.chauffage = this.chauffage.copie();
        result.eauChaude = this.eauChaude.copie();
        result.autres = this.autres.copie();
        result.electricitePrice = copyEnergyPrice(this.electricitePrice);
        result.gazPrice = copyEnergyPrice(this.gazPrice);
        result.fioulPrice = copyEnergyPrice(this.fioulPrice);
        result.boisPrice = copyEnergyPrice(this.boisPrice);
        return result;
    };
}

/**
 * obtient la différence entre deux poste de depense. return A-B;
 * @param A
 * @param B
 * @returns A-B
 */
export const getEconomieBetween = (A: DepenseParPoste, B: DepenseParPoste): DepenseParPoste => {
    const diff: DepenseParPoste = new DepenseParPoste();
    diff.chauffage.fioul = A.chauffage.fioul - B.chauffage.fioul;
    diff.chauffage.electricite = A.chauffage.electricite - B.chauffage.electricite;
    diff.chauffage.bois = A.chauffage.bois - B.chauffage.bois;
    diff.chauffage.gaz = A.chauffage.gaz - B.chauffage.gaz;
    diff.eauChaude.fioul = A.eauChaude.fioul - B.eauChaude.fioul;
    diff.eauChaude.electricite = A.eauChaude.electricite - B.eauChaude.electricite;
    diff.eauChaude.bois = A.eauChaude.bois - B.eauChaude.bois;
    diff.eauChaude.gaz = A.eauChaude.gaz - B.eauChaude.gaz;
    diff.autres.fioul = A.autres.fioul - B.autres.fioul;
    diff.autres.electricite = A.autres.electricite - B.autres.electricite;
    diff.autres.bois = A.autres.bois - B.autres.bois;
    diff.autres.gaz = A.autres.gaz - B.autres.gaz;

    // On copie le prix des énergies, arbitrairemnt depuis A, mais on va recaclculer l'abonnement.
    diff.fioulPrice = copyEnergyPrice(A.fioulPrice);
    diff.electricitePrice = copyEnergyPrice(A.electricitePrice);
    diff.boisPrice = copyEnergyPrice(A.boisPrice);
    diff.gazPrice = copyEnergyPrice(A.gazPrice);

    // En cas de calcul d'une économie,
    // l'abonnement aussi peut être économisé
    if (diff.fioulPrice) diff.fioulPrice.subscriptionPrice = A.abonnementFioul() - B.abonnementFioul();
    if (diff.electricitePrice) diff.electricitePrice.subscriptionPrice = A.abonnementElectricite() - B.abonnementElectricite();
    if (diff.boisPrice) diff.boisPrice.subscriptionPrice = A.abonnementBois() - B.abonnementBois();
    if (diff.gazPrice) diff.gazPrice.subscriptionPrice = A.abonnementGaz() - B.abonnementGaz();

    return diff;
};

/**
 * obtient la somme entre deux poste de depense. return A+B;
 * @param A
 * @param B
 * @param shouldSumAbo true, si on doit faire la somme des abonnements.
 * @returns A-B
 */
export const sum = (a: DepenseParPoste, b: DepenseParPoste, shouldSumAbo: boolean): DepenseParPoste => {
    const sum: DepenseParPoste = new DepenseParPoste();
    sum.chauffage.fioul = a.chauffage.fioul + b.chauffage.fioul;
    sum.chauffage.electricite = a.chauffage.electricite + b.chauffage.electricite;
    sum.chauffage.bois = a.chauffage.bois + b.chauffage.bois;
    sum.chauffage.gaz = a.chauffage.gaz + b.chauffage.gaz;
    sum.eauChaude.fioul = a.eauChaude.fioul + b.eauChaude.fioul;
    sum.eauChaude.electricite = a.eauChaude.electricite + b.eauChaude.electricite;
    sum.eauChaude.bois = a.eauChaude.bois + b.eauChaude.bois;
    sum.eauChaude.gaz = a.eauChaude.gaz + b.eauChaude.gaz;
    sum.autres.fioul = a.autres.fioul + b.autres.fioul;
    sum.autres.electricite = a.autres.electricite + b.autres.electricite;
    sum.autres.bois = a.autres.bois + b.autres.bois;
    sum.autres.gaz = a.autres.gaz + b.autres.gaz;

    sum.fioulPrice = copyEnergyPrice(a.fioulPrice);
    sum.electricitePrice = copyEnergyPrice(a.electricitePrice);
    sum.boisPrice = copyEnergyPrice(a.boisPrice);
    sum.gazPrice = copyEnergyPrice(a.gazPrice);

    // SUM : 2 cas,
    // - on additionne l'abonnement, par exemple si on fait un calcul tous les ans.
    // - on garde le même : par exemple, au augment la dépense lors d'une année.
    if (shouldSumAbo) {
        if (sum.fioulPrice) sum.fioulPrice.subscriptionPrice = a.abonnementFioul() + b.abonnementFioul();
        if (sum.electricitePrice) sum.electricitePrice.subscriptionPrice = a.abonnementElectricite() + b.abonnementElectricite();
        if (sum.boisPrice) sum.boisPrice.subscriptionPrice = a.abonnementBois() + b.abonnementBois();
        if (sum.gazPrice) sum.gazPrice.subscriptionPrice = a.abonnementGaz() + b.abonnementGaz();
    }
    return sum;
};

/**
 * incremente un poste de depénse avec l'autre.
 * @param A le poste de départ qui sera incrémenté, a la sortie de la fonction A vaudra (A+B)
 * @param B l'incrément
 */
export const inc = (A: DepenseParPoste, B: DepenseParPoste): void => {
    A.chauffage.fioul += B.chauffage.fioul;
    A.chauffage.electricite += B.chauffage.electricite;
    A.chauffage.bois += B.chauffage.bois;
    A.chauffage.gaz += B.chauffage.gaz;
    A.eauChaude.fioul += B.eauChaude.fioul;
    A.eauChaude.electricite += B.eauChaude.electricite;
    A.eauChaude.bois += B.eauChaude.bois;
    A.eauChaude.gaz += B.eauChaude.gaz;
    A.autres.fioul += B.autres.fioul;
    A.autres.electricite += B.autres.electricite;
    A.autres.bois += B.autres.bois;
    A.autres.gaz += B.autres.gaz;

    if (A.fioulPrice) A.fioulPrice.subscriptionPrice += B.abonnementFioul();
    if (A.electricitePrice) A.electricitePrice.subscriptionPrice += B.abonnementElectricite();
    if (A.boisPrice) A.boisPrice.subscriptionPrice += B.abonnementBois();
    if (A.gazPrice) A.gazPrice.subscriptionPrice += B.abonnementGaz();
};

/**
 * Cette fonction est utilisée pour calculer le comportement d'une dépense selon l'indexation lors de la nieme année.
 * @param depenseInitiale la dépnse à extrapoler
 * @param indexations l'indexation recue par le servuer, stockée dans un fichier local
 * @param year L'année pour laquelle on calcul cette 'inflation'
 * @returns une dépense apr poste, à laquelle on a aindexé le cout de l'electricité pour l'année year
 */
export const indexedAtYear = (depenseInitiale: DepenseParPoste, indexations: Indexations, year: number): DepenseParPoste => {
    // TODO : FAUX, indexer le prix
    const depenseAtYear: DepenseParPoste = new DepenseParPoste();
    // cumul += y0 * (1,05)^i
    depenseAtYear.chauffage.fioul = depenseInitiale.chauffage.fioul * Math.pow(indexations.fioul, year);
    depenseAtYear.chauffage.electricite = depenseInitiale.chauffage.electricite * Math.pow(indexations.electricite, year);
    depenseAtYear.chauffage.bois = depenseInitiale.chauffage.bois * Math.pow(indexations.bois, year);
    depenseAtYear.chauffage.gaz = depenseInitiale.chauffage.gaz * Math.pow(indexations.gaz, year);
    depenseAtYear.eauChaude.fioul = depenseInitiale.eauChaude.fioul * Math.pow(indexations.fioul, year);
    depenseAtYear.eauChaude.electricite = depenseInitiale.eauChaude.electricite * Math.pow(indexations.electricite, year);
    depenseAtYear.eauChaude.bois = depenseInitiale.eauChaude.bois * Math.pow(indexations.bois, year);
    depenseAtYear.eauChaude.gaz = depenseInitiale.eauChaude.gaz * Math.pow(indexations.gaz, year);
    depenseAtYear.autres.fioul = depenseInitiale.autres.fioul * Math.pow(indexations.fioul, year);
    depenseAtYear.autres.electricite = depenseInitiale.autres.electricite * Math.pow(indexations.electricite, year);
    depenseAtYear.autres.bois = depenseInitiale.autres.bois * Math.pow(indexations.bois, year);
    depenseAtYear.autres.gaz = depenseInitiale.autres.gaz * Math.pow(indexations.gaz, year);

    depenseAtYear.fioulPrice = copyEnergyPrice(depenseInitiale.fioulPrice);
    depenseAtYear.electricitePrice = copyEnergyPrice(depenseInitiale.electricitePrice);
    depenseAtYear.boisPrice = copyEnergyPrice(depenseInitiale.boisPrice);
    depenseAtYear.gazPrice = copyEnergyPrice(depenseInitiale.gazPrice);

    // Les prix des abonnements aussi sont augmentés.
    if (depenseAtYear.fioulPrice) depenseAtYear.fioulPrice.subscriptionPrice = depenseAtYear.fioulPrice.subscriptionPrice * Math.pow(indexations.fioul, year);
    if (depenseAtYear.electricitePrice)
        depenseAtYear.electricitePrice.subscriptionPrice = depenseAtYear.electricitePrice.subscriptionPrice * Math.pow(indexations.electricite, year);
    if (depenseAtYear.boisPrice) depenseAtYear.boisPrice.subscriptionPrice = depenseAtYear.boisPrice.subscriptionPrice * Math.pow(indexations.bois, year);
    if (depenseAtYear.gazPrice) depenseAtYear.gazPrice.subscriptionPrice = depenseAtYear.gazPrice.subscriptionPrice * Math.pow(indexations.gaz, year);

    return depenseAtYear;
};

// Cette fonction fait les caclculs pour retourner l'energie finale et l'energie primaire.
// Première étape du process complet, chapitre 1.1 et 1.2 des specs.
export const getEnergieFinalePrimaireTheorique = (audit: any): { energieFinale: EnergieParSource; energiePrimaire: EnergieParSource } => {
    // Enum relatives : dans ce fichier : /src/pages/energy-system-informations/EnergySystemInformations.tsx
    // - heaterTypeList
    // - heaterSubscriptionList
    // Creation de l'objet résult, avec toutes les valeurs à 0 par défaut !
    const finale = new EnergieParSource();
    const primaire = new EnergieParSource();
    // On a TOUJOURS de l'electricite, contrairement aux autres énergies.
    // 2021/07/28 à date si on a choisit une autre source d'énergie que l'electricite lors de l'audit,
    // on ne connait pas l'abonnement d'électricité !
    // Dans ce cas on admet qu'on prend un abonnement par defaut : 1.
    let abonnementElectricite = 1; // Choix arbitraire par defaut.
    if (audit.heaterType.value === '0')
        // Type 0 = electricite
        abonnementElectricite = +audit.heaterSubscriptionElectricity.value;

    const elecPrices = getPricesWithSubscription('electricity', abonnementElectricite);

    //console.log("elec Price = " + JSON.stringify(elecPrices));
    if (elecPrices != null && elecPrices.unitPrice !== 0) {
        finale.electricite = (+audit.energyCostsElectric.value - elecPrices.subscriptionPrice) / elecPrices.unitPrice;
        primaire.electricite = finale.electricite * elecPrices.primaryCoeficient;
    }
    //else {  console.log("no elec"); } // a retirer
    if (audit.energyCostsGas !== undefined && +audit.energyCostsGas.value !== 0) {
        // Pour le gaz il y a un abonnement (on espère !)
        // A date, il y a certains cas ou on n'a pas l'abonnement renseigné.
        let abonnementGaz = 34; // Choix arbitraire par defaut.
        if (+audit.heaterType.value === 4)
            // gaz
            abonnementGaz = +audit.heaterSubscriptionGas.value;
        const gazPrices = getPricesWithSubscription('gaz', abonnementGaz);
        if (gazPrices && gazPrices.unitPrice !== 0) {
            finale.gaz = (+audit.energyCostsGas.value - gazPrices.subscriptionPrice) / gazPrices.unitPrice;
            primaire.gaz = finale.gaz * gazPrices.primaryCoeficient;
        }
    }
    // Si je n'ai pas de dépense en bois l'énergie liée au bois est 0.
    if (audit.energyCostsWood !== undefined && +audit.energyCostsWood.value !== 0) {
        // Pour le bois pas d'abonnement donc, on divise par le prix unitaire.
        const price = getPrices('wood');
        if (price && price.unitPrice !== 0) {
            finale.bois = +audit.energyCostsWood.value / price.unitPrice;
            primaire.bois = finale.bois * price.primaryCoeficient;
        }
    }
    //else {  console.log("no wood"); }// a retirer
    // Si je n'ai pas de dépense en fuel l'énergie liée au fuel est 0.
    if (audit.energyCostsFuel !== undefined && +audit.energyCostsFuel.value !== 0) {
        // Pour le fioul pas d'abonnement donc, on divise par le prix unitaire.
        const price = getPrices('fuel');
        if (price && price.unitPrice !== 0) {
            finale.fioul = +audit.energyCostsFuel.value / price.unitPrice;
            primaire.fioul = finale.fioul * price.primaryCoeficient;
        }
    }
    //else {  console.log("no fuel"); }// a retirer
    return { energieFinale: finale, energiePrimaire: primaire };
};
// Cette fonction fait les caclculs pour retourner l'energie finale et l'energie primaire APRES RENOVATION
export const getEnergieFinalePrimaireApresRenovation = (
    audit: any,
    depense: DepenseParPoste
): { energieFinale: EnergieParSource; energiePrimaire: EnergieParSource } => {
    // Enum relatives : dans ce fichier : /src/pages/energy-system-informations/EnergySystemInformations.tsx
    // - heaterTypeList
    // - heaterSubscriptionList
    // Dans ce cas la depense par poste, ne contient pas le cout des abonnements.
    // Creation de l'objet résult, avec toutes les valers à 0 par défaut !
    const finale = new EnergieParSource();
    const primaire = new EnergieParSource();
    // On a TOUJOURS de l'electricite, contrairement aux autres énergies.
    // 2021/07/28 à date si on a choisit une autre source d'énergie que l'electricite lors de l'audit,
    // on ne connait pas l'abonnement d'électricité !
    // Dans ce cas on admet qu'on prend un abonnement par defaut : 0.
    let abonnementElectricite = 1; // Choix arbitraire par defaut.
    if (audit.heaterType.value === 0)
        // Type 0 = electricite
        abonnementElectricite = +audit.heaterSubscriptionElectricity.value;
    const elecPrices = getPricesWithSubscription('electricity', abonnementElectricite);
    //console.log("elec Price = " + JSON.stringify(elecPrices));
    if (elecPrices != null && elecPrices.unitPrice !== 0) {
        finale.electricite = depense.prixElectricite() /*- elecPrices.subscriptionPrice*/ / elecPrices.unitPrice;
        primaire.electricite = finale.electricite * elecPrices.primaryCoeficient;
    } //else {  console.log("no elec"); } // a retirer
    if (audit.energyCostsGas !== undefined && +audit.energyCostsGas.value !== 0) {
        // Pour le gaz il y a un abonnement (on espère !)
        // A date, il y a certains cas ou on n'a pas l'abonnement renseigné.
        let abonnementGaz = 34; // Choix arbitraire par defaut.
        if (audit.heaterType.value === 4)
            // gaz
            abonnementGaz = +audit.heaterSubscriptionGas.value;
        const gazPrices = getPricesWithSubscription('gaz', abonnementGaz);
        if (gazPrices != null && gazPrices.unitPrice !== 0) {
            finale.gaz = depense.prixGaz() /*- gazPrices.subscriptionPrice*/ / gazPrices.unitPrice;
            primaire.gaz = finale.gaz * gazPrices.primaryCoeficient;
        }
    }
    //else {  console.log("no gaz"); }// a retirer
    // Si je n'ai pas de dépense en bois l'énergie liée au bois est 0.
    if (audit.energyCostsWood !== undefined && +audit.energyCostsWood.value !== 0) {
        // Pour le bois pas d'abonnement donc, on divise par le prix unitaire.
        const price = getPrices('wood');
        if (price != null && price.unitPrice !== 0) {
            finale.bois = depense.prixBois() / price.unitPrice;
            primaire.bois = finale.bois * price.primaryCoeficient;
        }
    }
    // Si je n'ai pas de dépense en fuel l'énergie liée au fuel est 0.
    if (audit.energyCostsFuel !== undefined && +audit.energyCostsFuel.value !== 0) {
        // Pour le fioul pas d'abonnement donc, on divise par le prix unitaire.
        const price = getPrices('fuel');
        if (price != null && price.unitPrice !== 0) {
            finale.fioul = depense.prixFioul() / price.unitPrice;
            primaire.fioul = finale.fioul * price.primaryCoeficient;
        }
    }
    return { energieFinale: finale, energiePrimaire: primaire };
};
export interface EnergyPrice {
    type: string;
    subscriptionType: number | undefined;
    subscriptionPrice: number;
    unitPrice: number;
    primaryCoeficient: number;
    name: string | null;
}
export const copyEnergyPrice = (source: EnergyPrice): EnergyPrice => {
    return JSON.parse(JSON.stringify(source)) as EnergyPrice;
};
// fonction privée qui va chercher quelques constantes dans les ressources téléchargées.
export const getPricesWithSubscription = (energyType: string, subscriptionType: number | string | undefined): EnergyPrice => {
    const ENERGY_PRICES = storageService.getConfig('energyprices');
    for (const e of ENERGY_PRICES!) {
        if (e.type === energyType && (+subscriptionType! === e.subscriptionType || subscriptionType! === e.name)) {
            return e;
        }
    }
    // si on arrive là, on prend fait un appel récursif avec undefined.
    return getPrices(energyType);
};
// fonction privée qui va chercher quelques constantes dans les ressources téléchargées.
const getPrices = (energyType: string): EnergyPrice => {
    const ENERGY_PRICES = storageService.getConfig('energyprices');
    for (const e of ENERGY_PRICES!) {
        if (e.type === energyType && e.subscriptionType === undefined) {
            return e;
        }
    }
    throw Error('Bad initailized ENERGY_PRICES');
};
/**
 * ATTENTION, 2 VERSIONS :
 *   => Option 1 On passe tout en KWh, puis on réparti
 * Cette fonction calcule la réparticiton d'énergie initiale par rapport aux données de l'audit.
 * @param audit l'audit d'entrée des calculs
 * @returns Les dépenses par poste.
 */
export const getDepensesParPoste = (audit: any): DepenseParPoste => {
    const mode = process.env.REACT_APP_MODE_CALCUL;
    if (mode === 'OPTION1') return getDepensesParPoste_Option1(audit);
    if (mode === 'OPTION2') return getDepensesParPoste_Option2(audit);

    //défaut :
    return getDepensesParPoste_Option1(audit);
};

/**
 * ATTENTION, 2 VERSIONS :
 *   => Option 1 On passe tout en KWh, puis on réparti
 * Cette fonction calcule la réparticiton d'énergie initiale par rapport aux données de l'audit.
 * @param audit l'audit d'entrée des calculs
 * @returns Les dépenses par poste.
 */
const getDepensesParPoste_Option1 = (audit: any): DepenseParPoste => {
    // le resultat qui sera retourné
    const result = new DepenseParPoste();

    // les prix des dépenses renseignées dans l'audit (lorsqu'elles existent)
    const auditPrixElec = audit.energyCostsElectric ? +audit.energyCostsElectric.value : 0;
    const auditPrixGaz = audit.energyCostsGas ? +audit.energyCostsGas.value : 0;
    const auditPrixBois = audit.energyCostsWood ? +audit.energyCostsWood.value : 0;
    const auditPrixFioul = audit.energyCostsFuel ? +audit.energyCostsFuel.value : 0;

    // rappel les prix des énergies sont fixés par défaut dans le constructeurs de DepenseParPoste.
    // on met à jour ce qu'il faut.
    if (auditPrixElec !== 0) {
        let abonnementElectriciteType = +audit.heaterSubscriptionElectricity.value;
        result.electricitePrice = getPricesWithSubscription('electricity', abonnementElectriciteType);
    }
    // si on a du gaz, on y va :
    if (auditPrixGaz !== 0) {
        let abonnementGazType = +audit.heaterSubscriptionGas.value;
        result.gazPrice = getPricesWithSubscription('gaz', abonnementGazType);
    }

    // on calcule les prix hors abonnement.
    const prixElec = auditPrixElec === 0 ? 0 : auditPrixElec - result.electricitePrice.subscriptionPrice;
    const prixGaz = auditPrixGaz === 0 ? 0 : auditPrixGaz - result.gazPrice.subscriptionPrice;
    const prixBois = auditPrixBois === 0 ? 0 : auditPrixBois - result.boisPrice.subscriptionPrice;
    const prixFioul = auditPrixFioul === 0 ? 0 : auditPrixFioul - result.fioulPrice.subscriptionPrice;

    // on calcule les conso selon le prix du KWh
    const consoElec = prixElec / result.electricitePrice.unitPrice;
    const consoGaz = prixGaz / result.gazPrice.unitPrice;
    const consoBois = prixBois / result.boisPrice.unitPrice;
    const consoFioul = prixFioul / result.fioulPrice.unitPrice;

    if (auditPrixGaz === 0 && auditPrixBois === 0 && auditPrixFioul === 0) {
        // Cas du mix tout electricite :
        if (audit.heaterHotWaterIncluded.value) {
            // NOUVEAU CAS (22/12/2022)
            // Cas pompe a chaleur par exemple, qui ferait aussi aussi l'eauchaude.
            // TODO : le bon mix (la on a copié sur le else.)
            result.autres.electricite = 0.2 * consoElec;
            result.eauChaude.electricite = 0.2 * consoElec; // 20% de toute la dépense, comme dans les autres cas. (mais peu importe.)
            result.chauffage.electricite = 0.6 * consoElec;
        } else {
            result.autres.electricite = 0.2 * consoElec;
            result.eauChaude.electricite = 0.2 * consoElec; // 20% de toute la dépense, comme dans les autres cas. (mais peu importe.)
            result.chauffage.electricite = 0.6 * consoElec;
        }
    } else {
        // Dans les autres cas, l'eau chaude représente 20% des dépenses énergétiques
        // (pour le premier cas aussi soit dit en passant).
        const consoEauChaude = 0.2 * (consoElec + consoGaz + consoBois + consoFioul); // 20% de tout.

        if (audit.heaterHotWaterIncluded.value) {
            // Deuxieme cas : Si l'eau chaude est lié au chauffage, (==  cas eau chaude combustible)
            // ... mais elle n'est prise que sur le combustible  (ce qui peut donner des résultats absurdes)
            // Equivalent :
            // result.eauChaude   = 0.2 * energie totale
            // result.chauffage   = totalCobustible - eauchade;
            // result.autres      = consoElec;

            // On va supprimer 20% de tout, du total combustible,
            // On calcul le ratio que l'eau chaude prend dans le combustible.
            const ratio = consoEauChaude / (consoGaz + consoBois + consoFioul);

            result.eauChaude.electricite = 0;
            result.eauChaude.gaz = ratio * consoGaz;
            result.eauChaude.bois = ratio * consoBois;
            result.eauChaude.fioul = ratio * consoFioul;

            result.chauffage.electricite = 0;
            result.chauffage.gaz = consoGaz - result.eauChaude.gaz;
            result.chauffage.bois = consoBois - result.eauChaude.bois;
            result.chauffage.fioul = consoFioul - result.eauChaude.fioul; // === (1-ratio) * fioul

            result.autres.electricite = consoElec;
            result.autres.fioul = 0;
            result.autres.gaz = 0;
            result.autres.bois = 0;
        } else {
            // Troisieme cas : eau chaude electrique, chauffage combustible
            // ... mais elle n'est prise que sur l'electricité :
            const ratio = consoEauChaude / consoElec;

            result.eauChaude.electricite = ratio * consoElec;
            result.eauChaude.fioul = 0;
            result.eauChaude.gaz = 0;
            result.eauChaude.bois = 0;

            result.chauffage.electricite = 0;
            result.chauffage.gaz = consoGaz;
            result.chauffage.bois = consoBois;
            result.chauffage.fioul = consoFioul;

            result.autres.electricite = consoElec - result.eauChaude.electricite; // === (1-ratio) * elec
            result.autres.fioul = 0;
            result.autres.gaz = 0;
            result.autres.bois = 0;
        }
    }
    return result;
};
/**
 * ATTENTION, 2 VERSIONS :
 *   => Option 1  = On réparti les proportions rapport aux prix, puis on passe en Kwh
 * Cette fonction calcule la réparticiton d'énergie initiale par rapport aux données de l'audit.
 * @param audit l'audit d'entrée des calculs
 * @returns Les dépenses par poste.
 */
const getDepensesParPoste_Option2 = (audit: any): DepenseParPoste => {
    // le resultat qui sera retourné
    const result = new DepenseParPoste();

    // les prix des dépenses renseignées dans l'audit (lorsqu'elles existent)
    const auditPrixElec = audit.energyCostsElectric ? +audit.energyCostsElectric.value : 0;
    const auditPrixGaz = audit.energyCostsGas ? +audit.energyCostsGas.value : 0;
    const auditPrixBois = audit.energyCostsWood ? +audit.energyCostsWood.value : 0;
    const auditPrixFioul = audit.energyCostsFuel ? +audit.energyCostsFuel.value : 0;

    // rappel els prix des énergies sont fixés par défaut dans le constructeurs de DepenseParPoste.
    // on met à jour ce qu'il faut.
    if (auditPrixElec !== 0) {
        let abonnementElectriciteType = +audit.heaterSubscriptionElectricity.value;
        result.electricitePrice = getPricesWithSubscription('electricity', abonnementElectriciteType);
    }
    // si on a du gaz, on y va :
    if (auditPrixGaz !== 0) {
        let abonnementGazType = +audit.heaterSubscriptionGas.value;
        result.gazPrice = getPricesWithSubscription('gaz', abonnementGazType);
    }

    // on calcule les prix hors abonnement.
    const prixElec = auditPrixElec === 0 ? 0 : auditPrixElec - result.electricitePrice.subscriptionPrice;
    const prixGaz = auditPrixGaz === 0 ? 0 : auditPrixGaz - result.gazPrice.subscriptionPrice;
    const prixBois = auditPrixBois === 0 ? 0 : auditPrixBois - result.boisPrice.subscriptionPrice;
    const prixFioul = auditPrixFioul === 0 ? 0 : auditPrixFioul - result.fioulPrice.subscriptionPrice;

    if (auditPrixGaz === 0 && auditPrixBois === 0 && auditPrixFioul === 0) {
        // Cas du mix tout electricite :
        if (audit.heaterHotWaterIncluded.value) {
            // NOUVEAU CAS (22/12/2022)
            // Cas pompe a chaleur par exemple, qui ferait aussi aussi l'eauchaude.
            // TODO : le bon mix (la on a copié sur le else.)
            result.autres.electricite = 0.2 * prixElec;
            result.eauChaude.electricite = 0.2 * prixElec; // 20% de toute la dépense, comme dans les autres cas. (mais peu importe.)
            result.chauffage.electricite = 0.6 * prixElec;
        } else {
            result.autres.electricite = 0.2 * prixElec;
            result.eauChaude.electricite = 0.2 * prixElec; // 20% de toute la dépense, comme dans les autres cas. (mais peu importe.)
            result.chauffage.electricite = 0.6 * prixElec;
        }
    } else {
        // Dans les autres cas, l'eau chaude représente 20% des dépenses énergétiques
        // (pour le premier cas aussi soit dit en passant).
        const consoEauChaude = 0.2 * (prixElec + prixGaz + prixBois + prixFioul); // 20% de tout.

        if (audit.heaterHotWaterIncluded.value) {
            // Deuxieme cas : Si l'eau chaude est lié au chauffage, (==  cas eau chaude combustible)
            // ... mais elle n'est prise que sur le combustible  (ce qui peut donner des résultats absurdes)
            // Equivalent :
            // result.eauChaude   = 0.2 * energie totale
            // result.chauffage   = totalCobustible - eauchade;
            // result.autres      = consoElec;

            // On va supprimer 20% de tout, du total combustible,
            // On calcul le ratio que l'eau chaude prend dans le combustible.
            const ratio = consoEauChaude / (prixGaz + prixBois + prixFioul);

            result.eauChaude.electricite = 0;
            result.eauChaude.gaz = ratio * prixGaz;
            result.eauChaude.bois = ratio * prixBois;
            result.eauChaude.fioul = ratio * prixFioul;

            result.chauffage.electricite = 0;
            result.chauffage.gaz = prixGaz - result.eauChaude.gaz;
            result.chauffage.bois = prixBois - result.eauChaude.bois;
            result.chauffage.fioul = prixFioul - result.eauChaude.fioul; // === (1-ratio) * fioul

            result.autres.electricite = prixElec;
            result.autres.fioul = 0;
            result.autres.gaz = 0;
            result.autres.bois = 0;
        } else {
            // Troisieme cas : eau chaude electrique, chauffage combustible
            // ... mais elle n'est prise que sur l'electricité :
            const ratio = consoEauChaude / prixElec;

            result.eauChaude.electricite = ratio * prixElec;
            result.eauChaude.fioul = 0;
            result.eauChaude.gaz = 0;
            result.eauChaude.bois = 0;

            result.chauffage.electricite = 0;
            result.chauffage.gaz = prixGaz;
            result.chauffage.bois = prixBois;
            result.chauffage.fioul = prixFioul;

            result.autres.electricite = prixElec - result.eauChaude.electricite; // === (1-ratio) * elec
            result.autres.fioul = 0;
            result.autres.gaz = 0;
            result.autres.bois = 0;
        }
    }

    // on passe en KWh

    result.eauChaude.electricite = result.eauChaude.electricite / result.electricitePrice.unitPrice;
    result.eauChaude.gaz = result.eauChaude.gaz / result.gazPrice.unitPrice;
    result.eauChaude.bois = result.eauChaude.bois / result.boisPrice.unitPrice;
    result.eauChaude.fioul = result.eauChaude.fioul / result.fioulPrice.unitPrice;

    result.chauffage.electricite = result.chauffage.electricite / result.electricitePrice.unitPrice;
    result.chauffage.gaz = result.chauffage.gaz / result.gazPrice.unitPrice;
    result.chauffage.bois = result.chauffage.bois / result.boisPrice.unitPrice;
    result.chauffage.fioul = result.chauffage.fioul / result.fioulPrice.unitPrice;

    result.autres.electricite = result.autres.electricite / result.electricitePrice.unitPrice;
    result.autres.fioul = result.autres.fioul / result.gazPrice.unitPrice;
    result.autres.gaz = result.autres.gaz / result.boisPrice.unitPrice;
    result.autres.bois = result.autres.bois / result.fioulPrice.unitPrice;

    return result;
};
