import * as api from '../../services/apiParticulierService';
import * as ticketService from '../../services/calculs/ticket';
import * as themesService from '../../services/calculs/theme';
import * as applyPrecoService from '../../services/calculs/applyPreconisation';
import * as filterPrecoService from '../../services/calculs/filterPreconisation';
import { getEnergieFinalePrimaireTheorique, getDepensesParPoste, getEnergieFinalePrimaireApresRenovation } from '../../services/calculs/bilanEnergieService';
import { DUREE_PROJETCTION_FINANCIERE, calculEconomie_IndexationSimple } from '../../services/calculs/calculEconomie';
import { PreconisationChauffage } from '../../services/calculs/calculPreconisationsCore';
import { createPreconisationsChauffage } from '../../services/calculs/createPreconisationsChauffage';
import { currencyFormat, parseCurrency, percentFormat } from '../../services/tools/TypeHelper';
import { caculSimulationComplete } from '../../services/calculs/simulationComplete';
import { PackageData } from '../../services/calculs/package';
import { defaultFilterThemePreference } from '../../pages/espace-agent/mon-compte/preference';
import { dummyInstallationPV } from './installationPV';

export const createSimulationSimple = (audit: any): api.SimulationSimple => {
    const energieInitiale = getEnergieFinalePrimaireTheorique(audit)!;
    const depenseInitiale = getDepensesParPoste(audit)!;
    const precosChauffage: Array<PreconisationChauffage> = createPreconisationsChauffage(audit);
    const depenseResult = applyPrecoService.estimerDepenseApresTravaux(precosChauffage, depenseInitiale);
    const energieResult = getEnergieFinalePrimaireApresRenovation(audit, depenseResult)!;
    const economie = calculEconomie_IndexationSimple(depenseInitiale, depenseResult, DUREE_PROJETCTION_FINANCIERE);

    const energiePrimaireParSurfaceAvant = energieInitiale.energiePrimaire.total() / +audit.SHab.value; // Vérifier si c'est / SHab ou / SHON
    const energiePrimaireParSurfaceApres = energieResult.energiePrimaire.total() / +audit.SHab.value; // Vérifier si c'est / SHab ou / SHON
    const ecoEnergiePrimaireParSurface = (energiePrimaireParSurfaceAvant - energiePrimaireParSurfaceApres) / energiePrimaireParSurfaceAvant;

    const energies = Array<string>();
    if (depenseInitiale.prixGazHorsAbo() !== 0) energies.push('Gaz');
    if (depenseInitiale.prixElectriciteHorsAbo() !== 0) energies.push('Electricité');
    if (depenseInitiale.prixFioulHorsAbo() !== 0) energies.push('Fioul');
    if (depenseInitiale.prixBoisHorsAbo() !== 0) energies.push('Bois');
    const consosHorsAbo = Array<{ energie: string; prix: string }>();
    if (depenseInitiale.prixGazHorsAbo() !== 0) consosHorsAbo.push({ energie: 'Gaz', prix: currencyFormat(depenseInitiale.prixGazHorsAbo()) });
    if (depenseInitiale.prixElectriciteHorsAbo() !== 0)
        consosHorsAbo.push({ energie: 'Electricité', prix: currencyFormat(depenseInitiale.prixElectriciteHorsAbo()) });
    if (depenseInitiale.prixFioulHorsAbo() !== 0) consosHorsAbo.push({ energie: 'Fioul', prix: currencyFormat(depenseInitiale.prixFioulHorsAbo()) });
    if (depenseInitiale.prixBoisHorsAbo() !== 0) consosHorsAbo.push({ energie: 'Bois', prix: currencyFormat(depenseInitiale.prixBoisHorsAbo()) });
    const abos = Array<{ energie: string; prix: string }>();
    if (depenseInitiale.abonnementGaz() !== 0) abos.push({ energie: 'Gaz', prix: currencyFormat(depenseInitiale.abonnementGaz()) });
    if (depenseInitiale.abonnementElectricite() !== 0) abos.push({ energie: 'Electricité', prix: currencyFormat(depenseInitiale.abonnementElectricite()) });
    const consos = Array<{ energie: string; prix: string }>();
    if (depenseInitiale.prixGaz() !== 0) consos.push({ energie: 'Gaz', prix: currencyFormat(depenseInitiale.prixGaz()) });
    if (depenseInitiale.prixElectricite() !== 0) consos.push({ energie: 'Electricité', prix: currencyFormat(depenseInitiale.prixElectricite()) });
    if (depenseInitiale.prixFioul() !== 0) consos.push({ energie: 'Fioul', prix: currencyFormat(depenseInitiale.prixFioul()) });
    if (depenseInitiale.prixBois() !== 0) consos.push({ energie: 'Bois', prix: currencyFormat(depenseInitiale.prixBois()) });

    const equipements = buildEquipementFromAudit(audit);

    return {
        avant: {
            parEnergie: {
                gaz: currencyFormat(depenseInitiale.prixGazHorsAbo()),
                electricite: currencyFormat(depenseInitiale.prixElectriciteHorsAbo()),
                fioul: currencyFormat(depenseInitiale.prixFioulHorsAbo()),
                bois: currencyFormat(depenseInitiale.prixBoisHorsAbo()),
                total: currencyFormat(depenseInitiale.prixHorsAbo()),
            },
            parPoste: {
                prixChauffage: currencyFormat(depenseInitiale.prixChauffage()),
                ratioChauffage: percentFormat(depenseInitiale.ratioPrixChauffage()),
                economieChauffage: percentFormat(0),
                prixEauChaude: currencyFormat(depenseInitiale.prixEauChaude()),
                ratioEauChaude: percentFormat(depenseInitiale.ratioPrixEauChaude()),
                economieEauChaude: percentFormat(0),
                prixAutres: currencyFormat(depenseInitiale.prixAutres()),
                ratioElectricite: percentFormat(depenseInitiale.ratioPrixAutres()),
                economieElectricite: percentFormat(0),
            },
            energies: {
                primaire: '' + Math.round(energieInitiale.energieFinale.total()),
                finale: '' + Math.round(energieInitiale.energiePrimaire.total()),
                primaireParSurface: '' + Math.round(energiePrimaireParSurfaceAvant),
            },
            equipements: { equipements, energies, consosHorsAbo, abos, consos },
        },
        apres: {
            parEnergie: {
                gaz: currencyFormat(depenseResult.prixGazHorsAbo()),
                electricite: currencyFormat(depenseResult.prixElectriciteHorsAbo()),
                fioul: currencyFormat(depenseResult.prixFioulHorsAbo()),
                bois: currencyFormat(depenseResult.prixBoisHorsAbo()),
                total: currencyFormat(depenseResult.prixHorsAbo()),
                premiereAnnee: currencyFormat(depenseInitiale.prixHorsAbo() - depenseResult.prixHorsAbo()),
            },
            parPoste: {
                prixChauffage: currencyFormat(Math.floor(depenseResult.prixChauffage())),
                ratioChauffage: percentFormat(depenseResult.ratioPrixChauffage()),
                economieChauffage: percentFormat((depenseInitiale.prixChauffage() - depenseResult.prixChauffage()) / depenseInitiale.prixChauffage()),
                prixEauChaude: currencyFormat(Math.floor(depenseResult.prixEauChaude())),
                ratioEauChaude: percentFormat(depenseResult.ratioPrixEauChaude()),
                economieEauChaude: percentFormat((depenseInitiale.prixEauChaude() - depenseResult.prixEauChaude()) / depenseInitiale.prixEauChaude()),
                prixAutres: currencyFormat(Math.floor(depenseResult.prixAutres())),
                ratioElectricite: percentFormat(depenseResult.ratioPrixAutres()),
                economieElectricite: percentFormat((depenseInitiale.prixAutres() - depenseResult.prixAutres()) / depenseInitiale.prixAutres()),
            },
            energies: {
                primaire: '' + Math.round(energieResult.energieFinale.total()),
                primaireEconomie:
                    '-' +
                    percentFormat((energieInitiale.energiePrimaire.total() - energieResult.energiePrimaire.total()) / energieInitiale.energiePrimaire.total()),
                finale: '' + Math.round(energieResult.energiePrimaire.total()),
                finaleEconomie:
                    '-' + percentFormat((energieInitiale.energieFinale.total() - energieResult.energieFinale.total()) / energieInitiale.energieFinale.total()),
                primaireParSurface: '' + Math.round(energiePrimaireParSurfaceApres),
                primaireParSurfaceEconomie: '-' + percentFormat(ecoEnergiePrimaireParSurface),
            },
            economie: {
                moyenneAnnuelle: currencyFormat(economie / DUREE_PROJETCTION_FINANCIERE),
                sur25ans: currencyFormat(economie),
                taux: percentFormat((depenseInitiale.prixHorsAbo() - depenseResult.prixHorsAbo()) / depenseInitiale.prixHorsAbo()),
            },
        },
    };
};

const initTicket = async (auditId: number, themes: Array<themesService.Theme>): Promise<ticketService.TicketData> => {
    console.log('init ticket');
    // Obtient le ticket du WS.
    let packages = themesService.getSelectedPackagesWithQty(themes);
    // on récupère le ticket
    let ticket = await ticketService.simulateDevis(auditId + '', packages);
    // A partir de la on connait les aides applicable sur _ce_ ticket.
    // On complete avec les aides locales, et on recalcule le ticket.

    const remisePrevisite: ticketService.Aide | undefined = await ticketService.createAidePrevisit();
    const aideCo: ticketService.Aide | undefined = await ticketService.createAideCommerciale();
    const remisemma: ticketService.Aide | undefined = await ticketService.createAideMMA(themes);
    const aideReventeSurplus: ticketService.Aide | undefined = await ticketService.createAideReventeSurplus(themes);

    const aidesLocales: ticketService.AidesLocalesList = {
        [ticketService.AIDE_ECO_PUBLICITAIRE_NAME]: undefined,
        [ticketService.AIDE_REMISE_MMA_NAME]: aideCo,
        [ticketService.AIDE_REMISE_PREVISITE]: remisemma,
        [ticketService.AIDE_REMISE_COMMERCIALE_NAME]: remisePrevisite,
        [ticketService.AIDE_PANNEAU_SOLAIRE_NAME]: aideReventeSurplus,
    };
    // TODO : les autres aides renovation d'ampleur

    ticketService.manageLocaleAide(ticket, aidesLocales);
    ticketService.populateAllPackageHelp(ticket, themes);
    ticketService.recalculTicket(ticket);
    return ticket;
};

const createSimulationComplete = async (
    ticket: ticketService.TicketData,
    themes: Array<themesService.Theme>
): Promise<{
    synthesePreco: api.SynthesePreco;
    synthesePv: api.SynthesePV | undefined;
    projectionFinanciere: applyPrecoService.ProjectionFinanciere | undefined;
}> => {
    const installation = themesService.getFirstInstallation(themes) ?? dummyInstallationPV();
    const { depenseInitiale, depenseFinaleApresTravaux, depenseFinaleApresPV, bilanPv, simuPv } = caculSimulationComplete(themes, installation); //, panneau);
    // Calcul du resteAcharge == ce que le client final devra investir, aide déduite == le prix du ticket,
    // le resteAchargee st utilisé pour le calcul du rendement.
    const resteAcharge = parseCurrency(ticket.reste_a_charge);
    // On calcul le bilan économique de sTous les travaux : Toutes les préco selectionnées, SANS le panneau photovoltaique.
    // on ne met pas le depenseFinaleApresPV, car une aprtie des calcul se fait sans.
    // ce dont on aura besoin est dans simuPV et bilanPV.
    let projectionFinanciere: applyPrecoService.ProjectionFinanciere = applyPrecoService.calculProjectionFinanciereGlobale(
        depenseInitiale,
        depenseFinaleApresTravaux,
        simuPv,
        bilanPv,
        resteAcharge,
        DUREE_PROJETCTION_FINANCIERE
    );

    // La dépense finale selon qu'on ait ou non des PV.
    const depenseFinale = depenseFinaleApresPV ?? depenseFinaleApresTravaux;

    const equipements = Array<string>();
    // mega boucle des noms de package.
    for (const theme of themes)
        for (const subTheme of theme.subThemes!) for (const pack of subTheme.packages) if (pack.applicable) equipements.push(pack.mainProduct.nom);

    const energies = Array<string>();
    if (depenseFinale.prixGazHorsAbo() !== 0) energies.push('Gaz');
    if (depenseFinale.prixElectriciteHorsAbo() !== 0) energies.push('Electricité');
    if (depenseFinale.prixFioulHorsAbo() !== 0) energies.push('Fioul');
    if (depenseFinale.prixBoisHorsAbo() !== 0) energies.push('Bois');
    const consosHorsAbo = Array<{ energie: string; prix: string }>();
    if (depenseFinale.prixGazHorsAbo() !== 0) consosHorsAbo.push({ energie: 'Gaz', prix: currencyFormat(depenseFinale.prixGazHorsAbo()) });
    if (depenseFinale.prixElectriciteHorsAbo() !== 0)
        consosHorsAbo.push({ energie: 'Electricité', prix: currencyFormat(depenseFinale.prixElectriciteHorsAbo()) });
    if (depenseFinale.prixFioulHorsAbo() !== 0) consosHorsAbo.push({ energie: 'Fioul', prix: currencyFormat(depenseFinale.prixFioulHorsAbo()) });
    if (depenseFinale.prixBoisHorsAbo() !== 0) consosHorsAbo.push({ energie: 'Bois', prix: currencyFormat(depenseFinale.prixBoisHorsAbo()) });
    const abos = Array<{ energie: string; prix: string }>();
    if (depenseFinale.abonnementGaz() !== 0) abos.push({ energie: 'Gaz', prix: currencyFormat(depenseFinale.abonnementGaz()) });
    if (depenseFinale.abonnementElectricite() !== 0) abos.push({ energie: 'Electricité', prix: currencyFormat(depenseFinale.abonnementElectricite()) });
    const consos = Array<{ energie: string; prix: string }>();
    if (depenseFinale.prixGaz() !== 0) consos.push({ energie: 'Gaz', prix: currencyFormat(depenseFinale.prixGaz()) });
    if (depenseFinale.prixElectricite() !== 0) consos.push({ energie: 'Electricité', prix: currencyFormat(depenseFinale.prixElectricite()) });
    if (depenseFinale.prixFioul() !== 0) consos.push({ energie: 'Fioul', prix: currencyFormat(depenseFinale.prixFioul()) });
    if (depenseFinale.prixBois() !== 0) consos.push({ energie: 'Bois', prix: currencyFormat(depenseFinale.prixBois()) });

    const synthesePreco: api.SynthesePreco = {
        avant: {
            chauffage: {
                prix: currencyFormat(depenseInitiale.prixChauffage()),
                energie: Math.round(depenseInitiale.chauffage.total()) + ' KWh',
                energieName: depenseInitiale.chauffage.energieName(),
                ratio: percentFormat(depenseInitiale.ratioPrixChauffage()),
            },
            eauchaude: {
                prix: currencyFormat(depenseInitiale.prixEauChaude()),
                energie: Math.round(depenseInitiale.eauChaude.total()) + ' KWh',
                energieName: depenseInitiale.eauChaude.energieName(),
                ratio: percentFormat(depenseInitiale.ratioPrixEauChaude()),
            },
            menager: {
                prix: currencyFormat(depenseInitiale.prixAutres()),
                energie: Math.round(depenseInitiale.autres.total()) + ' KWh',
                energieName: depenseInitiale.autres.energieName(),
                ratio: percentFormat(depenseInitiale.ratioPrixAutres()),
            },
            abonnements: {
                electricite: currencyFormat(depenseInitiale.abonnementElectricite()),
                gaz: currencyFormat(depenseInitiale.abonnementGaz()),
            },
            total: currencyFormat(depenseInitiale.prix()),
        },
        apres: {
            economie: percentFormat((depenseInitiale.prix() - depenseFinale.prix()) / depenseInitiale.prix()),
            chauffage: {
                prix: currencyFormat(depenseFinale.prixChauffage()),
                energie: Math.round(depenseFinale.chauffage.total()) + ' KWh',
                energieName: depenseFinale.chauffage.energieName(),
                ratio: percentFormat(depenseFinale.ratioPrixChauffage()),
            },
            eauchaude: {
                prix: currencyFormat(depenseFinale.prixEauChaude()),
                energie: Math.round(depenseFinale.eauChaude.total()) + ' KWh',
                energieName: depenseFinale.eauChaude.energieName(),
                ratio: percentFormat(depenseFinale.ratioPrixEauChaude()),
            },
            menager: {
                prix: currencyFormat(depenseFinale.prixAutres()),
                energie: Math.round(depenseFinale.autres.total()) + ' KWh',
                energieName: depenseFinale.autres.energieName(),
                ratio: percentFormat(depenseFinale.ratioPrixAutres()),
            },
            abonnements: {
                electricite: currencyFormat(depenseFinale.abonnementElectricite()),
                gaz: currencyFormat(depenseFinale.abonnementGaz()),
            },
            total: currencyFormat(depenseFinale.prix()),
        },
        synthese: {
            indep: bilanPv ? percentFormat(bilanPv.independence) : percentFormat(0),
            eco25: currencyFormat(projectionFinanciere.economieHorsPV.prix() + projectionFinanciere.economiePV.total),
            rendement: percentFormat(projectionFinanciere.rendement, 2),
        },
        equipements: { equipements, energies, consosHorsAbo, abos, consos },
    };

    let synthesePv = undefined;
    if (bilanPv !== undefined && simuPv !== undefined) {
        // const besoinUtilisateurEuro = audit.energyCostsElectric === undefined ? 0 : +audit.energyCostsElectric.value;
        // // Le besoin de l'utilisateur est transformé en puissance :
        // // Mais on ne peut pas autoconsommer plus de 70% de son besoin
        // const besoinUtilisateurPuissanceK = (0.7 * besoinUtilisateurEuro) / 0.258; // Cout divisé par prix du Kwh.
        const power = themesService.getFirstPanneau(themes)?.puissance ?? 0;

        const resteAcharge = parseCurrency(ticket.reste_a_charge);

        synthesePv = {
            //puissanceRecommandee: Math.round(besoinUtilisateurPuissanceK / 100) / 10 + ' kWh',
            puissanceRecommandee: Math.round(power / 100) / 10 + ' kWh',
            production: Math.round(simuPv.maxProd()) + ' kWh',
            independance: synthesePreco.synthese.indep,
            investissement: currencyFormat(resteAcharge),
            reventeSurplus: currencyFormat(bilanPv.reventeGain) + ' /an',
            autoconso: currencyFormat(bilanPv.autoconsomationGain) + ' /an',
            tauxAutoconomation: percentFormat(bilanPv.tauxAutoconsommationEffectif),
            economieGlobale25ans: synthesePreco.synthese.eco25,
            aide: currencyFormat(parseCurrency(ticket.montant_aides_cumul)),
        };
    }

    //return synthesePreco;
    return {
        synthesePreco,
        synthesePv,
        projectionFinanciere,
    };
};

export const createSimulationCompleteAndPv = async (
    type: api.DevisPdfType,
    auditId: number,
    audit: any,
    themes: themesService.Theme[] | undefined = undefined
): Promise<{
    synthesePreco: api.SynthesePreco;
    synthesePv: api.SynthesePV | undefined;
    projectionFinanciere: applyPrecoService.StringifiedProjectionFinanciere | undefined;
    equipements: Array<string>;
    ticket: ticketService.TicketData;
    fichesTechniques: Array<string>;
    selectedPackages: Array<PackageData>;
}> => {
    if (themes === undefined) {
        const userFilterPref = (await api.getMyself())?.preferences?.themeFilter ?? defaultFilterThemePreference;
        // Ces lignes sont des copies de src/pages/audit-simulator/audit-simulator.tsx.
        // TODO : C'est peut être de la duplication, mais c'est pas sur.
        themes = await themesService.getThemes();
        filterPrecoService.filterAL(themes);
        filterPrecoService.filterTva(audit, themes);
        //TODO : what to do there ?
        filterPrecoService.filterByUserPref(userFilterPref, themes);
        themes = await filterPrecoService.filterPreconisations(audit, themes);
    }
    // Obtient le ticket du WS.
    let ticketComplet = await initTicket(auditId, themes);

    const fichesTechniques = Array<string>();
    const selectedPackagesComplete = Array<PackageData>();

    // Ces lignes sont des copies de src/components/recommandations/SimulatorContainer.tsx
    // TODO : c'est peut être de la duplication, mais c'est pas sur.

    // const panneau: pvService.PanneauPhotovoltaique | undefined = themesService.getFirstPanneau(themes);

    const simComplete = await createSimulationComplete(ticketComplet, themes);

    if (type === 'Complete') {
        // On  sauvegarde les fiches techniques des produits.
        // et le produit.
        // mega boucle sur les themes sous themes et package

        const equipements = buildEquipementFromPackages(themes);
        for (const theme of themes)
            for (const subTheme of theme.subThemes!)
                for (const pack of subTheme.packages) {
                    if (pack.applicable) {
                        selectedPackagesComplete.push(pack);
                        if (pack.mainProduct.doc_path) fichesTechniques.push(pack.mainProduct.doc_path);
                    }
                }

        return {
            synthesePreco: simComplete.synthesePreco,
            synthesePv: simComplete.synthesePv,
            projectionFinanciere: applyPrecoService.stringifyProjectionFinanciere(simComplete.projectionFinanciere),
            equipements,
            ticket: ticketComplet,
            fichesTechniques,
            selectedPackages: selectedPackagesComplete,
        };
    }

    // A partir de la on est dans le type "early" ou le type "simple"

    // Supprimer tous les packages sauf PV et refaire les calculs.
    // On en profite pour sauvegarder les fiches techniques des produits.
    // et le produit.
    // mega boucle sur les themes sous themes et package
    const selectedPackagesPvOnly = Array<PackageData>();

    for (const theme of themes)
        for (const subTheme of theme.subThemes!)
            for (const pack of subTheme.packages) {
                if (pack.applicable)
                    if (subTheme.subThemeType !== 'Photovoltaïque') {
                        pack.applicable = false;
                    } else {
                        selectedPackagesPvOnly.push(pack);
                        if (pack.mainProduct.doc_path) fichesTechniques.push(pack.mainProduct.doc_path);
                    }
            }
    const equipements = buildEquipementFromPackages(themes);

    // refaire le ticket
    let ticketPvOnly = await initTicket(auditId, themes);

    const simPvOnly = await createSimulationComplete(ticketPvOnly, themes);

    // Dans le cas de 'early', l'utilisateur n'est jamais arrivé jusqu'à l'écran preco.
    // => on considère qu'il a selectionné tous les packages par défaut pour calculer le graphique équivalent de SimulatorContainer.
    //
    // dans le cas de 'simple', on prend les package effectivement selectionné par l'utilisateur.
    // Pour les autres propriétés de l'objet de sortie, on prend uniquement PV.

    return {
        synthesePreco: type === 'Early' ? simPvOnly.synthesePreco : simComplete.synthesePreco,
        synthesePv: simPvOnly.synthesePv,
        projectionFinanciere: applyPrecoService.stringifyProjectionFinanciere(simPvOnly.projectionFinanciere),
        equipements,
        ticket: ticketPvOnly, // type === 'Early' ? ticketPvOnly : ticketComplet,
        fichesTechniques,
        selectedPackages: selectedPackagesPvOnly, // type === 'Early' ? selectedPackagesPvOnly : selectedPackagesComplete,
    };
};

const buildEquipementFromAudit = (audit: any): Array<string> => {
    const result = Array<string>();
    const step3 = audit;
    const step6 = audit;
    const step7 = audit;

    // Ici des règles métiers à la con !

    // - Dans Audit/etape 3, si le bloc générateur est rempli, on affiche 'Générateur (chaudière, PAC, convecteur éléctrique....)'
    if (step3.heaterGeneratorPower.value && step3.heaterGeneratorIdenticalNumber.value) {
        result.push(
            `${step3.heaterGeneratorIdenticalNumber.value} générateur(s) (chaudière, PAC, convecteur éléctrique...) - puissance ${step3.heaterGeneratorPower.value}kW`
        );
    } else result.push('Générateur (chaudière, PAC, convecteur éléctrique...)');

    // - Dans Audit/etape 3, si on remplit le champ ‘Ventilation’ avec autre chose que ‘Pas de ventilation mécanique’, on affiche 'Ventilation (VMC)'
    if (+step3.ventilationType.value !== 7) result.push('Ventilation (VMC)');

    // - Dans Audit/etape 3, si on remplit le champs 'Type d'énergie pour l'eau chaude sanitaire' avec 'Lié au chauffage', on affiche 'Eau chaude sanitaire (liée au chauffage)'
    // - Dans Audit/etape 3, si on remplit le champs 'Type d'énergie pour l'eau chaude sanitaire' avec 'Electrique', on affiche 'Eau chaude sanitaire (Electrique)'
    if (step3.heaterHotWaterIncluded.value === true) result.push('Eau chaude sanitaire (liée au chauffage)');
    else result.push('Eau chaude sanitaire (Electrique)');

    // - Dans Audit / Etape 6/caractéristique des plafonds, si on remplit 'Présence d'isolation' à 'Oui', on affiche 'Comble/rampant isolé'
    if (step6.ceilingInsulationPresence.value === true) result.push('Comble/rampant isolé');
    // if(step6.rampantIsolated === true) result.push('Comble/rampant isolé') ??

    // - Dans Audit / Etape 6/caractéristique du plancher de l'habitation, si on remplit 'Présence d'isolation' à 'Oui', on affiche 'Plancher isolé'
    if (step6.floorInsulationPresence.value === true) result.push('Plancher isolé');

    // - Dans Audit / Etape 6/caractéristique des murs extérieurs, si on sélectionne 'Isolation par l'extérieur', on affiche 'Murs extérieurs isolés'
    // - Dans Audit / Etape 6/caractéristique des murs extérieurs, si on sélectionne 'Isolation par l'intérieur', on affiche 'Murs intérieurs isolés'
    if (step6.exteriorWallInsulationPresence.value === true) {
        if (step6.exteriorWallInsulationExterior.value === true) result.push('Murs extérieurs isolés');
        else result.push('Murs intérieurs isolés');
    }

    // - Dans Audit / Etape 7/typologie fenêtre 1 en fonction des critères 'Compositon des fenêtres' Type et épaisseur , on affiche 'Fenètres principales - 'Composition' 'Type' de 'Epaisseur' mm d'épaisseur
    let fenetre1 = 'Fenètres principales - ' + step7.buildingWindows1.value_label;
    if (step7.buildingWindows1glazing && step7.buildingWindows1glazing.value_label !== '') {
        fenetre1 += ' ' + step7.buildingWindows1glazing.value_label;
    }
    if (step7.buildingWindows1glazingSize && step7.buildingWindows1glazingSize.value_label !== '') {
        fenetre1 += ' de ' + step7.buildingWindows1glazingSize.value_label + " mm d'épaisseur";
    }
    result.push(fenetre1);

    // - Dans Audit / Etape 7/typologie fenêtre 2 (si remplit) en fonction des critères 'Compositon des fenêtres' Type et épaisseur , on affiche 'Fenètres secondaire ? - 'Composition' 'Type' de 'Epaisseur' mm d'épaisseur
    if (step7.buildingWindows2Presence.value === true) {
        let fenetre2 = 'Fenètres secondaires - ' + step7.buildingWindows2.value_label;
        if (step7.buildingWindows2glazing && step7.buildingWindows2glazing.value_label !== '') {
            fenetre2 += ' ' + step7.buildingWindows1glazing.value_label;
        }
        if (step7.buildingWindows2glazingSize && step7.buildingWindows2glazingSize.value_label !== '') {
            fenetre2 += ' de ' + step7.buildingWindows2glazingSize.value_label + " mm d'épaisseur";
        }
        result.push(fenetre2);
    }

    return result;
};

const buildEquipementFromPackages = (themes: themesService.Theme[] | undefined): Array<string> => {
    const result = Array<string>();
    if (themes === undefined) return result;

    // Package des thèmes devant apparaitre :
    // - Thème ‘Economie d’energie’ / Photovoltaïque
    // - Thème ‘Economie d’energie’ / Chauffage
    // - Thème ‘Economie d’energie’ / Eau chaude sanitaire
    // - Thème ‘Economie d’energie’ / Isolation
    // - Thème ‘Economie d’energie’ / Ventilation
    // - Thème ‘Economie d’energie’ / Changement comportemental

    // Ce que l'on doit afficher:
    // - Si on a du ‘Economie d’energie’ / Photovoltaïque, l'on affiche le titre du produit  ‘Central photovoltaïque 3kwc’, correct ?
    // - Si on a ‘Economie d’energie’ / Chauffage, on affiche ‘Pompe à chaleur’ (en dur) + ‘Puissance’
    // - Si on a ‘Economie d’energie’ / Eau chaude sanitaire, on affiche  le titre du produit
    // - Si on a ‘Economie d’energie’ / Isolation, on affiche le titre du produit
    // - Si on a ‘Economie d’energie’ / Ventilation, on affiche le titre du produit, par exemple ‘RAV VMC DOUBLE ATLANTIC DUOCOSY’
    // Si on a ‘Economie d’energie’ / Changement comportemental, on affiche le titre produit, correct ?"

    for (const theme of themes) {
        if (theme.themeType !== "Economie d'énergie") continue;
        for (const subTheme of theme.subThemes!)
            for (const pack of subTheme.packages) {
                if (pack.applicable) {
                    const subThemeType: themesService.ThemeOfEconomie = subTheme.subThemeType as themesService.ThemeOfEconomie;
                    switch (subThemeType) {
                        case 'Photovoltaïque':
                        case 'Eau chaude sanitaire':
                        case 'Isolation':
                        case 'Ventilation':
                        case 'Changement comportemental':
                            result.push(pack.title);
                            break;
                        case 'Chauffage':
                            result.push('Pompe à chaleur ' + pack.mainProduct.puissance + ' kW');
                            break;
                    }
                }
            }
    }

    return result;
};
