import { SetterOrUpdater } from 'recoil';
import * as ticketService from '../../services/calculs/ticket';
import * as themesService from '../../services/calculs/theme';
import { updatePackagePrice } from '../../services/calculs/package';

/** Chaque carte de package contient une aide qu'on ne connait pas à l'avance
 *  une fois recu le ticket il faut ajouter les aides dans la carte de package, concernée.
 *  Cette function permet d'annuler les aides déjà appliquées à certaines cartes de package lors d'un précédent ticket.
 */
export const clearAllPackageHelp = (allThemesClone: Array<themesService.Theme>): void => {
    // ré-initialise toutes les aides lcoales des package à 0, avant prendre en compte les aides du ticket.
    for (const theme of allThemesClone) {
        for (const subTheme of theme.subThemes!) {
            for (const pack of subTheme.packages) {
                updatePackagePrice(pack);
            }
        }
    }
};

export type AidesLocalesBuilder = (
    aidesLocales: ticketService.AidesLocalesList,
    allThemesClone: Array<themesService.Theme>,
    ticket: ticketService.TicketData,
    context: any
) => Promise<ticketService.AidesLocalesList>;

/**
 * Cette fonction permet de déclencher le re-render de la page complète depuis les composants sous-jacents.
 * Elle va aussi recalculer les aides locales à l'aide de la fonction aidesLocalesBuilder, et réaffecter l'atom aidesLocales.
 * @param allThemesClone un clone writable (et à jour) de la collection de theme
 * @param setThemes la fonction setThemes de l'atome themes
 * @param ticketClone la variable contenant le ticket en local. Si elle est undefined, l'api icoll sera appellée, pour actualiser le devis. Sinon l'api ne sera pas appelée et opérera les changement en utilisant le clone du ticket.
 * @param setTicket la fonction setTicket de l'atome ticket
 * @param aidesLocales le pointeur vers l'atom des aides locales. (la variable contenant les aides locales)
 * @param setAidesLocales le setter de l'atom des aides locales.
 * @param aidesLocalesBuilder Une fonction qui va re-construire les aides locales en lui passant en paramètres tout le nécessaire (dont le contexte). Cette fonction doit créer une nouvelle instance, qui sera réaffectée dans l'atom
 * @param aidesLocalesBuilderContext le contexte qui sera passé en dernièr paramètre de la fonction aidesLocalesBuilder.
 */
export const updateTicket = async (
    allThemesClone: Array<themesService.Theme>,
    setThemes: SetterOrUpdater<Array<themesService.Theme>>,

    ticketClone: ticketService.TicketData | undefined,
    setTicket: SetterOrUpdater<ticketService.TicketData>,

    aidesLocales: ticketService.AidesLocalesList,
    setAidesLocales: SetterOrUpdater<ticketService.AidesLocalesList>,
    aidesLocalesBuilder?: AidesLocalesBuilder,
    aidesLocalesBuilderContext?: unknown
): Promise<void> => {
    // efface les aides qui existait déjà
    clearAllPackageHelp(allThemesClone);

    // on récupère le ticket local
    let newTicket = ticketClone;
    if (!newTicket) {
        // si on a besoin de faire un appel a l'api.
        // Obtient le ticket du WS.
        const auditId = localStorage.getItem('auditId')!;
        const packages = themesService.getSelectedPackagesWithQty(allThemesClone);
        newTicket = await ticketService.simulateDevis(auditId, packages);
    }
    // Si on a une fonction de re-construction des aides, on l'appelle.
    if (aidesLocalesBuilder) {
        aidesLocales = await aidesLocalesBuilder(aidesLocales, allThemesClone, newTicket, aidesLocalesBuilderContext);
    }
    // On set l'atom des aides locales.
    if (setAidesLocales) {
        setAidesLocales(aidesLocales);
    }

    // A partir de la on connait les aides applicable sur _ce_ ticket.
    // On complete avec les aides locales, et on recalcule le ticket.
    ticketService.manageLocaleAide(newTicket, aidesLocales);
    ticketService.populateAllPackageHelp(newTicket, allThemesClone);
    ticketService.recalculTicket(newTicket);

    // puis on set les atomes, pour déclencher tous les re-render.
    setTicket(newTicket);
    setThemes(allThemesClone);
};
