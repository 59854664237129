import { getFirstPackMMA, getFirstPanneau, Theme } from './theme';

// Read me
//
// Ici, j'ai ajouté les règles pour gérer les compatibilité de selection de package, qu'on ne peut pas traiter directement.
// l'utilisateur clique sur le selecteur d'un package, on traite ce clic.
// puis on refait une passe sur toutes les règles (ce qui peut potentiellement annuler son clic)
//
// A cette heure il n'y a qu'une règle :
//   - si aucun panneau solaire n'est sélectionné, on ne peut pas prendre d'assurance sur ce panneau.

export type PackageSelector = (themes: Array<Theme>) => Array<Theme>;

export type SwalableErrorMessage = { title: string; message: string };
/**
 * La fonction qui va boucler sur les règles spéciales.
 * @param themes prends un CLONE des themes. Ce clone sera modifié ET retourné.
 * @returns les themes passés en paramètres, potentiellement modifiés
 */
export const applyPackageSelectorSpecialRules = (themes: Array<Theme>): Array<SwalableErrorMessage> => {
    const errors = Array<SwalableErrorMessage>();
    for (const rule of rules) {
        errors.push(...rule(themes));
    }
    return errors;
};

/**
 * Cette règle déselectionne tous les packages de type 'Services/Garantie de revenu solaire' si il n'y a pas de panneau solaire.
 * @param themes prends un CLONE des themes. Ce clone sera modifié ET retourné.
 * @returns les themes passés en paramètres, potentiellement modifiés
 */
const removeMmaIfNoSolarPanel = (themes: Array<Theme>): Array<SwalableErrorMessage> => {
    const errors = Array<SwalableErrorMessage>();
    const firstPanneau = getFirstPanneau(themes);

    // Si il y a un panneau, qu'on ait ou non une garantie, c'est ok.
    if (firstPanneau !== undefined) return errors;

    const firstMMa = getFirstPackMMA(themes);
    if (firstMMa) {
        errors.push({
            title: 'Garantie de revenu solaire',
            message: 'Une garantie de revenu solaire ne peut être souscrite que si vous avez sélectionné un produit photovoltaïque',
        });
        firstMMa.applicable = false;
    }
    return errors;
};

const rules: Array<(themes: Array<Theme>) => Array<SwalableErrorMessage>> = [removeMmaIfNoSolarPanel];
