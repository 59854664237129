import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { FlowStep, flowStepNames, initialStepProperties } from './DetailEnums';
import * as storageService from '../../../services/localStorageService';
import * as apiFlow from '../../../services/apiFlowService';
import * as api from '../../../services/apiParticulierService';
import * as routes from '../../../routing/paths';
import Template from '../../../assets/json/template-audit.json';
import TemplateSimulator from '../../../assets/json/template-simulator.json';
import TemplatePreVisit from '../../../assets/json/template-previsite.json';
import { format } from 'date-fns';
import { Back } from '../../../components/Back/Back';
import { useSetRecoilState } from 'recoil';
import { themesStateAtom } from '../../../services/Recoil/Atom/Themes.atom';
import { EMPTY_THEMES } from '../../../services/calculs/theme';
import { currentRouteAtom } from '../../../services/Recoil/Atom/PackageContext.atom';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { PdfModal } from '../../../components/PdfModal/PdfModal';

// Style
import '../list-audit/ListAudit.scss';

// Icon
import { ReactComponent as IconLoader } from '../../../assets/icons/loader-white.svg';
import { ReactComponent as IconEye } from '../../../assets/icons/espace-agent/icon-eye.svg';
import { ReactComponent as IconDownload } from '../../../assets/icons/espace-agent/icon-download.svg';
import { FormRow } from '../../../components/FormRow/FormRow';

type DownloadButtonProps = {
    file: api.Document;
};

const DownloadButton: React.FC<DownloadButtonProps> = ({ file }) => {
    const handleDownload = () => {
        if (!file) {
            console.error('Aucun consentement ENEDIS trouvé pour cet audit');
            return;
        }

        // Create dynamic <a> element
        const link = document.createElement('a');
        link.href = file.url;
        link.download = file.fileName; // Nom de fichier souhaité

        document.body.appendChild(link);
        link.click();

        // Destroy element after download
        document.body.removeChild(link);
    };

    return (
        <button type="button" className="btn btn-orange btn-orange--icon w-100" onClick={handleDownload}>
            <IconDownload fill="#FFF" />
            Consentement ENEDIS
        </button>
    );
};

const Detail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [flowDetail, setFlowDetail] = useState<apiFlow.FlowOutputDetail | null>(null);
    const [flowFunding, setFlowFunding] = useState<api.FlowFundingOutput | null>(null);
    const [flowDocumentUrl, setFlowDocumentUrl] = useState<string | null>(null);
    const [signedDocuments, setSignedDocuments] = useState<apiFlow.SignedDocs>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [documentTitle, setDocumentTitle] = useState<string | null>(null);
    const [enedisConsent, setEnedisConsent] = useState<api.Document | null>(null);
    const AlertSwal = withReactContent(Swal);
    const [comment, setComment] = useState<string>('');
    const [disableComment, setDisableComment] = useState<boolean>(true);
    const setThemes = useSetRecoilState(themesStateAtom);
    const setCurrent = useSetRecoilState(currentRouteAtom);

    /**
     * Status :
     * grey === '';
     * yellow === 'edit';
     * green === 'complete';
     */
    const links = useMemo(() => {
        const updatedLinks = { ...initialStepProperties };
        const url = flowDetail && apiFlow.isAuditThermique(flowDetail.auditType) ? `${process.env.PUBLIC_URL}/audit-thermique/audit` : routes.ROUTE_AUDIT;

        switch (flowDetail?.state) {
            case 'Created':
            case 'AuditSent':
                updatedLinks.audit.btnStatus = 'edit';
                updatedLinks.audit.url = url;
                updatedLinks.audit.label = "Visualiser l'audit";
                updatedLinks.simulateur.btnStatus = '';
                updatedLinks.previsite.btnStatus = '';
                updatedLinks.financement.btnStatus = '';
                updatedLinks.extra.btnStatus = 'invisible';
                break;

            case 'WithoutRdv':
                updatedLinks.audit.btnStatus = 'complete';
                updatedLinks.audit.url = url;
                updatedLinks.audit.label = 'Audit sans rendez-vous';
                // Extra button
                updatedLinks.extra.btnStatus = 'edit';
                updatedLinks.extra.label = 'Prise de rendez-vous';
                updatedLinks.extra.url = routes.ROUTE_AUDIT_RDV;

                updatedLinks.simulateur.btnStatus = '';
                updatedLinks.simulateur.url = '';

                updatedLinks.previsite.btnStatus = '';
                updatedLinks.previsite.url = '';

                updatedLinks.financement.btnStatus = '';
                break;

            case 'WithoutDevis':
            case 'RdvRequested':
            case 'RdvSigned':
            case 'DevisSent':
            case 'WithoutCommand':
                updatedLinks.audit.btnStatus = 'complete';
                updatedLinks.audit.url = url;
                updatedLinks.audit.label = "Visualiser l'audit";
                updatedLinks.simulateur.btnStatus = 'edit';
                updatedLinks.simulateur.url = routes.ROUTE_SIM_RECOMMANDATION;
                updatedLinks.previsite.btnStatus = '';
                updatedLinks.financement.btnStatus = '';
                updatedLinks.extra.btnStatus = 'invisible';
                break;

            case 'CommandSigned':
                updatedLinks.audit.btnStatus = 'complete';
                updatedLinks.audit.url = url;
                updatedLinks.audit.label = "Visualiser l'audit";
                updatedLinks.simulateur.btnStatus = 'complete';
                updatedLinks.simulateur.url = ''; // leads nowhere
                updatedLinks.previsite.btnStatus = 'edit';
                updatedLinks.previsite.url = routes.ROUTE_PV_VALIDATION;
                updatedLinks.financement.btnStatus = '';
                updatedLinks.extra.btnStatus = 'invisible';
                break;

            // Pre-visite
            case 'WithoutPrevisit':
                updatedLinks.audit.btnStatus = 'complete';
                updatedLinks.audit.url = url;
                updatedLinks.audit.label = "Visualiser l'audit";
                updatedLinks.simulateur.btnStatus = 'complete';
                updatedLinks.simulateur.url = ''; // leads nowhere
                updatedLinks.previsite.btnStatus = '';
                updatedLinks.extra.btnStatus = 'invisible';
                break;

            case 'WithoutInstallation':
            case 'WithInstallation':
                updatedLinks.audit.btnStatus = 'complete';
                updatedLinks.audit.url = url;
                updatedLinks.audit.label = "Visualiser l'audit";
                updatedLinks.simulateur.btnStatus = 'complete';
                updatedLinks.simulateur.url = ''; // leads nowhere
                updatedLinks.previsite.btnStatus = 'complete';
                updatedLinks.previsite.url = ''; // leads nowhere
                updatedLinks.financement.btnStatus = '';
                updatedLinks.extra.btnStatus = 'invisible';
                break;

            default:
                updatedLinks.audit.btnStatus = 'edit';
                updatedLinks.audit.url = url;
                updatedLinks.audit.label = "Visualiser l'audit";
                updatedLinks.simulateur.btnStatus = '';
                updatedLinks.previsite.btnStatus = '';
                updatedLinks.financement.btnStatus = '';
                updatedLinks.extra.btnStatus = 'invisible';
                break;
        }

        switch (flowFunding?.fundingState) {
            case 'EN ANALYSE':
            case 'A CONTROLER':
            case 'INCOMPLET':
                updatedLinks.financement.btnStatus = 'edit';
                updatedLinks.financement.url = flowFunding?.fundingUrl as string;
                updatedLinks.financement.label = 'Financement';
                break;

            case 'A FINANCER':
                updatedLinks.financement.btnStatus = 'complete';
                updatedLinks.financement.url = flowFunding?.fundingUrl as string;
                updatedLinks.financement.label = 'Financement';
                break;

            case 'ABANDONNEE':
            case 'DEFAVORABLE':
                updatedLinks.financement.btnStatus = 'error';
                updatedLinks.financement.url = flowFunding?.fundingUrl as string;
                updatedLinks.financement.label = 'Financement';
                break;

            default:
                updatedLinks.financement.btnStatus = '';
                updatedLinks.financement.label = 'Financement';
                break;
        }

        if (loading) {
            updatedLinks.financement.btnStatus = 'loading';
            updatedLinks.financement.label = <IconLoader width={38} height={38} />;
        }

        return updatedLinks;
    }, [flowDetail, flowFunding, loading]);

    const load = (to: string, target: '_self' | '_blank' | undefined): void => {
        if (!flowDetail) return;
        // clear storage & reset states
        localStorage.removeItem('flowId');
        localStorage.removeItem('auditId');
        localStorage.removeItem('devisId');
        localStorage.removeItem('previsitDocUrl');
        localStorage.removeItem('installationDate');
        localStorage.removeItem('flowState');
        localStorage.removeItem('auditType');

        storageService.resetRecoAppointment();
        storageService.resetFunding();
        setThemes(EMPTY_THEMES);
        setCurrent(routes.ROUTE_PV_VALIDATION);

        // load general
        localStorage.setItem('flowState', flowDetail.state);
        localStorage.setItem('auditType', flowDetail.auditType);

        // load funding
        if (flowFunding?.fundingType === 'Loan') {
            storageService.setFunding(flowFunding);
        }

        // load / unload audit data
        storageService.setAudit(flowDetail.audit ?? Template);
        // TODO Manage AL / RAC
        storageService.setAuditCampagne(undefined);
        localStorage.setItem('flowId', flowDetail.id);
        if (flowDetail.auditId) {
            localStorage.setItem('auditId', flowDetail.auditId.toString());
        }

        // load / unload sim data
        storageService.setSim(flowDetail.devis ?? TemplateSimulator);
        if (flowDetail?.themes) {
            setThemes(flowDetail.themes);
        }
        if (flowDetail?.devisId) {
            localStorage.setItem('devisId', flowDetail.devisId.toString());
        }

        // load / unload previsit data
        storageService.setPrevisit(flowDetail.previsit ?? TemplatePreVisit);
        if (flowDetail.previsitDoc && flowDetail.previsitDoc.url) {
            localStorage.setItem('previsitDocUrl', flowDetail.previsitDoc.url);
        }

        if (flowDetail.installationAppointment) {
            localStorage.setItem('installationDate', flowDetail.installationAppointment.date);
        }

        if (flowDetail.recoAppointment) {
            storageService.setRecoAppointment(flowDetail.recoAppointment);
        }

        window.open(to, target);
        return;
    };

    // Save/Remove installation date
    useEffect(() => {
        if (flowDetail && flowDetail.recoAppointment) {
            storageService.setRecoAppointment(flowDetail.recoAppointment);
        }

        if (flowDetail && flowDetail.installationAppointment) {
            localStorage.setItem('installationDate', flowDetail.installationAppointment.date);
        }

        if (flowDetail && flowDetail.previsitDoc) {
            localStorage.setItem('previsitDocUrl', flowDetail.previsitDoc.url);
        }

        if (flowDetail && flowDetail.comment) {
            setComment(flowDetail.comment);
        }
    }, [flowDetail]);

    // Update current study status
    useEffect(() => {
        const fetchData = async () => {
            try {
                const flowDetail = await apiFlow.getFlow(id);
                setFlowDetail(flowDetail);
                // Debug
                // console.log('Detail:', flowDetail);

                if (flowDetail.quotationDoc !== null) {
                    setFlowDocumentUrl(flowDetail.quotationDoc.url);
                }

                if (flowDetail.quotationType !== null) {
                    setDocumentTitle(flowDetail.quotationType !== 'Complete' ? 'Devis' : 'Proposition commerciale & technique');
                }

                if (flowDetail.enedisConsent !== null) {
                    setEnedisConsent(flowDetail.enedisConsent.document);
                }

                if (flowDetail.fundingType !== 'Loan') return;
                setLoading(true);
                const funding = await api.getFundingData(id);
                storageService.setFunding(funding);
                setFlowFunding(funding);
            } catch (error) {
                console.error("Une erreur s'est produite lors de la récupération des données :", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    // Update signed documents
    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const apiDocuments = await apiFlow.getFlowDocuments(id);
                // console.log('Documents signés:', apiDocuments);
                setSignedDocuments(apiDocuments);
            } catch (error: any) {
                if (error.statusCode === 400) {
                    console.log('Aucun document signé trouvé pour cet audit');
                } else {
                    console.error('Erreur lors de la génération des documents:', error);
                }
            }
        };

        fetchDocuments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    // Handle documentation visualisation
    const handleDocumentModal = (pdf: string, title: string | null) => {
        // Prevent document title null
        if (!title) return;

        AlertSwal.fire({
            title: '',
            html: <PdfModal pdf={pdf} title={title} />,
            width: 900,
            confirmButtonText: 'Fermer',
            customClass: {
                confirmButton: 'btn btn-continue min-width',
            },
        });
    };

    // date d'installation
    const installationDate: string | null = localStorage.getItem('installationDate');
    // PDF
    const previsitDoc = localStorage.getItem('previsitDocUrl') ?? '';

    const displayDate = (date: string) => {
        return format(Date.parse(date), 'dd/MM/yyyy');
    };

    const displayHours = (date: string) => {
        const hours = format(Date.parse(date), 'kk:mm');

        switch (hours) {
            case '08:00':
                return '08:00 - 12:00';

            case '14:00':
                return '14:00 - 18:00';

            default:
                return '';
        }
    };

    const capitalizeFirstLetter = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    // Update comment
    const updateComment = (newComment: string) => {
        try {
            api.updateFlowComment(id, newComment);

            // Show success modal and autoclose
            AlertSwal.fire({
                icon: 'success',
                title: 'Succès',
                html: <p>Votre modification a bien été prise en compte</p>,
                timer: 1000, // time in ms
                timerProgressBar: true,
                showConfirmButton: false,
            });

            // Disable comment field
            setDisableComment(true);
        } catch (error) {
            console.error('Erreur lors de la mise à jour du commentaire:', error);
        }
    };

    const duplicateAudit = async (): Promise<void> => {
        AlertSwal.fire({
            title: <strong>Duplication d'audit pour la rénovation d'ampleur</strong>,
            html: (
                <div style={{ whiteSpace: 'pre-wrap', fontSize: '16px' }}>
                    {' '}
                    <p>Vous vous apprêtez à dupliquer cet audit. L'audit dupliqué apparaîtra dans la liste des audits. Merci de confirmer cette action.</p>
                </div>
            ),
            width: 960,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Dupliquer',
            cancelButtonText: 'Annuler',
            reverseButtons: true,
            allowOutsideClick: false,
            customClass: {
                confirmButton: 'btn btn-continue min-width',
                cancelButton: 'btn btn-retour min-width',
            },
        }).then((response) => {
            if (response.isConfirmed) {
                apiFlow
                    .duplicateAudit(id, true)
                    .then(() => {
                        load(routes.ROUTE_EA_LIST, '_self');
                    })
                    .catch(() => {
                        AlertSwal.fire({
                            html: (
                                <div style={{ whiteSpace: 'pre-wrap', fontSize: '16px' }}>
                                    {' '}
                                    <p>Une erreur s'est produite, la duplication a échouée.</p>
                                </div>
                            ),
                            width: 960,
                            showConfirmButton: true,
                            confirmButtonText: 'OK',
                            allowOutsideClick: false,
                            customClass: {
                                confirmButton: 'btn btn-continue min-width',
                            },
                        });
                    });
            }
        });
    };

    const hasRenovationDampleur =
        flowDetail !== null &&
        flowDetail.renoDampleurParams !== null &&
        flowDetail.renoDampleurParams !== undefined &&
        Object.keys(flowDetail.renoDampleurParams).length !== 0;
    const isCustomerEligible = flowDetail?.renoDampleurParams?.elligible;
    const customerReponse = flowDetail?.renoDampleurParams?.marShouldContactCustomer;

    return (
        <div className="container">
            <div className="py-4">
                <Back title="Liste" url={routes.ROUTE_EA_LIST} />
            </div>
            <div className="row align-items-stretch py-4">
                <div className="col-12 col-md-8">
                    <div className="container-detail split-70 py-4 h-100">
                        <div className="row">
                            <h2>Informations</h2>

                            <div className="col-12 col-md-6 mb-3 mb-md-0">
                                <div className="form-inline">
                                    <FormRow label="Type d'audit" value={flowDetail?.auditType ?? '-'} />
                                    <FormRow
                                        label="Date"
                                        value={
                                            flowDetail?.updatedAt ?? flowDetail?.createdAt
                                                ? format(new Date(flowDetail?.updatedAt ?? flowDetail?.createdAt), 'dd/MM/yyyy')
                                                : '-'
                                        }
                                    />
                                    <FormRow label="Nom" value={flowDetail?.customer?.userLastname ?? '-'} />
                                    <FormRow label="Prénom" value={flowDetail?.customer?.userFirstname ?? '-'} />

                                    <div className="form-inline">
                                        <FormRow
                                            label="Date d'installation"
                                            value={installationDate ? `${displayDate(installationDate)} ${displayHours(installationDate)}` : 'Non défini'}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 mb-3 mb-md-0">
                                <div className="form-inline">
                                    <FormRow label="Téléphone" value={flowDetail?.customer?.userTelephone ?? '-'} />
                                    <FormRow label="Adresse" value={flowDetail?.customer?.userAddress ?? '-'} />
                                    <FormRow label="Code postal" value={flowDetail?.customer?.userZipCode ?? '-'} />
                                    <FormRow label="Ville" value={flowDetail?.customer?.userLocality ?? '-'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-4">
                    <div className="container-detail split-30 py-4 h-100">
                        <div className="row">
                            <h2>Commentaire</h2>
                            <div className="col-12">
                                {!disableComment ? (
                                    <button className="btn btn-change-pref btn-change-pref-save" onClick={() => updateComment(comment)}>
                                        Enregistrer
                                    </button>
                                ) : (
                                    <button className="btn btn-change-pref" onClick={() => setDisableComment(false)}>
                                        Modifier
                                    </button>
                                )}
                                <div className="form-group">
                                    <textarea
                                        name="comment"
                                        className="form-control invisible-field form-control--textarea"
                                        value={comment}
                                        placeholder="Aucun commentaire"
                                        onChange={(e) => setComment(e.target.value)}
                                        disabled={disableComment}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {hasRenovationDampleur && (
                <div className="container-detail py-4 mb-4">
                    <div className="row">
                        <h2>Rénovation d'Ampleur</h2>

                        <div className="col-12 col-md-6 mb-3 mb-md-0">
                            {isCustomerEligible && customerReponse && (
                                <button className="btn btn-change-pref" onClick={duplicateAudit}>
                                    Dupliquer
                                </button>
                            )}
                            <div className="form-inline">
                                <FormRow
                                    label="Client éligible"
                                    value={isCustomerEligible === true ? 'Oui' : isCustomerEligible === false ? 'Non' : 'Pas encore vérifié'}
                                />
                                {isCustomerEligible && customerReponse && (
                                    <>
                                        <FormRow label="Société MAR" value={flowDetail?.mar?.company ?? '-'} />
                                        <FormRow label="Prénom MAR" value={flowDetail?.mar?.firstname ?? '-'} />
                                        <FormRow label="Email MAR" value={flowDetail?.mar?.email ?? '-'} />
                                    </>
                                )}
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className="form-inline">
                                {isCustomerEligible && (
                                    <>
                                        <FormRow label="Demande de contact MAR" value={customerReponse ? 'Oui' : 'Non'} />
                                        {customerReponse && (
                                            <>
                                                <FormRow label="Nom MAR" value={flowDetail?.mar?.lastname ?? '-'} />
                                                <FormRow label="Téléphone MAR" value={flowDetail?.mar?.phoneNumber ?? '-'} />
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="container-detail py-4 mb-4">
                <div className="row">
                    <h2>Statut</h2>

                    {flowStepNames.map((key, index) => {
                        const step = key as FlowStep;
                        const descriptor = links[step];

                        return (
                            <div className="col" key={index} style={descriptor.btnStatus !== 'invisible' ? { display: 'block' } : { display: 'none' }}>
                                <div className="status" data-status={descriptor.btnStatus}>
                                    <button className="btn btn-status" onClick={() => load(descriptor.url, descriptor.target)}>
                                        {descriptor.label}
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="container-detail py-4">
                <div className="row mb-4">
                    <h2>Liste des documents</h2>
                    <p>Vous pouvez charger les documents relatifs à cette commande en les sélectionnant ci-dessous</p>

                    {flowDocumentUrl && (
                        <div className="col-12 col-md-3">
                            <button
                                type="button"
                                className="btn btn-orange btn-orange--icon w-100"
                                onClick={() => handleDocumentModal(flowDocumentUrl, documentTitle)}
                            >
                                <IconEye fill="#FFF" />
                                {documentTitle}
                            </button>
                        </div>
                    )}

                    {enedisConsent && (
                        <div className="col-12 col-md-3">
                            <DownloadButton file={enedisConsent} />
                        </div>
                    )}
                </div>

                {previsitDoc && (
                    <div className="row mb-4">
                        <div className="col-12 col-md-3">
                            <button
                                type="button"
                                className="btn btn-orange btn-orange--icon w-100"
                                onClick={() => handleDocumentModal(previsitDoc, 'Pré-visite')}
                            >
                                <IconEye fill="#FFF" />
                                Pré-visite
                            </button>
                        </div>
                    </div>
                )}

                <div className="row">
                    {Object.entries(signedDocuments).map(([key, value], index) => {
                        const title = key.replaceAll('_', ' ');

                        return (
                            <div className="col col-md-3 mb-4" key={index}>
                                <button type="button" className="btn btn-orange btn-orange--icon w-100" onClick={() => handleDocumentModal(value, title)}>
                                    <IconEye fill="#FFF" />
                                    {capitalizeFirstLetter(title)}
                                </button>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Detail;
