import React from 'react';
import { IconProps } from '../EspaceAgent';
import { CreationCards } from './CreationAuditEnums';
import * as storageService from '../../../services/localStorageService';

import { useHistory } from 'react-router';

// Templates
import Template from '../../../assets/json/template-audit.json';
import TemplateSimulator from '../../../assets/json/template-simulator.json';
import TemplatePreVisit from '../../../assets/json/template-previsite.json';

// Style
import '../EspaceAgent.scss';

// Icons
import { ReactComponent as IconAuditThermic } from '../../../assets/icons/espace-agent/icon-audit-thermique.svg';
import { ReactComponent as IconAudit } from '../../../assets/icons/espace-agent/icon-audit.svg';
import { AuditType } from '../../../services/apiFlowService';

const CreationAudit: React.FC = () => {
    // Update document title
    React.useEffect(() => {
        document.title = 'SOGYS.APP - Création audit';
    }, []);

    const history = useHistory();

    // Display icon according to card.icon
    const displayIcon = (icon: string) => {
        const iconsDefault: IconProps = {
            width: 50,
            height: 50,
            fill: '#82786b',
        };

        switch (icon) {
            case 'icon-study':
                return <IconAudit {...iconsDefault} />;

            case 'icon-mail':
                return <IconAuditThermic {...iconsDefault} />;

            default:
                return '';
        }
    };

    // Reset audit templates and redirect
    const resetAndRedirect = async (auditType: AuditType, url: string, href?: boolean) => {
        // Reset Audit expert & thermique
        storageService.setAudit(Template);
        // Reset simulator
        storageService.setSim(TemplateSimulator);
        // Reset Pre Visit
        storageService.setPrevisit(TemplatePreVisit);
        // clear storage
        localStorage.removeItem('flowId');
        localStorage.removeItem('auditType');
        localStorage.removeItem('auditId');
        localStorage.removeItem('devisId');
        localStorage.removeItem('flowState');
        localStorage.removeItem('previsitDocUrl');
        localStorage.removeItem('installationDate');
        localStorage.setItem('auditType', auditType);

        if (href) {
            window.open(url, '_self');
        } else {
            history.push(url);
        }
    };

    return (
        <div className="container p-5 mt-5">
            <div className="container-creation ugly py-5 row justify-content-center">
                <h1 className="text-center">Créer une nouvelle étude</h1>
                {CreationCards.map((card, index) => (
                    <div className="col-12 col-md-4" key={index}>
                        {card.href ? (
                            <div className="agent-card" onClick={() => resetAndRedirect(card.type, card.url, true)}>
                                <div className="agent-card--title text-uppercase">{card.title}</div>
                                <div className="agent-card--body" aria-hidden="true">
                                    <div className="icon">{displayIcon(card.icon)}</div>
                                </div>
                            </div>
                        ) : (
                            <div className="agent-card" onClick={() => resetAndRedirect(card.type, card.url)}>
                                <div className="agent-card--title text-uppercase">{card.title}</div>
                                <div className="agent-card--body" aria-hidden="true">
                                    <div className="icon">{displayIcon(card.icon)}</div>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CreationAudit;
