import React, { useEffect, useState, useRef } from 'react';
import { Formik } from 'formik';
import { PropertyType } from '../../services/localStorageService';
import * as storageService from '../../services/localStorageService';

import AddStepPhotos from '../../services/componentsHelpers/addStepPhotos';
import CheckboxService from '../../components/checkbox/checkboxService';
import {
    ceilingInsulationPresence,
    floorInsulationPresence,
    rampantIsolated,
    sharedWall,
    sharedHouseWall,
    unheatedRoomPresence,
} from './services/handlCheckbox';

import { domTomCheck } from '../../services/tools/domTomServices';

import { generateArrayOfYears } from '../../services/tools/dateService';

import { ReactComponent as IconFloor } from '../../assets/icons/icon-floor.svg';
import { ReactComponent as IconCeiling } from '../../assets/icons/icon-ceiling.svg';
import { ReactComponent as IconWall } from '../../assets/icons/icon-wall.svg';

import * as Yup from 'yup';
import Select, { components, OptionProps } from 'react-select';
import { ReactComponent as IconHelp } from '../../assets/icons/icon-help.svg';
import ReactTooltip from 'react-tooltip';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

import withoutInsulation from '../../assets/images/without-insulation.png';
import withInsulationExterior from '../../assets/images/with-insulation-exterior.png';
import withInsulationInside from '../../assets/images/with-insulation-inside.png';
import {
    ceilingTypeList,
    ceilingCompositionList,
    floorTypeList,
    floorCompositionList,
    exteriorWallCompositionList,
    unheatedRoomCompositionList,
} from './EnvelopeInformationsEnums';
import { ROUTE_AUDIT_OUVRANT } from '../../routing/paths';
import { useStepNavigation } from '../../routing/StepNavigationContext';
import { renderDefaultValueSelect } from '../../services/tools/selectValue';

const EnvelopeInformations: React.FC = () => {
    const { validateThenRoute, returnToHome } = useStepNavigation();
    /**
     * Gestion désactivation formulaire
     */
    const [auditSent, setAuditSent] = useState(false);

    const buildingPictureCeilingInsulationPresence = useRef(null);
    const buildingPictureFloorInsulationPresence = useRef(null);
    const buildingPictureExteriorWall = useRef(null);
    const buildingPictureUnheatedRoom = useRef(null);

    useEffect(() => {
        /**
         * Scroll de la page en haut
         */
        window.scrollTo(0, 0);

        /**
         * Vérification si Audit déjà envoyé
         */
        const template = storageService.getAudit();
        if (template?.auditSent) {
            setAuditSent(true);
        }
    }, []);

    const AlertSwal = withReactContent(Swal);

    /**
     * Pour le changement de couleurs du react-select
     */
    const colourStyles = {
        option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
            return {
                ...styles,
                backgroundColor: isFocused ? '#76ca4f' : null,
                color: isFocused ? '#ffffff' : '#1F2F3D',
            };
        },
    };

    /**
     * Fonction pour mettre à jour un champ dans le local storage ainsi que dans le form
     * @param event (Event provenant de l'input)
     * @param handleChange (Event du formulaire pour mettre à jour une valeur)
     * @param isValid (Event du formulaire pour savoir s'il est valide)
     * @param step (étape)
     * @param propertyName (nom de la propriété)
     * @param propertyList (List des propriétés)
     */
    const updateValue = (event: any, handleChange: any, isValid: boolean, step: number, propertyName: string, propertyList?: any) => {
        // Obtention du type de variable pour écriture
        const type: any = (validationSchema as any).fields[propertyName].type;

        // Mise à jour du formulaire
        if (event.hasOwnProperty('target')) {
            handleChange(event);
        }

        // Mise à jour de la valeur du champ label dans le local storage
        if (propertyList) {
            const findLabel = propertyList.filter((property: any) => {
                if (event.hasOwnProperty('target')) {
                    return property.value === event.target.value;
                }
                return property.value === event.value;
            });
            return storageService.setAuditValue(step, isValid, propertyName, findLabel[0].value, findLabel[0].label);
        }

        // Cas standard
        return storageService.setAuditValue(step, isValid, propertyName, type === 'number' ? parseFloat(event.target.value) : event.target.value);
    };

    /**
     * Liste année des travaux d'isolation
     */
    const ceilingInsulationAgeList: { label: string; value: string }[] = generateArrayOfYears();
    const floorInsulationAgeList: { label: string; value: string }[] = generateArrayOfYears();
    /**
     * Type de bâtiment
     * 1 - Maison individuelle
     * 2 - Appartement
     * 3 - Immeuble collectif
     * 4 - Bâtiment
     */
    const buildingType: string = storageService.getAudit().step2.projectType.value;
    const zipCode: string = storageService.getAudit().step1.userZipCode.value;

    /**
     * Valeur lors de l'initialisation de la page
     */
    const initialValues = {
        // Plafonds
        ceilingType: storageService.checkPropertyExistThenCreateOrRender(6, 'ceilingType', `Caractéristiques des plafonds`, PropertyType['withLabel']),
        ceilingComposition: storageService.checkPropertyExistThenCreateOrRender(6, 'ceilingComposition', `Composition`, PropertyType['withLabel']),
        ceilingInsulationPresence: storageService.checkPropertyExistThenCreateOrRender(
            6,
            'ceilingInsulationPresence',
            `Présence d’isolation`,
            PropertyType['boolean']
        ),
        ceilingInsulationThickness: storageService.checkPropertyExistThenCreateOrRender(6, 'ceilingInsulationThickness', `Épaisseur`, PropertyType['number']),
        ceilingInsulationResistance: storageService.checkPropertyExistThenCreateOrRender(
            6,
            'ceilingInsulationResistance',
            `Résistance Thermique`,
            PropertyType['basic']
        ),
        ceilingInsulationAge: storageService.checkPropertyExistThenCreateOrRender(
            6,
            'ceilingInsulationAge',
            `Année des travaux d’isolation`,
            PropertyType['withLabel']
        ),
        // Planchers
        floorType: storageService.checkPropertyExistThenCreateOrRender(6, 'floorType', `Caractéristiques des planchers`, PropertyType['withLabel']),
        floorComposition: storageService.checkPropertyExistThenCreateOrRender(6, 'floorComposition', `Composition`, PropertyType['withLabel']),
        floorInsulationPresence: storageService.checkPropertyExistThenCreateOrRender(
            6,
            'floorInsulationPresence',
            `Présence d’isolation`,
            PropertyType['boolean']
        ),
        floorInsulationThickness: storageService.checkPropertyExistThenCreateOrRender(6, 'floorInsulationThickness', `Épaisseur`, PropertyType['boolean']),
        floorInsulationResistance: storageService.checkPropertyExistThenCreateOrRender(
            6,
            'floorInsulationResistance',
            `Résistance Thermique`,
            PropertyType['basic']
        ),
        floorInsulationAge: storageService.checkPropertyExistThenCreateOrRender(
            6,
            'floorInsulationAge',
            `Année des travaux d’isolation`,
            PropertyType['basic']
        ),
        // Murs extérieurs
        exteriorWallComposition: storageService.checkPropertyExistThenCreateOrRender(6, 'exteriorWallComposition', `Composition`, PropertyType['withLabel']),
        exteriorWallInsulationPresence: storageService.checkPropertyExistThenCreateOrRender(
            6,
            'exteriorWallInsulationPresence',
            `Présence d’isolation`,
            PropertyType['boolean']
        ),
        exteriorWallInsulationExterior: storageService.checkPropertyExistThenCreateOrRender(
            6,
            'exteriorWallInsulationExterior',
            `Position extérieure`,
            PropertyType['boolean']
        ),
        customExteriorWallInsulation:
            storageService.getAudit().step6.exteriorWallInsulationPresence.value === false
                ? 'withoutExteriorWallInsulation'
                : storageService.getAudit().step6.exteriorWallInsulationExterior.value === true
                ? 'outsideExteriorWallInsulation'
                : 'insideExteriorWallInsulation',
        exteriorWallInsulationThickness: storageService.checkPropertyExistThenCreateOrRender(
            6,
            'exteriorWallInsulationThickness',
            `Épaisseur`,
            PropertyType['number']
        ),
        exteriorWallInsulationResistance: storageService.checkPropertyExistThenCreateOrRender(
            6,
            'exteriorWallInsulationResistance',
            `Résistance Thermique`,
            PropertyType['basic']
        ),
        exteriorWallInsulationAge: storageService.checkPropertyExistThenCreateOrRender(
            6,
            'exteriorWallInsulationAge',
            `Année des travaux d’isolation`,
            PropertyType['withLabel']
        ),
        // Murs sur local non-chauffé
        unheatedRoomPresence: storageService.checkPropertyExistThenCreateOrRender(
            6,
            'unheatedRoomPresence',
            `Présence de murs sur local non-chauffé`,
            PropertyType['boolean']
        ),
        unheatedRoomComposition: storageService.checkPropertyExistThenCreateOrRender(6, 'unheatedRoomComposition', `Composition`, PropertyType['withLabel']),
        unheatedRoomInsulationPresence: storageService.checkPropertyExistThenCreateOrRender(
            6,
            'unheatedRoomInsulationPresence',
            `Présence d’isolation`,
            PropertyType['boolean']
        ),
        unheatedRoomInsulationExterior: storageService.checkPropertyExistThenCreateOrRender(
            6,
            'unheatedRoomInsulationExterior',
            `Position extérieure`,
            PropertyType['boolean']
        ),
        customUnheatedRoomInsulation:
            storageService.getAudit().step6.unheatedRoomInsulationPresence.value === false
                ? 'withoutUnheatedRoomInsulation'
                : storageService.getAudit().step6.unheatedRoomInsulationExterior.value === true
                ? 'outsideUnheatedRoomInsulation'
                : 'insideUnheatedRoomInsulation',
        unheatedRoomInsulationThickness: storageService.checkPropertyExistThenCreateOrRender(
            6,
            'unheatedRoomInsulationThickness',
            `Épaisseur`,
            PropertyType['number']
        ),
        unheatedRoomInsulationResistance: storageService.checkPropertyExistThenCreateOrRender(
            6,
            'unheatedRoomInsulationResistance',
            `Résistance Thermique`,
            PropertyType['basic']
        ),
        unheatedRoomInsulationAge: storageService.checkPropertyExistThenCreateOrRender(
            6,
            'unheatedRoomInsulationAge',
            `Année des travaux d’isolation`,
            PropertyType['basic']
        ),
        rampantSize: storageService.checkPropertyExistThenCreateOrRender(6, 'rampantSize', `Taille des rampants`, PropertyType['basic']),
        rampantIsolated: storageService.checkPropertyExistThenCreateOrRender(6, 'rampantIsolated', `Les rampants sont isolés`, PropertyType['boolean']),
        sharedCeiling: storageService.checkPropertyExistThenCreateOrRender(
            6,
            'sharedCeiling',
            `Plafond mitoyen (accolé à un autre appartement)`,
            PropertyType['boolean']
        ),
        sharedFloor: storageService.checkPropertyExistThenCreateOrRender(
            6,
            'sharedFloor',
            `Plancher mitoyen (accolé à un autre appartement)`,
            PropertyType['boolean']
        ),
        sharedWall: storageService.checkPropertyExistThenCreateOrRender(
            6,
            'sharedWall',
            `Mur mitoyen (accolé à un autre appartement)`,
            PropertyType['boolean']
        ),
        sharedHouseWall: storageService.checkPropertyExistThenCreateOrRender(
            6,
            'sharedHouseWall',
            `Mur mitoyen (accolé à une autre maison)`,
            PropertyType['boolean']
        ),

        buildingPictureCeilingInsulationPresence: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPictureCeilingInsulationPresence',
            `Photo des caractéristiques des plafonds`,
            PropertyType['photo']
        ),
        buildingPictureFloorInsulationPresence: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPictureFloorInsulationPresence',
            `Photo des planchers`,
            PropertyType['photo']
        ),
        buildingPictureExteriorWall: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPictureExteriorWall',
            `Photo des murs extérieurs`,
            PropertyType['photo']
        ),
        buildingPictureUnheatedRoom: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPictureUnheatedRoom',
            `Photo des murs sur local non-chauffé`,
            PropertyType['photo']
        ),
    };

    /**
     * Schéma de validation
     */
    const validationSchema = Yup.object({
        // Plafonds
        ceilingType: Yup.string().required(`Le choix du type de plafonds est obligatoire`),
        ceilingComposition: Yup.string().required(`Le choix de la composition des plafonds est obligatoire`),
        ceilingInsulationPresence: Yup.boolean()
            .required(`Vous n'avez pas précisé si les plafonds, combles et/ou rampants sont isolés`)
            .test('ceilingInsulationPresence', `Vous n'avez pas précisé si les plafonds, combles et/ou rampants sont isolés`, (value) => {
                return [true, false].includes(value as boolean);
            }),
        ceilingInsulationThickness: Yup.number()
            .nullable()
            .notRequired()
            .when('ceilingInsulationPresence', {
                is: (value: string) => ['1', true, 'true'].includes(value),
                then: Yup.number()
                    .min(0, `L'épaisseur minimale de l'isolant des plafonds est de 0 cm`)
                    .max(50, `L'épaisseur maximale de l'isolant des plafonds est de 50 cm`)
                    .typeError(`L'épaisseur de l'isolant des plafonds est obligatoire`)
                    .required(`L'épaisseur de l'isolant des plafonds est obligatoire`),
            }),
        ceilingInsulationResistance: Yup.string(),
        ceilingInsulationAge: Yup.string()
            .notRequired()
            .when('ceilingInsulationPresence', {
                is: (value: string) => ['1', true, 'true'].includes(value),
                then: Yup.string().required(`L'année des travaux d'isolation des plafonds est obligatoire`),
            }),
        // Planchers
        floorType: Yup.string().required(`Le choix du type de planchers est obligatoire`),
        floorComposition: Yup.string().required(`Le choix de la composition des planchers est obligatoire`),
        floorInsulationPresence: Yup.boolean()
            .required(`Vous n'avez pas précisé si les planchers sont isolés`)
            .test('floorInsulationPresence', `Vous n'avez pas précisé si les planchers sont isolés`, (value) => {
                return [true, false].includes(value as boolean);
            }),
        floorInsulationThickness: Yup.number()
            .nullable()
            .notRequired()
            .when('floorInsulationPresence', {
                is: (value: string) => ['1', true, 'true'].includes(value),
                then: Yup.number()
                    .min(0, `L'épaisseur minimale de l'isolant des planchers est de 0 cm`)
                    .max(50, `L'épaisseur maximale de l'isolant des planchers est de 50 cm`)
                    .typeError(`L'épaisseur de l'isolant des planchers est obligatoire`)
                    .required(`L'épaisseur de l'isolant des planchers est obligatoire`),
            }),
        floorInsulationResistance: Yup.string(),
        floorInsulationAge: Yup.string()
            .notRequired()
            .when('floorInsulationPresence', {
                is: (value: string) => ['1', true, 'true'].includes(value),
                then: Yup.string().required(`L'année des travaux d'isolation des planchers est obligatoire`),
            }),
        // Murs extérieurs
        exteriorWallComposition: Yup.string().required(`Le choix de la composition des murs extérieurs est obligatoire`),
        exteriorWallInsulationPresence: Yup.boolean(),
        exteriorWallInsulationExterior: Yup.boolean(),
        customExteriorWallInsulation: Yup.string().required(`Le choix de l'isolation des murs extérieurs est obligatoire`),
        exteriorWallInsulationThickness: Yup.number()
            .nullable()
            .notRequired()
            .when('customExteriorWallInsulation', {
                is: (value: string) => ['outsideExteriorWallInsulation', 'insideExteriorWallInsulation'].includes(value),
                then: Yup.number()
                    .min(0, `L'épaisseur minimale de l'isolant des murs extérieurs est de 0 cm`)
                    .max(50, `L'épaisseur maximale de l'isolant des murs extérieurs est de 50 cm`)
                    .typeError(`L'épaisseur de l'isolant des murs extérieurs est obligatoire`)
                    .required(`L'épaisseur de l'isolant des murs extérieurs est obligatoire`),
            }),
        exteriorWallInsulationResistance: Yup.string().when('customExteriorWallInsulation', {
            is: (value: string) => ['outsideExteriorWallInsulation', 'insideExteriorWallInsulation'].includes(value),
            then: Yup.string().required(`La résistance thermique de l'isolant des murs extérieurs est obligatoire`),
        }),
        exteriorWallInsulationAge: Yup.string()
            .notRequired()
            .when('customExteriorWallInsulation', {
                is: (value: string) => ['outsideExteriorWallInsulation', 'insideExteriorWallInsulation'].includes(value),
                then: Yup.string().required(`L'année des travaux d'isolation des murs extérieurs est obligatoire`),
            }),
        // Murs sur local non-chauffé
        unheatedRoomPresence:
            domTomCheck(zipCode) !== undefined
                ? Yup.boolean().notRequired()
                : Yup.boolean()
                      .notRequired()
                      .when(storageService.getAudit().step2.projectType.value, {
                          is: (value: string = storageService.getAudit().step2.projectType.value) => ['1', '4'].includes(value),
                          then: Yup.boolean()
                              .required(`Vous n'avez pas précisé si l'habitaiton comporte des murs sur local non chauffé`)
                              .test('unheatedRoomPresence', `Vous n'avez pas précisé si l'habitaiton comporte des murs sur local non chauffé`, (value) => {
                                  return [true, false].includes(value as boolean);
                              }),
                      }),
        unheatedRoomComposition: Yup.string().required(`Le choix de la composition des murs sur local non-chauffé est obligatoire`),
        unheatedRoomInsulationPresence: Yup.boolean(),
        unheatedRoomInsulationExterior: Yup.boolean(),
        customUnheatedRoomInsulation: Yup.string().required(`Le choix de l'isolation est obligatoire`),
        unheatedRoomInsulationThickness: Yup.number()
            .nullable()
            .notRequired()
            .when('customUnheatedRoomInsulation', {
                is: (value: string) => ['outsideUnheatedRoomInsulation', 'insideUnheatedRoomInsulation'].includes(value),
                then: Yup.number()
                    .min(0, `L'épaisseur minimale de l'isolant des murs sur local non-chauffé est de 0 cm`)
                    .max(50, `L'épaisseur maximale de l'isolant des murs sur local non-chauffé est de 50 cm`)
                    .typeError(`L'épaisseur de l'isolant des murs sur local non-chauffé est obligatoire`)
                    .required(`L'épaisseur de l'isolant des murs sur local non-chauffé est obligatoire`),
            }),
        unheatedRoomInsulationResistance: Yup.string().when('customUnheatedRoomInsulation', {
            is: (value: string) => ['outsideUnheatedRoomInsulation', 'insideUnheatedRoomInsulation'].includes(value),
            then: Yup.string().required(`La résistance thermique de l'isolant des murs sur local non-chauffé est obligatoire`),
        }),
        unheatedRoomInsulationAge: Yup.number()
            .nullable()
            .notRequired()
            .when('customUnheatedRoomInsulation', {
                is: (value: string) => ['outsideUnheatedRoomInsulation', 'insideUnheatedRoomInsulation'].includes(value),
                then: Yup.number()
                    .min(parseInt(storageService.getAudit().step2.houseAge.value), `Ne peut pas être inférieure à la date de construction`)
                    .max(new Date().getFullYear(), `Ne peut pas être supérieur à cette année`)
                    .required(`L'année des travaux d'isolation des murs sur local non-chauffé est obligatoire`),
            }),
        rampantSize: Yup.string()
            .notRequired()
            .when('rampantIsolated', {
                is: true,
                then: Yup.string().required(`La taille des rampants est obligatoire`),
            }),
        rampantIsolated: Yup.boolean()
            .notRequired()
            .when('ceilingType', {
                is: (value: string) => value === '2',
                then: Yup.boolean()
                    .required(`Vous n'avez pas précisé si les rampants sont isolés`)
                    .test('rampantIsolated', `Vous n'avez pas précisé si les rampants sont isolés`, (value) => {
                        return [true, false].includes(value as boolean);
                    }),
            }),
        sharedCeiling: Yup.boolean().notRequired(),
        sharedFloor: Yup.boolean().notRequired(),
        sharedWall: Yup.boolean()
            .notRequired()
            .when(storageService.getAudit().step2.projectType.value, {
                is: (value: string = storageService.getAudit().step2.projectType.value) => ['2', '3'].includes(value),
                then: Yup.boolean()
                    .required(`Vous n'avez pas précisé si l'habitation comporte des murs mitoyens`)
                    .test('sharedWall', `Vous n'avez pas précisé si l'habitation comporte des murs mitoyens`, (value) => {
                        return [true, false].includes(value as boolean);
                    }),
            }),
        sharedHouseWall: Yup.boolean()
            .notRequired()
            .when(storageService.getAudit().step2.projectType.value, {
                is: (value: string = storageService.getAudit().step2.projectType.value) => ['1', '4'].includes(value),
                then: Yup.boolean()
                    .required(`Vous n'avez pas précisé si l'habitation comporte des murs mitoyens`)
                    .test('sharedHouseWall', `Vous n'avez pas précisé si l'habitation comporte des murs mitoyens`, (value) => {
                        return [true, false].includes(value as boolean);
                    }),
            }),

        buildingPictureCeilingInsulationPresence: Yup.string().notRequired(),
        buildingPictureFloorInsulationPresence: Yup.string().notRequired(),
        buildingPictureExteriorWall: Yup.string().notRequired(),
        buildingPictureUnheatedRoom: Yup.string().notRequired(),
    });

    /**
     * Fonction pour confirmer l'épaisseur
     */
    const confirmInsulationThickness = async (currentValue: string, limit: { start: number; end: number }) => {
        return await AlertSwal.fire({
            title: `Épaisseur de l’isolant`,
            html: `Vous indiquez une valeur de <b>${currentValue}cm</b> pour l’épaisseur de l'isolant.<br/>
                    Or elle devrait être comprise entre <b>${limit?.start} et ${limit?.end} cm</b>.<br/>
                    La valeur indiquée semble incohérente et risque de fausser fortement les résultats de votre Audit.<br/><br/>
                    Confirmez vous votre choix ou souhaitez vous la modifier ?`,
            width: 550,
            cancelButtonText: 'Modifier',
            confirmButtonText: 'Confirmer',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCancelButton: true,
            reverseButtons: true,
            focusCancel: true,
            customClass: {
                cancelButton: 'btn btn-red-border min-width',
                confirmButton: 'btn btn-primary min-width',
            },
        }).then((result) => {
            return result.isConfirmed;
        });
    };

    return (
        <Formik
            onSubmit={() => {}}
            initialValues={initialValues}
            isInitialValid={storageService.getAudit().step6.isValid}
            validateOnMount={true}
            validationSchema={validationSchema}
        >
            {({ values, errors, handleChange, isValid, setFieldValue, setValues, touched, handleBlur }) => {
                // Mise à jour validité Formik
                storageService.setAuditStepValidity(6, isValid);
                // Mise à jour de l'étape globale
                if (isValid) {
                    let globalCurrentStep = storageService.getAuditCurrentStep();
                    if (6 === globalCurrentStep) {
                        storageService.setAuditCurrentStep(globalCurrentStep + 1);
                    }
                }
                return (
                    <div className="container">
                        <h1 className="main-title-mini">6. Enveloppe</h1>
                        <h2 className="main-subtitle">Composition et isolation des parois de l’habitation</h2>
                        <div className="card mb-5">
                            <div className="card-title">
                                <IconCeiling fill="#76ca4f" width={40} height={40} className="icon" />
                                <h2>Caractéristiques des plafonds (Comble et/ou rampant)</h2>

                                <AddStepPhotos
                                    prop={buildingPictureCeilingInsulationPresence}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    fieldValue={'buildingPictureCeilingInsulationPresence'}
                                    disabled={auditSent}
                                />
                            </div>
                            <div className="card-body">
                                <fieldset className="w-100" disabled={auditSent}>
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-4 mb-3 mb-md-0">
                                            <label htmlFor="ceilingType" className="form-label">
                                                Caractéristiques des plafonds<sup>*</sup>
                                            </label>
                                            <Select
                                                value={renderDefaultValueSelect(ceilingTypeList, values.ceilingType)}
                                                options={ceilingTypeList}
                                                isClearable={false}
                                                isSearchable={false}
                                                isMulti={false}
                                                inputId="ceilingType"
                                                onBlur={handleBlur}
                                                isDisabled={auditSent}
                                                onChange={(event) => {
                                                    storageService.setAuditValue(6, isValid, 'ceilingType', event.value, event.label);
                                                    setValues({
                                                        ...values,
                                                        ceilingType: event.value,
                                                    });
                                                }}
                                                className={
                                                    'basic-single' +
                                                    (values.ceilingType ? ' filled' : '') +
                                                    (touched.ceilingType && errors.ceilingType ? ' invalid' : '') +
                                                    (!values.ceilingType ? ' required' : '')
                                                }
                                                placeholder={'Choisissez les caractéristiques des plafonds'}
                                                styles={colourStyles}
                                                components={{
                                                    Option: (props: OptionProps<any, any>) => (
                                                        <components.Option
                                                            {...props}
                                                            className={
                                                                'custom-react-select' + (!props.data.buildingType.includes(buildingType) ? ' d-none' : '')
                                                            }
                                                        >
                                                            {props.label}
                                                        </components.Option>
                                                    ),
                                                }}
                                            />
                                            {touched.ceilingType && errors.ceilingType && <div className="invalid-feedback">{errors.ceilingType}</div>}
                                        </div>
                                        <div className="col-12 col-md-4 mb-3 mb-md-0">
                                            <label htmlFor="ceilingComposition" className="form-label">
                                                Composition<sup>*</sup>
                                            </label>
                                            <Select
                                                value={renderDefaultValueSelect(ceilingCompositionList, values.ceilingComposition)}
                                                options={ceilingCompositionList}
                                                isClearable={false}
                                                isSearchable={false}
                                                isMulti={false}
                                                inputId="ceilingComposition"
                                                onBlur={handleBlur}
                                                isDisabled={auditSent}
                                                onChange={(event) => {
                                                    storageService.setAuditValue(6, isValid, 'ceilingComposition', event.value, event.label);
                                                    setValues({
                                                        ...values,
                                                        ceilingComposition: event.value,
                                                    });
                                                }}
                                                className={
                                                    'basic-single' +
                                                    (values.ceilingComposition ? ' filled' : '') +
                                                    (touched.ceilingComposition && errors.ceilingComposition ? ' invalid' : '') +
                                                    (!values.ceilingComposition ? ' required' : '')
                                                }
                                                placeholder={'Choisissez la composition des plafonds'}
                                                styles={colourStyles}
                                            />
                                            {touched.ceilingComposition && errors.ceilingComposition && (
                                                <div className="invalid-feedback">{errors.ceilingComposition}</div>
                                            )}
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <CheckboxService
                                                handleCheckbox={ceilingInsulationPresence}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'ceilingInsulationPresence'}
                                                propName={values.ceilingInsulationPresence}
                                                title={"Présence d'isolation ?"}
                                                emptyLabel={true}
                                                border={true}
                                            />
                                        </div>
                                    </div>
                                    {['1', true].includes(values.ceilingInsulationPresence) && (
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-4 mb-3 mb-md-0">
                                                <label htmlFor="ceilingInsulationThickness" className="form-label">
                                                    Épaisseur de l’isolant<sup>*</sup>
                                                    <div className="help-text" data-tip data-for="helpCeilingInsulationThickness">
                                                        <IconHelp fill="#E69736" width={14} height={14} className="icon" />
                                                    </div>
                                                </label>
                                                <div className="input-group">
                                                    <input
                                                        type="number"
                                                        className={
                                                            'form-control' +
                                                            (values.ceilingInsulationThickness || values.ceilingInsulationThickness === 0 ? ' filled' : '') +
                                                            (touched.ceilingInsulationThickness && errors.ceilingInsulationThickness ? ' invalid' : '') +
                                                            (!values.ceilingInsulationThickness ? ' required' : '')
                                                        }
                                                        placeholder="15"
                                                        min={0}
                                                        id="ceilingInsulationThickness"
                                                        onBlur={(event: any) => {
                                                            handleBlur(event);
                                                            // Vérification de la valeur saisie & affichage modal confirmation
                                                            if (
                                                                parseInt(values.ceilingInsulationThickness) < 15 ||
                                                                (parseInt(values.ceilingInsulationThickness) > 30 &&
                                                                    parseInt(values.ceilingInsulationThickness) <= 50)
                                                            ) {
                                                                confirmInsulationThickness(event.target.value, { start: 15, end: 30 }).then(
                                                                    (response: boolean) => {
                                                                        // Suppression des données
                                                                        if (!response) {
                                                                            setValues({
                                                                                ...values,
                                                                                ceilingInsulationThickness: null,
                                                                                ceilingInsulationResistance: '',
                                                                            });
                                                                            storageService.removeAuditValue(6, 'ceilingInsulationThickness');
                                                                            storageService.removeAuditValue(6, 'ceilingInsulationResistance');
                                                                        }
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                        value={values?.ceilingInsulationThickness || ''}
                                                        onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                        onChange={(event) => {
                                                            updateValue(event, handleChange, isValid, 6, 'ceilingInsulationThickness');
                                                            // Calcul du total
                                                            setValues({
                                                                ...values,
                                                                ceilingInsulationThickness: event.target.value,
                                                                ceilingInsulationResistance: (parseFloat(event.target.value) * 0.25).toString(),
                                                            });
                                                            // Injection dans local storage
                                                            storageService.setAuditValue(
                                                                6,
                                                                isValid,
                                                                'ceilingInsulationResistance',
                                                                (parseFloat(event.target.value) * 0.25).toString()
                                                            );
                                                        }}
                                                    />
                                                    <span className="input-group-text">cm</span>
                                                </div>
                                                {touched.ceilingInsulationThickness && errors.ceilingInsulationThickness && (
                                                    <div className="invalid-feedback">{errors.ceilingInsulationThickness}</div>
                                                )}
                                                <ReactTooltip id="helpCeilingInsulationThickness" place="bottom" type={'light'} effect={'solid'}>
                                                    <h3>Épaisseur de l’isolation :</h3>
                                                    <p>L’épaisseur de l’isolant des plafonds est généralement comprise entre 15cm et 50cm.</p>
                                                </ReactTooltip>
                                            </div>
                                            <div className="col-12 col-md-4 mb-3 mb-md-0">
                                                <label htmlFor="ceilingInsulationResistance" className="form-label">
                                                    Résistance thermique
                                                </label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className={'form-control'}
                                                        readOnly={true}
                                                        placeholder="2.5"
                                                        id="ceilingInsulationResistance"
                                                        onBlur={handleBlur}
                                                        value={values.ceilingInsulationResistance || ''}
                                                    />
                                                    <span className="input-group-text">m²/kW</span>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <label htmlFor="ceilingInsulationAge" className="form-label">
                                                    Année des travaux d’isolation<sup>*</sup>
                                                </label>
                                                <Select
                                                    value={renderDefaultValueSelect(ceilingInsulationAgeList, values.ceilingInsulationAge)}
                                                    options={ceilingInsulationAgeList}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    isMulti={false}
                                                    inputId="ceilingInsulationAge"
                                                    onBlur={handleBlur}
                                                    isDisabled={auditSent}
                                                    onChange={(event) => {
                                                        storageService.setAuditValue(6, isValid, 'ceilingInsulationAge', event.value, event.label);
                                                        setValues({
                                                            ...values,
                                                            ceilingInsulationAge: event.value,
                                                        });
                                                    }}
                                                    className={
                                                        'basic-single' +
                                                        (values.ceilingInsulationAge ? ' filled' : '') +
                                                        (touched.ceilingInsulationAge && errors.ceilingInsulationAge ? ' invalid' : '') +
                                                        (!values.ceilingInsulationAge ? ' required' : '')
                                                    }
                                                    placeholder={storageService.getAudit().step2.houseAge.value || 2001}
                                                    styles={colourStyles}
                                                />
                                                {touched.ceilingInsulationAge && errors.ceilingInsulationAge && (
                                                    <div className="invalid-feedback">{errors.ceilingInsulationAge}</div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    <div className="row">
                                        {
                                            // Actif si les combles sont `aménagés`
                                            values.ceilingType === '2' && (
                                                <div className="col-12 col-md-4">
                                                    <CheckboxService
                                                        handleCheckbox={rampantIsolated}
                                                        values={values}
                                                        isValid={isValid}
                                                        setValues={setValues}
                                                        name={'rampantIsolated'}
                                                        propName={values.rampantIsolated}
                                                        title={'Les rampants sont isolés ?'}
                                                        emptyLabel={true}
                                                        border={true}
                                                    />
                                                </div>
                                            )
                                        }

                                        {
                                            // Actif si les rampants sont `isolés`
                                            values.rampantIsolated && (
                                                <div className="col-12 col-md-4">
                                                    <label htmlFor="rampantSize">
                                                        Taille des rampants <sup>*</sup>
                                                    </label>
                                                    <div className="input-group">
                                                        <input
                                                            type="number"
                                                            min={1}
                                                            className={
                                                                'form-control' +
                                                                (values.rampantSize ? ' filled' : '') +
                                                                (touched.rampantSize && errors.rampantSize ? ' invalid' : '') +
                                                                (!values.rampantSize ? ' required' : '')
                                                            }
                                                            placeholder="15"
                                                            id="rampantSize"
                                                            onBlur={handleBlur}
                                                            defaultValue={values.rampantSize}
                                                            onChange={(event) => updateValue(event, handleChange, isValid, 6, 'rampantSize')}
                                                            onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                        />
                                                        <span className="input-group-text">
                                                            m<sup>2</sup>
                                                        </span>
                                                    </div>

                                                    {touched.rampantSize && errors.rampantSize && <div className="invalid-feedback">{errors.rampantSize}</div>}
                                                </div>
                                            )
                                        }
                                    </div>

                                    {/* <div className="row">
                                        {
                                            // Immeuble collectif
                                            ['3'].includes(buildingType) &&
                                            <div className="col-12 col-md-4">
                                                <label>&nbsp;</label>
                                                <div className="form-switch custom-switch">
                                                    <label className="form-check-label" htmlFor="sharedCeiling">Plafond mitoyen (accolé à un autre appartement) ?</label>
                                                    <input className="form-check-input" type="checkbox"
                                                        id="sharedCeiling"
                                                        checked={values.sharedCeiling}
                                                        onChange={(event) => {
                                                            setFieldValue('sharedCeiling', !values.sharedCeiling);
                                                            storageService.setAuditValue(6, isValid, 'sharedCeiling', !values.sharedCeiling);
                                                            if (event.target.value) {
                                                                setValues({
                                                                    ...values,
                                                                    sharedCeiling: !values.sharedCeiling
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div> */}
                                </fieldset>
                            </div>
                        </div>
                        <div className="card mb-5">
                            <div className="card-title">
                                <IconFloor fill="#76ca4f" width={40} height={40} className="icon" />
                                <h2>Caractéristique du plancher de l'habitation</h2>

                                <AddStepPhotos
                                    prop={buildingPictureFloorInsulationPresence}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    fieldValue={'buildingPictureFloorInsulationPresence'}
                                    disabled={auditSent}
                                />
                            </div>
                            <div className="card-body">
                                <fieldset className="w-100" disabled={auditSent}>
                                    <div className={'row' + (['1', true].includes(values.floorInsulationPresence) ? ' mb-3' : '')}>
                                        <div className="col-12 col-md-4 mb-3 mb-md-0">
                                            <label htmlFor="floorType" className="form-label">
                                                Caractéristiques des planchers<sup>*</sup>
                                            </label>
                                            <Select
                                                value={renderDefaultValueSelect(floorTypeList, values.floorType)}
                                                options={floorTypeList}
                                                isClearable={false}
                                                isSearchable={false}
                                                isMulti={false}
                                                inputId="floorType"
                                                onBlur={handleBlur}
                                                isDisabled={auditSent}
                                                onChange={(event) => {
                                                    storageService.setAuditValue(6, isValid, 'floorType', event.value, event.label);
                                                    setValues({
                                                        ...values,
                                                        floorType: event.value,
                                                    });
                                                }}
                                                className={
                                                    'basic-single' +
                                                    (values.floorType ? ' filled' : '') +
                                                    (touched.floorType && errors.floorType ? ' invalid' : '') +
                                                    (!values.floorType ? ' required' : '')
                                                }
                                                placeholder={'Choisissez les caractéristiques des planchers'}
                                                styles={colourStyles}
                                                components={{
                                                    Option: (props: OptionProps<any, any>) => (
                                                        <components.Option
                                                            {...props}
                                                            className={
                                                                'custom-react-select' + (!props.data.buildingType.includes(buildingType) ? ' d-none' : '')
                                                            }
                                                        >
                                                            {props.label}
                                                        </components.Option>
                                                    ),
                                                }}
                                            />
                                            {touched.floorType && errors.floorType && <div className="invalid-feedback">{errors.floorType}</div>}
                                        </div>
                                        <div className="col-12 col-md-4 mb-3 mb-md-0">
                                            <label htmlFor="floorComposition" className="form-label">
                                                Composition<sup>*</sup>
                                            </label>
                                            <Select
                                                value={renderDefaultValueSelect(floorCompositionList, values.floorComposition)}
                                                options={floorCompositionList}
                                                isClearable={false}
                                                isSearchable={false}
                                                isMulti={false}
                                                inputId="floorComposition"
                                                onBlur={handleBlur}
                                                isDisabled={auditSent}
                                                onChange={(event) => {
                                                    storageService.setAuditValue(6, isValid, 'floorComposition', event.value, event.label);
                                                    setValues({
                                                        ...values,
                                                        floorComposition: event.value,
                                                    });
                                                }}
                                                className={
                                                    'basic-single' +
                                                    (values.floorComposition ? ' filled' : '') +
                                                    (touched.floorComposition && errors.floorComposition ? ' invalid' : '') +
                                                    (!values.floorComposition ? ' required' : '')
                                                }
                                                placeholder={'Choisissez la composition des planchers'}
                                                styles={colourStyles}
                                            />
                                            {touched.floorComposition && errors.floorComposition && (
                                                <div className="invalid-feedback">{errors.floorComposition}</div>
                                            )}
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <CheckboxService
                                                handleCheckbox={floorInsulationPresence}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'floorInsulationPresence'}
                                                propName={values.floorInsulationPresence}
                                                title={"Présence d'isolation ?"}
                                                emptyLabel={true}
                                                border={true}
                                            />
                                        </div>
                                    </div>
                                    {['1', true].includes(values.floorInsulationPresence) && (
                                        <div className="row">
                                            <div className="col-12 col-md-4 mb-3 mb-md-0">
                                                <label htmlFor="floorInsulationThickness" className="form-label">
                                                    Épaisseur de l’isolant<sup>*</sup>
                                                    <div className="help-text" data-tip data-for="helpFloorInsulationThickness">
                                                        <IconHelp fill="#E69736" width={14} height={14} className="icon" />
                                                    </div>
                                                </label>
                                                <div className="input-group">
                                                    <input
                                                        type="number"
                                                        className={
                                                            'form-control' +
                                                            (values.floorInsulationThickness || values.floorInsulationThickness === 0 ? ' filled' : '') +
                                                            (touched.floorInsulationThickness && errors.floorInsulationThickness ? ' invalid' : '') +
                                                            (!values.floorInsulationThickness ? ' required' : '')
                                                        }
                                                        placeholder="15"
                                                        min={0}
                                                        id="floorInsulationThickness"
                                                        onBlur={(event: any) => {
                                                            handleBlur(event);
                                                            // Vérification de la valeur saisie & affichage modal confirmation
                                                            if (
                                                                parseInt(values.floorInsulationThickness) < 7 ||
                                                                parseInt(values.floorInsulationThickness) > 25
                                                            ) {
                                                                confirmInsulationThickness(event.target.value, { start: 7, end: 25 }).then(
                                                                    (response: boolean) => {
                                                                        // Suppression des données
                                                                        if (!response) {
                                                                            setValues({
                                                                                ...values,
                                                                                floorInsulationThickness: null,
                                                                                floorInsulationResistance: '',
                                                                            });
                                                                            storageService.removeAuditValue(6, 'floorInsulationThickness');
                                                                            storageService.removeAuditValue(6, 'floorInsulationResistance');
                                                                        }
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                        value={values.floorInsulationThickness || ''}
                                                        onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                        onChange={(event) => {
                                                            updateValue(event, handleChange, isValid, 6, 'floorInsulationThickness');
                                                            // Calcul du total
                                                            setValues({
                                                                ...values,
                                                                floorInsulationThickness: event.target.value,
                                                                floorInsulationResistance: (parseFloat(event.target.value) * 0.25).toString(),
                                                            });
                                                            // Injection dans local storage
                                                            storageService.setAuditValue(
                                                                6,
                                                                isValid,
                                                                'floorInsulationResistance',
                                                                (parseFloat(event.target.value) * 0.25).toString()
                                                            );
                                                        }}
                                                    />
                                                    <span className="input-group-text">cm</span>
                                                </div>
                                                {touched.floorInsulationThickness && errors.floorInsulationThickness && (
                                                    <div className="invalid-feedback">{errors.floorInsulationThickness}</div>
                                                )}
                                                <ReactTooltip id="helpFloorInsulationThickness" place="bottom" type={'light'} effect={'solid'}>
                                                    <h3>Épaisseur de l’isolation :</h3>
                                                    <p>L’épaisseur de l’isolant des planchers est généralement comprise entre 7cm et 25cm.</p>
                                                </ReactTooltip>
                                            </div>
                                            <div className="col-12 col-md-4 mb-3 mb-md-0">
                                                <label htmlFor="floorInsulationResistance" className="form-label">
                                                    Résistance thermique
                                                </label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className={'form-control'}
                                                        readOnly={true}
                                                        placeholder="2.5"
                                                        id="floorInsulationResistance"
                                                        onBlur={handleBlur}
                                                        value={values.floorInsulationResistance || ''}
                                                    />
                                                    <span className="input-group-text">m²/kW</span>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <label htmlFor="floorInsulationAge" className="form-label">
                                                    Année des travaux d’isolation<sup>*</sup>
                                                </label>
                                                <Select
                                                    value={renderDefaultValueSelect(floorInsulationAgeList, values.floorInsulationAge)}
                                                    options={floorInsulationAgeList}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    isMulti={false}
                                                    inputId="floorInsulationAge"
                                                    onBlur={handleBlur}
                                                    isDisabled={auditSent}
                                                    onChange={(event) => {
                                                        storageService.setAuditValue(6, isValid, 'floorInsulationAge', event.value, event.label);
                                                        setValues({
                                                            ...values,
                                                            floorInsulationAge: event.value,
                                                        });
                                                    }}
                                                    className={
                                                        'basic-single' +
                                                        (values.floorInsulationAge ? ' filled' : '') +
                                                        (touched.floorInsulationAge && errors.floorInsulationAge ? ' invalid' : '') +
                                                        (!values.floorInsulationAge ? ' required' : '')
                                                    }
                                                    placeholder={storageService.getAudit().step2.houseAge.value || 2001}
                                                    styles={colourStyles}
                                                />
                                                {/* <input type="number"
                                                   min={getLocalStorageCurrentAgent().step2.houseAge.value}
                                                   max={(new Date().getFullYear())}
                                                   className={'form-control' + ((values.floorInsulationAge || values.floorInsulationAge === 0) ? ' filled' : '') + (touched.floorInsulationAge && errors.floorInsulationAge ? ' invalid' : '') + (!values.floorInsulationAge ? ' required' : '')}
                                                   placeholder={getLocalStorageCurrentAgent().step2.houseAge.value || 2001}
                                                   disabled={!getLocalStorageCurrentAgent().step2.houseAge.value}
                                                   name="floorInsulationAge"
                                                   id="floorInsulationAge"
                                                   onBlur={handleBlur}
                                                   value={values.floorInsulationAge || ''}
                                                   onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                   onChange={(event) => updateValue(event, handleChange, isValid, 6, 'floorInsulationAge')}
                                                /> */}
                                                {touched.floorInsulationAge && errors.floorInsulationAge && (
                                                    <div className="invalid-feedback">{errors.floorInsulationAge}</div>
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    <div className="row">
                                        {/* {
                                            // Immeuble collectif
                                            ['3'].includes(buildingType) &&
                                            <div className="col-12 col-md-4">
                                                <label>&nbsp;</label>
                                                <div className="form-switch custom-switch">
                                                    <label className="form-check-label" htmlFor="sharedFloor">Plancher mitoyen (accolé à un autre appartement) ?</label>
                                                    <input className="form-check-input" type="checkbox"
                                                        id="sharedFloor"
                                                        checked={values.sharedFloor}
                                                        onChange={(event) => {
                                                            setFieldValue('sharedFloor', !values.sharedFloor);
                                                            storageService.setAuditValue(6, isValid, 'sharedFloor', !values.sharedFloor);
                                                            if (event.target.value) {
                                                                setValues({
                                                                    ...values,
                                                                    sharedFloor: !values.sharedFloor
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        } */}
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className="card mb-5">
                            <div className="card-title">
                                <IconWall fill="#76ca4f" width={40} height={40} className="icon" />
                                <h2>Caractéristiques des murs extérieurs</h2>

                                <AddStepPhotos
                                    prop={buildingPictureExteriorWall}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    fieldValue={'buildingPictureExteriorWall'}
                                    disabled={auditSent}
                                />
                            </div>
                            <div className="card-body">
                                <fieldset className="w-100" disabled={auditSent}>
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-6 mb-3 mb-md-0">
                                            <label htmlFor="exteriorWallComposition" className="form-label">
                                                Composition<sup>*</sup>
                                            </label>
                                            <Select
                                                value={renderDefaultValueSelect(exteriorWallCompositionList, values.exteriorWallComposition)}
                                                options={exteriorWallCompositionList}
                                                isClearable={false}
                                                isSearchable={false}
                                                isMulti={false}
                                                inputId="exteriorWallComposition"
                                                onBlur={handleBlur}
                                                isDisabled={auditSent}
                                                onChange={(event) => {
                                                    storageService.setAuditValue(6, isValid, 'exteriorWallComposition', event.value, event.label);
                                                    setValues({
                                                        ...values,
                                                        exteriorWallComposition: event.value,
                                                    });
                                                }}
                                                className={
                                                    'basic-single' +
                                                    (values.exteriorWallComposition ? ' filled' : '') +
                                                    (touched.exteriorWallComposition && errors.exteriorWallComposition ? ' invalid' : '') +
                                                    (!values.exteriorWallComposition ? ' required' : '')
                                                }
                                                placeholder={'Choisissez la composition des murs extérieurs'}
                                                styles={colourStyles}
                                            />
                                            {touched.exteriorWallComposition && errors.exteriorWallComposition && (
                                                <div className="invalid-feedback">{errors.exteriorWallComposition}</div>
                                            )}
                                        </div>

                                        {
                                            // Appartement & Immeuble collectif
                                            ['2', '3'].includes(buildingType) && (
                                                <div className="col-12 col-md-6">
                                                    <CheckboxService
                                                        handleCheckbox={sharedWall}
                                                        values={values}
                                                        isValid={isValid}
                                                        setValues={setValues}
                                                        name={'sharedWall'}
                                                        propName={values.sharedWall}
                                                        title="Mur mitoyen (accolé à un autre appartement) ?"
                                                        emptyLabel={true}
                                                        border={true}
                                                    />
                                                </div>
                                            )
                                        }

                                        {
                                            // Maison individuelle ou Bâtiment
                                            ['1', '4'].includes(buildingType) && (
                                                <div className="col-12 col-md-6">
                                                    <CheckboxService
                                                        handleCheckbox={sharedHouseWall}
                                                        values={values}
                                                        isValid={isValid}
                                                        setValues={setValues}
                                                        name={'sharedHouseWall'}
                                                        propName={values.sharedHouseWall}
                                                        title="Mur mitoyen (accolé à une autre maison) ?"
                                                        emptyLabel={true}
                                                        border={true}
                                                    />
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div
                                        className={
                                            'row justify-content-center' +
                                            (['outsideExteriorWallInsulation', 'insideExteriorWallInsulation'].includes(values.customExteriorWallInsulation)
                                                ? ' mb-3'
                                                : '')
                                        }
                                    >
                                        <label htmlFor="customExteriorWallInsulation" className="form-label">
                                            Isolation des murs extérieurs<sup>*</sup>
                                        </label>
                                        <div className="col-12 col-sm-6 col-md-4 mb-3 mb-md-0">
                                            <div className="form-check image-form-check">
                                                <label
                                                    className={
                                                        'form-check-label' +
                                                        (values.customExteriorWallInsulation === 'withoutExteriorWallInsulation' ? ' selected' : '')
                                                    }
                                                    htmlFor="withoutExteriorWallInsulation"
                                                >
                                                    <span>Pas d'isolation</span>
                                                    <img src={withoutInsulation} className="img-fluid" alt={`Pas d'isolation`} />
                                                </label>
                                                <input
                                                    className="form-check-input m-0"
                                                    type="radio"
                                                    name="customExteriorWallInsulation"
                                                    id="withoutExteriorWallInsulation"
                                                    value={values.customExteriorWallInsulation}
                                                    checked={values.customExteriorWallInsulation === 'withoutExteriorWallInsulation'}
                                                    onChange={() => {
                                                        storageService.setAuditValue(6, isValid, 'exteriorWallInsulationPresence', false);
                                                        storageService.setAuditValue(6, isValid, 'exteriorWallInsulationExterior', false);
                                                        // Réinitialisation des champs associés
                                                        storageService.removeAuditValue(6, 'exteriorWallInsulationThickness');
                                                        storageService.removeAuditValue(6, 'exteriorWallInsulationResistance');
                                                        storageService.removeAuditValue(6, 'exteriorWallInsulationAge', true);
                                                        setValues({
                                                            ...values,
                                                            customExteriorWallInsulation: 'withoutExteriorWallInsulation',
                                                            exteriorWallInsulationThickness: null,
                                                            exteriorWallInsulationResistance: '',
                                                            exteriorWallInsulationAge: '',
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-4 mb-3 mb-md-0">
                                            <div className="form-check image-form-check">
                                                <label
                                                    className={
                                                        'form-check-label' +
                                                        (values.customExteriorWallInsulation === 'outsideExteriorWallInsulation' ? ' selected' : '')
                                                    }
                                                    htmlFor="outsideExteriorWallInsulation"
                                                >
                                                    <span>Isolation par l'extérieur</span>
                                                    <img src={withInsulationExterior} className="img-fluid" alt={`Isolation par l'extérieur`} />
                                                </label>
                                                <input
                                                    className="form-check-input m-0"
                                                    type="radio"
                                                    name="customExteriorWallInsulation"
                                                    id="outsideExteriorWallInsulation"
                                                    value={values.customExteriorWallInsulation}
                                                    checked={values.customExteriorWallInsulation === 'outsideExteriorWallInsulation'}
                                                    onChange={() => {
                                                        setValues({
                                                            ...values,
                                                            customExteriorWallInsulation: 'outsideExteriorWallInsulation',
                                                        });
                                                        storageService.setAuditValue(6, isValid, 'exteriorWallInsulationPresence', true);
                                                        storageService.setAuditValue(6, isValid, 'exteriorWallInsulationExterior', true);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-4">
                                            <div className="form-check image-form-check">
                                                <label
                                                    className={
                                                        'form-check-label' +
                                                        (values.customExteriorWallInsulation === 'insideExteriorWallInsulation' ? ' selected' : '')
                                                    }
                                                    htmlFor="insideExteriorWallInsulation"
                                                >
                                                    <span>Isolation par l'intérieur</span>
                                                    <img src={withInsulationInside} className="img-fluid" alt={`Isolation par l'intérieur`} />
                                                </label>
                                                <input
                                                    className="form-check-input m-0"
                                                    type="radio"
                                                    name="customExteriorWallInsulation"
                                                    id="insideExteriorWallInsulation"
                                                    value={values.customExteriorWallInsulation}
                                                    checked={values.customExteriorWallInsulation === 'insideExteriorWallInsulation'}
                                                    onChange={() => {
                                                        setValues({
                                                            ...values,
                                                            customExteriorWallInsulation: 'insideExteriorWallInsulation',
                                                        });
                                                        storageService.setAuditValue(6, isValid, 'exteriorWallInsulationPresence', true);
                                                        storageService.setAuditValue(6, isValid, 'exteriorWallInsulationExterior', false);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {['outsideExteriorWallInsulation', 'insideExteriorWallInsulation'].includes(values.customExteriorWallInsulation) && (
                                        <div className="row">
                                            <div className="col-12 col-md-4 mb-3 mb-md-0">
                                                <label htmlFor="exteriorWallInsulationThickness" className="form-label">
                                                    Épaisseur de l’isolant<sup>*</sup>
                                                    <div className="help-text" data-tip data-for="helpExteriorWallInsulationThickness">
                                                        <IconHelp fill="#E69736" width={14} height={14} className="icon" />
                                                    </div>
                                                </label>
                                                <div className="input-group">
                                                    <input
                                                        type="number"
                                                        className={
                                                            'form-control' +
                                                            (values.exteriorWallInsulationThickness || values.exteriorWallInsulationThickness === 0
                                                                ? ' filled'
                                                                : '') +
                                                            (touched.exteriorWallInsulationThickness && errors.exteriorWallInsulationThickness
                                                                ? ' invalid'
                                                                : '') +
                                                            (!values.exteriorWallInsulationThickness ? ' required' : '')
                                                        }
                                                        placeholder="15"
                                                        min={0}
                                                        id="exteriorWallInsulationThickness"
                                                        onBlur={(event: any) => {
                                                            handleBlur(event);
                                                            // Vérification de la valeur saisie & affichage modal confirmation
                                                            if (
                                                                parseInt(values.exteriorWallInsulationThickness) < 5 ||
                                                                parseInt(values.exteriorWallInsulationThickness) > 20
                                                            ) {
                                                                confirmInsulationThickness(event.target.value, { start: 5, end: 20 }).then(
                                                                    (response: boolean) => {
                                                                        // Suppression des données
                                                                        if (!response) {
                                                                            setValues({
                                                                                ...values,
                                                                                exteriorWallInsulationThickness: null,
                                                                                exteriorWallInsulationResistance: '',
                                                                            });
                                                                            storageService.removeAuditValue(6, 'exteriorWallInsulationThickness');
                                                                            storageService.removeAuditValue(6, 'exteriorWallInsulationResistance');
                                                                        }
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                        value={values.exteriorWallInsulationThickness || ''}
                                                        onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                        onChange={(event) => {
                                                            updateValue(event, handleChange, isValid, 6, 'exteriorWallInsulationThickness');
                                                            // Calcul du total
                                                            setValues({
                                                                ...values,
                                                                exteriorWallInsulationThickness: event.target.value,
                                                                exteriorWallInsulationResistance: (parseFloat(event.target.value) * 0.25).toString(),
                                                            });
                                                            // Injection dans local storage
                                                            storageService.setAuditValue(
                                                                6,
                                                                isValid,
                                                                'exteriorWallInsulationResistance',
                                                                (parseFloat(event.target.value) * 0.25).toString()
                                                            );
                                                        }}
                                                    />
                                                    <span className="input-group-text">cm</span>
                                                </div>
                                                {touched.exteriorWallInsulationThickness && errors.exteriorWallInsulationThickness && (
                                                    <div className="invalid-feedback">{errors.exteriorWallInsulationThickness}</div>
                                                )}
                                                <ReactTooltip id="helpExteriorWallInsulationThickness" place="bottom" type={'light'} effect={'solid'}>
                                                    <h3>Épaisseur de l’isolation :</h3>
                                                    <p>L’épaisseur de l’isolant des murs extérieurs est généralement comprise entre 5cm et 20cm.</p>
                                                </ReactTooltip>
                                            </div>
                                            <div className="col-12 col-md-4 mb-3 mb-md-0">
                                                <label htmlFor="exteriorWallInsulationResistance" className="form-label">
                                                    Résistance thermique
                                                </label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className={'form-control'}
                                                        readOnly={true}
                                                        placeholder="2.5"
                                                        id="exteriorWallInsulationResistance"
                                                        onBlur={handleBlur}
                                                        value={values.exteriorWallInsulationResistance || ''}
                                                    />
                                                    <span className="input-group-text">m²/kW</span>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <label htmlFor="exteriorWallInsulationAge" className="form-label">
                                                    Année des travaux d’isolation<sup>*</sup>
                                                </label>
                                                <Select
                                                    value={renderDefaultValueSelect(floorInsulationAgeList, values.exteriorWallInsulationAge)}
                                                    options={floorInsulationAgeList}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    isMulti={false}
                                                    inputId="exteriorWallInsulationAge"
                                                    onBlur={handleBlur}
                                                    isDisabled={auditSent}
                                                    onChange={(event) => {
                                                        storageService.setAuditValue(6, isValid, 'exteriorWallInsulationAge', event.value, event.label);
                                                        setValues({
                                                            ...values,
                                                            exteriorWallInsulationAge: event.value,
                                                        });
                                                    }}
                                                    className={
                                                        'basic-single' +
                                                        (values.exteriorWallInsulationAge ? ' filled' : '') +
                                                        (touched.exteriorWallInsulationAge && errors.exteriorWallInsulationAge ? ' invalid' : '') +
                                                        (!values.exteriorWallInsulationAge ? ' required' : '')
                                                    }
                                                    placeholder={storageService.getAudit().step2.houseAge.value || 2001}
                                                    styles={colourStyles}
                                                />
                                                {touched.exteriorWallInsulationAge && errors.exteriorWallInsulationAge && (
                                                    <div className="invalid-feedback">{errors.exteriorWallInsulationAge}</div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </fieldset>
                            </div>
                        </div>
                        {(storageService.getAudit().step2.projectType.value === '1' || storageService.getAudit().step2.projectType.value === '4') &&
                            !domTomCheck(zipCode) && (
                                <div className="card mb-5">
                                    <div className="card-title">
                                        <IconWall fill="#76ca4f" width={40} height={40} className="icon" />
                                        <h2>Caractéristiques des murs sur local non-chauffé</h2>
                                        {values.unheatedRoomPresence && (
                                            <AddStepPhotos
                                                prop={buildingPictureUnheatedRoom}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                fieldValue={'buildingPictureUnheatedRoom'}
                                                disabled={auditSent}
                                            />
                                        )}
                                    </div>
                                    <div className="card-body">
                                        <fieldset className="w-100" disabled={auditSent}>
                                            <div className={'row' + (['1', 1, true].includes(values.unheatedRoomPresence === '') ? ' mb-3' : '')}>
                                                <div className="col-12 col-md-6 mb-3 mb-md-0" style={{ minHeight: 72 }}>
                                                    <CheckboxService
                                                        handleCheckbox={unheatedRoomPresence}
                                                        values={values}
                                                        isValid={isValid}
                                                        setValues={setValues}
                                                        name={'unheatedRoomPresence'}
                                                        propName={values.unheatedRoomPresence}
                                                        title="L’habitation comporte des murs sur local non chauffé ?"
                                                        emptyLabel={true}
                                                        border={true}
                                                    />
                                                </div>
                                                {['1', 1, true].includes(values.unheatedRoomPresence) && (
                                                    <div className="col-12 col-md-6">
                                                        <label htmlFor="unheatedRoomComposition" className="form-label">
                                                            Composition<sup>*</sup>
                                                        </label>
                                                        <Select
                                                            value={renderDefaultValueSelect(unheatedRoomCompositionList, values.unheatedRoomComposition)}
                                                            options={unheatedRoomCompositionList}
                                                            isClearable={false}
                                                            isSearchable={false}
                                                            isMulti={false}
                                                            inputId="unheatedRoomComposition"
                                                            onBlur={handleBlur}
                                                            isDisabled={auditSent}
                                                            onChange={(event) => {
                                                                storageService.setAuditValue(6, isValid, 'unheatedRoomComposition', event.value, event.label);
                                                                setValues({
                                                                    ...values,
                                                                    unheatedRoomComposition: event.value,
                                                                });
                                                            }}
                                                            className={
                                                                'basic-single' +
                                                                (values.unheatedRoomComposition ? ' filled' : '') +
                                                                (touched.unheatedRoomComposition && errors.unheatedRoomComposition ? ' invalid' : '') +
                                                                (!values.unheatedRoomComposition ? ' required' : '')
                                                            }
                                                            placeholder={'Choisissez la composition des murs sur local non-chauffé'}
                                                            styles={colourStyles}
                                                        />
                                                        {touched.unheatedRoomComposition && errors.unheatedRoomComposition && (
                                                            <div className="invalid-feedback">{errors.unheatedRoomComposition}</div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            {['1', 1, true].includes(values.unheatedRoomPresence) && (
                                                <div>
                                                    <div
                                                        className={
                                                            'row justify-content-center' +
                                                            (['outsideUnheatedRoomInsulation', 'insideUnheatedRoomInsulation'].includes(
                                                                values.customUnheatedRoomInsulation
                                                            )
                                                                ? ' mb-3'
                                                                : '')
                                                        }
                                                    >
                                                        <label htmlFor="customUnheatedRoomInsulation" className="form-label">
                                                            Isolation des murs extérieurs<sup>*</sup>
                                                        </label>
                                                        <div className="col-12 col-sm-6 col-md-4 mb-3 mb-md-0">
                                                            <div className="form-check image-form-check">
                                                                <label
                                                                    className={
                                                                        'form-check-label' +
                                                                        (values.customUnheatedRoomInsulation === 'withoutUnheatedRoomInsulation'
                                                                            ? ' selected'
                                                                            : '')
                                                                    }
                                                                    htmlFor="withoutUnheatedRoomInsulation"
                                                                >
                                                                    <span>Pas d'isolation</span>
                                                                    <img src={withoutInsulation} className="img-fluid" alt={`Pas d'isolation`} />
                                                                </label>
                                                                <input
                                                                    className="form-check-input m-0"
                                                                    type="radio"
                                                                    name="customUnheatedRoomInsulation"
                                                                    id="withoutUnheatedRoomInsulation"
                                                                    value={values.customUnheatedRoomInsulation}
                                                                    checked={values.customUnheatedRoomInsulation === 'withoutUnheatedRoomInsulation'}
                                                                    onChange={() => {
                                                                        storageService.setAuditValue(6, isValid, 'unheatedRoomInsulationPresence', false);
                                                                        storageService.setAuditValue(6, isValid, 'unheatedRoomInsulationExterior', false);
                                                                        // Réinitialisation des champs associés
                                                                        storageService.removeAuditValue(6, 'unheatedRoomInsulationThickness');
                                                                        storageService.removeAuditValue(6, 'unheatedRoomInsulationResistance');
                                                                        storageService.removeAuditValue(6, 'unheatedRoomInsulationAge');
                                                                        setValues({
                                                                            ...values,
                                                                            customUnheatedRoomInsulation: 'withoutUnheatedRoomInsulation',
                                                                            unheatedRoomInsulationThickness: null,
                                                                            unheatedRoomInsulationResistance: '',
                                                                            unheatedRoomInsulationAge: '',
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-md-4 mb-3 mb-md-0">
                                                            <div className="form-check image-form-check">
                                                                <label
                                                                    className={
                                                                        'form-check-label' +
                                                                        (values.customUnheatedRoomInsulation === 'outsideUnheatedRoomInsulation'
                                                                            ? ' selected'
                                                                            : '')
                                                                    }
                                                                    htmlFor="outsideUnheatedRoomInsulation"
                                                                >
                                                                    <span>Isolation par l'extérieur</span>
                                                                    <img src={withInsulationExterior} className="img-fluid" alt={`Isolation par l'extérieur`} />
                                                                </label>
                                                                <input
                                                                    className="form-check-input m-0"
                                                                    type="radio"
                                                                    name="customUnheatedRoomInsulation"
                                                                    id="outsideUnheatedRoomInsulation"
                                                                    value={values.customUnheatedRoomInsulation}
                                                                    checked={values.customUnheatedRoomInsulation === 'outsideUnheatedRoomInsulation'}
                                                                    onChange={() => {
                                                                        setValues({
                                                                            ...values,
                                                                            customUnheatedRoomInsulation: 'outsideUnheatedRoomInsulation',
                                                                        });
                                                                        storageService.setAuditValue(6, isValid, 'unheatedRoomInsulationPresence', true);
                                                                        storageService.setAuditValue(6, isValid, 'unheatedRoomInsulationExterior', true);
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-6 col-md-4">
                                                            <div className="form-check image-form-check">
                                                                <label
                                                                    className={
                                                                        'form-check-label' +
                                                                        (values.customUnheatedRoomInsulation === 'insideUnheatedRoomInsulation'
                                                                            ? ' selected'
                                                                            : '')
                                                                    }
                                                                    htmlFor="insideUnheatedRoomInsulation"
                                                                >
                                                                    <span>Isolation par l'intérieur</span>
                                                                    <img src={withInsulationInside} className="img-fluid" alt={`Isolation par l'intérieur`} />
                                                                </label>
                                                                <input
                                                                    className="form-check-input m-0"
                                                                    type="radio"
                                                                    name="customUnheatedRoomInsulation"
                                                                    id="insideUnheatedRoomInsulation"
                                                                    value={values.customUnheatedRoomInsulation}
                                                                    checked={values.customUnheatedRoomInsulation === 'insideUnheatedRoomInsulation'}
                                                                    onChange={() => {
                                                                        setValues({
                                                                            ...values,
                                                                            customUnheatedRoomInsulation: 'insideUnheatedRoomInsulation',
                                                                        });
                                                                        storageService.setAuditValue(6, isValid, 'unheatedRoomInsulationPresence', true);
                                                                        storageService.setAuditValue(6, isValid, 'unheatedRoomInsulationExterior', false);
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {['outsideUnheatedRoomInsulation', 'insideUnheatedRoomInsulation'].includes(
                                                        values.customUnheatedRoomInsulation
                                                    ) && (
                                                        <div className="row">
                                                            <div className="col-12 col-md-4 mb-3 mb-md-0">
                                                                <label htmlFor="unheatedRoomInsulationThickness" className="form-label">
                                                                    Épaisseur de l’isolant<sup>*</sup>
                                                                    <div className="help-text" data-tip data-for="helpUnheatedRoomInsulationThickness">
                                                                        <IconHelp fill="#E69736" width={14} height={14} className="icon" />
                                                                    </div>
                                                                </label>
                                                                <div className="input-group">
                                                                    <input
                                                                        type="number"
                                                                        className={
                                                                            'form-control' +
                                                                            (values.unheatedRoomInsulationThickness ||
                                                                            values.unheatedRoomInsulationThickness === 0
                                                                                ? ' filled'
                                                                                : '') +
                                                                            (touched.unheatedRoomInsulationThickness && errors.unheatedRoomInsulationThickness
                                                                                ? ' invalid'
                                                                                : '') +
                                                                            (!values.unheatedRoomInsulationThickness ? ' required' : '')
                                                                        }
                                                                        placeholder="15"
                                                                        min={0}
                                                                        id="unheatedRoomInsulationThickness"
                                                                        onBlur={(event: any) => {
                                                                            handleBlur(event);
                                                                            // Vérification de la valeur saisie & affichage modal confirmation
                                                                            if (
                                                                                parseInt(values.unheatedRoomInsulationThickness) < 5 ||
                                                                                (parseInt(values.unheatedRoomInsulationThickness) > 12 &&
                                                                                    parseInt(values.unheatedRoomInsulationThickness) <= 50)
                                                                            ) {
                                                                                confirmInsulationThickness(event.target.value, { start: 5, end: 15 }).then(
                                                                                    (response: boolean) => {
                                                                                        // Suppression des données
                                                                                        if (!response) {
                                                                                            setValues({
                                                                                                ...values,
                                                                                                unheatedRoomInsulationThickness: null,
                                                                                                unheatedRoomInsulationResistance: '',
                                                                                            });
                                                                                            storageService.removeAuditValue(
                                                                                                6,
                                                                                                'unheatedRoomInsulationThickness'
                                                                                            );
                                                                                            storageService.removeAuditValue(
                                                                                                6,
                                                                                                'unheatedRoomInsulationResistance'
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                );
                                                                            }
                                                                        }}
                                                                        value={values?.unheatedRoomInsulationThickness || ''}
                                                                        onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                                        onChange={(event) => {
                                                                            updateValue(event, handleChange, isValid, 6, 'unheatedRoomInsulationThickness');
                                                                            // Calcul du total
                                                                            setValues({
                                                                                ...values,
                                                                                unheatedRoomInsulationThickness: event.target.value,
                                                                                unheatedRoomInsulationResistance: (
                                                                                    parseFloat(event.target.value) * 0.25
                                                                                ).toString(),
                                                                            });
                                                                            // Injection dans local storage
                                                                            storageService.setAuditValue(
                                                                                6,
                                                                                isValid,
                                                                                'unheatedRoomInsulationResistance',
                                                                                (parseFloat(event.target.value) * 0.25).toString()
                                                                            );
                                                                        }}
                                                                    />
                                                                    <span className="input-group-text">cm</span>
                                                                </div>
                                                                {touched.unheatedRoomInsulationThickness && errors.unheatedRoomInsulationThickness && (
                                                                    <div className="invalid-feedback">{errors.unheatedRoomInsulationThickness}</div>
                                                                )}
                                                                <ReactTooltip
                                                                    id="helpUnheatedRoomInsulationThickness"
                                                                    place="bottom"
                                                                    type={'light'}
                                                                    effect={'solid'}
                                                                >
                                                                    <h3>Épaisseur de l’isolation :</h3>
                                                                    <p>
                                                                        L’épaisseur de l’isolant des murs sur local non-chauffé est généralement comprise entre
                                                                        5cm et 15cm.
                                                                    </p>
                                                                </ReactTooltip>
                                                            </div>
                                                            <div className="col-12 col-md-4 mb-3 mb-md-0">
                                                                <label htmlFor="unheatedRoomInsulationResistance" className="form-label">
                                                                    Résistance thermique
                                                                </label>
                                                                <div className="input-group">
                                                                    <input
                                                                        type="text"
                                                                        className={'form-control'}
                                                                        readOnly={true}
                                                                        placeholder="2.5"
                                                                        id="unheatedRoomInsulationResistance"
                                                                        onBlur={handleBlur}
                                                                        value={values.unheatedRoomInsulationResistance || ''}
                                                                    />
                                                                    <span className="input-group-text">m²/kW</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4">
                                                                <label htmlFor="unheatedRoomInsulationAge" className="form-label">
                                                                    Année des travaux d’isolation<sup>*</sup>
                                                                </label>
                                                                <Select
                                                                    value={renderDefaultValueSelect(floorInsulationAgeList, values.unheatedRoomInsulationAge)}
                                                                    options={floorInsulationAgeList}
                                                                    isClearable={false}
                                                                    isSearchable={false}
                                                                    isMulti={false}
                                                                    inputId="unheatedRoomInsulationAge"
                                                                    onBlur={handleBlur}
                                                                    isDisabled={auditSent}
                                                                    onChange={(event) => {
                                                                        storageService.setAuditValue(
                                                                            6,
                                                                            isValid,
                                                                            'unheatedRoomInsulationAge',
                                                                            event.value,
                                                                            event.label
                                                                        );
                                                                        setValues({
                                                                            ...values,
                                                                            unheatedRoomInsulationAge: event.value,
                                                                        });
                                                                    }}
                                                                    className={
                                                                        'basic-single' +
                                                                        (values.unheatedRoomInsulationAge ? ' filled' : '') +
                                                                        (touched.unheatedRoomInsulationAge && errors.unheatedRoomInsulationAge
                                                                            ? ' invalid'
                                                                            : '') +
                                                                        (!values.unheatedRoomInsulationAge ? ' required' : '')
                                                                    }
                                                                    placeholder={storageService.getAudit().step2.houseAge.value || 2001}
                                                                    styles={colourStyles}
                                                                />
                                                                {/* <input type="number"
                                                       min={getLocalStorageCurrentAgent().step2.houseAge.value}
                                                       max={(new Date().getFullYear())}
                                                       className={'form-control' + ((values.unheatedRoomInsulationAge || values.unheatedRoomInsulationAge === 0) ? ' filled' : '') + (touched.unheatedRoomInsulationAge && errors.unheatedRoomInsulationAge ? ' invalid' : '') + (!values.unheatedRoomInsulationAge ? ' required' : '')}
                                                       placeholder={getLocalStorageCurrentAgent().step2.houseAge.value || 2001}
                                                       disabled={!getLocalStorageCurrentAgent().step2.houseAge.value}
                                                       name="unheatedRoomInsulationAge"
                                                       id="unheatedRoomInsulationAge"
                                                       onBlur={handleBlur}
                                                       value={values.unheatedRoomInsulationAge || ''}
                                                       onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                       onChange={(event) => updateValue(event, handleChange, isValid, 6, 'unheatedRoomInsulationAge')}
                                                    /> */}
                                                                {touched.unheatedRoomInsulationAge && errors.unheatedRoomInsulationAge && (
                                                                    <div className="invalid-feedback">{errors.unheatedRoomInsulationAge}</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </fieldset>
                                    </div>
                                </div>
                            )}

                        <div className="navigation text-center">
                            <button className={'btn btn-secondary maxi-min-width mx-2 mb-3'} onClick={() => returnToHome(6, '/audit')}>
                                Retour au menu principal
                            </button>
                            <button
                                type="button"
                                onClick={(event: any) => {
                                    event.preventDefault();
                                    if (Object.entries(errors).length !== 0) {
                                        // Préparation liste d'étapes en erreur
                                        let errorsList: string[] = [];
                                        Object.keys(errors).forEach((field: any) => {
                                            // @ts-ignore
                                            if (typeof errors[field] === 'object') {
                                                // @ts-ignore
                                                errors[field].forEach((element: any) => {
                                                    for (let key in element) {
                                                        errorsList = [...errorsList, element[key]];
                                                    }
                                                });
                                            } else {
                                                // @ts-ignore
                                                errorsList = [...errorsList, `${errors[field]}`];
                                            }
                                        });
                                        // Affichage message
                                        AlertSwal.fire({
                                            title: `Champs manquants`,
                                            html: `<p class="mb-0">Vous ne pouvez pas valider l’étape en cours car certains champs n’ont pas été saisis ou sont en erreur:<br/>
                                        <span>${errorsList.toString().replace(/(,)/g, '<br/>')}</span></p>`,
                                            cancelButtonText: 'Continuer la saisie',
                                            showConfirmButton: false,
                                            showCancelButton: true,
                                            focusCancel: true,
                                            showClass: {
                                                popup: 'errors',
                                                backdrop: 'swal2-backdrop-show swal2-backdrop-black',
                                            },
                                            customClass: {
                                                cancelButton: 'btn btn-primary maxi-min-width',
                                            },
                                        });
                                    } else {
                                        validateThenRoute(6, ROUTE_AUDIT_OUVRANT);
                                    }
                                }}
                                className={'btn btn-green maxi-min-width mx-2 mb-3'}
                            >
                                Étape suivante
                            </button>
                        </div>
                    </div>
                );
            }}
        </Formik>
    );
};

export default EnvelopeInformations;
