import React, { useEffect, useState } from 'react';
import { FieldArray, FieldArrayRenderProps, Formik, validateYupSchema, yupToFormErrors } from 'formik';
import {
    FormValues,
    electricityMeterAmpsList,
    heatPumpList,
    externalGroupList,
    wallTypeList,
    soilTypeList,
    externalGroupSetupList,
    HeatingCircuit,
    panelDisabled,
} from './PompeAChaleurEnums';
import { validationSchema } from './schema/schema';
import { PropertyType } from '../../../services/localStorageService';
import * as storageService from '../../../services/localStorageService';

import Select from 'react-select';
import { renderDefaultValueSelect } from '../../../services/tools/selectValue';
import CheckboxTriState from '../../../components/checkbox/TriStateCheckbox';
import HeatingCircuitFields from './component/HeatingCircuitFields';
import { panelStyles } from './PompeAChaleurEnums';
import { Link } from 'react-router-dom';
import { ROUTE_PV_POMPE_A_CHALEUR } from '../../../routing/paths';
import { LabelledString } from '../../../services/tools/TypeHelper';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { currentRouteAtom, nextPackageRouteSelector, contextPackagesSelector } from '../../../services/Recoil/Atom/PackageContext.atom';
import { useHistory } from 'react-router';

import Radiators from './component/Radiators';

// Icons
import { ReactComponent as IconBDC } from '../../../assets/icons/simulator/icon-recapitulatif.svg';
import { ReactComponent as IconPlus } from '../../../assets/icons/pre-visite/icon-plus.svg';
import { ReactComponent as IconHeat } from '../../../assets/icons/pre-visite/icon-heat.svg';
import { ReactComponent as IconRadiator } from '../../../assets/icons/pre-visite/icon-radiator.svg';
// TODO : ce import créé une ref circulaire. Trouver mieux que la dupli de def.
// import { PAC_AA_START, PAC_AE_START } from '../../../services/calculs/filterPreconisation/filterChauffage';
export const PAC_AA_START = 'PAC-AA';
export const PAC_AE_START = 'PAC-AE';

const PompeAChaleurPV: React.FC = () => {
    const [currentPac, setCurrentPac] = useState<string | null>(null);
    const history = useHistory();
    const setCurrent = useSetRecoilState(currentRouteAtom);
    const nextRoute = useRecoilValue(nextPackageRouteSelector);

    // Context packages
    const contextPacks = useRecoilValue(contextPackagesSelector);

    useEffect(() => {
        window.scrollTo(0, 0);
        setCurrent(ROUTE_PV_POMPE_A_CHALEUR);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Pac Air ou Eau
    useEffect(() => {
        const extractPacType = (reference: string | undefined): string | null => {
            if (!reference) return null;

            if (reference.startsWith(PAC_AA_START)) {
                return 'air';
            } else if (reference.startsWith(PAC_AE_START)) {
                return 'eau';
            } else {
                return null;
            }
        };

        let pacType: string | null = null;
        for (const item of contextPacks!) {
            const extractedType = extractPacType(item.reference);
            if (extractedType) {
                pacType = extractedType;
                break;
            }
        }

        setCurrentPac(pacType);
    }, [contextPacks]);

    // Set currentPac default value into localStorage
    useEffect(() => {
        if (currentPac && currentPac === 'eau') {
            storageService.setPrevisitValue('externalGroupSetup', 'sols');
        }
    }, [currentPac]);

    const defaultCircuit: HeatingCircuit = { type: '', source: '', temperature: '' };
    // Default values
    const [crawl, setCrawl] = useState<boolean | ''>('');
    const [ampere, setAmpere] = useState<string>('');

    // Previsit
    const preVisite = storageService.getPrevisit();

    // Steps from Audit
    const step3 = storageService.getAudit().step3;
    const step6 = storageService.getAudit().step6;

    // ElectricityAmpMeters default value
    const powerFromStep3 = Number(step3.heaterGeneratorPower.value * 5);

    // CrawlSpace default value
    const floorTypeFromStep6 = step6.floorType.value;

    // Default value
    useEffect(() => {
        // ElectricityAmpMeters
        if (!preVisite.electricityMeterAmps.value) {
            setAmpere(powerFromStep3.toString());
            storageService.setPrevisitValue('electricityMeterAmps', powerFromStep3.toString());
        }

        // CrawlSpace
        if (!preVisite.crawlSpace.value) {
            if (floorTypeFromStep6 === '2') {
                setCrawl(true);
                storageService.setPrevisitValue('crawlSpace', true);
            } else {
                setCrawl(false);
                storageService.setPrevisitValue('crawlSpace', false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // leave empty

    const initialValues: FormValues = {
        electricityMeterAmps: !preVisite.electricityMeterAmps.value
            ? ampere
            : storageService.checkPropertyExistThenCreateOrRenderPreVisite(
                  'electricityMeterAmps',
                  'Ampèrage compteur électrique (Ampère)',
                  PropertyType['basic']
              ),
        heatPump: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'heatPump',
            "Temperature de l'eau de la chaudière actuelle (°C)",
            PropertyType['basic']
        ),
        heatingInPlaceRetained: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'heatingInPlaceRetained',
            'Chauffage en place à conserver',
            PropertyType['boolean']
        ),
        externalGroup: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'externalGroup',
            'Orientation du mur où est installé le groupe extérieur',
            PropertyType['basic']
        ),
        wallType: storageService.checkPropertyExistThenCreateOrRenderPreVisite('wallType', 'Type de mur', PropertyType['basic']),
        soilTypeSetup: storageService.checkPropertyExistThenCreateOrRenderPreVisite('soilTypeSetup', 'Type de sol', PropertyType['basic']),
        externalGroupSetup:
            currentPac && currentPac === 'eau'
                ? 'sols'
                : storageService.checkPropertyExistThenCreateOrRenderPreVisite('externalGroupSetup', 'Installation du groupe extérieur', PropertyType['basic']),
        crawlSpace: !preVisite.crawlSpace.value
            ? crawl
            : storageService.checkPropertyExistThenCreateOrRenderPreVisite('crawlSpace', 'Vide sanitaire', PropertyType['boolean']),
        heatingCircuit: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'heatingCircuit',
            'Circuit de chauffage désiré',
            PropertyType['heatingCircuit']
        ),
        identicalRadiators: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'identicalRadiators',
            "Les radiateurs de l'habitation sont-ils identiques",
            PropertyType['boolean']
        ),
        numberOfRadiator: storageService.checkPropertyExistThenCreateOrRenderPreVisite('numberOfRadiator', 'Nombre de radiateur(s)', PropertyType['number']),
        radiatorCircuit: storageService.checkPropertyExistThenCreateOrRenderPreVisite('radiatorCircuit', 'Radiateurs', PropertyType['radiatorCircuit']),
    };

    return (
        <Formik
            validateOnMount={true}
            initialValues={initialValues}
            // validationSchema={validationSchema}
            validate={(value) => {
                try {
                    validateYupSchema(value, validationSchema, true, value);
                } catch (e) {
                    return yupToFormErrors(e);
                }
            }}
            enableReinitialize
            onSubmit={() => {}}
        >
            {({ values, errors, touched, setValues, handleChange, handleBlur, setFieldValue }) => {
                // Debug
                // console.log('Values:', values);
                // console.log('Errors:', errors);
                return (
                    <div className="container">
                        <h1 className="main-title-mini">Pompe à chaleur</h1>

                        <div className="card card-audit-simulator pv mb-5">
                            <div className="card-header">
                                <IconBDC />
                                <h2>Informations générales</h2>
                            </div>

                            <div className="card-body">
                                <div className="row justify-content-center mb-3">
                                    <div className="col-12 col-md-4 mb-3">
                                        <div className="form-group">
                                            <label htmlFor="electricityMeterAmps">
                                                Ampèrage compteur électrique (Ampère)<sup>*</sup>
                                            </label>
                                            <Select
                                                value={renderDefaultValueSelect(electricityMeterAmpsList, values.electricityMeterAmps)}
                                                name="electricityMeterAmps"
                                                id="electricityMeterAmps"
                                                options={electricityMeterAmpsList}
                                                placeholder="Ampèrage compteur électrique (Ampère)"
                                                onChange={(event: LabelledString | null) => {
                                                    if (event) {
                                                        setValues({
                                                            ...values,
                                                            electricityMeterAmps: event.value,
                                                        });

                                                        storageService.setPrevisitValue('electricityMeterAmps', event.value, event.label);
                                                    }
                                                }}
                                                styles={panelStyles}
                                                isSearchable={false}
                                                isMulti={false}
                                                isClearable={false}
                                                className={
                                                    'basic-single' +
                                                    (values.electricityMeterAmps ? ' filled' : '') +
                                                    (touched.electricityMeterAmps && errors.electricityMeterAmps ? ' invalid' : '') +
                                                    (!values.electricityMeterAmps ? ' required' : '')
                                                }
                                            />
                                            {touched.electricityMeterAmps && errors.electricityMeterAmps && (
                                                <span className="invalid-feedback">{errors.electricityMeterAmps}</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4 mb-3">
                                        <div className="form-group">
                                            <label htmlFor="heatPump">
                                                Temperature de l'eau de la chaudière actuelle (°C)<sup>*</sup>
                                            </label>
                                            <Select
                                                value={renderDefaultValueSelect(heatPumpList, values.heatPump)}
                                                options={heatPumpList}
                                                onChange={(event: LabelledString | null) => {
                                                    if (event) {
                                                        setValues({
                                                            ...values,
                                                            heatPump: event.value,
                                                        });

                                                        storageService.setPrevisitValue('heatPump', event.value, event.label);
                                                    }
                                                }}
                                                name="heatPump"
                                                isMulti={false}
                                                isClearable={false}
                                                isSearchable={false}
                                                placeholder={"Temperature de l'eau de la chaudière actuelle"}
                                                styles={panelStyles}
                                                onBlur={handleBlur}
                                                className={
                                                    'basic-single' +
                                                    (values.heatPump ? ' filled' : '') +
                                                    (touched.heatPump && errors.heatPump ? ' invalid' : '') +
                                                    (!values.heatPump ? ' required' : '')
                                                }
                                            />
                                            {touched.heatPump && errors.heatPump && <span className="invalid-feedback">{errors.heatPump}</span>}
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <CheckboxTriState
                                                name="heatingInPlaceRetained"
                                                title="Chauffage en place à conserver"
                                                emptyLabel={true}
                                                onCustomChange={(e: boolean) => {
                                                    storageService.setPrevisitValue('heatingInPlaceRetained', e);
                                                }}
                                                style={{ height: '43px' }}
                                            />
                                            {touched.heatingInPlaceRetained && errors.heatingInPlaceRetained && (
                                                <span className="invalid-feedback">{errors.heatingInPlaceRetained}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-12 col-md-4 mb-3">
                                        <div className="form-group">
                                            <label htmlFor="externalGroupSetup">
                                                Positionnement du groupe extérieur<sup>*</sup>
                                            </label>
                                            <Select
                                                value={renderDefaultValueSelect(externalGroupSetupList, values.externalGroupSetup)}
                                                options={externalGroupSetupList}
                                                onChange={(event: LabelledString | null) => {
                                                    if (!event) return;

                                                    // Update state with a functional update that takes the previous state and returns the next state.
                                                    setValues((prevState) => {
                                                        let newState = { ...prevState };

                                                        switch (event.value) {
                                                            case 'sols':
                                                                // Update state properties based on the selected value.
                                                                newState = {
                                                                    ...newState,
                                                                    externalGroupSetup: event.value,
                                                                    wallType: '',
                                                                };
                                                                // Set local storage values for updated state properties.
                                                                storageService.removePrevisitValue('wallType', true);
                                                                storageService.removePrevisitValue('externalGroup', true);
                                                                break;
                                                            case 'murs':
                                                                // Update state properties based on the selected value.
                                                                newState = {
                                                                    ...newState,
                                                                    externalGroupSetup: event.value,
                                                                    soilTypeSetup: '',
                                                                    externalGroup: '',
                                                                };
                                                                // Set local storage values for updated state properties.
                                                                storageService.removePrevisitValue('soilTypeSetup', true);
                                                                storageService.removePrevisitValue('externalGroup', true);
                                                                break;
                                                            default:
                                                                // Update state properties based on the selected value.
                                                                newState = {
                                                                    ...newState,
                                                                    externalGroupSetup: event.value,
                                                                };
                                                                break;
                                                        }
                                                        // Set local storage value for the selected option.
                                                        storageService.setPrevisitValue('externalGroupSetup', event.value, event.label);

                                                        return newState;
                                                    });
                                                }}
                                                isMulti={false}
                                                isClearable={false}
                                                isSearchable={false}
                                                placeholder={'Endroit où sera installé le groupe extérieur'}
                                                styles={currentPac === 'eau' ? panelDisabled : panelStyles}
                                                onBlur={handleBlur}
                                                className={
                                                    'basic-single' +
                                                    (values.externalGroupSetup ? ' filled' : '') +
                                                    (touched.externalGroupSetup && errors.externalGroupSetup ? ' invalid' : '') +
                                                    (!values.externalGroupSetup ? ' required' : '')
                                                }
                                                isDisabled={currentPac === 'eau'}
                                            />
                                            {touched.externalGroupSetup && errors.externalGroupSetup && (
                                                <span className="invalid-feedback">{errors.externalGroupSetup}</span>
                                            )}
                                        </div>
                                    </div>

                                    {values.externalGroupSetup === 'murs' && (
                                        <div className="col-12 col-md-4 mb-3">
                                            <div className="form-group">
                                                <label htmlFor="wallType">
                                                    Type de murs où sera installé le groupe extérieur<sup>*</sup>
                                                </label>
                                                <Select
                                                    value={renderDefaultValueSelect(wallTypeList, values.wallType)}
                                                    options={wallTypeList}
                                                    onChange={(event: LabelledString | null) => {
                                                        if (event) {
                                                            setValues({
                                                                ...values,
                                                                wallType: event.value,
                                                            });

                                                            storageService.setPrevisitValue('wallType', event.value, event.label);
                                                        }
                                                    }}
                                                    isMulti={false}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    placeholder={'Type de mur'}
                                                    styles={panelStyles}
                                                    onBlur={handleBlur}
                                                    className={
                                                        'basic-single' +
                                                        (values.wallType ? ' filled' : '') +
                                                        (touched.wallType && errors.wallType ? ' invalid' : '') +
                                                        (!values.wallType ? ' required' : '')
                                                    }
                                                />
                                                {touched.wallType && errors.wallType && <span className="invalid-feedback">{errors.wallType}</span>}
                                            </div>
                                        </div>
                                    )}

                                    {values.externalGroupSetup === 'sols' && (
                                        <div className="col-12 col-md-4 mb-3">
                                            <div className="form-group">
                                                <label htmlFor="soilTypeSetup">
                                                    Type de sol où sera installé le groupe extérieur<sup>*</sup>
                                                </label>
                                                <Select
                                                    value={renderDefaultValueSelect(soilTypeList, values.soilTypeSetup)}
                                                    options={soilTypeList}
                                                    onChange={(event: LabelledString | null) => {
                                                        if (event) {
                                                            setValues({
                                                                ...values,
                                                                soilTypeSetup: event.value,
                                                            });

                                                            storageService.setPrevisitValue('soilTypeSetup', event.value, event.label);
                                                        }
                                                    }}
                                                    isMulti={false}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    placeholder={'Type de sol'}
                                                    styles={panelStyles}
                                                    onBlur={handleBlur}
                                                    className={
                                                        'basic-single' +
                                                        (values.soilTypeSetup ? ' filled' : '') +
                                                        (touched.soilTypeSetup && errors.soilTypeSetup ? ' invalid' : '') +
                                                        (!values.soilTypeSetup ? ' required' : '')
                                                    }
                                                />
                                                {touched.wallType && errors.wallType && <span className="invalid-feedback">{errors.wallType}</span>}
                                            </div>
                                        </div>
                                    )}
                                    <div className="col-12 col-md-4 mb-3">
                                        <div className="form-group">
                                            <label htmlFor="externalGroup">
                                                Orientation du mur où est installé le groupe extérieur<sup>*</sup>
                                            </label>
                                            <Select
                                                value={renderDefaultValueSelect(externalGroupList, values.externalGroup)}
                                                options={externalGroupList}
                                                onChange={(event: LabelledString | null) => {
                                                    if (event) {
                                                        setValues({
                                                            ...values,
                                                            externalGroup: event.value,
                                                        });

                                                        storageService.setPrevisitValue('externalGroup', event.value, event.label);
                                                    }
                                                }}
                                                name="externalGroup"
                                                isMulti={false}
                                                isClearable={false}
                                                isSearchable={false}
                                                placeholder={'Orientation du mur où est installé le groupe extérieur'}
                                                styles={panelStyles}
                                                onBlur={handleBlur}
                                                className={
                                                    'basic-single' +
                                                    (values.externalGroup ? ' filled' : '') +
                                                    (touched.externalGroup && errors.externalGroup ? ' invalid' : '') +
                                                    (!values.externalGroup ? ' required' : '')
                                                }
                                            />
                                            {touched.externalGroup && errors.externalGroup && <span className="invalid-feedback">{errors.externalGroup}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <CheckboxTriState
                                                name="crawlSpace"
                                                title="Vide sanitaire"
                                                emptyLabel={true}
                                                onCustomChange={(e: boolean) => {
                                                    storageService.setPrevisitValue('crawlSpace', e);
                                                }}
                                                style={{ minHeight: '43px' }}
                                            />
                                            {touched.crawlSpace && errors.crawlSpace && <span className="invalid-feedback">{errors.externalGroupSetup}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card card-audit-simulator pv mb-5">
                            <div className="card-header">
                                <IconHeat width="60" height="60" fill="white" />
                                <h2>Circuit(s) de chauffage existant(s)</h2>
                            </div>

                            <div className="card-body">
                                <>
                                    <div className="row">
                                        <div className="card-body--inner">
                                            <FieldArray name="heatingCircuit">
                                                {(arrayHelpers: FieldArrayRenderProps) => {
                                                    const hasEmptyField = values.heatingCircuit.some((heatingCircuit: HeatingCircuit) =>
                                                        Object.values(heatingCircuit).some((field) => !field)
                                                    );

                                                    return (
                                                        <>
                                                            {values.heatingCircuit.map((_, index) => {
                                                                return (
                                                                    <HeatingCircuitFields
                                                                        key={index}
                                                                        index={index}
                                                                        remove={arrayHelpers.remove}
                                                                        values={values}
                                                                        setFieldValue={setFieldValue}
                                                                    />
                                                                );
                                                            })}
                                                            <div className="btn-grp justify-content-end mt-5 p-circuit-btn">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-ajouter-circuit"
                                                                    disabled={hasEmptyField}
                                                                    onClick={() => {
                                                                        arrayHelpers.push(defaultCircuit);
                                                                    }}
                                                                >
                                                                    <div className="icon">
                                                                        <IconPlus />
                                                                    </div>
                                                                    Ajouter un circuit
                                                                </button>
                                                            </div>
                                                        </>
                                                    );
                                                }}
                                            </FieldArray>
                                            {touched.heatingCircuit && errors.heatingCircuit && (
                                                <span className="invalid-feedback">{errors.heatingCircuit}</span>
                                            )}
                                        </div>
                                    </div>
                                </>
                            </div>
                        </div>

                        <div className="card card-audit-simulator pv mb-5">
                            <div className="card-header">
                                <IconRadiator width="60" height="60" fill="white" />
                                <h2>Radiateurs</h2>
                            </div>

                            <div className="card-body">
                                <Radiators values={values} onChange={handleChange} errors={errors} touched={touched} setValues={setValues} />
                            </div>
                        </div>

                        <div className="btn-grp justify-content-end">
                            <button type="button" onClick={() => history.goBack()} className="btn btn-retour">
                                Retour
                            </button>

                            <Link to={nextRoute} className={`btn btn-continue${Object.entries(errors).length > 0 ? ' disabled' : ''}`}>
                                Continuer
                            </Link>
                        </div>
                    </div>
                );
            }}
        </Formik>
    );
};

export default PompeAChaleurPV;
