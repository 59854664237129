import { getData, PackageEntry, PackagesEntries } from '../apiParticulierService';
import { stepListToAuditAndClient } from '../localStorageService';
import * as storageService from '../localStorageService';
import { parseCurrency, Writable } from '../tools/TypeHelper';
import { getAideInvestissement, getPanneauFromPackage, PanneauPhotovoltaique } from './calculPhotovoltaique';
import { createSite, initializeInstallationPV, InstallationPV } from './installationPV';
import { icollPackageOutput, packIsPV, PackageData, PackageData_PaneauPhotovoltaique, isBattery, packIsMMA } from './package';
import { extractProducts } from './products';
import * as pvgis from '../pvgis/api';
import { isDeadLineAideOk } from './ticket';
import { createShortScenarioNameForRA } from './aides/aideRenovationDAmpleurDefs';

// readme :
//
// Ce fichier est structurant pour une bonne partie de cette page :/simulator/recommandation
//   Soyons vigilent avec les modifs
//   Certains typages et certaines fonctions sont inutiles d'un point de vue fonctionnel,
//   ET sont uniquement destinés a faire péter la compilation, en cas de défaut d'écriture de code. (Order par exemple)
//
// Ce fichier est un peu long et je m'en excuse.
//   la longueur est légèrement liée aux commentaires, mais pas seulement.
//   Je n'ai aps trouvé judicieux de le séparer, ce qui devrait favoriser la maintenance des commentaires.
//   SVP : faites en sorte que les commentaires soient maintenus !!
//   Pour faciliter il est découpées en 'régions'
//
// Un produit icoll contient
//   - des informations produit (prix, catégories etc,)
//   - des information d'économie d'énergie (le cas échéant) pour permettre les calculs
//
// Un package est l'assemblage de 1 OU 2 produits.
//    - dans le cas ou il y aurait deux produit l'un est 'la pose' de l'autre.
//
// pour des raisons d'interfaces UI, les produits sont organisés dans deux niveaux de themes.
//      - theme.
//      - sous theme
//    Côté icoll il n'y a qu'un niveau. Le deuxième niveau est créé artificiellement ici, grâce aux '/' contenus dans les nom des themes icoll.
//
// Il ne faut pas confondre les themes et les catégories.
//    Les catégories de produit sont des classements techniques (utilisé pour les calculs par exemple)
//    Les themes et les sous-themes sont des rangements visuels dédié à notre usage.
//    Il se trouve les catégories correspondent quand même aux themes, mais c'est un hazard, et cela pourait changer.
//
// En cas d'évolution
//    La liste des themes et sous-themes (nommé ici complexTheme) est issue du retour de la route icoll
//    'https://preprod.icoll.fr/api-v1/listes?agentCode=VA-999-33&tb_name=liste_theme_demat'
//    si le résultat de la route change == de nouveaux themes sont ajoutés par Ylan (par exemple)
//    Tout continuera de marcher,
//        mais les nouveaux Themes arriveront à la fin (dernier onglet lors de l'affichage)
//        mais les nouveaux subThemes arriveront à la fin (en bas de page lors de l'affichage)
//        mais les nouveaux Themes arriveront à la fin (lors des calculs)
//        mais les nouveaux subThemes arriveront à la fin (lors de des calculs)
//     Lorsque le code sera mis à jour, tous les types sont dynamiques, donc mettre à jour la liste   ALL_COMPLEX_THEMES_FROM_ICALL, suffit à mettre à jour tous les typages.
//     La seule exception sont les Orders. Mais les concernant une erreur sera levée grâce au garde fou nommé checkThemeOrder
//

//#region typage complexe pour retranscrire les noms des themes et subthemes avec la sécurité typescript

const ComplexThemeTypesNames = [
    "Economie d'énergie/Photovoltaïque",
    "Economie d'énergie/Chauffage",
    "Economie d'énergie/Eau chaude sanitaire",
    "Economie d'énergie/Isolation",
    "Economie d'énergie/Ventilation",
    "Economie d'énergie/Changement comportemental",
    "Economie d'énergie/Batterie",
    "Economie d'énergie/Porte et fenêtre",
    "S'agrandir/Veranda",
    "S'agrandir/Pergola",
    "S'agrandir/Ma piéce en plus",
    "S'agrandir/Carport",
    'Toiture/Nettoyage-refection',
    "Services/Mon contrat d'énergie",
    'Services/Assurance habitation',
    'Services/Alarme et sécurité',
    'Mobilité/Voiture électrique',
    'Mobilité/Moto électrique',
    'Mobilité/Borne de recharge',
    'Confort/Piscine',
    'Confort/Eclairage exterieur',
    'Confort/Portail',
    'Services/Garantie de revenu solaire',
] as const;

export type ComplexThemeType = (typeof ComplexThemeTypesNames)[number];

//const ComplexThemeTypesNames: ComplexThemeType[] = Object.values(ALL_COMPLEX_THEMES_FROM_ICALL);
const WritableComplexThemeTypesNames = ComplexThemeTypesNames as Writable<typeof ComplexThemeTypesNames> as Array<string>;

type ThemeExtractor<T extends ComplexThemeType> = T extends `${infer A}/${string}` ? A : never;
type SubThemeExtractor<T extends ComplexThemeType> = T extends `${string}/${infer SUB}` ? SUB : never;

export type ThemeType = ThemeExtractor<`${ComplexThemeType}`>;
export type SubThemeType = SubThemeExtractor<`${ComplexThemeType}`>;

// useless type for the next real type.
type SubThemeOfThemeExtractor<CPLX extends ComplexThemeType, T extends ThemeType> = CPLX extends `${T}/${infer A}` ? A : never;
export type SubThemesOf<T extends ThemeType> = SubThemeOfThemeExtractor<`${ComplexThemeType}`, T>;
type AllSubThemesOf = { [T in ThemeType]: SubThemesOf<T> };

// This :
export type ThemeOfEconomie = SubThemesOf<"Economie d'énergie">;
// type ThemeOfSagrandir = SubThemesOf<"S'agrandir">;
// type ThemeOfToiture = SubThemesOf<'Toiture'>;
// type ThemeOfServices = SubThemesOf<'Services'>;
// type ThemeOfMobilite = SubThemesOf<'Mobilité'>;
// type ThemeOfConfort = SubThemesOf<'Confort'>;

// Is equivalent to :
// type AllThemesOfEconomie = AllSubThemesOf["Economie d'énergie"];
// type AllThemesOfSagrandir = AllSubThemesOf["S'agrandir"];
// type AllThemesOfToiture = AllSubThemesOf['Toiture'];
// type AllThemesOfServices = AllSubThemesOf['Services'];
// type AllThemesOfMobilite = AllSubThemesOf['Mobilité'];
// type AllThemesOfConfort = AllSubThemesOf['Confort'];

//#endregion

//#region type guard

// type guards are mainly base on

export const isComplexThemeType = (candidate: string): candidate is ComplexThemeType => {
    return WritableComplexThemeTypesNames.includes(candidate);
};
export const isThemeType = (candidate: string): candidate is ThemeType => {
    return themeTypeNames.includes(candidate);
};
export const isSubThemeType = (candidate: string): candidate is SubThemeType => {
    for (const complex of WritableComplexThemeTypesNames) {
        const split = complex.split('/');
        if (split.length >= 2 && split[1] === candidate) return true;
    }
    return false;
};
export const isSubThemeOfTheme = (theme: ThemeType, subTheme: SubThemeType): boolean => {
    const complexCandidate = theme + '/' + subTheme;
    return isComplexThemeType(complexCandidate);
};

//#endregion

//#region names of themes and subThemes

// Types names, calculated from ALL_COMPLEX_THEMES_FROM_ICALL or WritableComplexThemeTypesNames
// used mainly in type guards.

const themeTypeNames: Array<string> = ((): Array<string> => {
    const result = new Array<string>();

    for (const complex of WritableComplexThemeTypesNames) {
        const split = complex.split('/');
        if (split.length > 0 && split[0] !== undefined) {
            if (!result.includes(split[0])) result.push(split[0]);
        }
    }

    return result;
})();

// Useless now, but let just in case ...
// const subThemeTypeNames: Array<string> = ((): Array<string> => {
//     const result = new Array<string>();

//     for (const complex of WritableComplexThemeTypesNames) {
//         const split = complex.split('/');
//         if (split.length > 1 && split[1] !== undefined) result.push(split[1]);
//     }

//     return result;
// })();

const getSubThemeTypeNamesOf = (theme: ThemeType): Array<string> => {
    const result = new Array<string>();

    for (const complex of WritableComplexThemeTypesNames) {
        const split = complex.split('/');
        if (split.length > 1 && split[1] !== undefined) {
            if (split[0] === theme) result.push(split[1]);
        }
    }

    return result;
};

const subThemesTypeNamesOf: { [theme in ThemeType]: Array<string> } = ((): { [theme in ThemeType]: Array<string> } => {
    const result: Partial<{ [theme in ThemeType]: Array<string> }> = {};
    for (const theme of themeTypeNames) {
        result[theme as ThemeType] = getSubThemeTypeNamesOf(theme as ThemeType);
    }
    return result as { [theme in ThemeType]: Array<string> };
})();

//#endregion

//#region typage des structures 'complexes' pour ranger les donnees de packages

/**
 * Theme is a named collection of package.
 */
export type SubTheme = {
    /** the subThemeType == identifier of the subTheme. It can be a string for subTheme that are not yet implemented in code.
     * If a subTheme is not implemented (== declared in code), the string type is used.
     */
    subThemeType: SubThemeType | string;
    packages: Array<PackageData>;
};

/** return all package ID of all package marked as 'applicable'. */
export const getSelectedPackagesId = (subThemes: Array<SubTheme>): Array<string> => {
    const result: Array<string> = [];
    for (const tab of subThemes) for (const pack of tab.packages) if (pack.applicable) result.push(pack.id);
    return result;
};

/**
 * this methode convert  ThemeByKey (indexed collection of Theme) to a simple array of theme.
 * @param subThemes
 * @returns
 */
export const toSubThemeArray = (subThemes: SubThemeByKey): Array<SubTheme> => {
    const array = Array<SubTheme>();
    for (const themeId of Object.keys(subThemes)) {
        array.push(subThemes[themeId]);
    }
    return array;
};

export type Theme = {
    themeType: ThemeType;
    subThemeByKey?: SubThemeByKey;
    subThemes?: Array<SubTheme>;
};

/**
 * list of Theme indexed by key.
 * This is used to dispatch Theme by types, when parsing getPackage Web Service.
 */
type ThemeByKey = {
    [themeKey: string]: Theme;
};

/**
 * list of theme indexed by key.
 * This is used to dispatch theme by types, when parsing getPackage Web Service.
 */
type SubThemeByKey = {
    [subThemeKey: string]: SubTheme;
};

/**
 * Appelle le WS get package de icoll et reconstruit l'arborescence de package rangé par theme et themes.
 * @returns un array de themes, ordonné selon notre affichage.
 */
export const getThemes = async (): Promise<Array<Theme>> => {
    try {
        let response = await getData(`preco/packages`);

        if (!response) {
            console.log('response undefined');
            return [];
        }
        if (response.status !== 'OK') {
            console.log('response status KO : ' + response.status);
            return [];
        }
        // Si le code de statut n'est pas dans la plage [200, 299]
        if (response.status === 201 || response.status === 204) {
            console.log('response empty');
            return [];
        }
        const result: ThemeByKey = {};
        const keys = Object.keys(response.response.data);

        // TRY PVGIS :
        // Avant c'était dans la boucle, mais c'etait catastrophique pour les perf.
        // on l'a sorti de la boucle, pour mettre le résultat en commun.
        // Il se trouve qe la puissance recherchée est propotionnelle à la puissance du panneau.
        const audit = stepListToAuditAndClient().audit;
        const site = createSite(audit);
        const PVcalcReportNormal = await pvgis.PVcalc(
            site.geoPosition.lat,
            site.geoPosition.lng,
            1, // pour 1Kwh
            100, // l'efficacité sera traité dans l'installationPV.
            site.inclinaison, // inclinaison
            pvgis.toAzimut(site.orientation) // orientation
        );

        for (const key of keys) {
            try {
                const icollPack: icollPackageOutput = response.response.data[key];
                const { mainProduct, poseProduct } = extractProducts(icollPack.produits);
                const reference = icollPack['ref'];
                // continue == ignore that package, when
                if (!reference || reference === '') continue;
                if (Object.keys(mainProduct).length === 0) continue;

                const complexTheme_id = icollPack['theme_id'];
                const complexTheme_lb = icollPack['theme_lb'];

                const priceTtc = icollPack.prix_vente_ttc && !isNaN(parseCurrency(icollPack.prix_vente_ttc)) ? parseCurrency(icollPack.prix_vente_ttc) : 0;
                const priceHt = icollPack.prix_vente_ht && !isNaN(parseCurrency(icollPack.prix_vente_ht)) ? parseCurrency(icollPack.prix_vente_ht) : 0;

                const pack: PackageData = {
                    themeId: complexTheme_id,
                    themeLb: complexTheme_lb,
                    title: icollPack.nom,
                    id: icollPack.id,
                    reference,
                    applicable: false,

                    mainProduct,
                    poseProduct,

                    puissance: undefined,
                    // quantity: icollPack.quantite,
                    //quantiteVariable: poseProduct === undefined,

                    priceHt,
                    priceTtc,
                    totalHelp: 0,
                    totalHelpDeduced: priceTtc,
                };

                if (packIsPV(pack)) {
                    //if (isComplexThemeType(pack.themeLb) && pack.themeLb.includes('Photovoltaïque')) {

                    const panneau = getPanneauFromPackage(pack);
                    // ceci contient un appel a l'api pvgis !!!
                    pack.installationPV = await initializeInstallationPV(panneau, PVcalcReportNormal);
                    pack.potentialAideInvestissement = await getAideInvestissement(pack.installationPV);
                    pack.totalHelp = pack.installationPV.modeConsommation === 'ReventeSurplus' ? -pack.potentialAideInvestissement : 0;
                    pack.totalHelpDeduced = pack.priceTtc + pack.totalHelp;
                    //console.log('pack.installation.autoconsomée = ' + pack.installationPV.energieAutoconsommee());
                }

                if (packIsMMA(pack)) {
                    const deadLineOK = isDeadLineAideOk();
                    const mmaActivated = storageService.getConfig('customerFlowParameters').operationsCommerciales.remiseMMA;
                    if (mmaActivated && deadLineOK) {
                        pack.totalHelp = -pack.priceTtc;
                        pack.totalHelpDeduced = pack.priceTtc + pack.totalHelp;
                    }
                }
                pack.scenarioNameForRA = createShortScenarioNameForRA(pack);

                const themeParts = complexTheme_lb.split('/');
                const themeName = themeParts.length === 1 ? "Economie d'énergie" : themeParts[0];
                const subThemeName = themeParts.length === 1 ? themeParts[0] : themeParts[1];

                if (!result[themeName]) {
                    result[themeName] = {
                        themeType: themeName as ThemeType,
                        subThemeByKey: {},
                    };
                }
                if (!result[themeName].subThemeByKey![subThemeName]) {
                    result[themeName].subThemeByKey![subThemeName] = {
                        subThemeType: subThemeName,
                        packages: new Array<PackageData>(),
                    };
                }
                result[themeName].subThemeByKey![subThemeName].packages.push(pack);
            } catch (error) {
                console.error(error);
                // continue, this package has fail. je sais aps pourquoi je m'en doutais ...
            }
        }
        // change structure so simple array
        const arrayResult = toThemeArray(result);

        // for (const theme of arrayResult) {
        //     for (const subTheme of theme.subThemes!) {
        //         for (const pack of subTheme.packages) {
        //             if (pack && pack.reference && (pack.reference.includes('Pergo') || pack.reference.includes('Carpot'))) {
        //                 console.log('pergo et carpot breadcrump' + pack.mainProduct.breadcrumb);
        //             }
        //         }
        //     }
        // }

        // reorder array for display
        return reorderThemes(arrayResult, displayThemeOrder, displayOrder);
    } catch (error) {
        console.error(error);
        throw error;
    }
};

// Dans le process de getThemes, on créé un tableau indexé, ici, on le transforme en simple array
const toThemeArray = (themes: ThemeByKey): Array<Theme> => {
    const array = Array<Theme>();
    for (const themeId of Object.keys(themes)) {
        let theme = themes[themeId];
        theme.subThemes = toSubThemeArray(theme.subThemeByKey!);
        array.push(theme);
    }
    return array;
};

//#endregion

//#region chercher dans les themes

export const getSelectedPackages = (themes: Array<Theme>): Array<string> => {
    const result: Array<string> = [];
    for (const theme of themes) for (const subTheme of theme.subThemes!) for (const pack of subTheme.packages) if (pack.applicable) result.push(pack.id);
    return result;
};
export const getSelectedPackagesWithQty = (themes: Array<Theme>): PackagesEntries => {
    const result: PackagesEntries = [];
    for (const theme of themes)
        for (const subTheme of theme.subThemes!) for (const pack of subTheme.packages) if (pack.applicable) result.push(getPackageWithQty(pack));
    return result;
};

export const getPackageWithQty = (pack: PackageData): PackageEntry => {
    const result: PackageEntry = {
        package_id: pack.id,
        produits: [
            {
                product_id: +pack.mainProduct.product_id,
                quantite: '' + pack.mainProduct.quantite,
                surface: '' + pack.mainProduct.quantite,
            },
        ],
    };
    if (pack.poseProduct) {
        // Si il y a un produit de pose, la pose comprend tout et ne contient pas de surface !
        result.produits.push({
            product_id: +pack.poseProduct.product_id,
            quantite: '' + pack.poseProduct.quantite,
            surface: '0',
        });
    }

    return result;
};

export const getFirstPackPV = (themes: Array<Theme>): PackageData_PaneauPhotovoltaique | undefined => {
    for (const theme of themes)
        if (theme.themeType === "Economie d'énergie")
            for (const subTheme of theme.subThemes!)
                if (subTheme.subThemeType === 'Photovoltaïque')
                    for (const pack of subTheme.packages) {
                        if (pack.applicable && packIsPV(pack)) return pack;
                    }

    return undefined;
};

export const getFirstPackMMA = (themes: Array<Theme>): PackageData | undefined => {
    for (const theme of themes)
        if (theme.themeType === 'Services')
            for (const subTheme of theme.subThemes!)
                if (subTheme.subThemeType === 'Garantie de revenu solaire')
                    for (const pack of subTheme.packages) {
                        if (pack.applicable) return pack;
                    }

    return undefined;
};

export const getFirstPackBattery = (themes: Array<Theme>): PackageData | undefined => {
    for (const theme of themes)
        if (theme.themeType === "Economie d'énergie")
            for (const subTheme of theme.subThemes!)
                if (subTheme.subThemeType === 'Batterie')
                    for (const pack of subTheme.packages) {
                        if (pack.applicable && isBattery(pack)) {
                            console.log(' batterie selectionnée : ' + pack.reference + '  ' + pack.puissance);
                            return pack;
                        }
                    }

    return undefined;
};
export const getFirstPanneau = (themes: Array<Theme>): PanneauPhotovoltaique | undefined => {
    const pack = getFirstPackPV(themes);
    if (!pack || !pack.mainProduct || !pack.mainProduct.quantite) return undefined;
    return getPanneauFromPackage(pack);
};

export const getFirstInstallation = (themes: Array<Theme>): InstallationPV | undefined => {
    const pack = getFirstPackPV(themes);
    return pack ? pack.installationPV : undefined;
};

export const REGEX_ASSURANCE_MMA = /assurancemma/;

export const hasInsurance = (themes: Array<Theme>): boolean => {
    for (const theme of themes) {
        for (const subTheme of theme.subThemes!) {
            for (const pack of subTheme.packages) {
                if (!pack.applicable) continue;
                if (pack.reference.match(REGEX_ASSURANCE_MMA)) return true;
            }
        }
    }
    return false;
};
const TVA_MAX = 20;
export const hasFullTVA = (themes: Array<Theme>): boolean => {
    for (const theme of themes) {
        for (const subTheme of theme.subThemes!) {
            for (const pack of subTheme.packages) {
                if (!pack.applicable) continue;
                //console.log('TVA ' + pack.reference + ' : ' + pack.mainProduct.tva + '  /  ' + pack.poseProduct?.tva);
                if (pack.mainProduct.tva !== TVA_MAX) return false;
                if (pack.poseProduct && pack.poseProduct.tva !== TVA_MAX) return false;
            }
        }
    }
    return true;
};

//#endregion

//#region order of themes / subthemes.

/**
 * type representing order of Theme/SubTheme structure group
 */
// type SubThemeOrder = { [theme in ThemeType]: Array<SubThemesOf<theme>> };
type SubThemeOrder = { [theme in ThemeType]: Array<AllSubThemesOf[theme]> };

// WARNING
// this 2 const might become invalid if new theme are added in icoll definitions. (see readme at top)
/**
 * order of Theme for displaying
 */
const displayThemeOrder: Array<ThemeType> = ["Economie d'énergie", "S'agrandir", 'Toiture', 'Services', 'Mobilité', 'Confort'];
/**
 * order of Theme for calculating
 */
export const calculationThemeOrder: Array<ThemeType> = ["Economie d'énergie", "S'agrandir", 'Toiture', 'Services', 'Mobilité', 'Confort'];

// WARNING
// this const might become invalid if new theme or subTheme are added in icoll definitions. (see readme at top)
/**
 * ordered themes (in each Theme) for displaying
 */
export const displayOrder: SubThemeOrder = {
    "Economie d'énergie": [
        'Photovoltaïque',
        'Batterie',
        'Chauffage',
        'Eau chaude sanitaire',
        'Isolation',
        'Porte et fenêtre',
        'Ventilation',
        'Changement comportemental',
    ],
    "S'agrandir": ['Veranda', 'Pergola', 'Carport', 'Ma piéce en plus'],
    Toiture: ['Nettoyage-refection'],
    Services: ["Mon contrat d'énergie", 'Garantie de revenu solaire', 'Assurance habitation', 'Alarme et sécurité'],
    Mobilité: ['Voiture électrique', 'Moto électrique', 'Borne de recharge'],
    Confort: ['Piscine', 'Portail', 'Eclairage exterieur'],
};
//"S'agrandir/Carport"
// WARNING
// this const might become invalid if new theme or subTheme are added in icoll definitions. (see readme at top)
/**
 * ordered themes (in each Theme) for calculating
 */
export const caclulationOrder: SubThemeOrder = {
    "Economie d'énergie": [
        'Isolation',
        'Porte et fenêtre',
        'Chauffage',
        'Eau chaude sanitaire',
        'Ventilation',
        'Changement comportemental',
        'Photovoltaïque',
        'Batterie',
    ],
    "S'agrandir": ['Veranda', 'Pergola', 'Carport', 'Ma piéce en plus'],
    Toiture: ['Nettoyage-refection'],
    Services: ["Mon contrat d'énergie", 'Garantie de revenu solaire', 'Assurance habitation', 'Alarme et sécurité'],
    Mobilité: ['Voiture électrique', 'Moto électrique', 'Borne de recharge'],
    Confort: ['Piscine', 'Portail', 'Eclairage exterieur'],
};

/**
 * Check if order definitions are correct. Used to secure the code, not functionnal.
 * To be valid, all theme shloud be present once and only once.
 * in each theme, all theme should be presente once and only once.
 * WILL CRASH if ordre definition is not correct !
 * @param order order to check
 * @param nameOfCandidate for console logging which 'order' is not valid.
 */
export const checkThemeOrder = (order: SubThemeOrder, nameOfCandidate: string): void => {
    const themes = Object.keys(order).map((e) => e as ThemeType);
    checkArrayOfTheme(themes, nameOfCandidate);

    for (const theme of themes) {
        checkArrayOfSubTheme(theme, order[theme], nameOfCandidate + '.' + theme);
    }
};
/**
 * check if an array of Theme is valid as an order definition
 * WILL CRASH if not correct
 * @param candidate candidate to check
 * @param nameOfCandidate name of variable for log
 */
const checkArrayOfTheme = (candidate: Array<ThemeType>, nameOfCandidate: string): void => {
    if (themeTypeNames.length !== candidate.length)
        throw Error('Bad server initialisation, checkArrayOfTheme failed (bad number of element) for ' + nameOfCandidate);
    for (const element of themeTypeNames)
        if (!candidate.includes(element as ThemeType)) throw Error('Bad server initialisation, checkArrayOfTheme failed for ' + nameOfCandidate);
};
/**
 * check if an array of theme is valid as an order definition, for a given Theme
 * WILL CRASH if not correct
 * @param theme Theme of the candidate
 * @param candidate candidate to check
 * @param nameOfCandidate name of variable for log
 */
const checkArrayOfSubTheme = (theme: ThemeType, candidate: Array<SubThemeType>, nameOfCandidate: string): void => {
    const awaitedElement = subThemesTypeNamesOf[theme];
    if (awaitedElement.length !== candidate.length)
        throw Error('Bad server initialisation, checkArrayOfTheme failed (bad number of element) for ' + nameOfCandidate);
    for (const element of awaitedElement) {
        if (!candidate.includes(element as SubThemeType))
            throw Error('Bad server initialisation, checkArrayOfTheme failed for ' + nameOfCandidate + '.' + element + ' (missing)');
    }
};

/**
 * reorder Themes, and  according to a themeOrder.
 * @param themes list or Themes to re-order
 * @param themeOrder order of  theme (level 1 of Order)
 * @param subThemeOrder order of theme for each Themes.
 * @returns same structure reordered like ThemeOrder and themeOrder
 */
export const reorderThemes = (themes: Array<Theme>, themeOrder: Array<ThemeType>, subThemeOrder: SubThemeOrder): Array<Theme> => {
    const missing = new Array<Theme>();
    // not existing Theme == 'not official', are added at th end.
    for (const theme of themes) {
        if (!isThemeType(theme.themeType)) missing.push(theme);
    }

    const reordered = new Array<Theme>();
    for (const indexOrder of themeOrder) {
        const theme: Theme | undefined = themes.find((element) => element.themeType === indexOrder);
        if (theme) reordered.push(theme);
    }
    reordered.push(...missing);

    // reorder each theme of each theme
    for (const theme of reordered) {
        const order = subThemeOrder[theme.themeType];
        theme.subThemes = reorderSubThemes(theme.themeType, theme.subThemes!, order);
    }

    return reordered;
};

/**
 * reorder subTheme inside a Theme
 * @param themeType reorder
 * @param subThemes
 * @param order
 * @returns
 */
const reorderSubThemes = <T extends ThemeType>(themeType: T, subThemes: Array<SubTheme>, order: Array<SubThemesOf<T>>): Array<SubTheme> => {
    const missing = new Array<SubTheme>();
    for (const subTheme of subThemes) {
        if (!isSubThemeType(subTheme.subThemeType)) missing.push(subTheme);
    }
    const reordered = new Array<SubTheme>();
    for (const indexOrder of order) {
        const subTheme: SubTheme | undefined = subThemes.find((element) => element.subThemeType === indexOrder);
        if (subTheme) reordered.push(subTheme);
    }

    reordered.push(...missing);
    return reordered;
};
//#endregion

//#region objets vides ou absurdes pour initialiser les states react.

/**
 * List of theme and themes to initialise front UI. (whitout packages).
 */
export const EMPTY_THEMES: Array<Theme> = themeTypeNames.map((name) => {
    const themes = getSubThemeTypeNamesOf(name as ThemeType);
    const subThemes: Array<SubTheme> = themes.map((theme) => {
        return {
            subThemeType: theme as SubThemeType,
            packages: new Array<PackageData>(),
        } as SubTheme;
    });
    const theme: Theme = {
        themeType: name as ThemeType,
        subThemes: subThemes,
    };
    return theme;
});

//#endregion
