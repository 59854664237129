import { ROUTE_AUDIT } from '../../../routing/paths';
import { AuditType } from '../../../services/apiFlowService';

type Cards = {
    type: AuditType;
    title: string;
    url: string;
    icon: string;
    href?: boolean;
};

export const CreationCards: Cards[] = [
    {
        type: 'Expert',
        title: 'Audit mode expert',
        url: ROUTE_AUDIT,
        icon: 'icon-study',
    },
    {
        type: 'Thermique',
        title: 'Etude thermique',
        url: `${process.env.PUBLIC_URL}/audit-thermique/audit`, // reset param is used to reset audit on first navigation
        icon: 'icon-mail',
        href: true,
    },
];
