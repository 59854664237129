/**
 * Fonction pour retrouver la valeur de défaut d'un champ via une liste d'éléments
 * @param propertyList
 * @param value
 */

import { Labelled } from './TypeHelper';

export const renderDefaultValueSelect = (propertyList: any, value: any) => {
    return propertyList.filter((property: any) => {
        return property.value === value;
    });
};

export const renderDefaultValueSelectV2 = <T>(propertyList: Array<Labelled<T>>, value: T) => {
    return propertyList.find((element: Labelled<T>) => {
        return element.value === value;
    });
};

/**
 * How To Use
 *
 * <Select
 *  defaultValue={renderDefaultValueSelect(optionsList, values.propertyName)}
 *  options={optionsList}
 *  ...
 * />
 */
