import { filterMethod } from '../filterPreconisation';
import { PackageData } from '../package';
import { SubTheme } from '../theme';

// #region filter eauchaude / ballon

//Les BALLON sont BT-200L ou BT-250L- == BallonThermo-VolumeL-.
const BALLON_VOLUME_REGEX = /^BT-([0-9]+)L/;
const BALLON_POUR_3_OU_MOINS = 200;
const BALLON_POUR_4_OU_PLUS = 201;

export const isBallonThermo = (pack: PackageData): boolean => {
    const matchBT = pack.reference.match(BALLON_VOLUME_REGEX); // = /^BT-([0-9]+)L/;
    if (matchBT && matchBT.length >= 1) {
        return true;
    }
    return false;
};
const extractBallonVloume = (pack: PackageData): number => {
    const matchBT = pack.reference.match(BALLON_VOLUME_REGEX); // = /^BT-([0-9]+)L/;
    if (matchBT && matchBT.length >= 1) {
        const volume = matchBT[1]; // match[0] toute la chaine, match[1] premier grope de parentheèse ([0-9]+) match[1] le volume
        //console.log(pack.reference + ' => ' + volume);
        return +volume;
    }
    return 0;
};
const keepPour3ouMoins = (pack: PackageData): boolean => {
    if (!isBallonThermo(pack)) return false;
    const volume = extractBallonVloume(pack);
    if (volume <= BALLON_POUR_3_OU_MOINS) return true;
    // if(volume >= BALLON_POUR_4_OU_PLUS) return false;
    return false;
};
const keepPour4ouPlus = (pack: PackageData): boolean => {
    if (!isBallonThermo(pack)) return false;
    const volume = extractBallonVloume(pack);
    if (volume >= BALLON_POUR_4_OU_PLUS) return true;
    // if (volume <= BALLON_POUR_3_OU_MOINS) return false;
    return false;
};

const isBallonSolaire = (pack: PackageData): boolean => {
    // TODO : cette rgègle 'nest plus à jour.
    return pack.mainProduct.nom.toUpperCase().includes('SOLAIRE');
};
// const isBallonThermo = (pack: PackageData): boolean => {
//     return pack.mainProduct.nom.toUpperCase().includes('THERMODYNAMIQUE');
// };
const keepThermoSolaire = (pack: PackageData): boolean => {
    // doit retourner true pour garder, false pour supprimer.
    // TODO : vérifier, je pense qu'un package ne peut pas remplir les deux conditions à la fois.
    return isBallonThermo(pack) && isBallonSolaire(pack);
};
const selectFirstThermoSolaire = (theme: SubTheme): void => {
    // loop on all package, and select the first that match
    for (const pack of theme.packages) {
        if (isBallonSolaire(pack)) {
            pack.applicable = true;
            console.log('selection du ballon solaire : ' + pack.id + ' ' + pack.reference);

            return;
        }
    }
};
const selectFirstThermoNonSolaire = (subTheme: SubTheme): void => {
    // loop on all package, and select the first that match
    for (const pack of subTheme.packages) {
        if (!isBallonSolaire(pack)) {
            pack.applicable = true;
            //console.log('selection du ballon thermo : ' + pack.id + ' ' + pack.reference);
            return;
        }
    }
};

/**
 * filtrer les packages inutiles du theme 'eau chaude'. Le theme sera modifé et retourné
 * @param audit l'audit sur lequel on préconise
 * @param subTheme le theme dont le type doit être 'eau chaude'. Le paramètre sera modifé !
 * @returns le theme filtré.
 */
export const filterEauChaudeSanitaire: filterMethod = async (audit: any, subTheme: SubTheme): Promise<SubTheme> => {
    // console.log(
    //     'filterEauChaudeSanitaire : ' +
    //         JSON.stringify(
    //             subTheme.packages.map((e) => '   ' + e.reference),
    //             null,
    //             2
    //         )
    // );
    //console.log('passed in filterEauChaudeSanitaire');
    // si pas de package, on laisse tomber,c 'est déjà filtré !
    if (subTheme.packages.length === 0) return subTheme;
    // La règle :
    // A ce jour, dans l'audit,
    // on dspose de deux booleens :
    // - ballon thermo
    // - Ballon solaire
    // => 3 combinaison peuvent exister : (false,false) , (true, false) , (false, true)
    // => La combinaison (true, true) n'existe pas.

    // La règle :
    // Si la combinaison est (true, true)
    //     masquer le ballon thermodynamique, et afficher et selection le ballon thermodynamique solaire.
    // Sinon,
    //     - afficher selon le nombre d'occupants
    //     - Si solaire selectionner le premier thermodynamique Solaire
    //     - Si non solaire selectionner le premier thermodynamique

    // normalement pour ces deux variables, les combinaison possible sont aucun, l'un, l'autre. Mais PAS LES DEUX.
    const eauChaudeThermoDynSolaire = audit.heaterHotSolarWaterThermodynamic.value === true;
    const eauChaudeThermoDyn = audit.heaterHotWaterThermodynamic.value === true;

    if (eauChaudeThermoDynSolaire && eauChaudeThermoDyn) {
        // A DATE :  CE CAS N'ARRIVE PAS.
        // SI j'ai déjà un ballon thermodynamique solaire, je propose un (meilleur) ballon solaire, et j'ignore les autres :
        // retirer tous les packages concernant du Non-Solaire
        subTheme.packages = subTheme.packages.filter(keepThermoSolaire);
        // selectionner le premier solaire
        selectFirstThermoSolaire(subTheme);
    } else {
        const occupants = +audit.buildingPeople.value;
        //console.log('avant filtrage ballon : ' + subTheme.packages.length);
        if (occupants <= 3) {
            subTheme.packages = subTheme.packages.filter(keepPour3ouMoins);
        } else {
            //if (occupants >= 4)
            subTheme.packages = subTheme.packages.filter(keepPour4ouPlus);
        }
        //console.log('apres filtrage ballon : ' + subTheme.packages.length);

        //console.log('eauChaudeThermoDyn : ' + eauChaudeThermoDyn);

        // SI j'ai déjà un ballon thermodynamique non solaire
        // selectionner le premier solaire
        if (eauChaudeThermoDyn) selectFirstThermoSolaire(subTheme);
        // sinon (cas non thermo, ou solaire : selectionner un thermo non solaire)
        else selectFirstThermoNonSolaire(subTheme);
    }

    return subTheme;
};

//#endregion
